import RootStore from 'src/common/RootStore'
import screenRouter from 'src/api/screenRouter'
import { runInAction } from 'mobx'

import i18n from 'src/i18n'

import DomainStore from './DomainStore'
import Screen from '../models/Screen'
import { LogoBoxLocation } from '../models/LogoBoxLocation'
import ScreenConfiguration from '../models/ScreenConfiguration'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'

export default class ScreenStore extends DomainStore<Screen> {
    constructor(rootStore: RootStore) {
        super(rootStore)
        this.router = screenRouter
        this.storeName = 'Screen'
    }

    // Override refresh to fetch the list of controllers when refreshing the screen list
    refresh = async (subscriptionKey?: string, showDeleted?: boolean) => {
        await this.rootStore.controllerStore.refresh(subscriptionKey, showDeleted)
        return super.refresh(subscriptionKey, showDeleted)
    }

    resolutionsForScreens = (screens?: Screen[]): string[] => {
        const screenSet = new Set<string>()

        if (!screens) {
            screens = this.items
        }

        for (const screen of screens) {
            screenSet.add(screen.resolution!)
        }

        // NOTE: Sorts resolutions by highest pixel value and then X value
        return Array.from(screenSet.values()).sort((a, b): number => {
            const aSplit = a.split('x', 2)
            const bSplit = b.split('x', 2)
            const aX = Number(aSplit[0])
            const aY = Number(aSplit[1])
            const bX = Number(bSplit[0])
            const bY = Number(bSplit[1])
            const aPix = aX * aY
            const bPix = bX * bY
            if (aPix !== bPix) {
                return aPix - bPix
            }
            return aX - bX
        })
    }

    async populateScreenConfiguration(screenId: string): Promise<ScreenConfiguration> {
        const json = await screenRouter.populateScreenConfiguration(screenId)
        return new ScreenConfiguration(json)
    }

    setLogoBoxLocation = (screenId: string, location: LogoBoxLocation | null) => {
        screenRouter
            .setLogoBoxLocation(screenId, location)
            .then(() => {
                runInAction('updateLogoBoxLocation', () => {
                    // Update current screen config
                    this.rootStore.screenDiagnosticsUIStore.currentScreen!.logoBoxLocation = location
                })

                AppToaster.show({
                    message: i18n.t('feedback.successes.successfullySetLogoBoxLocation'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorSettingLogoBoxLocation'),
                    intent: Intent.DANGER,
                })
            })
    }
}
