import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import DiagnosticsErrorGroup from 'src/common/models/DiagnosticsErrorGroup'
import { ErrorSeverity, ErrorSource } from 'src/common/models/DiagnosticErrorEnums'
import { severityColour } from 'src/common/utils/Colour'
import tileIcon from 'src/assets/images/tileIcon.svg'
import controllerIcon from 'src/assets/images/controllerIcon.svg'
import cloudIcon from 'src/assets/images/cloudIcon.svg'

interface ErrorStatusIconProps {
    errorGroup: DiagnosticsErrorGroup
}

@inject('store')
@observer
class ErrorStatusIcon extends React.Component<{ store?: RootStore } & ErrorStatusIconProps> {
    render() {
        const errorGroup: DiagnosticsErrorGroup = this.props.errorGroup
        if (!errorGroup) {
            return null
        }

        let statusIconSrc
        switch (errorGroup.source) {
            case ErrorSource.tile:
                statusIconSrc = tileIcon
                break
            case ErrorSource.controller:
                statusIconSrc = controllerIcon
                break
            case ErrorSource.cloud:
                statusIconSrc = cloudIcon
                break
            default:
                break
        }

        return (
            <div
                className='error-status-icon'
                style={{
                    backgroundColor: severityColour(errorGroup.severity || ErrorSeverity.noError),
                }}
            >
                <img src={statusIconSrc} alt='Source' />
            </div>
        )
    }
}

export default ErrorStatusIcon
