import { rootStore } from '../RootStore'

import i18n from 'src/i18n'

import { ErrorDataKey } from '../models/DiagnosticErrorEnums'
import {
    getTemperatureCelsius,
    getPercentage,
    getHexString,
    millisecondsToString,
    millisecondsToSecondsString,
} from '../utils/Number'
import moment from 'moment'

const getDataForArray = (key: ErrorDataKey | string, values: Array<string | number>): string => values
        .map(v => {
            if (typeof v === 'string') {
                return getDataForString(key, v)
            }
            if (typeof v === 'number') {
                return getDataForNumber(key, v)
            }
            return ''
        })
        .join(', ')

const getDataForString = (key: ErrorDataKey | string, value: string): string => {
    switch (key) {
        case 'user':
            const user = rootStore.userStore.findItem(value)
            return user ? user.name ?? i18n.t('placeholders.unknownUser') : ''
        case ErrorDataKey.screenIds:
            return rootStore.screenStore.findItem(value)?.name ?? value
        case ErrorDataKey.version:
        case ErrorDataKey.versions:
        case ErrorDataKey.tnls:
        case ErrorDataKey.exe:
        case ErrorDataKey.fault:
        case ErrorDataKey.input:
            return value
        default:
            const timestamp = moment(value, moment.ISO_8601, true)
            if (timestamp.isValid()) {
                // Check if data is valid ISO8601, if so then format appropriately
                return timestamp.format(rootStore.userStore.me?.dateTimeFormat)
            } else {
                // If key is not in the switch statement, it is unknown so we return an empty string
                return ''
            }
    }
}

const getDataForNumber = (key: ErrorDataKey | string, value: number): string => {
    switch (key) {
        case ErrorDataKey.temperature:
            return getTemperatureCelsius(value)
        case ErrorDataKey.timeout:
            return millisecondsToSecondsString(value)
        case ErrorDataKey.load:
            return getPercentage(value)
        case ErrorDataKey.actualAvg:
        case ErrorDataKey.targetAvg:
            return millisecondsToString(value)
        case ErrorDataKey.version:
        case ErrorDataKey.versions:
            return getHexString(value)
        default:
            return String(value)
    }
}

export const getDataString = (key: ErrorDataKey | string, data: string | number | Array<string | number>): string => {
    if (Array.isArray(data)) {
        return getDataForArray(key, data)
    }
    if (typeof data === 'string') {
        return getDataForString(key, data)
    }
    if (typeof data === 'number') {
        return getDataForNumber(key, data)
    }
    return data ? String(data) : i18n.t('placeholders.missingData')
}
