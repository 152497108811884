import { observable, action, computed, runInAction } from 'mobx'

import i18n from 'src/i18n'

import ScreenDiagnosticsUIStore from './ScreenDiagnosticsUIStore'

import DiagnosticsError from 'src/common/models/DiagnosticsError'
import DiagnosticsErrorLog from 'src/common/models/DiagnosticsErrorLog'
import { ErrorSeverity, ErrorCode, errorCodeSeverity } from 'src/common/models/DiagnosticErrorEnums'

import errorLogRouter, { ScreenErrorHistorySearchParams } from 'src/api/errorLogRouter'
import { LimitOffsetParams } from 'src/api/BaseRouter'
import { ValueLabelPair } from 'src/common/components/SelectComponents'
import moment from 'moment'
import eq from 'react-fast-compare'

const searchLimit = 100

export default class ScreenErrorHistoryManager {
    @observable areControlsOpen = true
    @observable searchParams?: ScreenErrorHistorySearchParams
    @observable errors = new Map<string, DiagnosticsError>()
    @observable logs = new Array<DiagnosticsErrorLog>()

    @observable searchOffset = 0
    @observable isFetching = false
    @observable isFetchComplete = false
    @observable errorFetching = false
    lastSearch?: LimitOffsetParams

    screenDiagnosticsUIStore: ScreenDiagnosticsUIStore

    constructor(screenDiagnosticsUIStore: ScreenDiagnosticsUIStore) {
        this.screenDiagnosticsUIStore = screenDiagnosticsUIStore
    }

    get errorCodeOptionsGrouped(): Array<{ label: string; options: ValueLabelPair[] }> {
        return (
            Object.keys(ErrorSeverity)
                .reverse()
                // Remove number keys
                .filter(value => isNaN(Number(value)))
                .map(severity => ({
                        label: i18n.t('diagnosticsPage.errorCodeSeverity.' + severity),
                        options: Object.values(ErrorCode)
                            .filter(
                                code =>
                                    code !== ErrorCode.unknown && errorCodeSeverity(code) === ErrorSeverity[severity]
                            )
                            .map((code: ErrorCode) => ({ value: code, label: i18n.t('diagnosticsPage.errorNames.' + ErrorCode[code]) }))
                            .sort((a, b) => a.label.localeCompare(b.label)),
                    }))
        )
    }

    @computed get startDate(): moment.Moment | undefined {
        return this.searchParams?.startTime
    }

    @computed get endDate(): moment.Moment | undefined {
        return this.searchParams?.endTime
    }

    @action toggleControls = () => {
        this.areControlsOpen = !this.areControlsOpen
    }

    @action updateSearchParams = (field: string, value: any) => {
        if (!this.searchParams) {
            return
        }

        switch (field) {
            case 'dateRange':
                this.searchParams.startTime = value[0]
                this.searchParams.endTime = value[1]
                break
            case 'errors':
                this.searchParams.errorCodes =
                    value.length > 0 ? value.map((code: string) => ErrorCode[code]) : undefined
                break
            default:
                break
        }
    }

    @action getInitialHistory = () => {
        const currentScreen = this.screenDiagnosticsUIStore.currentScreen
        if (!currentScreen) {
            return
        }
        this.searchParams = { screenIds: [currentScreen.id!] }

        // Set to past 24 hours by default
        this.updateSearchParams('dateRange', [moment().subtract(24, 'hours'), moment()])
        this.searchHistory(false)
    }

    @action searchHistory(fetchMore: boolean): Promise<any> {
        if (!this.searchParams || !this.shouldContinue(!fetchMore, this.searchParams)) {
            return Promise.resolve()
        }
        const limitOffset = { limit: searchLimit, offset: this.searchOffset }
        this.errorFetching = false
        return errorLogRouter
            .searchScreenErrorHistory({ ...this.searchParams, ...limitOffset })
            .then(json => {
                runInAction('sythesiseHistoryData', () => {
                    json.errors.forEach(errorJson => {
                        if (!this.errors.has(errorJson.id!)) {
                            const error = new DiagnosticsError(errorJson)
                            this.errors.set(error.id!, error)
                        }
                    })

                    json.logs.forEach(logJson => {
                        const error = this.errors.get(logJson.diagnosticsErrorId)
                        if (error) {
                            const log = new DiagnosticsErrorLog(logJson, error)
                            this.logs.push(log)
                        }
                    })

                    const logsLength = json.logs.length
                    if (logsLength < searchLimit) {
                        this.isFetchComplete = true
                    }
                    this.searchOffset += logsLength
                })
            })
            .catch(err => {
                console.error(err)
                runInAction('historyErrorSearching', () => {
                    this.errorFetching = true
                })
            })
            .finally(() => {
                runInAction('historyFinishedSearching', () => {
                    this.isFetching = false
                })
            })
    }

    @action shouldContinue(refresh: boolean, searchParams?: any): boolean {
        // FIXME: Use toJSON to convert Mobx observable to vanilla JS
        const jsonParams = searchParams ? JSON.parse(JSON.stringify(searchParams)) : undefined
        if (!refresh && eq(this.lastSearch, jsonParams)) {
            if (this.isFetching || this.isFetchComplete) {
                return false
            }
        } else {
            if (refresh) {
                this.lastSearch = undefined
            }
            this.searchOffset = 0
            this.errors = new Map<string, DiagnosticsError>()
            this.logs = new Array<DiagnosticsErrorLog>()
        }
        this.lastSearch = jsonParams
        this.isFetching = true
        this.isFetchComplete = false
        return true
    }
}
