import { observable, computed, runInAction } from 'mobx'
import { Omit } from 'react-router'

import { rootStore } from '../RootStore'

import moment from 'moment'
import * as math from 'mathjs'

export interface MediaFileData {
    name: string
    width: number
    height: number
}

class Media {
    static newMedia(): Media {
        return new Media({
            name: '',
            size: 0,
            lastModified: moment(),
            signedGetURL: '',
            width: 0,
            height: 0,
            index: 0,
        })
    }

    static newMediaFromFile(file: File, index: number, screenId?: string): Media {
        const media = new Media({
            name: file.name,
            size: file.size,
            // lastModified: moment(file.lastModified),
            lastModified: moment(),
            signedGetURL: '',
            width: 0,
            height: 0,
            index,
            file,
        })
        media.screenId = screenId
        media.getResolution()
        return media
    }

    @observable name: string
    @observable size: number
    @observable lastModified: moment.Moment
    @observable signedGetURL: string
    @observable width: number
    @observable height: number
    @observable index: number
    @observable dwellTime?: number
    @observable group?: number
    @observable file?: File
    @observable screenId?: string
    @observable startDate?: moment.Moment
    @observable stopDate?: moment.Moment

    constructor(json: MediaJSON) {
        this.name = json.name
        this.size = json.size || 0
        this.lastModified = json.lastModified
        this.signedGetURL = json.signedGetURL || ''
        this.width = json.width || 0
        this.height = json.height || 0
        this.index = json.index
        this.dwellTime = json.dwellTime || undefined
        this.group = json.group || undefined
        this.file = json.file
        this.startDate = json.startDate ? moment(json.startDate) : undefined
        this.stopDate = json.stopDate ? moment(json.stopDate) : undefined
    }

    @computed get isPendingDelete(): boolean {
        return rootStore.mediaStore.getMediaManager(this.screenId!).removeScreenFilesSet.has(this)
    }

    @computed get isPendingUpload(): boolean {
        return rootStore.mediaStore.getMediaManager(this.screenId!).addScreenFilesSet.has(this)
    }

    @computed get hash(): string {
        return this.name + ':' + this.size
    }

    getResolution = (): void => {
        if (!this.file || (this.width !== 0 && this.height !== 0)) {
            return
        }

        const img = document.createElement('img')
        img.onload = () => {
            window.URL.revokeObjectURL(img.src)
            runInAction('getResolution', () => {
                this.width = img.width
                this.height = img.height
            })
        }
        img.src = this.signedGetURL || URL.createObjectURL(this.file)
    }

    @computed get dimensions(): string {
        if (this.width === 0 || this.height === 0) {
            return ''
        }
        return this.width + 'x' + this.height
    }

    @computed get aspectRatio(): number {
        return this.width / this.height
    }

    @computed get aspectRatioString(): string {
        const divisor = math.gcd(this.width, this.height)
        return this.width / divisor + 'x' + this.height / divisor
    }

    fileData(): MediaFileData {
        return {
            name: this.name,
            width: this.width,
            height: this.height,
        }
    }

    toJSON(): MediaJSON {
        return {
            name: this.name,
            width: this.width,
            height: this.height,
            dwellTime: this.dwellTime,
            group: this.group,
            startDate: this.startDate,
            stopDate: this.stopDate,
        } as MediaJSON
    }
}

export type MediaJSON = Omit<
    Media,
    | 'fileData'
    | 'hash'
    | 'toJSON'
    | 'isPendingDelete'
    | 'isPendingUpload'
    | 'dimensions'
    | 'aspectRatio'
    | 'getResolution'
    | 'aspectRatioString'
>

export default Media
