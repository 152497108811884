import { observable, computed } from 'mobx'

import i18n from 'src/i18n'

import { DisplayImageStatus } from './DisplayImage'

class LiveImage {
    static newLiveImage(): LiveImage {
        return new LiveImage({
            screenId: '',
            displayImageId: '',
            filename: '',
            status: DisplayImageStatus.normal,
            URL: '',
            smallURL: '',
            plays: 0,
        })
    }

    @observable screenId: string
    @observable displayImageId?: string
    @observable filename: string
    @observable status?: DisplayImageStatus
    @observable URL?: string
    @observable smallURL?: string
    @observable plays?: number

    constructor(json: LiveImageJSON) {
        this.screenId = json.screenId
        this.displayImageId = json.displayImageId
        this.filename = json.filename
        this.status = json.status ?? DisplayImageStatus.unnamed
        this.URL = json.URL
        this.smallURL = json.smallURL
        this.plays = json.plays
    }

    @computed get displayableFilename(): string {
        if (!this.filename) {
            return ''
        }

        return this.status === 'normal' ? this.filename : i18n.t('reportsPage.unnamedImage') + '(' + this.filename + ')'
    }

    @computed get hasProofData(): boolean {
        return !!this.URL
    }

    @computed get unnamed(): boolean {
        return this.status === DisplayImageStatus.unnamed
    }
}

export type LiveImageJSON = Omit<LiveImage, 'displayableFilename' | 'hasProofData' | 'unnamed'>

export default LiveImage
