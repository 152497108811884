import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from '../../../../common/RootStore'

import i18n from 'src/i18n'

import Select from 'react-select'
import { ClearIndicator, DropdownIndicator, Option, ValueLabelPair } from 'src/common/components/SelectComponents'
import { Form, FormGroup, InputGroup, Label, Input, Row, Col, Button, FormText } from 'reactstrap'
import Toggle from 'react-toggle'
import { formIntegerValidation, eventPreventDefault } from 'src/common/utils/EventUtils'
import { TileType, TileSupport, tileTypeSupport, tileTypeName } from 'src/common/models/Tile'
import { Dialog } from '@blueprintjs/core'

// TODO: Figure out how data will be saved before doing more work on this form
@inject('store')
@observer
class CreateScreenForm extends React.Component<{ store?: RootStore }> {
    lastItem: Screen

    state = { deviceManagementOpen: false, originalItemAviorId: undefined }

    UNSAFE_componentWillMount = () => {
        if (!this.props.store!.adminUIStore.isEditMode) {
            this.props.store!.adminUIStore.assetData.screen.setItem()
        }
        this.setState({
            originalItemAviorId: this.props.store?.adminUIStore.assetData.screen.upsertItem.aviorId,
        })
    }

    handleFormValue = (event: any) => {
        const id = event.target.id
        let value = event.target.value
        if (id === 'enabled' || id === 'isTest') {
            value = event.target.checked
        }
        this.props.store!.adminUIStore.assetData.screen.updateItemForm(id, value)
    }

    handleTileTypeSelect = (selectedOption: ValueLabelPair) => {
        this.props.store!.adminUIStore.assetData.screen.updateItemForm('tileType', selectedOption?.value)
    }

    handleFormSelect = (field: string, selectedOption: ValueLabelPair) => {
        this.props.store!.adminUIStore.assetData.screen.updateItemForm(
            field,
            selectedOption ? selectedOption.value : undefined
        )
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }

        const adminUIStore = this.props.store!.adminUIStore
        const screenUpsertItem = adminUIStore.assetData.screen.upsertItem

        const associatedOrgs = adminUIStore.associatedOrgsForLocation
        const associatedOrgsOptions =
            associatedOrgs && associatedOrgs.map(item => ({ value: item.id, label: item.name }))

        const tileTypeOptionsGrouped = Object.values(TileSupport).map(s => ({
            label: s,
            options: Object.values(TileType)
                .filter(t => tileTypeSupport(t) === s)
                .map(t => ({ value: t, label: tileTypeName(t) })),
        }))

        const defaultContentOwnerOption =
            screenUpsertItem && screenUpsertItem.contentOwner
                ? { value: screenUpsertItem.contentOwner.id, label: screenUpsertItem.contentOwner.name }
                : undefined

        const defaultOpsManagerOption =
            screenUpsertItem && screenUpsertItem.operationsManager
                ? { value: screenUpsertItem.operationsManager.id, label: screenUpsertItem.operationsManager.name }
                : undefined

        return (
            <React.Fragment>
                <Form className='admin-form' onSubmit={eventPreventDefault}>
                    <FormGroup>
                        <Label for='name'>Name</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='name'
                            autoComplete='off'
                            defaultValue={screenUpsertItem ? screenUpsertItem.name : undefined}
                        />
                    </FormGroup>

                    <Row>
                        <Col md={6} sm={12}>
                            <FormGroup>
                                <Label for='tilesWide'>Tiles wide</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='text'
                                    onKeyDown={formIntegerValidation}
                                    id='tilesWide'
                                    autoComplete='off'
                                    defaultValue={
                                        screenUpsertItem && screenUpsertItem.tilesWide > 0
                                            ? String(screenUpsertItem.tilesWide)
                                            : undefined
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} sm={12}>
                            <FormGroup>
                                <Label for='tilesHigh'>Tiles high</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='text'
                                    onKeyDown={formIntegerValidation}
                                    id='tilesHigh'
                                    autoComplete='off'
                                    defaultValue={
                                        screenUpsertItem && screenUpsertItem.tilesHigh > 0
                                            ? String(screenUpsertItem.tilesHigh)
                                            : undefined
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Tile type</Label>
                        <Select
                            className='custom-select-wrapper custom-select-inverted'
                            classNamePrefix='custom-select'
                            isClearable
                            blurInputOnSelect
                            onChange={this.handleTileTypeSelect}
                            options={tileTypeOptionsGrouped}
                            defaultValue={
                                (screenUpsertItem?.tileType && {
                                    value: screenUpsertItem.tileType,
                                    label: tileTypeName(screenUpsertItem.tileType),
                                }) as ValueLabelPair | undefined
                            }
                            components={{ ClearIndicator, DropdownIndicator, Option }}
                        />
                        <FormText>
                            {`Tile type can be found in LEDNet under the Info tab on the Home screen. If you're unsure
                            which product you have, please contact Candelic support or select "Unknown" to enable manual
                            entry of tile dimensions.`}
                        </FormText>
                    </FormGroup>
                    {adminUIStore.assetData.screen.isTileTypeUnknown && (
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <Label for='tileWidth'>Tile width (px)</Label>
                                    <Input
                                        onChange={this.handleFormValue}
                                        type='text'
                                        onKeyDown={formIntegerValidation}
                                        id='tileWidth'
                                        autoComplete='off'
                                        defaultValue={
                                            screenUpsertItem && screenUpsertItem.tileWidth > 0
                                                ? String(screenUpsertItem.tileWidth)
                                                : undefined
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <Label for='tileHeight'>Tile height (px)</Label>
                                    <Input
                                        onChange={this.handleFormValue}
                                        type='text'
                                        onKeyDown={formIntegerValidation}
                                        id='tileHeight'
                                        autoComplete='off'
                                        defaultValue={
                                            screenUpsertItem && screenUpsertItem.tileHeight > 0
                                                ? String(screenUpsertItem.tileHeight)
                                                : undefined
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    {(screenUpsertItem?.organisation?.isOwned || screenUpsertItem?.isOwned) && (
                        <React.Fragment>
                            <FormGroup>
                                <Label>Content Owner</Label>
                                <Select
                                    className='custom-select-wrapper custom-select-inverted'
                                    classNamePrefix='custom-select'
                                    isClearable
                                    blurInputOnSelect
                                    onChange={this.handleFormSelect.bind(this, 'contentOwner')}
                                    options={associatedOrgsOptions}
                                    defaultValue={defaultContentOwnerOption}
                                    components={{ ClearIndicator, DropdownIndicator, Option }}
                                />
                                <FormText>This organisation will have access to the content for this screen.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label>Operations Manager</Label>
                                <Select
                                    className='custom-select-wrapper custom-select-inverted'
                                    classNamePrefix='custom-select'
                                    isClearable
                                    blurInputOnSelect
                                    onChange={this.handleFormSelect.bind(this, 'operationsManager')}
                                    options={associatedOrgsOptions}
                                    defaultValue={defaultOpsManagerOption}
                                    components={{ ClearIndicator, DropdownIndicator, Option }}
                                />
                                <FormText>
                                    This organisation will have access to diagnostics data for this screen.
                                </FormText>
                            </FormGroup>
                        </React.Fragment>
                    )}
                    <FormGroup>
                        <Label for='enabled'>Enabled</Label>
                        <InputGroup>
                            <Toggle
                                defaultChecked={screenUpsertItem?.enabled}
                                className='custom-toggle'
                                icons={false}
                                onChange={this.handleFormValue}
                                id='enabled'
                            />
                        </InputGroup>
                        <FormText>
                            Disabling the screen will prevent it from showing up on the diagnostics page.
                        </FormText>
                    </FormGroup>
                    {me.isSuperUser && (
                        <FormGroup>
                            <Label for='isTest'>Test</Label>
                            <InputGroup>
                                <Toggle
                                    defaultChecked={screenUpsertItem?.isTest}
                                    className='custom-toggle'
                                    icons={false}
                                    onChange={this.handleFormValue}
                                    id='isTest'
                                />
                            </InputGroup>
                            <FormText>
                                Marking the screen as a test screen will prevent it from showing up in notifications.
                            </FormText>
                        </FormGroup>
                    )}
                    <FormGroup>
                        <InputGroup>
                            <Button
                                className='custom-button'
                                onClick={() => this.setState({ deviceManagementOpen: true })}
                                color='primary'
                            >
                                Device Management
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </Form>
                <div className='custom-panel-footer-actions'>
                    <Button
                        className='custom-button-large'
                        onClick={adminUIStore.handleFormSave.bind(this, true)}
                        color='primary'
                        disabled={!adminUIStore.assetData.screen.createItemFormValid}
                    >
                        {i18n.t('actions.save')}
                    </Button>
                </div>
                {/* Device Management */}
                <Dialog
                    className='custom-dialog'
                    title='Device Management'
                    onClose={() => this.setState({ deviceManagementOpen: false })}
                    isOpen={this.state.deviceManagementOpen}
                    canOutsideClickClose={false}
                >
                    <div className='dialog-content p-4'>
                        <Form className='admin-form' onSubmit={eventPreventDefault}>
                            <h4 className='text-black'>Avior</h4>
                            <FormGroup>
                                <Label for='aviorId'>ID</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='text'
                                    id='aviorId'
                                    autoComplete='off'
                                    maxLength={6}
                                    defaultValue={screenUpsertItem?.aviorId ?? undefined}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='aviorPassword'>Password</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='password'
                                    id='aviorPassword'
                                    autoComplete='off'
                                    maxLength={10}
                                    defaultValue={screenUpsertItem?.aviorPassword ?? undefined}
                                    placeholder={
                                        this.state.originalItemAviorId &&
                                        this.state.originalItemAviorId === screenUpsertItem.aviorId
                                            ? '••••••••••'
                                            : undefined
                                    }
                                />
                                <FormText>If the ID is updated, the password must be re-entered.</FormText>
                            </FormGroup>
                        </Form>
                        <div className='dialog-actions'>
                            <Button
                                className='custom-button custom-button-large'
                                onClick={() => this.setState({ deviceManagementOpen: false })}
                                color='primary'
                            >
                                Done
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default CreateScreenForm
