import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import views from 'src/config/views'

import AdminTab from './components/AdminTab'
import { TabContent, TabPane } from 'reactstrap'
import { Dialog, Alert, Intent, Button, Classes, Icon } from '@blueprintjs/core'
import AdminPanelForm from './components/AdminPanelForm'
import { INamedBaseModel } from 'src/common/models/BaseModel'
import AdminTable from './components/AdminTable'
import NavTabs, { NavTab } from 'src/common/components/navigation/NavTabs'
import LockedOverlay from 'src/common/components/LockedOverlay'
import CopyToClipboard from 'src/common/components/CopyToClipboard'
import Input from 'reactstrap/lib/Input'
import EditOrgLogo from './components/EditOrgLogo'
import Organisation from 'src/common/models/Organisation'

export enum AdminTabName {
    organisations = 'organisations',
    users = 'users',
    screens = 'screens',
}

@inject('store')
@observer
class Admin extends React.Component<{ store?: RootStore } & WithNamespaces> {
    componentDidMount() {
        // Initialise live subscription to receive liveScreenState updates
        this.props.store!.liveUIStore.initialiseSubscription()
        // Fetch available LEDNet versions
        this.props.store!.adminUIStore.getLednetVersions()
    }

    handleCloseDialog = () => {
        const adminUIStore = this.props.store!.adminUIStore
        if (adminUIStore.isEditLogoDialogOpen) {
            adminUIStore.closeEditLogoDialog()
        } else if (adminUIStore.isEditMode) {
            if (adminUIStore.assetData.hasUnsavedChanges) {
                adminUIStore.toggleCloseAlert()
            } else {
                adminUIStore.toggleEditMode()
            }
        } else {
            if (adminUIStore.assetData.data?.hasChanges) {
                adminUIStore.toggleCloseAlert()
            } else {
                adminUIStore.toggleAdminPanelStack()
            }
        }
    }

    confirmCloseAdminDialog = () => {
        const adminUIStore = this.props.store!.adminUIStore
        if (adminUIStore.isEditMode) {
            adminUIStore.toggleEditMode()
        } else {
            adminUIStore.toggleAdminPanelStack()
        }
        adminUIStore.toggleCloseAlert()
    }

    confirmSaveErrorAlert = () => {
        this.props.store!.adminUIStore.toggleSaveErrorAlert()
    }

    deleteContentForTab = (adminTab: AdminTabName, item?: INamedBaseModel): JSX.Element | null => {
        if (!item) {
            return null
        }

        switch (adminTab) {
            case AdminTabName.organisations:
                return (
                    <p>
                        Are you sure you want to delete <b>{item.name}</b>? This will delete any users or screens which
                        depend on this organisation.
                    </p>
                )
            case AdminTabName.users:
            case AdminTabName.screens:
                return (
                    <p>
                        Are you sure you want to delete <b>{item.name}</b>?
                    </p>
                )
        }
    }

    restoreContentForTab = (item?: INamedBaseModel): JSX.Element | null => {
        if (!item) {
            return null
        }
        return (
            <p>
                Are you sure you want to restore <b>{item.name}</b>?
            </p>
        )
    }

    permanentlyDeleteContentForTab = (activeAdminTab: AdminTabName, item?: INamedBaseModel): JSX.Element | null => {
        if (!item) {
            return null
        }
        return (
            <>
                <div className={Classes.ALERT_BODY}>
                    <Icon icon='trash' iconSize={40} intent={Intent.DANGER} />
                    <div className={Classes.ALERT_CONTENTS}>
                        <p>
                            Are you sure you want to permanently delete <b>{item.name}</b>? This action cannot be
                            undone.
                        </p>
                        <p>{`Type "delete" to confirm.`}</p>
                        <Input
                            onChange={this.props.store!.adminUIStore.setPermanentlyDeleteConfirmation}
                            autoComplete='off'
                        />
                    </div>
                </div>
                <div className={Classes.ALERT_FOOTER}>
                    <Button
                        intent={Intent.DANGER}
                        onClick={this.props.store!.adminUIStore.confirmPermanentlyDelete.bind(this, activeAdminTab)}
                        disabled={this.props.store!.adminUIStore.permanentlyDeleteConfirmation !== 'delete'}
                    >
                        Confirm permanent delete
                    </Button>
                    <Button onClick={this.props.store!.adminUIStore.togglePermanentlyDeleteAlert}>Cancel</Button>
                </div>
            </>
        )
    }

    render() {
        const adminUIStore = this.props.store!.adminUIStore
        const adminTabs = adminUIStore.adminTabs
        const activeAdminTab = adminUIStore.activeAdminTab
        const me = this.props.store!.userStore.me
        if (!adminTabs || !activeAdminTab || !me) {
            return null
        }

        const storeItem = this.props.store!.adminUIStore.storeItemForTab(activeAdminTab)
        const itemPendingAction = storeItem ? storeItem.item : undefined

        const tabs: NavTab[] = adminTabs?.map(tabName => ({
            name: i18n.t('adminPage.adminTabs.' + tabName),
            view: views.admin,
            params: { tab: tabName },
            active: activeAdminTab === tabName,
        }))

        return (
            <React.Fragment>
                <div className='grey-container'>
                    <div className='admin-container custom-container'>
                        <NavTabs tabs={tabs} />
                        <TabContent activeTab={activeAdminTab}>
                            {adminTabs.map((tabName: AdminTabName) => (
                                <TabPane key={tabName} tabId={tabName}>
                                    <AdminTab tabName={tabName} />
                                </TabPane>
                            ))}
                            {activeAdminTab && <AdminTable tabName={activeAdminTab} />}
                        </TabContent>
                    </div>
                </div>
                {/* Create item */}
                <Dialog
                    className='custom-dialog'
                    title={
                        i18n.t('adminPage.dialogTitles.create') +
                        ' ' +
                        i18n.t('adminPage.dialogTitles.' + activeAdminTab)
                    }
                    onClose={this.handleCloseDialog}
                    isOpen={adminUIStore.isAdminPanelStackOpen}
                    canOutsideClickClose={false}
                >
                    {adminUIStore.isSaving && (
                        <LockedOverlay isFullscreen>
                            <div className='admin-saving'>
                                <h2>Saving new items</h2>
                            </div>
                        </LockedOverlay>
                    )}
                    <AdminPanelForm activeTab={activeAdminTab} />
                </Dialog>
                {/* Edit item */}
                <Dialog
                    className='custom-dialog'
                    title={
                        i18n.t('adminPage.dialogTitles.edit') + ' ' + i18n.t('adminPage.dialogTitles.' + activeAdminTab)
                    }
                    onClose={this.handleCloseDialog}
                    isOpen={adminUIStore.isEditMode}
                    canOutsideClickClose={false}
                >
                    {adminUIStore.isSaving && (
                        <LockedOverlay isFullscreen>
                            <div className='admin-saving'>
                                <h2>Saving changes</h2>
                            </div>
                        </LockedOverlay>
                    )}
                    <AdminPanelForm activeTab={activeAdminTab} />
                </Dialog>
                {/* Edit organisation logo */}
                <Dialog
                    className='custom-dialog'
                    title='Edit Logo'
                    onClose={this.handleCloseDialog}
                    isOpen={adminUIStore.isEditLogoDialogOpen}
                    canOutsideClickClose={false}
                    usePortal={false}
                >
                    <EditOrgLogo
                        selectedOrg={adminUIStore.storeItemForTab(AdminTabName.organisations)?.item as Organisation}
                        onClose={this.handleCloseDialog}
                    />
                </Dialog>
                {/* Unsaved changes*/}
                <Alert
                    className='custom-alert'
                    cancelButtonText='Cancel'
                    confirmButtonText='Discard changes'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={adminUIStore.isCloseAlertOpen}
                    onCancel={adminUIStore.toggleCloseAlert}
                    onConfirm={this.confirmCloseAdminDialog}
                >
                    <p>Your changes will be discarded, are you sure you want to continue?</p>
                </Alert>
                {/* Delete item */}
                <Alert
                    className='custom-alert'
                    cancelButtonText='Cancel'
                    confirmButtonText='Confirm delete'
                    icon='trash'
                    intent={Intent.DANGER}
                    isOpen={this.props.store!.adminUIStore.isDeleteAlertOpen}
                    onCancel={this.props.store!.adminUIStore.toggleDeleteAlert}
                    onConfirm={this.props.store!.adminUIStore.confirmDelete.bind(this, activeAdminTab)}
                >
                    {this.deleteContentForTab(activeAdminTab, itemPendingAction)}
                </Alert>
                {/* Restore item */}
                <Alert
                    className='custom-alert'
                    cancelButtonText='Cancel'
                    confirmButtonText='Confirm restore'
                    icon='history'
                    intent={Intent.SUCCESS}
                    isOpen={this.props.store!.adminUIStore.isRestoreAlertOpen}
                    onCancel={this.props.store!.adminUIStore.toggleRestoreAlert}
                    onConfirm={this.props.store!.adminUIStore.confirmRestore.bind(this, activeAdminTab)}
                >
                    {this.restoreContentForTab(itemPendingAction)}
                </Alert>
                {/* Permanently delete item */}
                <Dialog className={Classes.ALERT} isOpen={this.props.store!.adminUIStore.isPermanentlyDeleteAlertOpen}>
                    {this.permanentlyDeleteContentForTab(activeAdminTab, itemPendingAction)}
                </Dialog>
                {/* Issue saving item */}
                <Alert
                    className='custom-alert'
                    confirmButtonText='Okay'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={adminUIStore.isSaveErrorAlertOpen}
                    onConfirm={this.confirmSaveErrorAlert}
                >
                    <div>
                        <p>
                            There was an issue saving your item. Please check that the data you have entered is correct
                            and try again.
                        </p>
                        {adminUIStore.saveAlertMessage && <p>Error: {adminUIStore.saveAlertMessage}</p>}
                    </div>
                </Alert>
                {/* Disconnected screen warning */}
                <Alert
                    className='custom-alert'
                    confirmButtonText='Continue'
                    cancelButtonText='Cancel'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={adminUIStore.isDisconnectedWarningOpen}
                    onConfirm={adminUIStore.toggleDisconnectedWarning.bind(this, true)}
                    onCancel={adminUIStore.toggleDisconnectedWarning.bind(this, false)}
                >
                    <p>
                        At least one selected screen has lost connection since you selected it. Any disconnected screens
                        will not be updated. Are you sure you want to continue?
                    </p>
                </Alert>
                {/* Error upgrading screen */}
                <Alert
                    className='custom-alert'
                    confirmButtonText='Dismiss'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={adminUIStore.isUpdateErrorOpen}
                    onConfirm={() => adminUIStore.toggleUpdateError()}
                >
                    <p className='pre-line'>{adminUIStore.updateErrorMessage}</p>
                </Alert>
                {/* Update screen confirmation */}
                <Alert
                    className='custom-alert'
                    confirmButtonText='Confirm software update'
                    cancelButtonText='Cancel'
                    icon='upload'
                    intent={Intent.PRIMARY}
                    isOpen={adminUIStore.isConfirmUpdateOpen}
                    onConfirm={adminUIStore.onConfirmUpdate}
                    onCancel={adminUIStore.closeConfirmUpdate}
                >
                    <div className='dialog-content'>
                        <p>Are you sure you want to update the selected screens to the following version?</p>
                        <details>
                            <summary>{adminUIStore.updateTargetVersion?.version}</summary>
                            <p className='pre-line'>{adminUIStore.updateTargetVersion?.description}</p>
                        </details>
                        <br />
                        {adminUIStore.selectedScreens.map(screen => (
                            <div key={screen.id}>
                                <p>
                                    {screen.name}
                                    <br />
                                    <small>
                                        {screen.controller?.version} → {adminUIStore.updateTargetVersion?.version}
                                    </small>
                                </p>
                            </div>
                        ))}
                    </div>
                </Alert>
                {/* Self edit permissions */}
                <Alert
                    className='custom-alert'
                    confirmButtonText='Refresh now'
                    cancelButtonText='Do it later'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={adminUIStore.isSelfEditPermissionsAlertOpen}
                    onConfirm={window.location.reload.bind(window.location)}
                    onCancel={adminUIStore.toggleSelfEditPermissionsAlert}
                >
                    <p>
                        The page must be refreshed to see updated changes to your permissions. Would you like to refresh
                        now?
                    </p>
                </Alert>
                {/* Copy IDs modal */}
                <Dialog
                    className='custom-dialog'
                    title={adminUIStore.screenToCopyIdentifiers?.name + ' Identifiers'}
                    onClose={adminUIStore.clearScreenToCopyIdentifiers}
                    isOpen={!!adminUIStore.screenToCopyIdentifiers}
                >
                    {adminUIStore.screenToCopyIdentifiers && (
                        <div className='dialog-content copy-ids-dialog'>
                            <div className='copy-row'>
                                <CopyToClipboard
                                    text={adminUIStore.screenToCopyIdentifiers.id || ''}
                                    label='screen ID'
                                />
                                <h3>Screen: {adminUIStore.screenToCopyIdentifiers.id || 'Unknown'}</h3>
                            </div>
                            <div className='copy-row'>
                                <CopyToClipboard
                                    text={adminUIStore.screenToCopyIdentifiers.controller?.id || ''}
                                    label='controller ID'
                                />
                                <h3>Controller: {adminUIStore.screenToCopyIdentifiers.controller?.id || 'Unknown'}</h3>
                            </div>
                            <div className='copy-row'>
                                <CopyToClipboard
                                    text={
                                        adminUIStore.screenToCopyIdentifiers.controller?.location?.organisationId || ''
                                    }
                                    label='organisation ID'
                                />
                                <h3>
                                    Organisation:{' '}
                                    {adminUIStore.screenToCopyIdentifiers.controller?.location?.organisationId ||
                                        'Unknown'}
                                </h3>
                            </div>
                        </div>
                    )}
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(Admin)
