import * as React from 'react'

import i18n from 'src/i18n'

import { inject, observer } from 'mobx-react'
import RootStore from '../../../../common/RootStore'

import { Form, FormGroup, Label, Input, FormFeedback, Button, FormText, InputGroup } from 'reactstrap'
import Toggle from 'react-toggle'
import { eventPreventDefault } from 'src/common/utils/EventUtils'
import { RoleType } from 'src/common/models/Roles'

@inject('store')
@observer
class CreateUserForm extends React.Component<{ store?: RootStore }> {
    componentDidMount = () => {
        if (!this.props.store!.adminUIStore.isEditMode) {
            this.props.store!.adminUIStore.assetData.user.setItem()
        }
    }

    handleFormValue = (event: any) => {
        const field = event.target.id
        let value = event.target.value
        if (field === 'hasConsoleAccess') {
            value = event.target.checked
        }
        this.props.store!.adminUIStore.assetData.user.updateItemForm(field, value)
    }

    handleRoleSelect = (role: RoleType, event: any) => {
        const isChecked = event.target.checked

        this.props.store!.adminUIStore.assetData.user.updateItemForm('userRole', { role, isChecked })
    }

    handleInputFocus = (event: any) => {
        this.props.store!.adminUIStore.assetData.user.setFormFieldFocused(event.target.id, true)
    }

    handleInputBlur = (event: any) => {
        this.props.store!.adminUIStore.assetData.user.setFormFieldFocused(event.target.id, false)
    }

    render() {
        const adminUIStore = this.props.store!.adminUIStore

        const me = this.props.store!.userStore.me
        const selectableRoles = adminUIStore.selectableRoles
        if (!me || !selectableRoles) {
            return null
        }

        const userItem = adminUIStore.assetData.user.item
        const isEdit = adminUIStore.isEditMode

        return (
            <React.Fragment>
                <Form className='admin-form' onSubmit={eventPreventDefault}>
                    <FormGroup>
                        <Label for='firstName'>First Name</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='firstName'
                            autoComplete='off'
                            defaultValue={userItem ? userItem.firstName : undefined}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for='lastName'>Last Name</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='lastName'
                            autoComplete='off'
                            defaultValue={userItem ? userItem.lastName : undefined}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for='email'>Email</Label>
                        <Input
                            onChange={this.handleFormValue}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            type='email'
                            id='email'
                            autoComplete='off'
                            invalid={!adminUIStore.assetData.user.isEmailValid}
                            defaultValue={userItem ? userItem.email : undefined}
                            readOnly={isEdit}
                        />
                        <FormFeedback>{i18n.t('feedback.errors.invalidEmail')}</FormFeedback>
                        {isEdit && <FormText>{i18n.t('feedback.info.emailDisabled')}</FormText>}
                    </FormGroup>
                    <FormGroup>
                        <Label for='phone'>Phone</Label>
                        <Input
                            onChange={this.handleFormValue}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            type='text'
                            id='phone'
                            autoComplete='off'
                            invalid={!adminUIStore.assetData.user.isPhoneValid}
                            defaultValue={userItem ? userItem.phone : undefined}
                        />
                        <FormFeedback>{i18n.t('feedback.errors.invalidPhone')}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Access</Label>
                        {selectableRoles.map(role => (
                            <InputGroup key={role}>
                                <div className='role-option'>
                                    <div>
                                        <h4>{i18n.t('adminPage.roles.' + role)}</h4>
                                        <FormText>{i18n.t('adminPage.roleDescriptions.' + role)}</FormText>
                                    </div>
                                    <Toggle
                                        checked={
                                            !!(userItem && userItem.roles && userItem.roles.includes(role as RoleType))
                                        }
                                        className='custom-toggle'
                                        icons={false}
                                        onChange={this.handleRoleSelect.bind(this, role)}
                                        disabled={
                                            userItem &&
                                            ((me.id! === userItem.id! &&
                                                // Don't allow self removing Superuser or Admin
                                                ((role === RoleType.superUser && me.isSuperUser) ||
                                                    (role === RoleType.admin && me.isAdmin))) ||
                                                // If Superuser, disable unchecking other roles
                                                (role !== RoleType.superUser &&
                                                    userItem.roles?.includes(RoleType.superUser)))
                                        }
                                    />
                                </div>
                            </InputGroup>
                        ))}
                        {me.isSuperUser && userItem && (
                            <InputGroup>
                                <div className='role-option'>
                                    <div className='role-option-title'>
                                        <h4>{i18n.t('adminPage.roles.ConsoleAccess')}</h4>
                                        <FormText>{i18n.t('adminPage.roleDescriptions.ConsoleAccess')}</FormText>
                                    </div>
                                    <Toggle
                                        checked={userItem.hasConsoleAccess}
                                        className='custom-toggle'
                                        icons={false}
                                        onChange={this.handleFormValue}
                                        disabled={userItem.roles?.includes(RoleType.superUser)}
                                        id='hasConsoleAccess'
                                    />
                                </div>
                            </InputGroup>
                        )}
                    </FormGroup>
                </Form>
                <div className='custom-panel-footer-actions'>
                    <Button
                        className='custom-button-large'
                        onClick={adminUIStore.handleFormSave.bind(this, true)}
                        color='primary'
                        disabled={
                            !adminUIStore.assetData.user.isPhoneValid ||
                            !adminUIStore.assetData.user.createItemFormValid
                        }
                    >
                        {i18n.t('actions.save')}
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateUserForm
