import React, { useCallback, useState } from 'react'
import { Intent, ProgressBar } from '@blueprintjs/core'
import { useDropzone } from 'react-dropzone'
import { AppToaster } from 'src/common/components/AppToaster'
import Organisation from 'src/common/models/Organisation'
import i18n from 'src/i18n'
import orgRouter from 'src/api/orgRouter'

interface EditOrgLogoProps {
    selectedOrg?: Organisation
    onClose?: () => void
}

export default function EditOrgLogo(props: EditOrgLogoProps) {
    const { selectedOrg, onClose } = props

    const [isLogoUploading, setIsLogoUploading] = useState(false)

    const handleUploadFile = async (file: File[]) => {
        if (!selectedOrg || !selectedOrg.id) {
            return
        }
        try {
            setIsLogoUploading(true)
            const { uploadURL, fileURL } = await orgRouter.fetchImageURL(selectedOrg.id, file[0])
            const response = await orgRouter.uploadLogo(uploadURL, file[0])
            if (!response.ok) {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUploadingImage'),
                    intent: Intent.DANGER,
                })
                throw Error()
            }
            await orgRouter.setLogo(selectedOrg.id, fileURL)
            AppToaster.show({
                message: i18n.t('feedback.successes.imageUploadedSuccessfully'),
                intent: Intent.SUCCESS,
            })
            if (onClose) {
                onClose()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLogoUploading(false)
        }
    }

    const onDrop = useCallback((acceptedFile: File[]) => {
        if (acceptedFile.length > 0) {
            handleUploadFile(acceptedFile)
        } else {
            AppToaster.show({
                message: i18n.t('feedback.errors.fileNotAccepted'),
                intent: Intent.DANGER,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        maxSize: 1048576,
    })

    return (
        <div className='custom-panel'>
            <div className='custom-panel-selected-org'>
                <h2>{selectedOrg?.name}</h2>
                {selectedOrg?.imageURL ? (
                    <img
                        className='img-fluid border checkered-background'
                        src={selectedOrg?.imageURL}
                        alt={selectedOrg?.name}
                    />
                ) : (
                    <span className='text-danger'>No logo uploaded</span>
                )}
                <div {...getRootProps()} className='custom-dropzone mt-4'>
                    <input {...getInputProps()} />
                    {!isLogoUploading ? (
                        <div className='text-center'>
                            <h6>{i18n.t('settingsPage.organisationLogoUploadHelpText')}</h6>
                            <h6>
                                <em>{i18n.t('settingsPage.organisationLogoUploadFileTypes')}</em>
                            </h6>
                        </div>
                    ) : (
                        <ProgressBar />
                    )}
                </div>
            </div>
        </div>
    )
}
