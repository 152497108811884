export enum ErrorCode {
    adapterMissing = 'adapterMissing',
    adapterIpMissing = 'adapterIpMissing',
    adapterDhcpRangeTooSmall = 'adapterDhcpRangeTooSmall',
    adapterDhcpConflict = 'adapterDhcpConflict',
    adapterHalfDuplex = 'adapterHalfDuplex',
    tileCalibrationMissing = 'tileCalibrationMissing',
    screenInputFault = 'screenInputFault',
    screenMapFileMissing = 'screenMapFileMissing',
    tileCannotCommunicate = 'tileCannotCommunicate',
    tileVsyncDroppedCritical = 'tileVsyncDroppedCritical',
    tileIncompleteFrameCritical = 'tileIncompleteFrameCritical',
    cloudConfigurationMismatch = 'cloudConfigurationMismatch',
    adapterUnknown = 'adapterUnknown',
    adapterNotSetToEnumerate = 'adapterNotSetToEnumerate',
    adapterExtraTiles = 'adapterExtraTiles',
    adapterAutoEnumerateFailed = 'adapterAutoEnumerateFailed',
    tileLocalTempCritical = 'tileLocalTempCritical',
    tileLedTempCritical = 'tileLedTempCritical',
    controllerCpuLoading = 'controllerCpuLoading',
    adapterNetworkLoading = 'adapterNetworkLoading',
    tileStackHeadroom = 'tileStackHeadroom',
    tileIpMissing = 'tileIpMissing',
    tileMacMissing = 'tileMacMissing',
    adapterTileMissing = 'adapterTileMissing',
    cloudNoSnapshot = 'cloudNoSnapshot',
    tileChainError = 'tileChainError',
    tileDeadLed = 'tileDeadLed',
    adapterArpTimeout = 'adapterArpTimeout',
    tileFirmwareOldLocalBoard = 'tileFirmwareOldLocalBoard',
    tileFirmwareOldLedBoard = 'tileFirmwareOldLedBoard',
    adapterLocalFirmwareMismatch = 'adapterLocalFirmwareMismatch',
    adapterLedFirmwareMismatch = 'adapterLedFirmwareMismatch',
    screenProofCannotRun = 'screenProofCannotRun',
    screenInputTooSmall = 'screenInputTooSmall',
    tileAvgVsync = 'tileAvgVsync',
    tileAvgPhase = 'tileAvgPhase',
    tileVsyncDroppedWarning = 'tileVsyncDroppedWarning',
    tileIncompleteFrameWarning = 'tileIncompleteFrameWarning',
    tileLocalTempWarning = 'tileLocalTempWarning',
    tileLedTempWarning = 'tileLedTempWarning',
    internalBufferCorruption = 'internalBufferCorruption',
    lednetUpdateFailed = 'lednetUpdateFailed',
    inputWindowLock = 'inputWindowLock',
    inputWindowMissing = 'inputWindowMissing',
    daemonNotAdmin = 'daemonNotAdmin',
    daemonNotRunning = 'daemonNotRunning',
    screenProofFrozen = 'screenProofFrozen',
    screenProofBlank = 'screenProofBlank',

    unknown = 'Unknown',
}

export enum ErrorSeverity {
    noError = 0,
    warning = 1,
    criticalWarning = 2,
    visualFault = 3,
    noConnection = 4,
}

export const errorCodeSeverity = (code: ErrorCode): ErrorSeverity => {
    switch (code) {
        case ErrorCode.adapterMissing:
        case ErrorCode.adapterIpMissing:
        case ErrorCode.adapterDhcpRangeTooSmall:
        case ErrorCode.adapterDhcpConflict:
        case ErrorCode.adapterHalfDuplex:
        case ErrorCode.adapterUnknown:
        case ErrorCode.screenInputFault:
        case ErrorCode.tileCalibrationMissing:
        case ErrorCode.screenMapFileMissing:
        case ErrorCode.tileCannotCommunicate:
        case ErrorCode.adapterNotSetToEnumerate:
        case ErrorCode.adapterExtraTiles:
        case ErrorCode.adapterAutoEnumerateFailed:
        case ErrorCode.adapterTileMissing:
        case ErrorCode.internalBufferCorruption:
        case ErrorCode.lednetUpdateFailed:
        case ErrorCode.inputWindowMissing:
        case ErrorCode.screenProofBlank:
        case ErrorCode.screenProofFrozen:
            return ErrorSeverity.visualFault
        case ErrorCode.tileVsyncDroppedCritical:
        case ErrorCode.tileIncompleteFrameCritical:
        case ErrorCode.cloudConfigurationMismatch:
        case ErrorCode.tileLocalTempCritical:
        case ErrorCode.tileLedTempCritical:
            return ErrorSeverity.criticalWarning
        case ErrorCode.daemonNotAdmin:
        case ErrorCode.daemonNotRunning:
        case ErrorCode.tileVsyncDroppedWarning:
        case ErrorCode.tileChainError:
        case ErrorCode.tileIncompleteFrameWarning:
        case ErrorCode.tileLocalTempWarning:
        case ErrorCode.tileLedTempWarning:
        case ErrorCode.controllerCpuLoading:
        case ErrorCode.adapterNetworkLoading:
        case ErrorCode.tileStackHeadroom:
        case ErrorCode.tileIpMissing:
        case ErrorCode.tileMacMissing:
        case ErrorCode.cloudNoSnapshot:
        case ErrorCode.tileDeadLed:
        case ErrorCode.adapterArpTimeout:
        case ErrorCode.tileFirmwareOldLocalBoard:
        case ErrorCode.tileFirmwareOldLedBoard:
        case ErrorCode.adapterLocalFirmwareMismatch:
        case ErrorCode.adapterLedFirmwareMismatch:
        case ErrorCode.screenProofCannotRun:
        case ErrorCode.screenInputTooSmall:
        case ErrorCode.tileAvgVsync:
        case ErrorCode.tileAvgPhase:
        case ErrorCode.inputWindowLock:
            return ErrorSeverity.warning
        default:
            return ErrorSeverity.warning
    }
}

export enum ErrorSource {
    tile = 'tile',
    controller = 'controller',
    cloud = 'cloud',
}

export enum ErrorDataKey {
    screen = 'screen',
    tile = 'tile',
    adapter = 'adapter',
    controller = 'controller',
    tnl = 'tnl',
    time = 'time',
    isAssigned = 'isAssigned',
    // Generated by controller
    screenId = 'screenId',
    tileId = 'tileId',
    adapterId = 'adapterId',
    controllerId = 'controllerId',
    load = 'load',
    available = 'available',
    found = 'found',
    timeout = 'timeout',
    ipAddresses = 'ipAddresses',
    versions = 'versions',
    screenIds = 'screenIds',
    input = 'input',
    sections = 'sections',
    lastSeen = 'lastSeen',
    temperature = 'temperature',
    count = 'count',
    headroom = 'headroom',
    version = 'version',
    actualAvg = 'actualAvg',
    required = 'required',
    expected = 'expected',
    fault = 'fault',
    filenames = 'filenames',
    targetAvg = 'targetAvg',
    exe = 'exe',
    tnls = 'tnls',
    width = 'width',
    height = 'height',
    // Generated by backend
    user = 'user',
}

export enum DiagnosticsUpdateCommand {
    newErrors = 'newErrors',
    resolvedErrors = 'resolvedErrors',
    screenState = 'screenState',
}

export const UpgradeMap: Map<ErrorCode, ErrorCode> = new Map<ErrorCode, ErrorCode>()
UpgradeMap.set(ErrorCode.tileIncompleteFrameWarning, ErrorCode.tileIncompleteFrameCritical)
UpgradeMap.set(ErrorCode.tileLedTempWarning, ErrorCode.tileLedTempCritical)
UpgradeMap.set(ErrorCode.tileLocalTempWarning, ErrorCode.tileLocalTempCritical)
UpgradeMap.set(ErrorCode.tileVsyncDroppedWarning, ErrorCode.tileVsyncDroppedCritical)

export const DowngradeMap: Map<ErrorCode, ErrorCode> = new Map<ErrorCode, ErrorCode>()
UpgradeMap.forEach((value, key) => DowngradeMap.set(value, key))
