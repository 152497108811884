import { action, observable, runInAction } from 'mobx'
import { IViewModel } from 'mobx-utils'

import RootStore from 'src/common/RootStore'
import DomainStore from './DomainStore'

import User from '../models/User'

import userRouter from 'src/api/userRouter'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import { UserNotification, UserNotificationType, AlertMethod, UserPreferences } from '../models/UserSettings'
import { RoleType } from '../models/Roles'
import { migration1UserPreferences } from '../migrations/BrowserStorageMigrations'
import i18n from 'src/i18n'
import moment from 'moment'
import 'moment-timezone'
import { updateSentry } from '../utils/Environment'
import { browserStorageManager, StorageKey } from '../managers/BrowserStorageManager'

export default class UserStore extends DomainStore<User> {
    @observable isUpdating: boolean
    @observable me: User | null

    private existingPreferences = !!migration1UserPreferences

    constructor(rootStore: RootStore) {
        super(rootStore)
        this.router = userRouter
        this.storeName = 'User'

        if (migration1UserPreferences) {
            this.restoreMigrations()
        }
    }

    async restoreMigrations() {
        await this.fetchLoggedInUser()
        // Only restore if no preferences retrieved from API
        if (!this.me?.preferences) {
            // Save preferences retrieved from local storage
            this.saveUserPreferences(migration1UserPreferences, false)
        }
    }

    async fetchLoggedInUser() {
        try {
            const user = await userRouter.readMe()

            runInAction('setUser', () => {
                this.me = user
            })

            if (!user.id) {
                return
            }
            const lastLoggedInUser = browserStorageManager.readLocalStorageString(StorageKey.userId)
            if (lastLoggedInUser !== user.id) {
                browserStorageManager.clearNonProtectedKeys()
                browserStorageManager.runMigrations()
            }
            browserStorageManager.updateLocalStorageItem(StorageKey.userId, user.id)

            if (!user.preferences && !this.existingPreferences) {
                // Set default language and timeZone for new user
                this.saveUserPreferences(
                    new UserPreferences({
                        language: user.language,
                        timeZone: user.timeZone,
                        timeFormat: null,
                        dateFormat: null,
                    }),
                    false
                )
            }

            // Set moment and i18n values
            moment.locale(user.locale)
            moment.tz.setDefault(user.timeZone)
            i18n.changeLanguage(user.language)

            updateSentry(user)
        } catch (err) {
            console.error('Error updating user', err)
        }
    }

    @action updateUserDetails = async (pendingUserDetails: User & IViewModel<User>): Promise<void> => {
        this.isUpdating = true

        // Don't update user notifications
        pendingUserDetails.notifications = null

        try {
            await this.router.update(pendingUserDetails)

            // Reset notifications property before copying new values to the user object
            pendingUserDetails.resetProperty('notifications')
            pendingUserDetails.submit()

            AppToaster.show({
                message: 'Successfully updated user details',
                intent: Intent.SUCCESS,
            })
        } catch (err) {
            console.error(err)

            AppToaster.show({
                message: 'Error updating user details',
                intent: Intent.DANGER,
            })
        } finally {
            runInAction('finishedUpdatingUserDetails', () => {
                this.isUpdating = false
            })
        }
    }

    @action updateRole = (roles: RoleType[] | null) => {
        if (!this.me) {
            return
        }
        if (roles) {
            this.me.roles = roles
        }
    }

    @action clearUser = () => {
        this.me = null
    }

    @action saveUserPreferences = async (preferences: UserPreferences, showToast = true) => {
        this.isUpdating = true

        try {
            await userRouter.updateUserPreferences(preferences.toJSON())

            await this.fetchLoggedInUser()

            if (showToast) {
                AppToaster.show({
                    message: 'Successfully updated preferences',
                    intent: Intent.SUCCESS,
                })
            }
        } catch (err) {
            if (showToast) {
                AppToaster.show({
                    message: 'Error updating preferences',
                    intent: Intent.DANGER,
                })
            }

            console.error(err)
        } finally {
            runInAction('finishedUpdatingUserPreferences', () => {
                this.isUpdating = false
            })
        }
    }

    @action saveUserNotifications = async (notifications: Map<UserNotificationType, AlertMethod[]>) => {
        this.isUpdating = true

        // TODO: There must be a better way to coerce this into Notification type
        const userNotifications: UserNotification[] = []
        Array.from(notifications).forEach(notification => {
            userNotifications.push(
                new UserNotification({
                    type: notification[0],
                    methods: notification[1],
                })
            )
        })

        try {
            await userRouter.updateUserNotifications(userNotifications)

            await this.fetchLoggedInUser()

            AppToaster.show({
                message: 'Successfully updated notification preferences',
                intent: Intent.SUCCESS,
            })
        } catch (err) {
            AppToaster.show({
                message: 'Error updating notification preferences',
                intent: Intent.DANGER,
            })

            console.error(err)
        } finally {
            runInAction('finishedUpdatingUserNotifications', () => {
                this.isUpdating = false
            })
        }
    }
}
