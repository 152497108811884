import i18n from 'src/i18n'

import { PubSub } from 'aws-amplify'

import { observable, action, computed, reaction } from 'mobx'

import { AppBanner } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import RootStore from '../RootStore'
import MqttSubscription from '../models/MqttSubscription'

const BASE_TOPIC = process.env.REACT_APP_IOT_BASE_TOPIC

const connectedInterval = 60000
const disconnectedInterval = 2000 // TODO: make this value backoff so we don't hammer the network

export default class MqttStore {
    private mqttCurrentSubscriptions = new Map<string, MqttSubscription>()

    isFirstPing = true
    @observable isConnected = true
    @observable isSocketConnected = true
    timeout: NodeJS.Timeout

    rootStore: RootStore

    // React to isConnected changes
    showConnectionError = reaction(
        () => this.isConnected,
        connected => {
            if (!connected) {
                // Show error banner if connection lost
                AppBanner.show(
                    {
                        icon: 'satellite',
                        intent: Intent.DANGER,
                        message: i18n.t('network.errors.lostConnection'),
                        timeout: 0,
                    },
                    'lostConnection'
                )
                // Dismiss socket error banner
                AppBanner.dismiss('socketError')
            } else {
                // Dismiss lost connection toast
                AppBanner.dismiss('lostConnection')
            }
        },
        {
            fireImmediately: true,
        }
    )

    // React to socketConnected changes
    showSocketError = reaction(
        () => this.socketConnected,
        connected => {
            if (!connected && this.isConnected) {
                // Show error banner if there's a problem with socket but there's still a network connection
                AppBanner.show(
                    {
                        icon: 'feed',
                        intent: Intent.DANGER,
                        message: i18n.t('network.errors.socketError'),
                        timeout: 0,
                    },
                    'socketError'
                )
            } else {
                // Dismiss socket error banner
                AppBanner.dismiss('socketError')
            }
        },
        {
            fireImmediately: true,
        }
    )

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.handleConnectionChange()
        window.addEventListener('online', this.handleConnectionChange)
        window.addEventListener('offline', this.handleConnectionChange)
    }

    @computed get socketConnected(): boolean {
        return this.isConnected && this.isSocketConnected
    }

    @action handleConnectionChange = () => {
        if (navigator.onLine) {
            this.getPingInterval(disconnectedInterval, this.isFirstPing)
            this.isFirstPing = false
            return
        }
        console.error('network disconnected')
        this.setConnected(false, false)
    }

    @action setConnected = (connected: boolean, hasNetwork: boolean) => {
        if (connected !== this.isConnected) {
            this.isConnected = connected
            clearInterval(this.timeout)
            if (hasNetwork) {
                this.getPingInterval(connected ? connectedInterval : disconnectedInterval, false)
            }
            if (connected) {
                this.refreshAll()
            }
        }
    }

    getPingInterval = (ping: number, firstPing: boolean) => {
        this.timeout = setInterval(() => {
            fetch('//google.com', {
                mode: 'no-cors',
            })
                .then(() => {
                    this.setConnected(true, true)
                    if (this.isConnected && firstPing) {
                        clearInterval(this.timeout)
                        this.getPingInterval(connectedInterval, false)
                    }
                })
                .catch(() => {
                    console.error('cannot reach the cloud')
                    this.setConnected(false, true)
                })
        }, ping)
    }

    addSubscription = (subscription: MqttSubscription) =>
        this.mqttCurrentSubscriptions.set(subscription.subscriptionKey, subscription)

    getSubscription = (subscriptionKey: string) => this.mqttCurrentSubscriptions.get(subscriptionKey)

    hasSubscription = (subscriptionKey: string) => this.mqttCurrentSubscriptions.has(subscriptionKey)

    publish = (publishTopicString: string, message: any) => {
        if (!this.socketConnected) {
            console.error('Unable to publish to', publishTopicString, 'network disconnected')
            return
        }

        PubSub.publish(BASE_TOPIC + publishTopicString, message, {
            options: [],
        }).catch(error => console.error('Publish error', error))
    }

    // Call refresh methods for all subscriptions
    refreshAll = () => {
        this.mqttCurrentSubscriptions.forEach((v, _) => {
            if (v.onStale) {
                v.onStale()
            }
        })
    }
}
