import * as React from 'react'

// i18n
import i18n from 'src/i18n'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import candelicLoader from 'src/assets/images/candelicLoader.gif'

@inject('store')
@observer
class SignOut extends React.Component<{ store?: RootStore }> {
    render() {
        return (
            <div className='auth-container'>
                <div className='auth-loading'>
                    <img src={candelicLoader} alt='Loading' height={58} />
                    <h2 className='mt-3'>{i18n.t('authPage.signingOut')}</h2>
                </div>
            </div>
        )
    }
}

export default SignOut
