import * as React from 'react'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import LiveFilters from './components/LiveFilters'
import LiveGrid from './components/LiveGrid'
import ScreenSummaryDrawer from './components/ScreenSummaryDrawer'

@inject('store')
@observer
class Live extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        this.props.store!.liveUIStore.initialiseSubscription()
    }

    render() {
        return (
            <React.Fragment>
                <LiveFilters />
                <LiveGrid />
                <ScreenSummaryDrawer />
            </React.Fragment>
        )
    }
}

export default Live
