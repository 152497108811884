import { action, computed, observable } from 'mobx'
import { rootStore } from '../RootStore'

import { PubSub } from 'aws-amplify'

import { StatCommand } from './ControllerStat'
import { RenewCommand } from './MqttEnums'
import { ConsoleCommand } from './ConsoleEnums'

const BASE_TOPIC = process.env.REACT_APP_IOT_BASE_TOPIC
export const TTL_INTERVAL = 1000 * 0.1 // Convert s to ms, divide by frequency to send TTL

export type KeepAliveCommand = RenewCommand | StatCommand | ConsoleCommand

export default class MqttSubscription {
    @observable private subscription?: ZenObservable.Subscription

    @observable inboundTopic: string
    @observable outboundTopic?: string
    @observable subscriptionKey: string
    @observable ttl?: number
    @observable keepAliveCommand?: KeepAliveCommand

    @observable expiresAt?: number

    @observable callback: (payload: any) => void
    @observable onStale?: () => void
    @observable onClose?: () => void

    constructor({
        topics,
        subscriptionKey,
        callback,
    }: {
        topics: [string, string?]
        subscriptionKey: string
        callback: (payload: any) => void
    }) {
        this.inboundTopic = topics[0]
        if (topics[1]) {
            this.outboundTopic = topics[1]
        }
        this.subscriptionKey = subscriptionKey
        this.callback = callback
    }

    @action setTTL = (ttl: number) => {
        this.ttl = ttl
        return this
    }

    @action setKeepAliveCommand = (keepAliveCommand: KeepAliveCommand) => {
        this.keepAliveCommand = keepAliveCommand
        return this
    }

    @action setOnStale = (onStale: () => void) => {
        this.onStale = onStale
        return this
    }

    @action setOnClose = (onClose: () => void) => {
        this.onClose = onClose
        return this
    }

    @action subscribe = () => {
        if (!rootStore.userStore.me) {
            return
        }
        if (!rootStore.userStore.me.iotPolicyAttached) {
            console.error(`Unable to subscribe to ${this.inboundTopic} no IOT policy for user`)
            return
        }
        if (rootStore.mqttStore.hasSubscription(this.subscriptionKey)) {
            console.error('Subscription already exists')
            return
        }
        if (!rootStore.mqttStore.isConnected) {
            console.error(`Unable to subscribe to ${this.inboundTopic} network disconnected`)
            return
        }

        if (this.ttl) {
            this.expiresAt = Date.now() + this.ttl * 1000 // ms
        }

        const subscriptionKey = this.subscriptionKey

        this.subscription = PubSub.subscribe(BASE_TOPIC + this.inboundTopic, {
            subscriptionKey,
        }).subscribe({
            next: (data: any) => {
                this.callback(data)
            },
            error: (errorValue: any) => {
                this.unsubscribe()
                console.error('Subscribe error', errorValue.errorCode)
            },
        })

        rootStore.mqttStore.addSubscription(this)

        if (this.shouldKeepAlive) {
            this.handleKeepAlive()
        }

        return this
    }

    @action private handleKeepAlive = () => {
        if (!this.outboundTopic || !this.keepAliveCommand || this.ttl === undefined) {
            console.error('Error renewing subscription', this.subscriptionKey)
            return
        }

        setInterval(() => {
            // Check if the subscription has expired
            if (this.isStale) {
                if (this.onStale) {
                    // Refresh stale data if we have a callback
                    this.onStale()
                } else {
                    // Otherwise, unsubscribe as the server will no longer send data for this subscription
                    this.unsubscribe()
                    return
                }
            }
            rootStore.mqttStore.publish(this.outboundTopic!, {
                command: this.keepAliveCommand,
                subscriptionKey: this.subscriptionKey,
            })

            this.expiresAt = Date.now() + this.ttl! * 1000
        }, this.ttl * TTL_INTERVAL)
    }

    unsubscribe = () => {
        if (this.onClose) {
            this.onClose()
        }

        this.subscription?.unsubscribe()
    }

    @computed get isStale(): boolean {
        if (!this.expiresAt) {
            return false
        }
        return this.expiresAt < Date.now()
    }

    @computed get shouldKeepAlive(): boolean {
        return !!this.ttl && this.ttl > 0 && !!this.keepAliveCommand && !!this.outboundTopic
    }
}
