import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import views, { DiagnosticsGlobalTab } from 'src/config/views'

import { TabContent, TabPane } from 'reactstrap'
import NavTabs, { NavTab } from 'src/common/components/navigation/NavTabs'
import GlobalDigest from './submodules/digest/GlobalDigest'

@inject('store')
@observer
export default class GlobalDiagnostics extends React.Component<{ store?: RootStore }> {
    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return
        }

        const router = this.props.store!.router
        const currentView = router.params.view || DiagnosticsGlobalTab.digest

        const tabs: NavTab[] = Object.values(DiagnosticsGlobalTab).map(view => ({
                name: i18n.t('diagnosticsPage.screenTabs.' + view),
                view: views.diagnosticsGlobal,
                params: { ...router.params, tab: view },
                active: currentView === view,
            }))

        return (
            <div className='diagnostics-global-container'>
                <NavTabs tabs={tabs} showDropdownOnMobile />
                <TabContent activeTab={currentView}>
                    <TabPane tabId={DiagnosticsGlobalTab.digest}>
                        <GlobalDigest />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}
