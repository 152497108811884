import * as React from 'react'
import { useCallback } from 'react'

// i18n
import i18n from 'src/i18n'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import PlayerSettings from './components/PlayerSettings'
import PlayerTable from './components/PlayerTable'
import { popouts } from 'src/common/components/popout/popouts'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { useDropzone } from 'react-dropzone'
import { Button, FormGroup, Input, Form } from 'reactstrap'
import { Alert, Icon, Intent } from '@blueprintjs/core'
import { ExternalToaster } from 'src/common/components/AppToaster'
import ScheduleDialog from './components/ScheduleDialog'
import LockedOverlay from 'src/common/components/LockedOverlay'
import candelicLoader from '../../../../assets/images/candelicLoader.gif'
import searchIcon from '../../../../assets/images/searchIcon.svg'
import refreshIcon from '../../../../assets/images/refreshIcon.svg'
import uploadIcon from '../../../../assets/images/uploadIcon.svg'
import addIcon from '../../../../assets/images/addIcon.svg'
import moment from 'moment'
import 'moment-duration-format'
import SyncState from 'src/common/components/SyncState'
import Select from 'react-select'
import { DropdownIndicator, Option } from 'src/common/components/SelectComponents'

interface DropzoneProps {
    handleUploadfiles: (fileList: File[]) => void
    onDrop: (event: any) => false | undefined
    isDragging: boolean
    playerDiv?: HTMLElement
}

function Dropzone(props: DropzoneProps) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            // Clear dragging state
            props.onDrop(null)

            if (acceptedFiles.length > 0) {
                props.handleUploadfiles(acceptedFiles)
            } else {
                if (props.playerDiv) {
                    const toaster = ExternalToaster(props.playerDiv)

                    toaster.show({
                        message: i18n.t('feedback.errors.fileNotAccepted'),
                        intent: Intent.DANGER,
                    })
                }
            }
        },
        [props]
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        maxSize: 2097152,
    })

    return (
        <div className='dropzone-input-wrapper' {...getRootProps()}>
            <Button className='custom-button-upload' onClick={eventPreventDefault}>
                {!props.isDragging && <input {...getInputProps()} />}
                <img src={uploadIcon} alt='Upload' height={25} draggable={false} onDragStart={eventPreventDefault} />
                <h4>Click to add files or drag items anywhere on this window</h4>
            </Button>
            {props.isDragging && (
                <div className='custom-dropzone-fullscreen'>
                    <div className='custom-dropzone'>
                        <input {...getInputProps()} />
                        <h3>{i18n.t('livePage.player.mediaUploadHelpText')}</h3>
                        <h3>
                            <em>{i18n.t('livePage.player.mediaUploadFileTypes')}</em>
                        </h3>
                    </div>
                </div>
            )}
        </div>
    )
}

interface PlayerProps {
    screenId: string
}

@inject('store')
@observer
class Player extends React.Component<PlayerProps & { store?: RootStore }> {
    // Prevents dragleave from firing when hovering a child element
    private dragCounter = 0
    private playerDiv?: HTMLElement
    private mediaManager = this.props.store!.mediaStore.getMediaManager(this.props.screenId)
    private playerManager = this.props.store!.playerStore.managerMap.get(this.props.screenId)

    componentDidMount = () => {
        this.mediaManager.fetchPlayerMedia()

        // Find player div in popouts
        Object.entries(popouts).forEach(entry => {
            if (entry[0] === this.props.screenId && entry[1].child) {
                this.playerDiv = entry[1].child.document.body
            }
        })
    }

    componentDidUpdate = () => {
        if (this.playerDiv) {
            this.playerDiv.addEventListener('dragenter', this.onDragEnter)
            this.playerDiv.addEventListener('dragleave', this.onDragLeave)
        }
    }

    componentWillUnmount() {
        if (this.playerDiv) {
            this.playerDiv.removeEventListener('dragenter', this.onDragEnter)
            this.playerDiv.removeEventListener('dragleave', this.onDragLeave)
        }
    }

    onDragEnter = (event: any) => {
        // NOTE: The DataTransfer.types read-only property returns an array of the drag data
        // formats (as strings) that were set in the drag event
        // Here, we detect if the drag data payload contains files. This prevents the
        // player getting stuck in a "dragging" state, since text won't trigger drop event
        // DataTransfer API: https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
        const dragHasFiles = event.dataTransfer.types.includes('Files')
        if (!this.playerManager || !dragHasFiles) {
            return
        }

        this.dragCounter++

        this.playerManager.setIsDragging(true)
        event.stopPropagation()
        event.preventDefault()
        return false
    }

    onDragLeave = (event: any) => {
        if (!this.playerManager) {
            return
        }

        this.dragCounter--

        if (this.dragCounter === 0) {
            this.playerManager.setIsDragging(false)
        }
        if (event) {
            event.stopPropagation()
            event.preventDefault()
        }
        return false
    }

    handleSearchQueryChange = (event: any) => {
        if (!this.playerManager) {
            return
        }

        this.playerManager.updateSearchQuery(event.target.value, ['name'])
    }

    handleUploadFiles = (fileList: File[]) => {
        if (!this.props.screenId) {
            return
        }

        this.mediaManager.addFiles(fileList)
    }

    handleSaveMedia = () => {
        this.mediaManager.saveMediaChanges()
    }

    handleClosePlayer = () => {
        if (!this.playerManager) {
            return
        }

        // If unsaved changes, prompt user to confirm close
        if (this.mediaManager.hasScreenUnsavedChanges) {
            this.playerManager.toggleCloseAlert()
        } else {
            this.playerManager.togglePopout()
        }
    }

    handleRefresh = () => {
        if (!this.playerManager) {
            return
        }

        // If unsaved changes, prompt user to confirm refresh
        if (this.mediaManager.hasScreenUnsavedChanges) {
            this.playerManager.toggleRefreshAlert()
        } else {
            this.confirmRefreshPlayer()
        }
    }

    confirmRefreshPlayer = () => {
        if (!this.playerManager) {
            return
        }

        this.playerManager.refreshPlayerMedia()
    }

    handleAddGroup = () => {
        this.mediaManager.addMediaGroup()
    }

    handleRemoveGroup = (group: number) => {
        this.mediaManager.removeMediaGroup(group)
    }

    handleChangeGroup = (group: number) => {
        this.mediaManager.setActiveGroup(group)
    }

    render() {
        const currentScreen = this.props.store!.screenStore.findItem(this.props.screenId)
        if (!currentScreen) {
            return null
        }

        const mediaManager = this.mediaManager
        if (!mediaManager.mediaContainer || !this.playerManager) {
            return (
                <LockedOverlay isFullscreen>
                    <img src={candelicLoader} alt='Loading' height={58} />
                </LockedOverlay>
            )
        }

        const groupOptions = Array(mediaManager.mediaGroupCount)
            .fill(null)
            .map((_, i) => ({
                label: `Group ${i + 1}`,
                value: i + 1,
            }))

        return (
            <div id={'player-' + this.props.screenId} style={{ minWidth: 780 }}>
                {(mediaManager.isFetching || mediaManager.isUpdating) && (
                    <LockedOverlay isFullscreen>
                        <div className='player-loading'>
                            {mediaManager.isFetching ? (
                                <h6>Loading</h6>
                            ) : mediaManager.isUpdating ? (
                                <h6>Saving changes</h6>
                            ) : null}
                        </div>
                    </LockedOverlay>
                )}
                <div className='player'>
                    <div className='player-header'>
                        <h1>{currentScreen.name} Adhoc</h1>
                        <Button
                            className='player-refresh-button custom-button-secondary custom-button-small custom-button-icon-right'
                            onClick={this.handleRefresh}
                        >
                            Refresh <img src={refreshIcon} alt='Refresh' />
                        </Button>
                    </div>
                    <br />
                    <PlayerSettings screen={currentScreen} isUpdating={mediaManager.isUpdating} />
                    <hr />
                    <Form className='player-actions'>
                        <FormGroup className='custom-form-group custom-form-group-inline custom-search custom-search-large'>
                            <img src={searchIcon} alt='Search' className='custom-search-icon' />
                            <Input
                                onChange={this.handleSearchQueryChange}
                                type='search'
                                placeholder={i18n.t('actions.search')}
                                autoComplete='off'
                            />
                        </FormGroup>
                        <Dropzone
                            handleUploadfiles={this.handleUploadFiles}
                            onDrop={this.onDragLeave}
                            isDragging={this.playerManager.isDragging}
                            playerDiv={this.playerDiv}
                        />
                    </Form>

                    {mediaManager.mediaGroupsEnabled && (
                        <Form className='player-actions'>
                            <FormGroup className='custom-form-group custom-form-group-inline'>
                                <Button
                                    className='custom-button-secondary custom-button-small custom-button-icon-right'
                                    onClick={this.handleAddGroup}
                                >
                                    Add group <img src={addIcon} height={16} alt='' />
                                </Button>
                            </FormGroup>
                            {mediaManager.hasMediaGroups && (
                                <>
                                    <FormGroup className='custom-form-group custom-form-group-inline'>
                                        <div className='d-flex'>
                                            <Select
                                                className='custom-select-wrapper'
                                                classNamePrefix='custom-select'
                                                isSearchable={false}
                                                blurInputOnSelect
                                                onChange={option => option && this.handleChangeGroup(option.value)}
                                                options={groupOptions}
                                                value={groupOptions[mediaManager.activeMediaGroup - 1]}
                                                components={{ DropdownIndicator, Option }}
                                            />
                                            {mediaManager.mediaGroupCount > 0 && (
                                                <Button
                                                    className='custom-button-small'
                                                    color='danger'
                                                    onClick={() =>
                                                        this.handleRemoveGroup(mediaManager.activeMediaGroup)
                                                    }
                                                >
                                                    <Icon icon='trash' size={16} color='white' />
                                                </Button>
                                            )}
                                        </div>
                                    </FormGroup>
                                    <div className='text-white'>
                                        {mediaManager.displayMediaList.length > 0 && (
                                            <span>
                                                {mediaManager.currentGroupMediaList?.length} creatives currently playing
                                                in group, loop time{' '}
                                                {moment
                                                    .duration(mediaManager.currentGroupMediaTime, 'seconds')
                                                    .format('H[h] m[m] s[s]')}
                                            </span>
                                        )}
                                    </div>
                                </>
                            )}
                        </Form>
                    )}

                    <PlayerTable
                        screenId={this.props.screenId}
                        isFetching={mediaManager.isFetching}
                        isUpdating={mediaManager.isUpdating}
                        playerDiv={this.playerDiv}
                    />
                </div>
                <div className='player-footer-actions'>
                    <Button className='custom-button-link' onClick={this.handleClosePlayer} color='link'>
                        <span>Cancel</span>
                    </Button>
                    <div className='media-playlist-summary'>
                        {mediaManager.combinedMediaList.length > 0 && (
                            <span>
                                {mediaManager.currentMediaList.length} creatives currently playing, loop time{' '}
                                {moment.duration(mediaManager.currentMediaTime, 'seconds').format('H[h] m[m] s[s]')}
                            </span>
                        )}
                    </div>
                    <div className='save-playlist'>
                        <SyncState size={20} controller={currentScreen.controller} playerDiv={this.playerDiv} />
                        <Button
                            className={
                                'custom-button-large save-media-button' + (!currentScreen.canEdit ? ' invisible' : '')
                            }
                            onClick={this.handleSaveMedia}
                            color='primary'
                            disabled={!mediaManager.hasScreenMediaChanges}
                        >
                            Save media changes
                        </Button>
                    </div>
                </div>
                {/* Schedule media dialog */}
                <ScheduleDialog mediaManager={mediaManager} playerDiv={this.playerDiv} />
                {/* Close player alert*/}
                <Alert
                    className='custom-alert '
                    cancelButtonText='Cancel'
                    confirmButtonText='Discard changes'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={this.playerManager.isCloseAlertOpen}
                    onCancel={this.playerManager.toggleCloseAlert}
                    onConfirm={this.playerManager.confirmClosePlayer}
                    portalContainer={this.playerDiv}
                >
                    <p>Your changes will be discarded, are you sure you want to continue?</p>
                </Alert>
                {/* Refresh alert */}
                <Alert
                    className='custom-alert '
                    cancelButtonText='Cancel'
                    confirmButtonText='Discard changes'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={this.playerManager.isRefreshAlertOpen}
                    onCancel={this.playerManager.toggleRefreshAlert}
                    onConfirm={this.confirmRefreshPlayer}
                    portalContainer={this.playerDiv}
                >
                    <p>Your changes will be discarded, are you sure you want to continue?</p>
                </Alert>
            </div>
        )
    }
}

export default Player
