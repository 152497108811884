import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'
import views from 'src/config/views'

import { capitalizeFirstLetter } from 'src/common/utils/String'
import { secondsDurationToString } from 'src/common/utils/Duration'
import { millisecondsToString } from 'src/common/utils/Number'

import ConsoleButton from 'src/common/components/ConsoleButton'
import DiagnosticsBackArrow from '../submodules/tile-view/components/DiagnosticsBackArrow'
import { Button } from 'reactstrap'
import { Tooltip, Position, Icon, Intent } from '@blueprintjs/core'
import tileIcon from 'src/assets/images/tileIcon.svg'
import controllerIcon from 'src/assets/images/controllerIcon.svg'

@inject('store')
@observer
class ScreenSummary extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleOpenTileView = () => {
        this.props.store!.router.goTo(views.diagnosticsScreen, this.props.store!.router.params, this.props.store!, {
            tileView: true,
        })
    }

    render() {
        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        const currentScreen = screenDiagnosticsUIStore.currentScreen
        const me = this.props.store!.userStore.me
        if (!currentScreen || !me) {
            return null
        }

        const currentScreenConfiguration = screenDiagnosticsUIStore.currentScreenConfiguration

        const isScreenConfigurationPopulating = this.props.store!.screenDiagnosticsUIStore
            .isScreenConfigurationPopulating
        const isErrorsFetching = this.props.store!.diagnosticsStore.isErrorsFetching
        const isMobile = this.props.store!.appUIStore.isMobile

        const totalUnassignedErrors = currentScreen.errorGroup?.totalUnassignedErrors ?? 0

        return (
            <div className='screen-summary-container'>
                <div className='screen-summary-header'>
                    <div className='screen-summary'>
                        <div className='screen-name selectable-text'>
                            {isMobile && (
                                <div className='mr-3'>
                                    <DiagnosticsBackArrow mobileOnly />
                                </div>
                            )}
                            <h2>{currentScreen.name}</h2>
                        </div>
                        <div className='screen-details'>
                            <div className='details'>
                                <h4>Sections</h4>
                                <span className={isScreenConfigurationPopulating ? 'bp3-skeleton' : ''}>
                                    <h5>
                                        {currentScreenConfiguration
                                            ? currentScreenConfiguration.sections.length
                                            : '???'}
                                    </h5>
                                </span>
                            </div>
                            <div className='details'>
                                <h4>Tiles</h4>
                                <span className={isScreenConfigurationPopulating ? 'bp3-skeleton' : ''}>
                                    <h5>{currentScreenConfiguration ? currentScreenConfiguration.tileCount : '???'}</h5>
                                </span>
                            </div>
                            <div className='details'>
                                <h4>Errors</h4>
                                <h5 className={isErrorsFetching ? 'bp3-skeleton' : ''}>
                                    {totalUnassignedErrors > 0 ? (
                                        <>
                                            {totalUnassignedErrors} {i18n.t('diagnosticsPage.statusUnassigned')}
                                        </>
                                    ) : (
                                        <div className='screen-details'>
                                            <Icon
                                                className='details-icon'
                                                icon='tick-circle'
                                                iconSize={15}
                                                intent={Intent.SUCCESS}
                                            />
                                            {i18n.t('diagnosticsPage.statusNoUnassigned')}
                                        </div>
                                    )}
                                </h5>
                            </div>
                        </div>
                        {currentScreen.controller?.version !== '' && (
                            <div className='screen-details'>
                                {isMobile ? (
                                    <Tooltip
                                        boundary='viewport'
                                        content={
                                            <div className='screen-details tooltip-details'>
                                                <div className='details'>
                                                    <h4>LEDNet Version</h4>
                                                    <h5>{currentScreen.controller!.version}</h5>
                                                </div>
                                                <div className='details'>
                                                    <h4>Daemon</h4>
                                                    <h5>
                                                        {capitalizeFirstLetter(
                                                            String(currentScreen.controller!.daemon)
                                                        )}
                                                    </h5>
                                                </div>
                                                <div className='details'>
                                                    <h4>Uptime</h4>
                                                    <h5>{secondsDurationToString(currentScreen.controller!.uptime)}</h5>
                                                </div>
                                                <div className='details'>
                                                    <h4>Restart</h4>
                                                    <h5>
                                                        {capitalizeFirstLetter(
                                                            String(currentScreen.controller!.restart)
                                                        )}
                                                    </h5>
                                                </div>
                                            </div>
                                        }
                                        position={Position.BOTTOM}
                                    >
                                        <div className='details tooltip-details-target'>
                                            <Icon className='details-icon' icon='info-sign' iconSize={15} />
                                            <h5>Info</h5>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <React.Fragment>
                                        <div className='details'>
                                            <img className='details-icon' src={controllerIcon} alt='LEDNet version' />
                                            <h5>{currentScreen.controller!.version}</h5>
                                        </div>
                                        <div className='details'>
                                            <h4>Daemon</h4>
                                            <h5>{currentScreen.controller!.daemonStatus}</h5>
                                        </div>
                                        <div className='details'>
                                            <h4>Uptime</h4>
                                            <h5>{secondsDurationToString(currentScreen.controller!.uptime)}</h5>
                                        </div>
                                        <div className='details'>
                                            <h4>Restart</h4>
                                            <h5>{capitalizeFirstLetter(String(currentScreen.controller!.restart))}</h5>
                                        </div>
                                    </React.Fragment>
                                )}
                                <Tooltip
                                    boundary='viewport'
                                    content={
                                        <div className='screen-details tooltip-details'>
                                            <div className='details'>
                                                <h4>Jitter</h4>
                                                <h5>{millisecondsToString(currentScreen.controller!.jitter)}</h5>
                                            </div>
                                            <div className='details'>
                                                <h4>Vsync Delays</h4>
                                                <h5>{String(currentScreen.controller!.vsyncDelays)}</h5>
                                            </div>
                                            <div className='details'>
                                                <h4>Vsync Drops</h4>
                                                <h5>{String(currentScreen.controller!.vsyncDrops)}</h5>
                                            </div>
                                            <div className='details'>
                                                <h4>Hardware Vsync</h4>
                                                <h5>{millisecondsToString(currentScreen.controller!.hardwareVsync)}</h5>
                                            </div>
                                            <div className='details'>
                                                <h4>Thread Vsync</h4>
                                                <h5>{millisecondsToString(currentScreen.controller!.threadVsync)}</h5>
                                            </div>
                                            <div className='details'>
                                                <h4>Thread Engine</h4>
                                                <h5>{millisecondsToString(currentScreen.controller!.threadEngine)}</h5>
                                            </div>
                                            <div className='details'>
                                                <h4>Thread Queue</h4>
                                                <h5>{millisecondsToString(currentScreen.controller!.threadQueue)}</h5>
                                            </div>
                                        </div>
                                    }
                                    position={Position.BOTTOM}
                                >
                                    <div className='details tooltip-details-target'>
                                        <Icon className='details-icon' icon='time' iconSize={15} />
                                        <h5>Timings</h5>
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
                <div className='screen-summary-actions'>
                    <Button
                        onClick={() => screenDiagnosticsUIStore.setIsPowerManagementOpen(true)}
                        className='custom-button-small mr-3 text-white'
                        outline
                        color='primary'
                        disabled={!currentScreen.hasAvior}
                    >
                        {!isMobile ? (
                            <span>Power</span>
                        ) : (
                            <Icon className={!isMobile ? 'ml-2' : 'mx-1'} icon='lightning' size={15} />
                        )}
                    </Button>
                    <ConsoleButton currentScreen={currentScreen} isMobile={isMobile} />
                    <Button
                        onClick={this.handleOpenTileView}
                        className='custom-button-small'
                        color='primary'
                        disabled={!currentScreenConfiguration}
                    >
                        {!isMobile ? (
                            <span>Tile View</span>
                        ) : (
                            <img className={!isMobile ? 'ml-2' : 'mx-1'} src={tileIcon} alt='Tile view' height={15} />
                        )}
                    </Button>
                </div>
            </div>
        )
    }
}

export default withNamespaces()(ScreenSummary)
