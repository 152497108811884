import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Toggle from 'react-toggle'
import DiagnosticsError from 'src/common/models/DiagnosticsError'
import { eventStopPropagation } from 'src/common/utils/EventUtils'

interface ErrorAssignedCellProps {
    isAssigned: boolean
    error: DiagnosticsError
}

@inject('store')
@observer
class ErrorAssignedCell extends React.Component<{ store?: RootStore } & ErrorAssignedCellProps> {
    toggleErrorAssigned = (error: DiagnosticsError, event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.store!.diagnosticsStore.assignDiagnosticsError(error, event.target.checked)
    }

    render() {
        const isAssigned = this.props.isAssigned
        const error = this.props.error

        if (!error) {
            return null
        }

        return (
            // Stop propogation on entire cell to prevent falsely opening the error history
            <div className='assigned-cell' onClick={eventStopPropagation}>
                <Toggle
                    checked={isAssigned}
                    className='custom-toggle custom-toggle-small'
                    icons={false}
                    onChange={this.toggleErrorAssigned.bind(this, error)}
                    disabled={this.props.store!.diagnosticsStore.errorAssigningSet.has(error)}
                />
            </div>
        )
    }
}

export default ErrorAssignedCell
