import * as React from 'react'
import { useCallback } from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { useDropzone } from 'react-dropzone'
import { AppToaster } from 'src/common/components/AppToaster'
import { Intent, ProgressBar } from '@blueprintjs/core'
import { FormText, Row, Col } from 'reactstrap'

function Dropzone(props: any) {
    const onDrop = useCallback(
        (acceptedFile: File[]) => {
            if (acceptedFile.length > 0) {
                props.handleUploadfile(acceptedFile)
            } else {
                AppToaster.show({
                    message: i18n.t('feedback.errors.fileNotAccepted'),
                    intent: Intent.DANGER,
                })
            }
        },
        [props]
    )
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        maxSize: 1048576,
    })

    return (
        <div {...getRootProps()} className='custom-dropzone'>
            <input {...getInputProps()} />
            {!props.isLogoUploading ? (
                <div className='text-center'>
                    <h6>{i18n.t('settingsPage.organisationLogoUploadHelpText')}</h6>
                    <h6>
                        <em>{i18n.t('settingsPage.organisationLogoUploadFileTypes')}</em>
                    </h6>
                </div>
            ) : (
                <ProgressBar />
            )}
        </div>
    )
}

@inject('store')
@observer
class OrgDetails extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleUploadFile = (file: File[]) => {
        this.props.store!.orgStore.uploadNewLogo(file[0])
    }

    render() {
        const me = this.props.store!.userStore.me
        const myOrg = this.props.store!.orgStore.myOrg

        if (!me || !myOrg) {
            return null
        }

        return (
            <div>
                <h2>{i18n.t('settingsPage.organisationDetails')}</h2>
                <br />
                <div>
                    <Row>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <h4>{i18n.t('settingsPage.organisationName')}</h4>
                            <h5 className='selectable-text'>{myOrg.name}</h5>
                            <h4>{i18n.t('settingsPage.organisationCountry')}</h4>
                            <h5 className='selectable-text'>{myOrg.country}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <h4>{i18n.t('settingsPage.organisationZipCode')}</h4>
                            <h5 className='selectable-text'>{myOrg.zipCode}</h5>
                            <h4>{i18n.t('settingsPage.organisationID')}</h4>
                            <h5 className='selectable-text'>{myOrg.id}</h5>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <h4>{i18n.t('settingsPage.associatedOrgs')}</h4>
                            <FormText>{i18n.t('settingsPage.associatedOrgsHelpText')}</FormText>
                            <br />
                            <h5 className='selectable-text'>
                                {this.props.store!.orgStore.associatedOrgsNames
                                    ? this.props.store!.orgStore.associatedOrgsNames
                                    : i18n.t('common.none')}
                            </h5>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <h4>{i18n.t('settingsPage.associatedByOrgs')}</h4>
                            <FormText>{i18n.t('settingsPage.associatedByOrgsHelpText')}</FormText>
                            <br />
                            <h5 className='selectable-text'>
                                {this.props.store!.orgStore.associatedByOrgsNames
                                    ? this.props.store!.orgStore.associatedByOrgsNames
                                    : i18n.t('common.none')}
                            </h5>
                        </Col>
                    </Row>
                </div>
                <hr />
                <h2>{i18n.t('settingsPage.organisationLogo')}</h2>
                <br />
                <h4>Upload organisation logo</h4>
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <Dropzone
                            handleUploadfile={this.handleUploadFile}
                            isLogoUploading={this.props.store!.orgStore.isLogoUploading}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withNamespaces()(OrgDetails)
