import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// Mobx
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { SignInStatus } from 'src/common/models/SignInStatus'

import { Form, FormGroup, Label, Input, Button, InputGroup, FormFeedback, FormText } from 'reactstrap'
import padlockIcon from 'src/assets/images/padlockIcon.svg'

@inject('store')
@observer
class ResetPassword extends React.Component<{ store?: RootStore } & WithNamespaces> {
    // Collect confirmation code and new password, then
    handleSubmitForgotPassword = () => {
        this.props.store!.signInUIStore.submitForgotPassword()
    }

    handleOnKeyPress = (event: any) => {
        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Handle "enter" or "return" key press
                this.props.store!.signInUIStore.submitForgotPassword()
                break
            default:
                return
        }
    }

    handleInputFocus = () => {
        this.props.store!.signInUIStore.setVerificationCodeFocused(true)
    }

    handleInputBlur = () => {
        this.props.store!.signInUIStore.setVerificationCodeFocused(false)
    }

    render() {
        if (this.props.store!.signInUIStore.signinStatus !== SignInStatus.submitCode) {
            return null
        }

        return (
            <div className='auth-form-container'>
                <h1 className='auth-form-lead'>{i18n.t('authPage.resetPasswordLead')}</h1>
                <Form onKeyPress={this.handleOnKeyPress} className='auth-form'>
                    <div className='auth-form-header text-center'>{i18n.t('authPage.authHeaders.resetPassword')}</div>
                    <div className='auth-form-body'>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.verificationCode')}</h6>
                            </Label>
                            <InputGroup className='custom-input'>
                                <Input
                                    autoFocus
                                    id='code'
                                    name='code'
                                    type='text'
                                    maxLength={this.props.store!.signInUIStore.authCodeLength}
                                    invalid={
                                        !this.props.store!.signInUIStore.isVerificationCodeInputValid ||
                                        this.props.store!.signInUIStore.isAuthAWSError
                                    }
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                />
                                <FormFeedback>
                                    {i18n.t(
                                        'awsErrors.' +
                                            (!this.props.store!.signInUIStore.isVerificationCodeInputValid
                                                ? 'invalidVerificationCodeFormat'
                                                : this.props.store!.signInUIStore.authAWSError)
                                    )}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.newPassword')}</h6>
                            </Label>
                            <InputGroup className='custom-input custom-input-prepend-icon'>
                                <img className='custom-input-icon-left' src={padlockIcon} alt='Locked' />
                                <Input
                                    id='newPassword'
                                    name='newPassword'
                                    type='password'
                                    invalid={this.props.store!.signInUIStore.isAuthAWSError}
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                />
                                <FormFeedback>
                                    {i18n.t('awsErrors.' + this.props.store!.signInUIStore.authAWSError)}
                                </FormFeedback>
                                <FormText>{i18n.t('authPage.newPasswordHelpText')}</FormText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.verifyNewPassword')}</h6>
                            </Label>
                            <InputGroup className='custom-input custom-input-prepend-icon'>
                                <img className='custom-input-icon-left' src={padlockIcon} alt='Locked' />
                                <Input
                                    id='verifyNewPassword'
                                    name='verifyNewPassword'
                                    type='password'
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    invalid={this.props.store!.signInUIStore.isAuthAWSError}
                                />
                                <FormFeedback>
                                    {i18n.t('awsErrors.' + this.props.store!.signInUIStore.authAWSError)}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        <Button
                            className='auth-form-body-button'
                            color='primary'
                            disabled={!this.props.store!.signInUIStore.isResetPasswordFormValid}
                            onClick={this.handleSubmitForgotPassword}
                        >
                            <h6>{i18n.t('actions.submit')}</h6>
                        </Button>
                    </div>
                </Form>
                <Button
                    className='custom-button-link'
                    color='link'
                    onClick={this.props.store!.signInUIStore.handleBackToSignIn}
                >
                    <span>{i18n.t('authPage.backToSignIn')}</span>
                </Button>
            </div>
        )
    }
}

export default withNamespaces()(ResetPassword)
