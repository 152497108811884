import { ErrorCode } from 'src/common/models/DiagnosticErrorEnums'
import DiagnosticsError, { DiagnosticsErrorJSON } from 'src/common/models/DiagnosticsError'
import DiagnosticsErrorLog, { DiagnosticsErrorLogJSON } from 'src/common/models/DiagnosticsErrorLog'
import Tile from 'src/common/models/Tile'
import { LimitOffsetParams } from './BaseRouter'
import onAPI from './onAPI'
import moment from 'moment'

export interface ScreenErrorHistorySearchParams {
    startTime?: moment.Moment
    endTime?: moment.Moment
    screenIds?: string[]
    errorCodes?: ErrorCode[]
}

interface ErrorLogJSON {
    errors: DiagnosticsErrorJSON[]
    logs: DiagnosticsErrorLogJSON[]
}

class ErrorLogRouter {
    protected route = '/diagnostics-error-logs'

    async fetchErrorHistory(error: DiagnosticsError): Promise<DiagnosticsErrorLog[]> {
        try {
            const jsons: DiagnosticsErrorLogJSON[] = await onAPI.fetchJSON(this.route + '/hash/' + error.hash)
            const items = Array<DiagnosticsErrorLog>()
            for (const json of jsons) {
                items.push(new DiagnosticsErrorLog(json, error))
            }
            return items
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async fetchTileErrorHistory(tile: Tile): Promise<DiagnosticsError[]> {
        try {
            const jsons: DiagnosticsErrorJSON[] = await onAPI.fetchJSON(this.route + '/tile/' + tile.id!)
            const items = Array<DiagnosticsError>()
            for (const json of jsons) {
                items.push(new DiagnosticsError(json))
            }
            return items
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async searchScreenErrorHistory(
        searchParams: ScreenErrorHistorySearchParams & LimitOffsetParams
    ): Promise<ErrorLogJSON> {
        const json: ErrorLogJSON = await onAPI.fetchJSON(this.route + '/search', searchParams)
        return json
    }
}

const errorLogRouter = new ErrorLogRouter()
export default errorLogRouter
