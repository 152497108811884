import { observable, computed, action, autorun, runInAction } from 'mobx'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import Organisation from 'src/common/models/Organisation'
import DisplayImage from 'src/common/models/DisplayImage'
import DisplayImageSummary from 'src/common/models/DisplayImageSummary'
import { ReportFormat, ReportType } from 'src/common/models/DisplayReport'

import { AppToaster } from 'src/common/components/AppToaster'
import { Intent } from '@blueprintjs/core'
import { GroupedOptionsType } from 'react-select'
import { ValueLabelPair } from 'src/common/components/SelectComponents'

import moment, { Moment } from 'moment'

import { ScreenSize } from 'src/providers/LednetProvider'

type CalculatedResolutions = Map<string, ScreenSize>

interface CreativeHash {
    fileName: string
    hashes: string[][]
}

export default class CreativesUIStore {
    @observable private setOrg?: Organisation

    @observable isProcessing: boolean = false
    @observable isFetching: boolean = false
    @observable isFetchingSummary: boolean = false

    @observable userImages: File[] = []
    @observable displayImages: DisplayImage[] = []
    @observable displayImageSummary: DisplayImageSummary

    @observable startDate?: Moment = moment()
        .subtract(1, 'week')
        .startOf('day')
    @observable stopDate?: Moment = moment().endOf('day')

    @observable calculatedResolutions: CalculatedResolutions
    @observable selectedResolutions: ValueLabelPair[]
    @observable resolutionOptions: GroupedOptionsType<ValueLabelPair>

    @observable isReportDialogOpen: boolean = false
    @observable exportName: string
    @observable exportOption: string
    @observable isExporting: boolean = false

    // RootStore
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        autorun(() => {
            if (this.selectedOrg) {
                // Clear all images upon org change
                this.handleClearAllImages()
                // Calculate resolutions
                this.calculateResolutions()
            }
        })
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    @action updateParams = (params: { org: string }) => {
        const { org } = params

        if (org) {
            this.setOrg = this.rootStore.orgStore.findItem(org)
        }
    }

    @action setDisplayImages = (value: DisplayImage[]) => {
        this.displayImages = value
    }

    @action setIsReportDialogOpen = (value: boolean) => {
        this.isReportDialogOpen = value
    }

    @action setExportName = (value: string) => {
        this.exportName = value
    }

    @action setExportOption = (value: string) => {
        this.exportOption = value
    }

    @action setUserImages = (value: File[]) => {
        this.userImages = value
    }

    @action setStartDate = (value: Moment | undefined) => {
        this.startDate = value
    }

    @action setStopDate = (value: Moment | undefined) => {
        this.stopDate = value
    }

    @action setSelectedResolutions = (value: ValueLabelPair[]) => {
        this.selectedResolutions = value
    }

    @action setIsFetchingSummary = (value: boolean) => {
        this.isFetchingSummary = value
    }

    @action setIsExporting = (value: boolean) => {
        this.isExporting = value
    }

    @action setCalculatedResolutions = (value: Map<string, { screenSize: number[]; tileSizes: number[][] }>) => {
        this.calculatedResolutions = value
    }

    @action setResolutionOptions = (value: GroupedOptionsType<ValueLabelPair>) => {
        this.resolutionOptions = value
    }

    @action setIsProcessing = (value: boolean) => {
        this.isProcessing = value
    }

    handleFetchHashes = async (creativeHashes: CreativeHash[]) => {
        try {
            runInAction(() => {
                this.isFetching = true
            })
            const result = await this.rootStore.displayImageStore.fetchHashes({
                organisationId: this.selectedOrg?.id,
                startDate: this.startDate,
                stopDate: this.stopDate,
                hashes: creativeHashes.flatMap(ch => ch.hashes.map(subset => subset[0])),
            })

            if (result.length === 0) {
                AppToaster.show({ message: 'No results for provided images', intent: Intent.WARNING })
                return
            }
            this.setDisplayImages(result)

            this.handleFetchSummary(result.map(di => di.id!))
        } catch (error) {
            console.error('Error fetching hashes:', error)
            AppToaster.show({ message: i18n.t('feedback.errors.errorGeneratingReport'), intent: Intent.DANGER })
        } finally {
            runInAction(() => {
                this.isFetching = false
            })
        }
    }

    handleFetchSummary = async (displayImageIds: string[]) => {
        try {
            runInAction(() => {
                this.isFetchingSummary = true
            })
            const result = await this.rootStore.displayImageStore.fetchSummary({
                organisationId: this.selectedOrg?.id,
                startDate: this.startDate,
                stopDate: this.stopDate,
                displayImageIds,
            })
            runInAction(() => {
                this.displayImageSummary = result
            })
        } catch (error) {
            console.error('Error fetching summary', error)
            AppToaster.show({ message: i18n.t('feedback.errors.errorGeneratingReport'), intent: Intent.DANGER })
        } finally {
            runInAction(() => {
                this.isFetchingSummary = false
            })
        }
    }

    handleExport = async () => {
        try {
            runInAction(() => {
                this.isExporting = true
            })
            let format: ReportFormat | undefined
            let type: ReportType | undefined
            switch (this.exportOption) {
                case 'candelicCsv':
                    format = ReportFormat.csv
                    type = ReportType.candelic
                    break
                case 'talonCsv':
                    format = ReportFormat.csv
                    type = ReportType.candelic
                    break
            }
            if (!this.exportName || !format || !type) {
                throw new Error('Invalid params')
            }
            const report = await this.rootStore.displayImageStore.downloadReport({
                name: this.exportName,
                organisationId: this.selectedOrg?.id,
                startDate: this.startDate,
                stopDate: this.stopDate,
                screenIds: this.displayImageSummary?.screens.map(s => s.screenId!),
                displayImageIds: this.displayImages.map(di => di.id!),
                format,
                type,
            })
            const { signedURL } = report
            window.open(signedURL)
        } catch (error) {
            console.error('Error generating report:', error)

            AppToaster.show({ message: i18n.t('feedback.errors.errorGeneratingReport'), intent: Intent.DANGER })
        } finally {
            this.setIsReportDialogOpen(false)
            runInAction(() => {
                this.isExporting = false
            })
        }
    }

    handleClearAllImages = () => {
        this.setUserImages([])
    }

    calculateResolutions = () => {
        const screens = this.selectedOrg?.screens
        if (!screens) {
            return
        }
        const values = Array.from(new Set(screens?.map(s => `${s.width}x${s.height}`))).map(v => {
            const matches = screens?.filter(s => `${s.width}x${s.height}` === v)
            if (!matches) {
                return undefined
            }
            // Width and height will always be the same across matches
            const { width, height } = matches[0]
            // Retrieve tile width and heights for all matches
            const tileSizes = Array.from(new Set(matches.map(m => JSON.stringify([m.tileWidth, m.tileHeight]))))
            return [
                v,
                {
                    screenSize: [width, height],
                    tileSizes: tileSizes.map(s => JSON.parse(s)),
                },
            ]
        }) as Array<[string, { screenSize: number[]; tileSizes: number[][] }]>
        runInAction(() => {
            this.calculatedResolutions = new Map(values)
        })

        const options = Array.from(new Set(screens?.map(s => `${s.width}x${s.height}`))).map(v => ({
            label: v,
            value: v,
        }))
        this.setSelectedResolutions(options)

        const groupedOptions = [
            {
                // Omitting group label as its redundant, group enables global selection
                options,
            },
        ] as GroupedOptionsType<ValueLabelPair>
        runInAction(() => {
            this.resolutionOptions = groupedOptions
        })
    }
}
