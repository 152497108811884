import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import views from 'src/config/views'

import Section from 'src/common/models/Section'
import Tile from 'src/common/models/Tile'
import { LogoBoxLocation } from 'src/common/models/LogoBoxLocation'
import { ZoomLevel } from './stores/TileViewUIStore'

import SectionTile from './components/SectionTile'
import TileViewControls from './components/TileViewControls'
import LogoBoxDialog from './components/LogoBoxDialog'
import DragScroll from 'react-dragscroll'
import CandelicLoader from 'src/common/components/CandelicLoader'

@inject('store')
@observer
class TileView extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen
        const screenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentScreen || !screenConfiguration) {
            return
        }

        // Check if screen configuration has a Logo Box, but doesn't have a Logo Box location set
        this.props.store!.tileViewUIStore.setLogoBoxDialog(
            screenConfiguration.hasLogoBox && !currentScreen.logoBoxLocation
        )
    }

    screenSection = (section: Section, zoomLevel: ZoomLevel, sectionIndex: number) => {
        const screenConfiguration = section.screenConfiguration
        const gridDimensions = screenConfiguration.gridDimensions(
            !section.isLogoBox ? screenConfiguration.standardSections : screenConfiguration.logoBoxSections
        )

        if (!gridDimensions) {
            return
        }

        return (
            <div
                key={section.id}
                className='section-grid'
                style={{
                    gridTemplateRows: 'repeat(' + section.height + ', auto)',
                    gridTemplateColumns: 'repeat(' + section.width + ', auto)',
                    gap: gridDimensions.gap + 'px',
                    gridArea: gridDimensions.gridArea.flat()[sectionIndex],
                }}
            >
                {section.tileListForRender.map((tile, i) => (
                    // TODO: Tile size should reflect what is sent in screen configuration
                    <SectionTile
                        key={i}
                        index={i}
                        tile={tile}
                        width={zoomLevel}
                        height={zoomLevel}
                        handleTileClick={this.handleTileClick}
                        className={this.props.store!.tileViewUIStore.isWaitingForStat ? ' bp3-skeleton' : undefined}
                    />
                ))}
            </div>
        )
    }

    handleTileClick = (tile: Tile) => {
        const router = this.props.store!.router
        router.goTo(views.diagnosticsScreen, router.params, this.props.store!, {
            tileView: true,
            tileHistory: tile?.id,
        })
    }

    render() {
        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen
        const screenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentScreen || !screenConfiguration) {
            return <CandelicLoader />
        }

        const standardSectionsGrid = screenConfiguration.gridDimensions(screenConfiguration.standardSections)
        if (!standardSectionsGrid) {
            return <h2>No screen sections found.</h2>
        }

        const zoomLevel = this.props.store!.tileViewUIStore.zoomLevel
        const logoBoxLocation = currentScreen.logoBoxLocation
        const screenWrapperWidth =
            standardSectionsGrid.width * zoomLevel + standardSectionsGrid.width * standardSectionsGrid.gap

        return (
            <React.Fragment>
                <div className='tile-view custom-scrollbars'>
                    <TileViewControls />
                    {/* Draggable area */}
                    <DragScroll height='100%' width='100%'>
                        {/* Keeps the screen wrapper centred when zooming */}
                        <div
                            className='centre-zoom-wrapper'
                            style={{
                                width: screenWrapperWidth + 'px',
                            }}
                        >
                            {/* Use standard sections grid dimensions to display logo box relative to main part of screen */}
                            <div
                                className='screen-wrapper'
                                style={{
                                    flexDirection:
                                        logoBoxLocation === LogoBoxLocation.topLeft ||
                                        logoBoxLocation === LogoBoxLocation.topCentre ||
                                        logoBoxLocation === LogoBoxLocation.topRight
                                            ? 'column-reverse'
                                            : 'column',
                                    width: screenWrapperWidth + 'px',
                                }}
                            >
                                <div
                                    className='screen-grid'
                                    style={{
                                        gridTemplateColumns:
                                            'repeat(' + standardSectionsGrid.width + ',' + zoomLevel + 'px)',
                                        gridTemplateRows:
                                            'repeat(' + standardSectionsGrid.height + ',' + zoomLevel + 'px)',
                                        gap: standardSectionsGrid.gap + 'px',
                                    }}
                                >
                                    {screenConfiguration.standardSections.map((section, i) => this.screenSection(section, zoomLevel, i))}
                                </div>
                                <div
                                    className='screen-grid logo-box-grid'
                                    style={{
                                        gridTemplateColumns:
                                            'repeat(' + screenConfiguration.logoBoxSections.length + ', auto)',
                                        gap: standardSectionsGrid.gap + 'px',
                                        marginLeft:
                                            logoBoxLocation === LogoBoxLocation.topLeft ||
                                            logoBoxLocation === LogoBoxLocation.bottomLeft
                                                ? 0
                                                : 'auto',
                                        marginRight:
                                            logoBoxLocation === LogoBoxLocation.topRight ||
                                            logoBoxLocation === LogoBoxLocation.bottomRight
                                                ? 0
                                                : 'auto',
                                    }}
                                >
                                    {screenConfiguration.logoBoxSections.map((section, i) => this.screenSection(section, zoomLevel, i))}
                                </div>
                            </div>
                        </div>
                    </DragScroll>
                </div>
                <LogoBoxDialog />
            </React.Fragment>
        )
    }
}

export default TileView
