import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import { getTemperatureCelsius, getHexString } from '../utils/Number'

export enum BasicDiagnosticsStatType {
    tnl = 'tnl',
    sections = 'sections',
    errors = 'errors',
    model = 'model',
}

export enum ControllerStatType {
    serial = 'serial',
    firmware = 'firmware',
    factory = 'factory',
    incompleteFrames = 'incompleteFrames',
    shortFrames = 'shortFrames',
    longFrames = 'longFrames',
    vsyncDrops = 'vsyncDrops',
    vsyncPackets = 'vsyncPackets',
    minVsync = 'minVsync',
    avgVsync = 'avgVsync',
    maxVsync = 'maxVsync',
    videoPackets = 'videoPackets',
    ledFirmware = 'ledFirmware',
    ocLedTest = 'ocLedTest',
    ledChainErrors = 'ledChainErrors',
    ledTemp = 'ledTemp',
    localTemp = 'localTemp',
    localVref = 'localVref',
    link = 'link',
    statsReceived = 'statsReceived',
    statusReceived = 'statusReceived',
    lastComms = 'lastComms',
    uptime = 'uptime',
    totalUptime = 'totalUptime',
    runtime = 'runtime',
    totalRuntime = 'totalRuntime',
    inventory = 'inventory',
    ledVdda = 'ledVdda',
    minPhase = 'minPhase',
    avgPhase = 'avgPhase',
    stackHeadroom = 'stackHeadroom',
    gclkUnlock = 'gclkUnlock',
    proof = 'proof',
    debug = 'debug',

    unknown = 'unknown',
}

export type TileMapStatType = ControllerStatType | BasicDiagnosticsStatType

export enum StatCommand {
    ping = 'ping',
    statChange = 'statChange',
    stopStatFeed = 'stopStatFeed',
}

class ControllerStat {
    static newControllerStat(): ControllerStat {
        return new ControllerStat({
            stat: ControllerStatType.unknown,
            values: [],
        })
    }

    @observable stat: ControllerStatType
    @observable values: Array<Record<string, any>>

    constructor(json: ControllerStatJSON) {
        this.stat = json.stat
        this.values = json.values
    }

    @computed get heatmapThreshold(): { floor: number; ceil: number; reverseGradient?: boolean } | undefined {
        switch (this.stat) {
            case ControllerStatType.localTemp:
                return { floor: 0, ceil: 150 }
            case ControllerStatType.ledTemp:
                return { floor: 0, ceil: 100 }
            case ControllerStatType.firmware:
            case ControllerStatType.factory:
            case ControllerStatType.ledFirmware:
                return { floor: 0, ceil: 1, reverseGradient: true }
            default:
                return undefined
        }
    }

    @computed get statValues(): Map<string, string | number> {
        const statValuesMap = new Map<string, string | number>()
        this.values.forEach(statValue => {
            const key: string = Object.keys(statValue)[0]
            let value: string | number = Object.values(statValue)[0]
            switch (this.stat) {
                case ControllerStatType.firmware:
                case ControllerStatType.factory:
                case ControllerStatType.ledFirmware:
                    value = typeof value === 'number' ? getHexString(value as number) : value
                    break
                case ControllerStatType.localTemp:
                case ControllerStatType.ledTemp:
                    value = getTemperatureCelsius(value as number)
                    break
                default:
                    if (typeof value === 'number') {
                        // toFixed returns a string, so convert to number
                        value = Number(value.toFixed(this.statDecimals))
                    }
                    break
            }

            statValuesMap.set(key, value)
        })

        return statValuesMap
    }

    private get statDecimals(): number {
        switch (this.stat) {
            case ControllerStatType.minVsync:
            case ControllerStatType.avgVsync:
            case ControllerStatType.maxVsync:
            case ControllerStatType.minPhase:
            case ControllerStatType.avgPhase:
                return 2
            default:
                return 0
        }
    }
}

export type ControllerStatJSON = Omit<ControllerStat, 'heatmapThreshold' | 'statValues'>

export default ControllerStat
