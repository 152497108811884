import { RoutePath } from 'src/config/views'
import { observable } from 'mobx'

export enum RoleType {
    superUser = 'SuperUser',
    admin = 'Admin',
    contentUser = 'ContentUser',
    operator = 'Operator',
    none = 'None',
}

export class UserRole {
    @observable types: RoleType[]
    @observable allowedPaths: RoutePath[]
    @observable startPath: RoutePath
    @observable startParams: any

    constructor(types: RoleType[]) {
        if (types.length === 0 || types[0] === RoleType.none) {
            this.allowedPaths = []
            this.startPath = RoutePath.auth
        } else if (types.includes(RoleType.superUser)) {
            this.allowedPaths = Object.keys(RoutePath).map(key => RoutePath[key])
            this.startPath = RoutePath.missionControl
            this.startParams = { org: undefined, screen: undefined }
        } else {
            this.allowedPaths = []
            for (const type of types) {
                this.startPath = RoutePath.diagnosticsScreen
                this.startParams = { org: undefined, screen: undefined }
                switch (type) {
                    case RoleType.admin:
                        this.allowedPaths.push(RoutePath.admin)
                        break
                    case RoleType.operator:
                        this.allowedPaths.push(RoutePath.diagnostics)
                        this.allowedPaths.push(RoutePath.diagnosticsGlobal)
                        this.allowedPaths.push(RoutePath.diagnosticsScreen)
                        break
                    case RoleType.contentUser:
                        this.allowedPaths.push(RoutePath.reports, RoutePath.live)
                        if (types.length === 1) {
                            this.startPath = RoutePath.live
                            this.startParams = undefined
                        }
                        break
                }
            }
        }
        this.allowedPaths.push(RoutePath.settings)
    }
}
