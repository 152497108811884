import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { formatCommas } from 'src/common/utils/Number'

import ResultsTable from './ResultsTable'

import DisplayImageStats from 'src/common/models/DisplayImageStats'
import LoadingSpinner from 'src/common/components/LoadingSpinner'
import playsIcon from 'src/assets/images/playsIcon.svg'
import timeIcon from 'src/assets/images/timeIcon.svg'
import viewsIcon from 'src/assets/images/viewsIcon.svg'
import { Button, Nav, NavItem, TabContent, Table, TabPane } from 'reactstrap'
import moment from 'moment'

interface ReportResultsProps {
    store?: RootStore
}

function ReportResults(props: ReportResultsProps) {
    const { store } = props
    const uiStore = store!.creativesUIStore

    const {
        isFetching,
        isFetchingSummary,
        displayImages,
        displayImageSummary,
        startDate,
        stopDate,
        setIsReportDialogOpen,
        setDisplayImages,
    } = uiStore

    const [currentTab, setCurrentTab] = React.useState('creatives')

    const renderReportSummaryScreenRow = (displayImageStats: DisplayImageStats) => {
        if (displayImageStats) {
            // Convert total and average time into moment object
            const totalTime = moment.duration(displayImageStats.totalTime, 'seconds')
            const averageTime = moment.duration(displayImageStats.averageTime, 'seconds')

            const screen = store!.screenStore.findItem(displayImageStats.screenId!)

            return (
                <tr className='custom-summary-table-row' key={displayImageStats.screenId}>
                    <td className='p-0'>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={5} className='align-middle table-title'>
                                        {screen && screen.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle'>
                                        <img src={playsIcon} alt='Play count' height={10} />{' '}
                                        {formatCommas(displayImageStats.totalPlays)} plays
                                    </td>
                                    <td className='align-middle'>
                                        <img src={timeIcon} alt='Play time' height={13} />
                                        Total time {totalTime.format('H[h] m[m] s[s]')} / {averageTime.format('s[s]')}{' '}
                                        {i18n.t('common.average')}
                                    </td>
                                    <td className='align-middle'>
                                        <img src={viewsIcon} alt='Last played' height={7.6} />
                                        Last played at{' '}
                                        {moment(displayImageStats.lastPlayed).format(
                                            store!.userStore.me?.dateTimeFormat
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
        } else {
            return null
        }
    }

    const me = store!.userStore.me
    if (!me) {
        return null
    }

    const tabs = [
        {
            title: 'Creatives',
            key: 'creatives',
        },
        {
            title: 'Screens',
            key: 'screens',
        },
    ]

    const totalTimeSeconds = moment.duration(displayImageSummary?.totals.totalTime, 'seconds')
    const averageTimeSeconds = moment.duration(displayImageSummary?.totals.averageTime, 'seconds')

    return (
        <>
            <div className='results-header'>
                <div className='results-left'>
                    <Button className='custom-button mr-4' onClick={() => setDisplayImages([])} color='secondary'>
                        Back{' '}
                    </Button>
                    <h3 className='mb-0'>
                        Results for {startDate?.format(me.shortDateTimeFormat)} -{' '}
                        {stopDate?.format(me.shortDateTimeFormat)}
                    </h3>
                </div>
                <Button
                    className='report-button custom-button custom-button-large'
                    onClick={() => setIsReportDialogOpen(true)}
                    color='primary'
                    disabled={isFetching || isFetchingSummary}
                >
                    {i18n.t('actions.report')}
                </Button>
            </div>

            <div className='px-2'>
                <h2 className='mt-4'>Summary</h2>
                <div
                    className={'results-summary mt-2' + (isFetching || isFetchingSummary ? ' bp3-skeleton' : '')}
                    style={isFetching || isFetchingSummary ? { width: '600px' } : {}}
                >
                    <h3 className='mr-2'>Creatives</h3>
                    <h4 className='mr-4'>{displayImages.length}</h4>
                    <h3 className='mr-2'>Screens</h3>
                    <h4 className='mr-4'>{displayImageSummary?.totals.totalScreens}</h4>
                    <h3 className='mr-2'>Total plays</h3>
                    <h4 className='mr-4'>{formatCommas(displayImageSummary?.totals.totalPlays ?? 0)}</h4>
                    <h3 className='mr-2'>Total time</h3>
                    <h4>
                        {totalTimeSeconds.format('H[h] m[m] s[s]')} / {averageTimeSeconds.format('s[s]')}{' '}
                        {i18n.t('common.average')}
                    </h4>
                </div>
            </div>

            <div className='nav-tabs-container'>
                <Nav className='nav-tabs' tabs>
                    {tabs.map(tab => (
                        <NavItem key={tab.key} onClick={() => setCurrentTab(tab.key)}>
                            <h3 className={'nav-link' + (currentTab === tab.key ? ' active' : '')}>{tab.title}</h3>
                        </NavItem>
                    ))}
                </Nav>
                <div className='nav-content-container'>
                    <TabContent activeTab={currentTab}>
                        <TabPane tabId='creatives'>
                            <ResultsTable store={store!} isFetching={isFetching} displayImages={displayImages} />
                            {!isFetching && (
                                <div className='results-footer'>
                                    <h3>End of results</h3>
                                </div>
                            )}
                        </TabPane>
                        <TabPane tabId='screens'>
                            {isFetchingSummary ? (
                                <LoadingSpinner />
                            ) : (
                                <>
                                    <div className='custom-summary-table ml-auto mr-auto'>
                                        <Table responsive>
                                            <tbody>
                                                {displayImageSummary?.screens.map(reportSummaryScreen =>
                                                    renderReportSummaryScreenRow(reportSummaryScreen)
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className='results-footer'>
                                        <h3>End of results</h3>
                                    </div>
                                </>
                            )}
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </>
    )
}

export default observer(ReportResults)
