import { observable, action, computed } from 'mobx'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { subscriptionTopics } from '../utils/String'

import tileViewRouter from 'src/api/tileViewRouter'

import ControllerStat, {
    ControllerStatType,
    StatCommand,
    BasicDiagnosticsStatType,
    TileMapStatType,
} from '../models/ControllerStat'
import ScreenConfiguration from '../models/ScreenConfiguration'
import MqttSubscription from '../models/MqttSubscription'

import { Intent } from '@blueprintjs/core'
import { AppToaster } from '../components/AppToaster'

const ASSET_LOG_TIMER_INTERVAL = 5000

export default class TileViewStore {
    // If changing firstStat, don't forget to change the stat order in TileViewControls
    static firstStat: TileMapStatType = BasicDiagnosticsStatType.tnl
    static primeStat: ControllerStatType = ControllerStatType.serial

    @observable subscriptionKey?: string
    @observable stat?: ControllerStat
    @observable screenConfig: ScreenConfiguration
    @observable generatingAssetLog = false
    @observable assetLogReady = false
    @observable currentStatType?: ControllerStatType

    subscriptionTTLTimer?: NodeJS.Timeout

    router = tileViewRouter
    rootStore: RootStore

    private storedStat?: ControllerStat
    private intervalTimer?: NodeJS.Timeout
    private queuedStatType?: ControllerStatType

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get isControllerPrimed(): boolean {
        return !!this.stat
    }

    @computed get commandPublishTopic(): string | undefined {
        if (!this.subscriptionKey) {
            return undefined
        }
        return subscriptionTopics('client', this.subscriptionKey)[1]
    }

    sendStatChangeCommand = (statType: ControllerStatType) => {
        if (!this.stat && statType !== TileViewStore.primeStat) {
            // Hasn't finished priming socket, queue requested stat
            this.queuedStatType = statType
            return
        }

        if (!this.isControllerPrimed && !this.intervalTimer) {
            this.intervalTimer = setInterval(() => {
                this.sendStatChangeCommand(statType)
            }, 2000)
        }

        if (!this.commandPublishTopic) {
            console.error('Subscription key missing')
            return
        }

        this.currentStatType = statType
        this.rootStore.mqttStore.publish(this.commandPublishTopic, {
            command: StatCommand.statChange,
            stat: statType,
        })
    }

    startIntervalTimer = () => {
        if (!this.intervalTimer) {
            this.intervalTimer = setInterval(() => {
                this.updateStat(new ControllerStat({ stat: this.currentStatType!, values: [] }))
            }, ASSET_LOG_TIMER_INTERVAL)
        }
    }

    stopIntervalTimer = () => {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer)
            this.intervalTimer = undefined
        }
    }

    clearIntervalTimer = () => {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer)
            this.intervalTimer = undefined
        }
    }

    @action startAssetLogGeneration = () => {
        this.assetLogReady = false
        this.generatingAssetLog = true
        this.storedStat =
            this.stat?.stat === this.currentStatType
                ? this.stat
                : new ControllerStat({ stat: this.currentStatType!, values: [] })
        this.updateStat()
        this.startIntervalTimer()
    }

    @action stopAssetLogGeneration = (completed = true, error?: string) => {
        this.stopIntervalTimer()
        this.assetLogReady = completed
        this.generatingAssetLog = false
        this.sendStatChangeCommand(this.storedStat!.stat)
        this.stat = this.storedStat
        this.updateStat(this.stat)
        if (!completed && error) {
            console.error(error)
        }
    }

    @action resetAssetLogReady = () => {
        this.assetLogReady = false
    }

    @action setScreenConfig(screenConfig: ScreenConfiguration) {
        this.screenConfig = screenConfig
        if (screenConfig) {
            this.stat = undefined
            this.primeControllerStatSubscription(screenConfig.screenId)
        }
    }

    @action updateStat = (stat?: ControllerStat) => {
        if (stat && stat.values && stat.values.length > 0) {
            if (stat.stat === ControllerStatType.serial) {
                for (const tile of this.screenConfig.tiles) {
                    tile.serial = stat.statValues.get(tile.tnl) as string | undefined
                }
            } else if (stat.stat === ControllerStatType.firmware) {
                for (const tile of this.screenConfig.tiles) {
                    tile.firmware = stat.statValues.get(tile.tnl) as string | undefined
                }
            }
        }
        if (this.generatingAssetLog) {
            if (
                this.screenConfig.tileCount === 0 ||
                (this.screenConfig.tiles.find(tile => tile.serial) &&
                    this.screenConfig.tiles.find(tile => tile.firmware))
            ) {
                this.stopAssetLogGeneration()
            } else if (!this.screenConfig.tiles[0].serial && (!stat || stat.stat !== ControllerStatType.serial)) {
                this.sendStatChangeCommand(ControllerStatType.serial)
            } else if (!this.screenConfig.tiles[0].firmware && (!stat || stat.stat !== ControllerStatType.firmware)) {
                this.sendStatChangeCommand(ControllerStatType.firmware)
            } else {
                // there was an error getting the data, report and stop generation
                this.stopAssetLogGeneration(false, 'unable to fetch all the data: ' + stat!.stat)
            }
        } else {
            this.stat = stat
        }
    }

    @action primeControllerStatSubscription = (screenId: string) => {
        // Use a long polling stat to prime the controller stat subscription
        tileViewRouter
            .subscribeToControllerStat(screenId, TileViewStore.primeStat, this.subscriptionKey)
            .then(response => {
                const { subscriptionKey, timeout: ttl } = response
                if (!subscriptionKey) {
                    console.error('Subscription key missing')
                    return
                }
                this.subscriptionKey = subscriptionKey

                new MqttSubscription({
                    topics: subscriptionTopics('client', subscriptionKey),
                    subscriptionKey,
                    callback: data => {
                        this.clearIntervalTimer()

                        const stat = new ControllerStat(data.value)

                        if (stat.stat === this.currentStatType) {
                            this.updateStat(stat)
                        }

                        if (this.queuedStatType) {
                            this.sendStatChangeCommand(this.queuedStatType)
                            this.queuedStatType = undefined
                        }
                    },
                })
                    .setTTL(ttl)
                    .setKeepAliveCommand(StatCommand.ping)
                    .setOnStale(() => this.primeControllerStatSubscription(screenId))
                    .subscribe()

                if (this.currentStatType) {
                    this.sendStatChangeCommand(this.currentStatType)
                } else {
                    this.sendStatChangeCommand(TileViewStore.primeStat)
                }
            })
            .catch(error => {
                // Show toast on error
                AppToaster.show({
                    icon: 'warning-sign',
                    intent: Intent.DANGER,
                    message: 'Error subscribing to controller stat feed: ' + error,
                })
            })
    }

    @action sendStopStatFeedCommand = () => {
        if (!this.currentStatType) {
            return
        }

        if (!this.commandPublishTopic) {
            console.error('Subscription key missing')
            return
        }

        this.rootStore.mqttStore.publish(this.commandPublishTopic, {
            command: StatCommand.stopStatFeed,
        })

        // Stop sending pings
        if (this.subscriptionTTLTimer) {
            clearInterval(this.subscriptionTTLTimer)
            this.subscriptionTTLTimer = undefined
        }

        this.clearIntervalTimer()

        // Reset current stat to first stat
        this.rootStore.tileViewUIStore.currentStat = {
            value: TileViewStore.firstStat,
            label: i18n.t('diagnosticsPage.errors'),
        }

        // Clear stat values
        this.stat = undefined
        this.currentStatType = undefined

        // Clear subscription key
        this.subscriptionKey = undefined
    }
}
