import DisplayImage, {
    DisplayImageHashParams,
    DisplayImageJSON,
    DisplayImageSearchParams,
    DisplayImageSummaryParams,
} from '../common/models/DisplayImage'
import DisplayImageSummary, { DisplayImageSummaryJSON } from 'src/common/models/DisplayImageSummary'
import BaseRouter, { RouterFlag } from './BaseRouter'
import onAPI from './onAPI'

export class DisplayImageRouter extends BaseRouter<DisplayImage, DisplayImageJSON, DisplayImageSearchParams> {
    protected route = '/display-image'

    protected flags = RouterFlag.list | RouterFlag.search

    async summary(summaryParams: DisplayImageSummaryParams): Promise<DisplayImageSummary> {
        const json: DisplayImageSummaryJSON = await onAPI.fetchJSON(this.route + 's/summary', summaryParams)
        return new DisplayImageSummary(json)
    }

    async hash(hashParams: DisplayImageHashParams): Promise<DisplayImage[]> {
        const json: DisplayImageJSON[] = await onAPI.fetchJSON(this.route + 's/hash', hashParams)
        return json.map(j => new DisplayImage(j))
    }

    protected newItem(json: DisplayImageJSON): DisplayImage {
        return new DisplayImage(json)
    }
}

const displayImageRouter = new DisplayImageRouter()
export default displayImageRouter
