import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import HelpLabel from 'src/common/components/HelpLabel'
import DatePicker from 'src/common/components/DatePicker'
import { useDropzone } from 'react-dropzone'
import { AppToaster } from 'src/common/components/AppToaster'
import { Icon, Intent } from '@blueprintjs/core'
import { Button, Form, FormGroup, InputGroup, Label } from 'reactstrap'
import Select from 'react-select'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    GroupHeading,
    ValueLabelPair,
} from 'src/common/components/SelectComponents'
import moment from 'moment'

import { LednetContext, ScreenSize } from 'src/providers/LednetProvider'

interface CreativeHash {
    fileName: string
    hashes: string[][]
}

interface ReportFormProps {
    store?: RootStore
}

function ReportForm(props: ReportFormProps) {
    const { store } = props
    const uiStore = store!.creativesUIStore

    const { calculateBufferProofHashAsync, isLoading: isLednetLoading } = React.useContext(LednetContext)

    const {
        isProcessing,
        userImages,
        startDate,
        stopDate,
        calculatedResolutions,
        selectedResolutions,
        resolutionOptions,
        handleClearAllImages,
        handleFetchHashes,
        setUserImages,
        setStartDate,
        setStopDate,
        setSelectedResolutions,
        setIsProcessing,
    } = uiStore

    const handleUploadFiles = (files: File[]) => {
        // Filter out duplicate filenames
        const accepted = files.filter(file => !userImages.map(ui => ui.name).includes(file.name))
        setUserImages([...userImages, ...accepted])
    }

    const Dropzone = () => {
        const onDrop = React.useCallback((acceptedFile: File[]) => {
            if (acceptedFile.length > 0) {
                handleUploadFiles(acceptedFile)
            } else {
                AppToaster.show({
                    message:
                        'One or more files are not allowed. Only JPEG, PNG, and BMP files, with a maximum size of 2MB are allowed.',
                    intent: Intent.DANGER,
                })
            }
        }, [])
        const { getRootProps, getInputProps } = useDropzone({
            onDrop,
            multiple: true,
            accept: ['image/jpeg', 'image/png', 'image/bmp'],
            maxSize: 4194304,
            disabled: isLednetLoading,
        })

        return (
            <div {...getRootProps()} className='custom-dropzone'>
                <input {...getInputProps()} />
                <div className='text-center'>
                    <h6>Drag and drop files or folders containing your creatives here</h6>
                    <h6>
                        <em>(Only *.jpeg, *.png and *.bmp images will be accepted)</em>
                    </h6>
                </div>
            </div>
        )
    }

    const renderUserImage = (image: File) => (
        <div key={image.name} className='proof-card'>
            <Button
                type='button'
                aria-label='Close'
                className='bp3-button remove-button'
                onClick={() => setUserImages(userImages.filter(ui => ui.name !== image.name))}
            >
                <Icon icon='small-cross' size={20} />
            </Button>
            <img src={URL.createObjectURL(image)} alt={image.name} />
            <div className='image-title'>
                <h3 className='mt-2 text-white'>{image.name}</h3>
            </div>
        </div>
    )

    const handleProcessImages = async (userImages: File[], sizes: ScreenSize[]): Promise<CreativeHash[]> => {
        try {
            setIsProcessing(true)
            const imageBuffers = await Promise.all(userImages.map(image => image.arrayBuffer()))
            const result = await Promise.all(imageBuffers.map(image => calculateBufferProofHashAsync(image, sizes)))
            const processedCreativeHashes: CreativeHash[] = result.map((hashes, i) => {
                const image = userImages[i]
                return {
                    fileName: image.name,
                    hashes: hashes ?? [],
                }
            }) as CreativeHash[]
            return processedCreativeHashes
        } catch (error) {
            throw new Error(`Error processing images: ${error}`)
        } finally {
            setIsProcessing(false)
        }
    }

    const handleProcessAndFetchHashes = async () => {
        try {
            const sizes = selectedResolutions
                .map((option: { value: any }) => calculatedResolutions?.get(option.value))
                .filter(Boolean) as ScreenSize[]

            const creativeHashes = await handleProcessImages(userImages, sizes)
            await handleFetchHashes(creativeHashes)
        } catch (error) {
            console.error('Error processing images and fetching hashes:', error)
        }
    }

    const me = store!.userStore.me
    if (!me) {
        return null
    }

    return (
        <>
            <div className='report-form'>
                <Form inline onSubmit={eventPreventDefault}>
                    <FormGroup className='custom-form-group custom-form-group-inline'>
                        <HelpLabel
                            label={`${i18n.t('reportsPage.reportRange')} (${moment.tz(me.timeZone).format('z')})`}
                        />
                        <DatePicker
                            handleChange={newDateRange => {
                                setStartDate(newDateRange[0])
                                setStopDate(newDateRange[1])
                            }}
                            startDate={startDate}
                            endDate={stopDate}
                            maxDate={moment()}
                        />
                    </FormGroup>

                    <InputGroup className='custom-input-group custom-input-group-inline'>
                        <Label className='custom-label custom-label-inline'>
                            <h6>Resolutions</h6>
                        </Label>
                        <Select
                            className='custom-select-wrapper'
                            classNamePrefix='custom-select'
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            value={selectedResolutions}
                            onChange={(selectedOptions: ValueLabelPair[]) => setSelectedResolutions(selectedOptions)}
                            options={resolutionOptions}
                            components={{
                                ValueContainer,
                                ClearIndicator,
                                DropdownIndicator,
                                Option,
                                NoOptionsMessage,
                                GroupHeading,
                            }}
                        />
                    </InputGroup>

                    <div className='content-filters-buttons'>
                        <Button
                            onClick={() => handleProcessAndFetchHashes()}
                            className='custom-button-large'
                            color='primary'
                            disabled={isProcessing || userImages.length === 0 || selectedResolutions.length === 0}
                        >
                            Scan proof records
                        </Button>
                    </div>
                </Form>
            </div>
            <h3 className='mt-4'>Generate a proof of display report by simply uploading your creatives</h3>
            <div className='mt-4'>
                <Dropzone />
            </div>
            <div className='user-images'>{userImages.map((image: File) => renderUserImage(image))}</div>
            {userImages.length > 0 && (
                <>
                    <hr />
                    <div className='clear-all-button mt-4'>
                        <Button className='custom-button custom-button-large' onClick={() => handleClearAllImages()}>
                            Clear all
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}

export default observer(ReportForm)
