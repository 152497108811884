import RootStore from 'src/common/RootStore'
import { observable, action, computed, runInAction } from 'mobx'

import diagnosticsRouter from 'src/api/diagnosticsRouter'

import Organisation from 'src/common/models/Organisation'

import { Outage, ErrorState, Error, RecurringError } from '../submodules/digest/GlobalDigest'
import DiagnosticsDigest from 'src/common/models/DiagnosticsDigest'
import { AppToaster } from 'src/common/components/AppToaster'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'
import views, { DiagnosticsGlobalTab } from 'src/config/views'

export default class GlobalDiagnosticsUIStore {
    @observable private setOrg?: Organisation
    @observable dateRange?: [moment.Moment | undefined, moment.Moment | undefined] = [
        moment().subtract(24, 'hours'),
        moment(),
    ]
    @observable digest?: DiagnosticsDigest
    @observable fetching = false

    @observable openSections = new Set<string>()

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    getDigest = async () => {
        if (!this.selectedOrg?.id || !this.dateRange?.[0] || !this.dateRange?.[1]) {
            return
        }
        try {
            runInAction('digestFetching', () => {
                this.fetching = true
            })
            const digest = await diagnosticsRouter.fetchDigest(this.selectedOrg.id, [
                this.dateRange[0],
                this.dateRange[1],
            ])
            runInAction('updateDigest', () => {
                this.digest = digest

                this.expandAll(digest)
            })
        } catch (error) {
            console.error(error)

            AppToaster.show({
                message: 'Error fetching digest for selected organisation',
                intent: Intent.DANGER,
            })
        } finally {
            runInAction('digestFinishedFetching', () => {
                this.fetching = false
            })
        }
    }

    isSectionOpen = (id: string): boolean => this.openSections.has(id)

    @action updateParams = (params: { org?: string; tab?: string }) => {
        // Receives params from the router
        const { org, tab } = params

        if (org) {
            if (org === this.setOrg?.id && !!this.digest) {
                // Navigating back to org that already has digest loaded
                // Prevents reloading digest on tab change
                return
            }
            this.setOrg = org ? this.rootStore.orgStore.findItem(org) : undefined
            this.getDigest()
        }

        if (!tab || !Object.values(DiagnosticsGlobalTab).includes(tab as DiagnosticsGlobalTab)) {
            this.updateParams({ tab: DiagnosticsGlobalTab.digest })
            this.rootStore.router.goTo(
                views.diagnosticsGlobal,
                { org, tab: DiagnosticsGlobalTab.digest },
                this.rootStore
            )
            return
        }
    }

    @action updateDateRange = (dateRange: [moment.Moment | undefined, moment.Moment | undefined]) => {
        this.dateRange = dateRange
    }

    @action getInitialDigest = () => {
        if (!this.selectedOrg || !!this.digest) {
            // Ignore if org already selected or digest already present
            // Prevents reloading digest on tab change
            return
        }
        // Set to past 24 hours by default
        this.updateDateRange([moment().subtract(24, 'hours'), moment()])
        this.getDigest()
    }

    @action collapseSection = (id: string) => {
        if (this.openSections.has(id)) {
            this.openSections.delete(id)
        } else {
            this.openSections.add(id)
        }
    }

    @action collapseAll = () => {
        this.openSections.clear()
    }

    @action expandAll = (digest: DiagnosticsDigest) => {
        this.openSections = new Set<string>([
            ...Object.values(Outage),
            ...Object.values(ErrorState),
            ...Object.values(Error).map(e => ErrorState.newErrors + e),
            ...Array.from(digest.newFaults.errorListMap.keys()).map(c => ErrorState.newErrors + Error.faults + c.name),
            ...Array.from(digest.newCritical.errorListMap.keys()).map(
                c => ErrorState.newErrors + Error.critical + c.name
            ),
            ...(ErrorState.newErrors + Error.warnings),
            ...Object.values(RecurringError).map(e => ErrorState.recurringErrors + e),
            ...Array.from(digest.recurringFaults.errorListMap.keys()).map(
                c => ErrorState.recurringErrors + RecurringError.recurringFaults + c.name
            ),
            ...Array.from(digest.recurringCritical.errorListMap.keys()).map(
                c => ErrorState.recurringErrors + RecurringError.recurringCritical + c.name
            ),
            ...(ErrorState.recurringErrors + RecurringError.recurringWarnings),
        ])
    }
}
