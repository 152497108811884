import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer, Provider } from 'mobx-react'
import RootStore, { rootStore } from 'src/common/RootStore'
import { RoutePath } from 'src/config/views'

import PopoutManager from '../managers/PopoutManager'
import PopoutStore from '../stores/PopoutStore'
import BaseModel from '../models/BaseModel'
import ErrorHistoryManager from '../managers/ErrorHistoryManager'
import TileHistoryManager from '../managers/TileHistoryManager'

import { Popout } from 'src/common/components/popout'
import Player from 'src/modules/live/submodules/player/container'
import Console from 'src/modules/diagnostics/screen/submodules/console/container'
import ErrorHistory from 'src/modules/diagnostics/screen/submodules/error-history/container'
import TileHistory from 'src/modules/diagnostics/screen/submodules/tile-error-history/container'
import Select from 'react-select'
import { DropdownIndicator, Option } from 'src/common/components/SelectComponents'

@inject('store')
@observer
class PopoutWrapper extends React.Component<{ store?: RootStore }> {
    @computed get stores(): Array<PopoutStore<PopoutManager, BaseModel | undefined>> {
        return [
            this.props.store!.playerStore,
            this.props.store!.consoleStore,
            this.props.store!.errorHistoryStore,
            this.props.store!.tileHistoryStore,
        ]
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload)
        window.addEventListener('unload', this.handleUnload)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload)
        window.removeEventListener('unload', this.handleUnload)
    }

    // Check if any portals are open before closing parent window
    handleBeforeUnload = (event: BeforeUnloadEvent): string | undefined => {
        let hasOpenPortals = false
        this.stores.forEach(store => {
            if (store.openPopoutMap.size > 0) {
                hasOpenPortals = true
                return
            }
        })

        // Prevents using browser confirmation dialog when user has acknowledged destructive action
        // NOTE: Newer browsers will ignore the returned string and display it's own message
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Browser_compatibility
        const confirmationMessage = 'You have open windows, are you sure you want to continue?'
        if (
            hasOpenPortals &&
            this.props.store!.router.currentView &&
            this.props.store!.router.currentView.rootPath !== RoutePath.auth
        ) {
            // Prevent prompting confirmation when signing out
            ;(event || window.event).returnValue = confirmationMessage
            return confirmationMessage
        } else {
            // NOTE: Internet Explorer does not respect the null return value and will display this
            // to users as "null" text. You have to use undefined to skip the prompt
            return undefined
        }
    }

    // Close any lingering portal windows upon closing the parent window
    @action handleUnload = (event: any) => {
        this.stores.forEach(store => {
            store.managerMap.clear()
        })
    }

    render() {
        // NOTE: Popout options are ignored on mobile browsers
        const width = 900
        const height = 900
        const left = window.screen.width / 2 - width / 2
        const top = window.screen.height / 2 - height / 2

        const openPlayers = Array.from(this.stores[0].openPopoutMap.entries())
        const openConsoles = Array.from(this.stores[1].openPopoutMap.entries())
        const openErrorHistorys = Array.from(this.stores[2].openPopoutMap.entries())
        const openTileHistorys = Array.from(this.stores[3].openPopoutMap.entries())

        const selectOptions = [
            { value: 0, label: '0' },
            { value: 1, label: '1' },
        ]

        return (
            <React.Fragment>
                {/* Player popouts */}
                {openPlayers.map(player => (
                    <div key={player[0]}>
                        <Popout
                            persistOnOrphan
                            title={player[1].title}
                            name={player[0]}
                            onClose={player[1].togglePopout}
                            onBeforeUnload={player[1].handleBeforeUnload}
                            onBlocked={player[1].handlePopoutBlocked}
                            options={{ width, height, left, top }}
                        >
                            <Provider store={rootStore}>
                                <Player screenId={player[0]} />
                            </Provider>
                        </Popout>
                    </div>
                ))}
                {/* Console popouts */}
                {openConsoles.map(consoleWindow => (
                    <div key={consoleWindow[0]}>
                        <Popout
                            persistOnOrphan
                            title={consoleWindow[1].title}
                            name={consoleWindow[0]}
                            onClose={consoleWindow[1].togglePopout}
                            onBlocked={consoleWindow[1].handlePopoutBlocked}
                            options={{ width, height, left, top }}
                        >
                            <Provider store={rootStore}>
                                <Console controllerId={consoleWindow[0]} />
                            </Provider>
                        </Popout>
                    </div>
                ))}
                {/* Error history popouts */}
                {openErrorHistorys.map(errorHistory => (
                    <div key={errorHistory[0]}>
                        <Popout
                            persistOnOrphan
                            title={errorHistory[1].title}
                            name={errorHistory[0]}
                            onClose={errorHistory[1].togglePopout}
                            onBlocked={errorHistory[1].handlePopoutBlocked}
                            options={{ width, height, left, top }}
                        >
                            <Provider store={rootStore}>
                                <ErrorHistory errorHistoryManager={errorHistory[1] as ErrorHistoryManager} />
                            </Provider>
                        </Popout>
                    </div>
                ))}
                {/* Tile history popouts */}
                {openTileHistorys.map(tileHistory => (
                    <div key={tileHistory[0]}>
                        <Popout
                            persistOnOrphan
                            title={tileHistory[1].title}
                            name={tileHistory[0]}
                            onClose={tileHistory[1].togglePopout}
                            onBlocked={tileHistory[1].handlePopoutBlocked}
                            options={{ width, height, left, top }}
                        >
                            <Provider store={rootStore}>
                                <TileHistory tileHistoryManager={tileHistory[1] as TileHistoryManager} />
                            </Provider>
                        </Popout>
                    </div>
                ))}
                {/* Render a hidden dropdown to prepare dynamic styles for player */}
                <Select
                    className='custom-select-wrapper select-hidden'
                    classNamePrefix='custom-select'
                    menuIsOpen
                    isSearchable={false}
                    blurInputOnSelect
                    options={selectOptions}
                    value={selectOptions[0]}
                    components={{ DropdownIndicator, Option }}
                />
            </React.Fragment>
        )
    }
}

export default PopoutWrapper
