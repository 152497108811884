import { observable, computed } from 'mobx'
import BaseModel from './BaseModel'

import { rootStore } from '../RootStore'

import { millisecondsDiffToString } from '../utils/Duration'

import { ErrorCode } from './DiagnosticErrorEnums'
import DiagnosticsError from './DiagnosticsError'
import Screen from './Screen'
import moment from 'moment'

class DigestAlert extends BaseModel {
    name: string
    objectId: string
    firstSeen: moment.Moment
    resolved: moment.Moment

    constructor(json: DigestAlertJSON) {
        super(json)
        this.name = rootStore.controllerStore.findItem(json.objectId)?.screen?.name || json.name
        this.objectId = json.objectId
        this.firstSeen = moment(json.firstSeen)
        this.resolved = moment(json.resolved)
    }

    @computed get start(): string | undefined {
        return this.firstSeen.isValid() ? this.firstSeen.fromNow() : undefined
    }

    @computed get end(): string | undefined {
        return this.resolved.isValid() ? this.resolved.fromNow() : undefined
    }

    @computed get duration(): string | undefined {
        return this.end && millisecondsDiffToString(this.firstSeen, this.resolved)
    }
}

type DigestAlertJSON = DigestAlert

export class DigestError extends DiagnosticsError {
    sourceName: string

    constructor(json: DigestErrorJSON) {
        super(json)
        this.sourceName = json.sourceName
    }
}

type DigestErrorJSON = DigestError

export class DigestWarningSummary {
    @observable errorListMap = new Map<Screen, Map<string, number>>()

    constructor(json: [string, { ErrorCode: number }]) {
        Object.entries(json).forEach((controllerCountPair: [string, { ErrorCode: number }]) => {
            const screen = rootStore.controllerStore.findItem(controllerCountPair[0])?.screen

            if (screen) {
                this.errorListMap.set(screen, new Map(Object.entries(controllerCountPair[1])))
            }
        })
    }

    @computed get errorList(): Array<[Screen, SummaryError[]]> {
        return Array.from(this.errorListMap.entries()).map((value: [Screen, Map<string, number>]) => [
                value[0],
                Array.from(value[1].entries()).map(v => ({
                        code: v[0],
                        count: v[1],
                    })) || [],
            ])
    }

    @computed get length(): number {
        return Array.from(this.errorListMap.entries()).reduce((a, b) => a + b[1].size, 0)
    }
}

export interface SummaryError {
    code: ErrorCode | string
    count: number
}

export class DigestErrorGroup {
    @observable errorListMap = new Map<Screen, DigestError[]>()
    @observable code: ErrorCode

    constructor(errorList: DigestError[]) {
        errorList?.forEach(e => {
            const digestError = new DigestError(e)
            // NOTE: Assumes controller has only one screen
            const screen = digestError.controller?.screen
            if (screen) {
                this.errorListMap.set(
                    screen,
                    this.errorListMap.get(screen) ? [...this.errorListMap.get(screen)!, digestError] : [digestError]
                )
            }
        })
        this.errorListMap = new Map([...this.errorListMap.entries()].sort((a, b) => a[0].name.localeCompare(b[0].name)))
    }

    @computed get errorList(): Array<[Screen, DigestError[]]> {
        return Array.from(this.errorListMap.entries())
    }

    @computed get length(): number {
        return Array.from(this.errorListMap.entries()).reduce((a, b) => a + b[1]?.length, 0)
    }
}

class DiagnosticsDigest {
    @observable outages: DigestAlert[]
    @observable stoppages: DigestAlert[]
    @observable newFaults: DigestErrorGroup
    @observable newCritical: DigestErrorGroup
    @observable newWarnings: DigestWarningSummary
    @observable recurringFaults: DigestErrorGroup
    @observable recurringCritical: DigestErrorGroup
    @observable recurringWarnings: DigestWarningSummary

    constructor(json: DiagnosticsDigestJSON) {
        this.outages = json.outages
            ?.map((o: DigestAlert) => new DigestAlert(o))
            .sort((a: DigestAlert, b: DigestAlert) => a.name.localeCompare(b.name))
        this.stoppages = json.stoppages
            ?.map((o: DigestAlert) => new DigestAlert(o))
            .sort((a: DigestAlert, b: DigestAlert) => a.name.localeCompare(b.name))
        this.newFaults = new DigestErrorGroup(json.newFaults?.map(e => new DigestError(e)))
        this.newCritical = new DigestErrorGroup(json.newCritical?.map(e => new DigestError(e)))
        this.newWarnings = new DigestWarningSummary(json.newWarnings)
        this.recurringFaults = new DigestErrorGroup(json.recurringFaults?.map(e => new DigestError(e)))
        this.recurringCritical = new DigestErrorGroup(json.recurringCritical?.map(e => new DigestError(e)))
        this.recurringWarnings = new DigestWarningSummary(json.recurringWarnings)
    }

    @computed get newErrorsCount(): number {
        return this.newFaults.length + this.newCritical.length + this.newWarnings.length
    }

    @computed get recurringErrorsCount(): number {
        return this.recurringFaults.length + this.recurringCritical.length + this.recurringWarnings.length
    }
}

interface DiagnosticsDigestJSON {
    outages: DigestAlertJSON[]
    stoppages: DigestAlertJSON[]
    newFaults: DigestErrorJSON[]
    newCritical: DigestErrorJSON[]
    newWarnings: any
    recurringFaults: DigestErrorJSON[]
    recurringCritical: DigestErrorJSON[]
    recurringWarnings: any
}

export default DiagnosticsDigest
