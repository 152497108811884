import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

// Sentry
import * as Sentry from '@sentry/browser'

import { browserStorageManager } from '../managers/BrowserStorageManager'
import { Alert, Intent } from '@blueprintjs/core'

@inject('store')
@observer
class ErrorBoundary extends React.Component<{ store?: RootStore }> {
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI
        return { hasError: true }
    }

    state: { hasError: boolean; eventId: string | null }

    constructor(props: any) {
        super(props)
        this.state = { hasError: false, eventId: null }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Captured exceptions not bubbled up to Sentry's global uncaught error handler in production
        Sentry.withScope(scope => {
            scope.setExtras({ errorInfo })
            const eventId = Sentry.captureException(error)
            this.setState({ eventId })

            // Mark crash as originating from error boundary
            scope.setExtra('isFromErrorBoundary', true)
        })
    }

    render() {
        if (this.state.hasError && process.env.REACT_APP_ENV !== 'development') {
            // TEMPORARY: Wipe local and session store if app crashes
            browserStorageManager.clearAllStorageItems()

            // Fallback UI
            return (
                <Alert
                    className='custom-alert custom-alert-full bp3-dark'
                    confirmButtonText='Refresh'
                    intent={Intent.PRIMARY}
                    isOpen
                    onConfirm={window.location.reload.bind(window.location)}
                >
                    <h1>Something went wrong</h1>
                    <h4>
                        Please try refreshing your browser. If you continue to experience issues, please{' '}
                        <a href='https://candelic.com' target='_blank' rel='noopener noreferrer'>
                            contact us
                        </a>
                        .
                    </h4>
                </Alert>
            )
        }
        // Normally, just render children
        return this.props.children
    }
}

export default ErrorBoundary
