export enum PlayerSyncState {
    unknown = 'unknown',
    inSync = 'inSync',
    syncing = 'syncing',
    outOfSync = 'outOfSync',
    notPlaying = 'notPlaying',
}

export enum PlayerName {
    candelic = 'candelic',
    broadsign = 'broadsign',
    scala = 'scala',
}
