import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { Drawer, Position } from '@blueprintjs/core'
import { Row, Col, Table } from 'reactstrap'
import clearIcon from 'src/assets/images/clearIcon.svg'
import playsIcon from 'src/assets/images/playsIcon.svg'
import timeIcon from 'src/assets/images/timeIcon.svg'
import viewsIcon from 'src/assets/images/viewsIcon.svg'
import imageBroken from 'src/assets/images/imageBroken.svg'
import moment from 'moment'
import 'moment-duration-format'

@inject('store')
@observer
class ScreenSummaryDrawer extends React.Component<{ store?: RootStore } & WithNamespaces> {
    toggleScreenSummaryDrawer = () => {
        this.props.store!.liveUIStore.toggleScreenSummaryDrawer()
    }

    handleImageResponseError = (event: any) => {
        // Prevent infinite loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
    }

    render() {
        const liveImage = this.props.store!.liveUIStore.liveImageForScreenSummary
        const liveImageStats = this.props.store!.liveUIStore.liveImageStatsForScreenSummary

        if (!liveImage) {
            return null
        }

        const { screenId, URL, filename, unnamed } = liveImage

        // Convert total and average time into moment object
        const totalTimeSeconds = moment.duration(liveImageStats?.totalTime, 'seconds')
        const averageTimeSeconds = moment.duration(liveImageStats?.averageTime, 'seconds')

        const screen = this.props.store!.screenStore.findItem(screenId)

        return (
            <Drawer
                isOpen={this.props.store!.liveUIStore.screenSummaryDrawerOpen}
                size='100%'
                portalClassName='custom-drawer report-summary-drawer screen-summary'
                canOutsideClickClose={false}
                position={Position.TOP}
            >
                <div className='custom-summary-modal-body custom-container'>
                    <img
                        className='report-summary-modal-close'
                        onClick={this.toggleScreenSummaryDrawer}
                        src={clearIcon}
                        alt='Close'
                    />
                    <Row className='custom-summary-body-content'>
                        <Col md={6} xs={12}>
                            <div className='custom-summary-table ml-auto mr-auto'>
                                <Table responsive>
                                    <tbody>
                                        <tr key={screenId} className='custom-summary-table-row'>
                                            <td className='p-0'>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={5} className='align-middle table-title'>
                                                                {screen && screen.name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-middle'>
                                                                <img src={playsIcon} alt='Play count' height={10} />{' '}
                                                                {liveImage.plays} plays
                                                            </td>
                                                            <td className='align-middle'>
                                                                <img src={timeIcon} alt='Play time' height={13} />
                                                                <span className={!liveImageStats ? 'bp3-skeleton' : ''}>
                                                                    Total time{' '}
                                                                    {totalTimeSeconds.format('H[h] m[m] s[s]')} /{' '}
                                                                    {averageTimeSeconds.format('s[s]')}{' '}
                                                                    {i18n.t('common.average')}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className='align-middle'>
                                                                <img src={viewsIcon} alt='First played' height={7.6} />
                                                                <span>First played at</span>
                                                                <span
                                                                    className={
                                                                        'ml-1' +
                                                                        (!liveImageStats ? ' bp3-skeleton' : '')
                                                                    }
                                                                >
                                                                    {moment(liveImageStats?.firstPlayed).format(
                                                                        this.props.store!.userStore.me?.dateTimeFormat
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-middle'>
                                                                <img src={viewsIcon} alt='Last played' height={7.6} />
                                                                <span>Last played at </span>
                                                                <span
                                                                    className={
                                                                        'ml-1' +
                                                                        (!liveImageStats ? ' bp3-skeleton' : '')
                                                                    }
                                                                >
                                                                    {moment(liveImageStats?.lastPlayed).format(
                                                                        this.props.store!.userStore.me?.dateTimeFormat
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className='custom-summary-modal-image-row-container'>
                                <div className='custom-summary-modal-content'>
                                    <div className='custom-summary-image-container'>
                                        <img
                                            className='modal-image'
                                            src={URL ? URL : imageBroken}
                                            alt={URL ? (filename ? filename : '') : 'Image missing'}
                                            onError={this.handleImageResponseError}
                                        />
                                        <span className='modal-image-title align-top'>
                                            {!unnamed
                                                ? filename
                                                : i18n.t('reportsPage.unnamedImage') + '(' + filename + ')'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Drawer>
        )
    }
}

export default withNamespaces()(ScreenSummaryDrawer)
