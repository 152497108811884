import Location from './Location'
import { observable, computed } from 'mobx'
import BaseModel from './BaseModel'
import { Omit } from 'react-router'
import { rootStore } from '../RootStore'
import Screen from './Screen'
import DomainStore from '../stores/DomainStore'
import { OrganisationPermission } from './Organisation'
import { PlayerSyncState } from './PlayerSyncState'
import { gte, lt } from 'semver'
import i18n from 'src/i18n'

// Minimum version to support firmware versions as strings
const MIN_VERSION_FIRMWARE_STRINGS = '1.7.0'

// Minimum version to support console subscription
const MIN_VERSION_CONSOLE_SUBSCRIPTION = '1.8.11'

export interface ControllerData {
    daemon: false
    restart: false
    uptime: 0 // seconds
    version: ''
    daemonVersion: ''
    jitter: 0
    vsyncDelays: 0
    vsyncDrops: 0
    hardwareVsync: 0
    threadVsync: 0
    threadEngine: 0
    threadQueue: 0
}

class Controller extends BaseModel {
    static newController(): Controller {
        return new Controller({
            name: '',
            enabled: true,
            locationId: '',
            isConnected: false,
            isTest: false,
            scheduledDowntime: [],
            daemon: false,
            daemonVersion: '',
            restart: false,
            uptime: 0, // seconds
            version: '',
            jitter: 0,
            vsyncDelays: 0,
            vsyncDrops: 0,
            hardwareVsync: 0,
            threadVsync: 0,
            threadEngine: 0,
            threadQueue: 0,
            maxFirmware: '',
            minFirmware: '',
        })
    }

    @observable name: string
    @observable enabled: boolean
    @observable locationId: string
    @observable isConnected: boolean
    @observable isTest: boolean
    @observable scheduledDowntime: string[][] // [["18:58 Europe/London", "06:00 Europe/London"]]
    @observable daemon: boolean
    @observable daemonVersion: string
    @observable restart: boolean
    @observable uptime: number
    @observable version: string
    @observable jitter: number
    @observable vsyncDelays: number
    @observable vsyncDrops: number
    @observable hardwareVsync: number
    @observable threadVsync: number
    @observable threadEngine: number
    @observable threadQueue: number
    @observable maxFirmware: string
    @observable minFirmware: string
    @observable playerName?: string
    @observable playerSyncState?: PlayerSyncState

    constructor(json: ControllerJSON) {
        super(json)

        this.name = json.name
        this.enabled = json.enabled
        this.locationId = json.locationId
        this.isConnected = json.isConnected
        this.isTest = json.isTest
        this.scheduledDowntime = json.scheduledDowntime
        this.daemon = json.daemon
        this.daemonVersion = json.daemonVersion
        this.restart = json.restart
        this.uptime = json.uptime
        this.version = json.version
        this.jitter = json.jitter
        this.vsyncDelays = json.vsyncDelays
        this.vsyncDrops = json.vsyncDrops
        this.hardwareVsync = json.hardwareVsync
        this.threadVsync = json.threadVsync
        this.threadEngine = json.threadEngine
        this.threadQueue = json.threadQueue
        this.maxFirmware = json.maxFirmware
        this.minFirmware = json.minFirmware
        this.playerName = json.playerName
        this.playerSyncState = json.playerSyncState
    }

    static get store(): DomainStore<Controller> {
        return rootStore.controllerStore
    }

    @computed get location(): Location | undefined {
        return rootStore.locationStore.findItem(this.locationId)
    }

    @computed get screen(): Screen | undefined {
        // NOTE: Assumes a 1:1 relationship between controller and screen
        return rootStore.screenStore.items.find(screen => screen.controllerId === this.id)
    }

    @computed get firmwareRange(): string | undefined {
        if (lt(this.version, MIN_VERSION_FIRMWARE_STRINGS)) {
            return undefined
        }
        if (this.minFirmware === '' || this.maxFirmware === '') {
            return ''
        }
        return this.maxFirmware === this.minFirmware ? this.maxFirmware : `${this.minFirmware} - ${this.maxFirmware}`
    }

    @computed get supportsConsoleSubscription(): boolean {
        return gte(this.version, MIN_VERSION_CONSOLE_SUBSCRIPTION)
    }

    @computed get daemonStatus(): string {
        if (!this.daemon) {
            return i18n.t('diagnosticsPage.daemonNotRunning')
        }
        return this.daemonVersion || i18n.t('diagnosticsPage.daemonRunning')
    }

    @computed get hasCompletedDataFields(): boolean {
        return this.name !== ''
    }

    @computed get isOwned(): boolean {
        return rootStore.authStore.isAdminsOrg(this.location?.organisation?.id)
    }

    @computed get canEdit(): boolean {
        return this.isOwned || this.location?.organisation?.permissions === OrganisationPermission.write
    }

    toJSON(includeDates?: boolean): ControllerJSON {
        const json = super.toJSON(includeDates) as ControllerJSON
        json.name = this.name
        json.enabled = this.enabled
        json.isTest = this.isTest
        json.locationId = this.locationId
        return json
    }
}

export type ControllerJSON = Omit<
    Controller,
    | 'toJSON'
    | 'isValid'
    | 'hasCompletedDataFields'
    | 'location'
    | 'screen'
    | 'firmwareRange'
    | 'supportsConsoleSubscription'
    | 'daemonStatus'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
>

export default Controller
