import * as React from 'react'
import { autorun, IReactionDisposer, reaction } from 'mobx'

import i18n from 'src/i18n'

import useSound from 'use-sound'
import { PlayFunction } from 'use-sound/dist/types'
import alertSfx from '../../assets/sounds/alert.mp3'
import resolveSfx from '../../assets/sounds/resolve.mp3'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Form, Label, InputGroup } from 'reactstrap'
import Select from 'react-select'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    ValueLabelPair,
    GroupHeading,
} from '../../common/components/SelectComponents'
import { Icon, Intent } from '@blueprintjs/core'
import MissionControlGrid from './components/MissionControlGrid'
import FormGroup from 'reactstrap/lib/FormGroup'
import Checkbox from 'src/common/components/Checkbox'
import moment from 'moment'
import LoadingSpinner from 'src/common/components/LoadingSpinner'

// HOC for adding useSound hook
function withNotification(Component: typeof MissionControl) {
    return function WrappedComponent(props: any) {
        const [alert] = useSound(alertSfx)
        const [resolved] = useSound(resolveSfx)
        return <Component {...props} notifyAlert={alert} notifyResolved={resolved} />
    }
}

interface MissionControlProps {
    store?: RootStore
    notifyAlert: PlayFunction
    notifyResolved: PlayFunction
}

@inject('store')
@observer
class MissionControl extends React.Component<MissionControlProps> {
    state = { concernCount: 0, fineCount: 0, timestamps: new Map() }
    fetchingOrgsDisposer: IReactionDisposer
    alertUpdateDisposer: IReactionDisposer
    resolveUpdateDisposer: IReactionDisposer

    componentDidMount() {
        // Silence on mount
        const missionControlUIStore = this.props.store!.missionControlUIStore
        missionControlUIStore.silenceNotifications()

        const orgStore = this.props.store!.orgStore

        // Reset initial timestamps when orgs refresh, once request has settled
        this.fetchingOrgsDisposer = reaction(
            () => orgStore.isFetching,
            isFetching => {
                if (!isFetching) {
                    this.setState({
                        timestamps: new Map(
                            [...missionControlUIStore.concernOrgs, ...missionControlUIStore.fineOrgs].map(o => [
                                o.id!,
                                o.screenStatuses && moment(o.screenStatuses.updatedAt).isValid
                                    ? o.screenStatuses.updatedAt
                                    : moment(),
                            ])
                        ),
                    })
                }
            },
            {
                fireImmediately: true,
            }
        )
        // Set up listeners for counts
        this.alertUpdateDisposer = autorun(() => {
            if (orgStore.isFetching) {
                // Don't update counts
                return
            }
            const cur = missionControlUIStore.concernOrgsCount
            const prev = this.state.concernCount
            if (!missionControlUIStore.isSilenced && cur !== prev && cur > prev) {
                if (missionControlUIStore.enableSounds) {
                    // Notify alert
                    this.props.notifyAlert()
                }
            }
            this.setState({ concernCount: cur })
        })
        this.resolveUpdateDisposer = autorun(() => {
            if (orgStore.isFetching) {
                // Don't update counts
                return
            }
            const cur = missionControlUIStore.fineOrgsCount
            const prev = this.state.fineCount
            if (!missionControlUIStore.isSilenced && cur !== prev && cur > prev) {
                if (missionControlUIStore.enableSounds) {
                    // Notify resolved
                    this.props.notifyResolved()
                }
            }
            this.setState({ fineCount: cur })
        })
    }

    componentWillUnmount() {
        // Dispose of reactions
        this.fetchingOrgsDisposer()
        this.alertUpdateDisposer()
        this.resolveUpdateDisposer()
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return
        }

        const orgStore = this.props.store!.orgStore
        const orgOptions = orgStore.availableAssociatedByOrganisationsOptionsGroupedByCountry

        const missionControlUIStore = this.props.store!.missionControlUIStore
        const filter = missionControlUIStore.filter
        const concernOrgs = missionControlUIStore.concernOrgs
        const fineOrgs = missionControlUIStore.fineOrgs

        const isFetching = orgStore.isFetching

        return (
            <div className='mission-control-container'>
                <Form className='filter-mission-control-orgs' inline onSubmit={eventPreventDefault}>
                    <InputGroup className='custom-input-group custom-input-group-inline'>
                        <Label className='custom-label custom-label-inline'>
                            <h5>Organisations</h5>
                        </Label>
                        <Select
                            className='custom-select-wrapper'
                            classNamePrefix='custom-select'
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            placeholder={i18n.t('common.all')}
                            value={
                                filter?.map(orgId => {
                                    const org = orgStore.findItem(orgId)
                                    return org && { value: orgId, label: org.name }
                                }) as ValueLabelPair[]
                            }
                            onChange={(selectedOptions: ValueLabelPair[]) =>
                                missionControlUIStore.updateOrgFilter(selectedOptions)
                            }
                            options={orgOptions}
                            components={{
                                ValueContainer,
                                ClearIndicator,
                                DropdownIndicator,
                                Option,
                                NoOptionsMessage,
                                GroupHeading,
                            }}
                        />
                    </InputGroup>
                    <FormGroup className='custom-form-group custom-form-group-inline checkbox-form-group'>
                        <Label className='custom-label custom-label-inline'>
                            <h5>{i18n.t('missionControlPage.enableSounds')}</h5>
                        </Label>
                        <Checkbox
                            isChecked={missionControlUIStore.enableSounds}
                            handleOnClick={missionControlUIStore.toggleEnableSounds}
                        />
                    </FormGroup>
                </Form>

                {!isFetching ? (
                    <>
                        {concernOrgs && concernOrgs.length > 0 ? (
                            <MissionControlGrid
                                data={concernOrgs}
                                timestamps={this.state.timestamps}
                                store={this.props.store!}
                            />
                        ) : (
                            <div className='diagnostics-non-ideal-state mt-4'>
                                <h2>
                                    <Icon className='mr-2' icon='tick-circle' iconSize={22} intent={Intent.SUCCESS} />
                                    {i18n.t('diagnosticsPage.noOrganisationsWithConcerns')}
                                </h2>
                            </div>
                        )}
                        <hr />
                        {fineOrgs && fineOrgs.length > 0 && (
                            <MissionControlGrid
                                data={fineOrgs}
                                timestamps={this.state.timestamps}
                                store={this.props.store!}
                            />
                        )}
                    </>
                ) : (
                    <div className='diagnostics-non-ideal-state mt-4'>
                        <LoadingSpinner />
                    </div>
                )}
            </div>
        )
    }
}

export default withNotification(MissionControl)
