import { action, computed, observable, runInAction } from 'mobx'

import RootStore from 'src/common/RootStore'

import { ValueLabelPair } from 'src/common/components/SelectComponents'
import { browserStorageManager, StorageKey } from 'src/common/managers/BrowserStorageManager'

export default class MissionControlUIStore {
    @observable filter: string[]
    @observable isSilenced: boolean = false
    @observable enableSounds: boolean = true

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        const savedFilters = browserStorageManager.readLocalStorageObject(StorageKey.missionControlFilters) as any
        if (savedFilters) {
            this.updateOrgFilter(savedFilters)
        }
        const soundsEnabled = browserStorageManager.readLocalStorageObject(StorageKey.missionControlSounds) as any
        if (soundsEnabled !== undefined) {
            this.enableSounds = soundsEnabled
        }
    }

    @action silenceNotifications = () => {
        if (this.isSilenced) {
            // Ignore, already silenced
            return
        }
        this.isSilenced = true
        setTimeout(() => {
            runInAction('unmuteNotifications', () => {
                this.isSilenced = false
            })
        }, 1000)
    }

    @action updateOrgFilter = (selectedOption: ValueLabelPair | ValueLabelPair[]) => {
        this.silenceNotifications()
        if (!Array.isArray(selectedOption)) {
            return
        }
        this.filter = selectedOption.map((org: ValueLabelPair) => org.value)
        browserStorageManager.updateLocalStorageItem(StorageKey.missionControlFilters, selectedOption)
    }

    @action toggleEnableSounds = () => {
        const enabled = !this.enableSounds
        this.enableSounds = enabled
        browserStorageManager.updateLocalStorageItem(StorageKey.missionControlSounds, String(enabled))
    }

    @computed get concernOrgs() {
        return (
            this.rootStore!.orgStore.items?.filter(
                org =>
                    org.deletedAt === null &&
                    org.id &&
                    (this.filter?.length > 0 ? this.filter.includes(org.id) : true) &&
                    org.screenStatuses &&
                    org.screenStatuses.isConcern
            )?.sort((a, b) => 
                // Sort by severity
                 (
                    (b.screenStatuses?.outages ?? 0) - (a.screenStatuses?.outages ?? 0) ||
                    (b.screenStatuses?.stoppages ?? 0) - (a.screenStatuses?.stoppages ?? 0) ||
                    (b.screenStatuses?.faults ?? 0) - (a.screenStatuses?.faults ?? 0) ||
                    a.name.localeCompare(b.name)
                )
            ) ?? []
        )
    }

    @computed get concernOrgsCount() {
        return this.concernOrgs.length ?? 0
    }

    @computed get fineOrgs() {
        return (
            this.rootStore!.orgStore.items?.filter(
                org =>
                    org.deletedAt === null &&
                    org.id &&
                    (this.filter?.length > 0 ? this.filter.includes(org.id) : true) &&
                    (!org.screenStatuses || !org.screenStatuses.isConcern)
            )?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
        )
    }

    @computed get fineOrgsCount() {
        return this.fineOrgs.length ?? 0
    }
}
