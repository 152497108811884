import * as React from 'react'

import candelicLoader from 'src/assets/images/candelicLoader.gif'

class CandelicLoader extends React.Component {
    render() {
        return (
            <div className='loading-state'>
                <img src={candelicLoader} alt='Loading' height={58} />
            </div>
        )
    }
}

export default CandelicLoader
