import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { AppToaster } from 'src/common/components/AppToaster'
import { Tooltip, Position, Dialog, Intent } from '@blueprintjs/core'
import { Font, Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer'
import { Button } from 'reactstrap'
import downloadIcon from 'src/assets/images/downloadIcon.svg'
import candelicLogoDark from 'src/assets/images/pdfAssets/candelicLogoDark.png'
import moment from 'moment'

// Register font for PDF
Font.register({
    family: 'Lato',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/lato/v13/v0SdcGFAl2aezM9Vq_aFTQ.ttf',
            fontStyle: 'regular',
        },
        {
            src: 'https://fonts.gstatic.com/s/lato/v11/iX_QxBBZLhNj5JHlTzHQzg.ttf',
            fontStyle: 'bold',
        },
    ],
})

// Create styles for PDF
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        paddingTop: 25,
        paddingBottom: 45,
        paddingHorizontal: 25,
        fontFamily: 'Lato',
        fontStyle: 'regular',
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        margin: 10,
        fontSize: 20,
    },
    headerImg: {
        height: 36,
        marginRight: 10,
        paddingRight: 10,
        borderRightWidth: 1,
    },
    details: {
        flexDirection: 'row',
    },
    detailsSection: {
        flex: 2,
        flexDirection: 'row',
        margin: 10,
    },
    userSection: {
        flex: 1,
        flexDirection: 'row',
        margin: 10,
    },
    detailsHeader: {
        fontFamily: 'Lato',
        fontStyle: 'bold',
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        margin: 10,
        bottom: 15,
        paddingLeft: 25,
        fontSize: 8,
    },
    table: {
        width: 'auto',
        margin: 10,
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableColHeader: {
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 'auto',
        fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: 'auto',
        fontSize: 10,
    },
})

// NOTE: Don't render this directly, use memoized function
const DownloadLink = (props: any) => (
        <PDFDownloadLink {...props}>
            {({ blob, url, loading, error }) => {
                if (error) {
                    console.error(error)
                    props.handleCancelReport()
                    AppToaster.show({
                        icon: 'warning-sign',
                        message: 'Error generating report',
                        intent: Intent.DANGER,
                    })
                    return
                }

                return loading ? (
                    <Button className='custom-button-small m-3' disabled>
                        Generating PDF
                    </Button>
                ) : (
                    <Button onClick={props.handleCloseReport} className='custom-button-small m-3' color='primary'>
                        Download report
                    </Button>
                )
            }}
        </PDFDownloadLink>
    )

// Prevent pushing to PDF generation stream by memoizing PDFDownloadLink
// More info: https://reactjs.org/docs/react-api.html#reactmemo
// Related bug: https://github.com/diegomura/react-pdf/issues/420
const MemoizedDownloadLink = React.memo(DownloadLink, (prevProps: any, newProps: any) => 
    // Only rerender on props change
     prevProps === newProps
)

@inject('store')
@observer
class AssetLogButton extends React.Component<{ store?: RootStore }> {
    handleCancelReport = () => {
        if (this.props.store!.tileViewStore.generatingAssetLog) {
            this.props.store!.tileViewStore.stopAssetLogGeneration(false)
        }
    }

    handleCloseReport = () => {
        this.props.store!.tileViewStore.resetAssetLogReady()
    }

    render() {
        const me = this.props.store!.userStore.me
        const myOrg = this.props.store!.orgStore.myOrg
        if (!me || !myOrg) {
            return null
        }

        const tileViewStore = this.props.store!.tileViewStore
        const screenConfig = tileViewStore.screenConfig
        if (!screenConfig) {
            return null
        }

        const screenConnected = screenConfig.screen!.isConnected
        const generatingAssetLog = tileViewStore.generatingAssetLog
        const assetLogReady = tileViewStore.assetLogReady

        const tiles = screenConfig.tiles.slice().sort((a, b): number => {
            const splitA = a.tnl.match(/[a-z]+|[^a-z]+/gi)
            const splitB = b.tnl.match(/[a-z]+|[^a-z]+/gi)
            if (splitA && splitB) {
                if (splitA[0] === splitB[0]) {
                    return Number(splitA[1]) - Number(splitB[1])
                } else {
                    return splitA[0].localeCompare(splitB[0])
                }
            } else {
                return a.tnl.localeCompare(b.tnl)
            }
        })

        const fileName = moment().format('YYYY-MM-DD') + ' ' + screenConfig.screen!.name + ' ASSET LOG.pdf'

        // PDF Markup
        const AssetLogPDF = () => (
            <Document>
                <Page size='A4' style={styles.page} wrap>
                    <View style={styles.header}>
                        <Image src={candelicLogoDark} style={styles.headerImg} />
                        <Text style={{ marginTop: 3 }}>Asset management report</Text>
                    </View>
                    <View style={styles.details}>
                        <View style={styles.detailsSection}>
                            <View style={{ width: '90px' }}>
                                <Text style={styles.detailsHeader}>Organisation:</Text>
                                <Text style={styles.detailsHeader}>Screen:</Text>
                            </View>
                            <View>
                                <Text>{screenConfig.screen!.controller!.location!.organisation!.name}</Text>
                                <Text>{screenConfig.screen!.name}</Text>
                            </View>
                        </View>
                        <View style={styles.userSection}>
                            <View style={{ width: '45px' }}>
                                <Text style={styles.detailsHeader}>Date:</Text>
                                <Text style={styles.detailsHeader}>Time:</Text>
                                <Text style={styles.detailsHeader}>User:</Text>
                            </View>
                            <View>
                                <Text>{moment().format(me.dateFormat)}</Text>
                                <Text>{moment().format(me.timeFormat)}</Text>
                                <Text>{me.name}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={{ ...styles.tableColHeader, width: '10%' }}>
                                <Text style={styles.tableCellHeader}>TNL</Text>
                            </View>
                            <View style={{ ...styles.tableColHeader, width: '40%' }}>
                                <Text style={styles.tableCellHeader}>Tile UID</Text>
                            </View>
                            <View style={{ ...styles.tableColHeader, width: '10%' }}>
                                <Text style={styles.tableCellHeader}>Type</Text>
                            </View>
                            <View style={{ ...styles.tableColHeader, width: '20%' }}>
                                <Text style={styles.tableCellHeader}>Serial #</Text>
                            </View>
                            <View style={{ ...styles.tableColHeader, width: '20%' }}>
                                <Text style={styles.tableCellHeader}>Firmware</Text>
                            </View>
                        </View>
                        {tiles.map((tile, i) => (
                            <View key={i} style={styles.tableRow}>
                                <View style={{ ...styles.tableCol, width: '10%' }}>
                                    <Text style={styles.tableCell}>{tile.tnl}</Text>
                                </View>
                                <View style={{ ...styles.tableCol, width: '40%' }}>
                                    <Text style={styles.tableCell}>{tile.printableId}</Text>
                                </View>
                                <View style={{ ...styles.tableCol, width: '10%' }}>
                                    <Text style={styles.tableCell}>{tile.type}</Text>
                                </View>
                                <View style={{ ...styles.tableCol, width: '20%' }}>
                                    <Text style={styles.tableCell}>{tile.serial}</Text>
                                </View>
                                <View style={{ ...styles.tableCol, width: '20%' }}>
                                    <Text style={styles.tableCell}>{tile.firmware}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={styles.footer} fixed>
                        <Text style={{ marginRight: 'auto' }}>Candelic NZ Limited</Text>
                        <Text>{fileName}</Text>
                        <Text
                            style={{ marginLeft: 'auto' }}
                            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                            fixed
                        />
                    </View>
                </Page>
            </Document>
        )

        return (
            <React.Fragment>
                <div className='asset-log-button'>
                    <Tooltip
                        boundary='viewport'
                        content={
                            screenConnected
                                ? this.props.store!.tileViewStore.generatingAssetLog ||
                                  !this.props.store!.tileViewStore.isControllerPrimed
                                    ? 'Establishing connection'
                                    : 'Download asset management report'
                                : 'Asset management report unavailable while screen offline'
                        }
                        position={Position.TOP}
                        intent={!screenConnected ? Intent.DANGER : undefined}
                    >
                        <Button
                            onClick={tileViewStore.startAssetLogGeneration}
                            color='link'
                            disabled={
                                !screenConnected ||
                                this.props.store!.tileViewStore.generatingAssetLog ||
                                !this.props.store!.tileViewStore.isControllerPrimed
                            }
                        >
                            <img
                                src={downloadIcon}
                                alt='Download'
                                height={25}
                                draggable={false}
                                onDragStart={eventPreventDefault}
                            />
                        </Button>
                    </Tooltip>
                </div>
                <Dialog
                    className='custom-dialog'
                    title='Download asset management report'
                    isOpen={generatingAssetLog || assetLogReady}
                    onClose={this.handleCloseReport}
                    canOutsideClickClose={!generatingAssetLog && assetLogReady}
                    isCloseButtonShown={!generatingAssetLog && assetLogReady}
                >
                    <div className='dialog-content'>
                        {generatingAssetLog ? (
                            <Button className='custom-button-small m-3' disabled>
                                Generating asset log
                            </Button>
                        ) : (
                            <MemoizedDownloadLink
                                document={<AssetLogPDF />}
                                fileName={fileName}
                                handleCloseReport={this.handleCloseReport}
                                handleCancelReport={this.handleCancelReport}
                            />
                        )}
                        <div className='dialog-actions'>
                            <Button
                                className='custom-button-link custom-button-link-black'
                                onClick={generatingAssetLog ? this.handleCancelReport : this.handleCloseReport}
                                color='link'
                            >
                                <span>Cancel</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default AssetLogButton
