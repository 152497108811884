import { observable, action, computed } from 'mobx'
import BaseModel from 'src/common/models/BaseModel'

import Organisation, { OrganisationPermission } from 'src/common/models/Organisation'
import User from 'src/common/models/User'
import Screen from 'src/common/models/Screen'
import { tileTypeName } from 'src/common/models/Tile'

export interface AdminTableColData {
    label: string
    width?: number
    minWidth?: number
}

export interface AdminTableRowData {
    id: string
    values: Record<string, any>
}

type RowData = (item: BaseModel) => AdminTableRowData

export abstract class AdminTabData {
    @observable storeName: string
    @observable columns: AdminTableColData[]
    @observable data: AdminTableRowData[]
    @observable rowData: RowData

    constructor(storeName: string, columns: AdminTableColData[], rowData: RowData) {
        this.storeName = storeName
        this.columns = columns
        this.rowData = rowData
    }

    @computed get columnLabels(): string[] {
        return this.columns.map(column => column.label)
    }

    @action setRowData = (items: BaseModel[], showDeleted: boolean) => {
        this.data = items.filter(i => (!showDeleted ? i.deletedAt === null : true))?.map(i => this.rowData(i)) ?? []
    }
}

export class OrgAdminTabData extends AdminTabData {
    constructor() {
        const columns = [
            { label: 'name', minWidth: 200 },
            { label: 'country', width: 200 },
            { label: 'zipCode', width: 100 },
            { label: 'maxScreens', width: 150 },
            { label: 'associatedOrganisations', minWidth: 260 },
        ]
        super(
            'org',
            columns,
            (org: Organisation): AdminTableRowData => ({
                id: org.id!,
                values: {
                    name: org.name,
                    country: org.country,
                    zipCode: org.zipCode,
                    maxScreens: org.maxScreens,
                    associatedOrganisations: org.associatedOrganisations
                        ?.map(
                            organisation =>
                                organisation.name +
                                (organisation.permissions === OrganisationPermission.read ? ' [R]' : '') +
                                (organisation.permissions === OrganisationPermission.write ? ' [W]' : '')
                        )
                        .join(', '),
                    canEdit: org.canEdit,
                    canDelete: org.canDelete,
                    id: org.id,
                    deletedAt: org.deletedAt,
                },
            })
        )
    }
}

export class UserAdminTabData extends AdminTabData {
    constructor() {
        const columns = [
            { label: 'firstName' },
            { label: 'lastName' },
            { label: 'email', minWidth: 240 },
            { label: 'phone', width: 140 },
        ]
        super(
            'user',
            columns,
            (user: User): AdminTableRowData => ({
                id: user.id!,
                values: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    access: user.roleString,
                    showConsoleIcon: user.isSuperUser || user.hasConsoleAccess,
                    canEdit: user.canEdit,
                    canDelete: user.canDelete,
                    id: user.id,
                    emailVerified: user.emailVerified,
                    deletedAt: user.deletedAt,
                },
            })
        )
    }
}

export class ScreenAdminTabData extends AdminTabData {
    constructor() {
        const columns = [
            { label: 'name' },
            { label: 'tilesWide', width: 120 },
            { label: 'tilesHigh', width: 120 },
            { label: 'tileType', width: 130 },
            { label: 'resolution', width: 130 },
            { label: 'lednetVersion', width: 90 },
            { label: 'contentOwner', width: 170 },
            { label: 'operationsManager', width: 200 },
        ]
        super(
            'screen',
            columns,
            (screen: Screen): AdminTableRowData => ({
                id: screen.id!,
                values: {
                    name: screen.name,
                    tilesWide: screen.tilesWide,
                    tilesHigh: screen.tilesHigh,
                    tileType: screen.tileType && tileTypeName(screen.tileType),
                    resolution: screen.resolution,
                    version: screen.controller?.version ?? '',
                    contentOwner: screen.controller?.location?.contentOwner?.name ?? '',
                    operationsManager: screen.controller?.location?.operationsManager?.name ?? '',
                    canEdit: screen.canEdit,
                    canDelete: screen.canDelete,
                    isConnected: screen.isConnected,
                    id: screen.id,
                    enabled: screen.controller?.enabled,
                    deletedAt: screen.deletedAt,
                },
            })
        )
    }
}
