import { ErrorSeverity } from '../models/DiagnosticErrorEnums'
import { Outage, Error, RecurringError, Info } from '../../modules/diagnostics/global/submodules/digest/GlobalDigest'

export enum Colour {
    none = '#666666',
    primary = '#165a8b',
    success = '#53902c',
    outage = '#233d4d',
    stoppage = '#6c8ead',
    warning = '#c0b007',
    criticalWarning = '#bc501b',
    danger = '#bc1b1b',
    resolve = '#39d23e',
    assign = '#1262e8',
    pageDark = '#1b1d21',
    primaryLight = '#48aff0',
    white = '#ffffff',
    black = '#000000',
    grey = '#333333',
}

export const severityColour = (severity: ErrorSeverity): Colour => {
    switch (severity) {
        case ErrorSeverity.noError:
            return Colour.success
        case ErrorSeverity.warning:
            return Colour.warning
        case ErrorSeverity.criticalWarning:
            return Colour.criticalWarning
        case ErrorSeverity.visualFault:
            return Colour.danger
        case ErrorSeverity.noConnection:
            return Colour.none
    }
}

export const tagColour = (key: Outage | Error | RecurringError | string): string => {
    switch (key) {
        case Outage.outages:
            return Colour.outage
        case Outage.stoppages:
            return Colour.stoppage
        case Error.faults:
        case RecurringError.recurringFaults:
            return Colour.danger
        case Error.critical:
        case Error.criticalWarnings:
        case RecurringError.recurringCritical:
            return Colour.criticalWarning
        case Error.warnings:
        case RecurringError.recurringWarnings:
            return Colour.warning
        case Info.healthy:
            return Colour.success
        default:
            return ''
    }
}
