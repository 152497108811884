import * as React from 'react'

import { StorageKey, browserStorageManager } from 'src/common/managers/BrowserStorageManager'

// models
import { Route } from 'mobx-router'
import RootStore, { rootStore } from 'src/common/RootStore'
import { SettingsPageName, SettingsTabName } from 'src/modules/settings/stores/SettingsUIStore'

// containers
import MissionControl from '../modules/mission-control/container'
import Creatives from '../modules/creatives/container'
import Admin from '../modules/admin/container'
import Reports from '../modules/reports/container'
import Live from '../modules/live/container'
import Diagnostics from '../modules/diagnostics/container'
import Settings from 'src/modules/settings/container'
import SignIn from 'src/modules/auth/submodules/sign-in/container'
import SignOut from 'src/modules/auth/submodules/sign-out/container'
import PrivacyPolicy from 'src/modules/policy/privacy-policy/container'

export enum RoutePath {
    home = '/',
    auth = '/auth',
    authSuccess = '/auth-success',
    missionControl = '/mission-control',
    diagnostics = '/diagnostics',
    diagnosticsScreen = '/screen',
    diagnosticsGlobal = '/global',
    live = '/live',
    reports = '/reports',
    creatives = '/creatives',
    admin = '/admin',
    settings = '/settings',
    privacyPolicy = '/privacy',
}

export enum DiagnosticsGlobalTab {
    digest = 'digest',
    // history = 'history', // Commented out for now
}

export enum DiagnosticsScreenView {
    tileView = 'tile-view',
    errorHistory = 'error-history',
    tileHistory = 'tile-history',
}

export const staticRoutes: RoutePath[] = [RoutePath.privacyPolicy]

// Any information from the URL that needs to be retained needs to be sent here first
function routePathAllowed(routePath: RoutePath, params: any, store: RootStore, queryParams?: any): boolean {
    const me = store.userStore.me

    // If no user, go to auth
    if (!me) {
        // Save requested path and params
        store.authStore.updateRequestedPath(routePath, params, queryParams)

        store.router.goTo(views.auth, undefined, store)
        return false
    }
    // Check if user role allowed access to route
    if (me.userRole.allowedPaths.includes(routePath)) {
        // Access allowed
        return true
    } else {
        // If user does not have privileges, send to home page
        store.router.goTo(views.home, params, store)
        return false
    }
}

function routeParamsAllowed(params: any, store: RootStore): boolean {
    const me = store.userStore.me

    // If no user, go to auth
    if (!me) {
        store.router.goTo(views.auth, undefined, store)
        return false
    }

    switch (true) {
        // Only allow SuperUser or admin to access organisation settings
        case params.page === SettingsPageName.organisationProfile:
            return me.isSuperUser || me.isAdmin
        default:
            return true
    }
}

function saveCurrentPath(store: RootStore) {
    browserStorageManager.updateSessionStorageItem(StorageKey.currentView, {
        rootPath: store.router.currentView.rootPath,
        params: store.router.params,
        queryParams: store.router.queryParams,
    })
}

const views = {
    // Home will redirect to start path for current role
    home: new Route({
        path: RoutePath.home,
        onEnter: (route: any, params: any, store: RootStore) => {
            const me = store.userStore.me

            // If no user, go to auth
            if (!me) {
                store.router.goTo(views.auth, undefined, store)
                return false
            }

            // Go to start path for current role
            store.router.goTo(navRoutes.get(me.userRole.startPath), me.userRole.startParams, store)
            return false
        },
    }),
    // Mission Control page for SuperUser
    missionControl: new Route({
        component: <MissionControl />,
        path: RoutePath.missionControl,
        title: 'MissionControl',
        beforeEnter: (route: any, params: any, store: RootStore, queryParams: any) =>
            routePathAllowed(RoutePath.missionControl, params, store, queryParams),
    }),
    // Diagnostics page for Operator, Admin, SuperUser
    diagnostics: new Route({
        component: <Diagnostics />,
        path: RoutePath.diagnostics,
        title: 'Diagnostics',
        beforeEnter: (route: any, params: any, store: RootStore, queryParams: any) =>
            routePathAllowed(RoutePath.diagnostics, params, store, queryParams),
        onEnter: (route: any, params: any, store: RootStore, queryParams: any) => {
            saveCurrentPath(store)
            store.router.goTo(views.diagnosticsScreen, params, store) // Route to screen view by default
        },
    }),
    diagnosticsScreen: new Route({
        component: <Diagnostics />,
        path: RoutePath.diagnostics + RoutePath.diagnosticsScreen + '/:org/:screen?/:tab?',
        subroute: true,
        title: 'DiagnosticsScreen',
        beforeEnter: (
            route: any,
            params: { org: string; screen?: string; tab?: string },
            store: RootStore,
            queryParams: any
        ) => routePathAllowed(RoutePath.diagnosticsScreen, params, store, queryParams),
        onEnter: (
            route: any,
            params: { org: string; screen?: string; tab?: string },
            store: RootStore,
            queryParams: any
        ) => {
            saveCurrentPath(store)
            store.screenDiagnosticsUIStore.updateParams(params, queryParams)
        },
        onParamsChange: (
            route: any,
            params: { org: string; screen?: string; tab?: string },
            store: RootStore,
            queryParams: any
        ) => {
            saveCurrentPath(store)
            store.screenDiagnosticsUIStore.updateParams(params, queryParams)
        },
        onExit: (route: any, params: { org: string; screen: string; tab?: string }, store: RootStore) => {
            // End stat feed upon any navigation from /diagnostics route
            store.tileViewStore.sendStopStatFeedCommand()
        },
    }),
    diagnosticsGlobal: new Route({
        component: <Diagnostics />,
        path: RoutePath.diagnostics + RoutePath.diagnosticsGlobal + '/:org/:tab?',
        subroute: true,
        title: 'DiagnosticsGlobal',
        beforeEnter: (route: any, params: { org: string; tab?: string }, store: RootStore, queryParams: any) =>
            routePathAllowed(RoutePath.diagnosticsGlobal, params, store, queryParams),
        onEnter: (route: any, params: { org: string; tab?: string }, store: RootStore, queryParams: any) => {
            saveCurrentPath(store)
            store.globalDiagnosticsUIStore.updateParams(params)
        },
        onParamsChange: (route: any, params: { org: string; tab?: string }, store: RootStore, queryParams: any) => {
            saveCurrentPath(store)
            store.globalDiagnosticsUIStore.updateParams(params)
        },
    }),
    // Live page for ContentOwner, Admin, SuperUser
    live: new Route({
        component: <Live />,
        path: RoutePath.live + '/:org',
        title: 'Live',
        beforeEnter: (route: any, params: { org: string }, store: RootStore) =>
            routePathAllowed(RoutePath.live, params, store),
        onEnter: (route: any, params: { org: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.liveUIStore.updateParams(params)
        },
        onParamsChange: (route: any, params: { org: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.liveUIStore.updateParams(params)
        },
    }),
    // Reports page for ContentOwner, Admin, SuperUser
    reports: new Route({
        component: <Reports />,
        path: RoutePath.reports + '/:org/:encodedParams?',
        title: 'Reports',
        beforeEnter: (route: any, params: { org: string; encodedParams?: string }, store: RootStore) =>
            routePathAllowed(RoutePath.reports, params, store),
        onEnter: (route: any, params: { org: string; encodedParams?: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.reportsUIStore.updateParams(params)
        },
        onParamsChange: (route: any, params: { org: string; encodedParams?: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.reportsUIStore.updateParams(params)
        },
    }),
    // Creatives page for SuperUser
    creatives: new Route({
        component: <Creatives store={rootStore} />,
        path: RoutePath.creatives + '/:org',
        title: 'Creatives',
        beforeEnter: (route: any, params: { org: string }, store: RootStore, queryParams: any) =>
            routePathAllowed(RoutePath.creatives, params, store, queryParams),
        onEnter: (route: any, params: { org: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.creativesUIStore.updateParams(params)
        },
        onParamsChange: (route: any, params: { org: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.creativesUIStore.updateParams(params)
        },
    }),
    // Admin page for Admin, SuperUser
    admin: new Route({
        component: <Admin />,
        path: RoutePath.admin + '/:org/:tab?',
        title: 'Admin',
        beforeEnter: (route: any, params: { org: string; tab?: string }, store: RootStore) =>
            routePathAllowed(RoutePath.admin, params, store),
        onEnter: (route: any, params: { org: string; tab?: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.adminUIStore.updateParams(params)
        },
        onParamsChange: (route: any, params: { org: string; tab?: string }, store: RootStore) => {
            saveCurrentPath(store)
            store.adminUIStore.updateParams(params)
        },
    }),
    // Settings page for logged in users
    settings: new Route({
        component: <Settings />,
        path: RoutePath.settings + '/:page?/:tab?',
        title: 'Settings',
        beforeEnter: (route: any, params: { page?: SettingsPageName; tab?: SettingsTabName }, store: RootStore) =>
            routePathAllowed(RoutePath.settings, params, store),
        onEnter: (route: any, params: { page?: SettingsPageName; tab?: SettingsTabName }, store: RootStore) => {
            saveCurrentPath(store)

            if (!routeParamsAllowed(params, store)) {
                store.router.goTo(views.home, params, store)
                return false
            } else {
                store.settingsUIStore.updateActiveSettings(params)
                return true
            }
        },
        onParamsChange: (route: any, params: { page?: SettingsPageName; tab?: SettingsTabName }, store: RootStore) => {
            saveCurrentPath(store)

            if (!routeParamsAllowed(params, store)) {
                store.router.goTo(views.home, params, store)
                return false
            } else {
                store.settingsUIStore.updateActiveSettings(params)
                return true
            }
        },
    }),
    // Unprotected route for auth actions
    auth: new Route({
        path: RoutePath.auth,
        title: 'Auth',
        beforeEnter: (route: any, params: any, store: RootStore, queryParams: any) => {
            const me = store.userStore.me
            if (!me) {
                // Authenticate if no user
                store.router.goTo(views.authSignIn, undefined, store)
            } else if (queryParams && queryParams.signOut) {
                // If signout requested, redirect to signout route
                store.router.goTo(views.authSignOut, undefined, store)
            }
            return false
        },
    }),
    // Path to send users to after successful authentication
    authSuccess: new Route({
        // Route requires a path, even if never entered
        path: RoutePath.authSuccess,
        beforeEnter: (route: any, params: any, store: RootStore) => {
            const me = store.userStore.me

            // If no user, go to auth
            if (!me) {
                store.router.goTo(views.auth, undefined, store)
                return false
            }

            const requestedPath = store.authStore.requestedPath
            // If a particular path was requested, allow user to continue through
            if (requestedPath) {
                store.router.goTo(
                    navRoutes.get(requestedPath.routePath),
                    requestedPath.params,
                    store,
                    requestedPath.queryParams
                )
                // If requested path returns false, fall through to path in session storage
                // If no path in session storage, send user to home page
            } else {
                const currentView = browserStorageManager.readSessionStorageObject(StorageKey.currentView) as Record<
                    string,
                    any
                >

                // If path read from session storage
                if (currentView) {
                    const currentRoutePath = currentView['rootPath'] as RoutePath
                    const currentParams = currentView['params'] as Record<string, any>
                    const currentQueryParams = currentView['queryParams'] as Record<string, any>

                    // Go to path retrieved from session storage
                    try {
                        store.router.goTo(navRoutes.get(currentRoutePath), currentParams, store, currentQueryParams)
                    } catch (error) {
                        // Issue with stored value
                        browserStorageManager.removeSessionStorageItem(StorageKey.currentView)
                        console.error(error)
                    }
                } else {
                    // If no requested page or path saved in storage, send to home page
                    store.router.goTo(views.home, params, store)
                }
            }
            return false
        },
    }),
    // Sign in page to show sign in UI
    authSignIn: new Route({
        component: <SignIn />,
        path: RoutePath.auth + '/sign-in',
        title: 'Sign In',
        beforeEnter: (route: any, params: any, store: RootStore) => {
            const me = store.userStore.me

            // If user already exists, go to auth success
            if (me) {
                store.router.goTo(views.authSuccess, undefined, store)
                return false
            }
            return true
        },
    }),
    // Sign out page to show signing out state
    authSignOut: new Route({
        component: <SignOut />,
        path: RoutePath.auth + '/sign-out',
        title: 'Sign Out',
        beforeEnter: (route: any, params: any, store: RootStore) => {
            const me = store.userStore.me

            // If no user, go to auth
            if (!me) {
                store.router.goTo(views.auth, undefined, store)
                return false
            } else {
                store.authStore.signOut()
                return true
            }
        },
    }),
    // Privacy policy page
    privacyPolicy: new Route({
        component: <PrivacyPolicy />,
        path: RoutePath.privacyPolicy,
        title: 'Privacy Policy',
    }),
}

export const navRoutes: Map<RoutePath, any> = new Map<RoutePath, any>()
navRoutes.set(RoutePath.auth, views.auth)
navRoutes.set(RoutePath.missionControl, views.missionControl)
navRoutes.set(RoutePath.diagnostics, views.diagnostics)
navRoutes.set(RoutePath.diagnosticsScreen, views.diagnosticsScreen)
navRoutes.set(RoutePath.diagnosticsGlobal, views.diagnosticsGlobal)
navRoutes.set(RoutePath.live, views.live)
navRoutes.set(RoutePath.reports, views.reports)
navRoutes.set(RoutePath.creatives, views.creatives)
navRoutes.set(RoutePath.admin, views.admin)
navRoutes.set(RoutePath.settings, views.settings)

export default views
