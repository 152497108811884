import * as Sentry from '@sentry/browser'

import User from '../models/User'

export const updateSentry = (me: User) => {
    // Set user when changed for crash reporting if not development
    if (process.env.REACT_APP_ENV !== 'development') {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: me.id,
                email: me.email,
            })
            scope.setExtra('me', me)
        })
    }
}
