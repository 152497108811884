// AWS
import { Auth } from 'aws-amplify'
import { rootStore } from 'src/common/RootStore'

export enum HTTPMethod {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
}

class OnAPI {
    static baseURL: string = process.env.REACT_APP_ON_API || 'http://localhost:3000/v1'

    async fetchJSON<T>(endpoint: string, body?: any): Promise<T> {
        try {
            const response = await this.fetchResponse(
                endpoint,
                body !== undefined ? HTTPMethod.post : HTTPMethod.get,
                body
            )
            const result = (await response.json()) as T
            return result
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async fetchJSONWithQueryString<T>(endpoint: string, params?: any): Promise<T> {
        const url =
            endpoint +
            '?' +
            Object.entries(params)
                .map(p => p[0] + '=' + p[1])
                .join('&')
        try {
            const response = await this.fetchResponse(url.toString(), HTTPMethod.get, null)
            const result = (await response.json()) as T
            return result
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async fetchSuccess(method: HTTPMethod, endpoint: string): Promise<boolean> {
        try {
            const response = await this.fetchResponse(endpoint, method, null)
            return response.ok
        } catch (err) {
            return Promise.reject(err)
        }
    }

    private async fetchResponse(endpoint: string, method: HTTPMethod, body: any): Promise<Response> {
        try {
            const data = await Auth.currentSession()
            const token = data.getIdToken().getJwtToken()
            if (body) {
                body = JSON.stringify(body)
            }
            const request: RequestInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                method,
                body,
            }
            const response = await fetch(this.endpoint(endpoint), request)
            if (!response.ok) {
                if (response.status === 426) {
                    // log the user out
                    await rootStore.authStore.signOut()
                    return Promise.reject('Upgrade Required')
                }

                // Read the response body if an error occurred
                try {
                    return Promise.reject(await response.json())
                } catch (err) {
                    return Promise.reject(response.statusText)
                }
            }
            return response
        } catch (err) {
            return Promise.reject(err)
        }
    }

    private endpoint(path: string): string {
        return OnAPI.baseURL + path
    }
}

const onAPI = new OnAPI()
export default onAPI
