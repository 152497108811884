import { action } from 'mobx'

import RootStore from 'src/common/RootStore'
import Controller, { ControllerData } from '../models/Controller'

import DomainStore from './DomainStore'
import controllerRouter from 'src/api/controllerRouter'
import { ScreenState } from '../models/ScreenState'
import { PlayerSyncState } from '../models/PlayerSyncState'

interface ScreenStatePayload {
    state: ScreenState
    isConnected: boolean
    brightnessMap?: Record<string, any>
    controllerData?: ControllerData
    playerName?: string
    playerSyncState?: PlayerSyncState
}

export default class ControllerStore extends DomainStore<Controller> {
    router = controllerRouter

    constructor(rootStore: RootStore) {
        super(rootStore)
        this.storeName = 'Controller'
    }

    // Override refresh to fetch the list of locations when refreshing the controller list
    refresh = async (subscriptionKey?: string, showDeleted?: boolean) => {
        await this.rootStore.locationStore.refresh(subscriptionKey, showDeleted)
        return super.refresh(subscriptionKey, showDeleted)
    }

    @action deleteItems(itemsToDelete: Controller[]) {
        for (const item of itemsToDelete) {
            if (item.screen) {
                this.rootStore.screenStore.deleteItems([item.screen])
            }
        }
        super.deleteItems(itemsToDelete)
    }

    @action updateScreensState(controllerId: string, screenStatePayload: ScreenStatePayload) {
        const controller = this.findItem(controllerId)
        if (!controller) {
            return
        }

        const { state, isConnected, brightnessMap, controllerData, playerName, playerSyncState } = screenStatePayload

        const screen = controller.screen
        if (screen) {
            controller.screen!.state = state
            if (screen.errorGroup && brightnessMap && brightnessMap[screen.id!]) {
                screen.errorGroup.brightness = brightnessMap[screen.id!]
            }
        }

        controller.isConnected = isConnected

        if (controllerData) {
            controller.daemon = controllerData.daemon
            controller.restart = controllerData.restart
            controller.uptime = controllerData.uptime
            controller.version = controllerData.version
            controller.daemonVersion = controllerData.daemonVersion
            controller.jitter = controllerData.jitter
            controller.vsyncDelays = controllerData.vsyncDelays
            controller.vsyncDrops = controllerData.vsyncDrops
            controller.hardwareVsync = controllerData.hardwareVsync
            controller.threadVsync = controllerData.threadVsync
            controller.threadEngine = controllerData.threadEngine
            controller.threadQueue = controllerData.threadQueue
        }

        if (playerName && playerSyncState) {
            controller.playerName = playerName
            controller.playerSyncState = playerSyncState
        }
    }
}
