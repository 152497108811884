import * as React from 'react'

import loadingSpinner from '../../assets/images/loadingSpinner.svg'

class LoadingSpinner extends React.Component {
    render() {
        return (
            <div className='loading-spinner'>
                <img src={loadingSpinner} alt='Loading' />
            </div>
        )
    }
}

export default LoadingSpinner
