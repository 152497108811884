import Location, { LocationJSON } from '../common/models/Location'
import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'

class LocationRouter extends BaseRouter<Location, LocationJSON, EmptySearchParams> {
    protected route = '/location'

    protected flags = RouterFlag.create | RouterFlag.read | RouterFlag.update | RouterFlag.delete | RouterFlag.list

    protected newItem(json: LocationJSON): Location {
        return new Location(json)
    }
}

const locationRouter = new LocationRouter()
export default locationRouter
