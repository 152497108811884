import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Media from 'src/common/models/Media'
import { formFloatValidation, eventPreventDefault } from 'src/common/utils/EventUtils'

import { Input } from 'reactstrap'
import clearIcon from 'src/assets/images/clearIcon.svg'

interface DwellTimeCellProps {
    media: Media
    screenId: string
}

@inject('store')
@observer
class DwellTimeCell extends React.Component<{ store?: RootStore } & DwellTimeCellProps> {
    private mediaManager = this.props.store!.mediaStore.getMediaManager(this.props.screenId)

    handleDwellTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.mediaManager.updateCustomDwellTime(this.props.media, event.target.value)
    }

    handleClearDwellTime = () => {
        this.mediaManager.updateCustomDwellTime(this.props.media, '')
        this.mediaManager.setDwellTimeFocused(this.props.media, false)
    }

    handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        this.mediaManager.setDwellTimeFocused(this.props.media, true)
    }

    handleInputBlur = () => {
        this.mediaManager.setDwellTimeFocused(this.props.media, false)
    }

    handleOnKeyDown = (event: any) => {
        formFloatValidation(event)

        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Trigger blur
                event.target.blur()
                break
            default:
                return
        }
    }

    render() {
        if (!this.props.screenId) {
            return
        }

        const mediaContainer = this.mediaManager.mediaContainer
        if (!mediaContainer) {
            return
        }

        const existingSettings = mediaContainer.settings
        if (!existingSettings) {
            return
        }

        const media = this.props.media
        const existingDwellTime = media.dwellTime
        const newDwellTime = this.mediaManager.customDwellTimeMap.get(media)
        const dwellTimeFocused = this.mediaManager.customDwellTimeFocusedSet.has(media)

        return (
            <React.Fragment>
                <Input
                    className={
                        'custom-text-input' +
                        (!dwellTimeFocused && !(existingDwellTime || newDwellTime) ? ' custom-input-ghost' : '')
                    }
                    value={
                        newDwellTime !== undefined
                            ? newDwellTime
                            : existingDwellTime !== undefined
                            ? existingDwellTime
                            : String(existingSettings.dwellTime)
                    }
                    onChange={this.handleDwellTimeChange}
                    onFocus={this.handleInputFocus}
                    onBlur={this.handleInputBlur}
                    onKeyDown={this.handleOnKeyDown}
                    type='text'
                />
                {(existingDwellTime || newDwellTime) && (
                    <img
                        className='clear-input'
                        onClick={this.handleClearDwellTime}
                        src={clearIcon}
                        alt='Clear'
                        draggable={false}
                        onDragStart={eventPreventDefault}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default DwellTimeCell
