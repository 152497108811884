import { action } from 'mobx'
import RootStore from '../RootStore'

import i18n from 'src/i18n'

import adminRouter from 'src/api/adminRouter'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'

export enum AdminBatchAction {
    lednetVersion = 'lednetVersion',
    firmwareVersion = 'firmwareVersion',
}

export interface LednetVersion {
    version: string
    prerelease: boolean
    description: string
    createdAt: moment.Moment
}

export default class AdminStore {
    rootStore: RootStore
    router = adminRouter

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    getLednetVersions() {
        return this.router.getLednetVersions()
    }

    @action updateLednetVersions = (controllerIds: string[], version: string) => this.router
            .updateLednetVersions(controllerIds, version)
            .then(() => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.lednetUpdatedSuccessfully'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUpdatingLednet'),
                    intent: Intent.DANGER,
                })
            })
}
