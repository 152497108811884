import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import i18n from 'src/i18n'

import ErrorData from '../models/ErrorData'
import { ErrorItemSource } from '../models/DiagnosticsError'
import { DiagnosticsErrorEvent } from '../models/DiagnosticsErrorLog'
import { ErrorSeverity, ErrorCode, ErrorSource, ErrorDataKey } from '../models/DiagnosticErrorEnums'

import { AutoSizer, GridProps, IndexRange } from 'react-virtualized'
import BaseVirtualizedMultiGrid, { BaseVirtualizedMultiGridCol } from './virtualized/BaseVirtualizedMultiGrid'
import { Tooltip, Position, Icon } from '@blueprintjs/core'
import { severityColour, Colour } from 'src/common/utils/Colour'
import Color from 'color'

import moment, { unix } from 'moment'
import { getDataString } from '../helpers/ErrorDataParser'

export interface LogTableData {
    event: DiagnosticsErrorEvent
    code: ErrorCode
    time: moment.Moment
    severity: ErrorSeverity
    source: ErrorSource | string
    data?: ErrorData
    errorSource?: ErrorItemSource
    errorLabel?: string
}

interface ErrorLogTableProps extends Partial<GridProps> {
    logTableData: LogTableData[]
    dataColumnTitles?: string[]
    showSourceColumn?: boolean
    hideErrorColumn?: boolean
    rowsLoading?: boolean
    popoutDiv?: HTMLElement
    onRowsRendered?: (params: IndexRange) => void
}

interface LogData {
    [key: string]: any
}

const ICON_SIZE = 16

@inject('store')
@observer
class ErrorLogTable extends React.Component<ErrorLogTableProps & { store?: RootStore }> {
    isRowLoading = (rowIndex: number): boolean => rowIndex > this.props.logTableData.length

    getRowStyle = (rowData: LogTableData): React.CSSProperties => {
        const log = rowData
        if (!log) {
            return {}
        }

        switch (log.event) {
            case DiagnosticsErrorEvent.unresolved:
            case DiagnosticsErrorEvent.new:
            case DiagnosticsErrorEvent.recur:
            case DiagnosticsErrorEvent.upgrade:
            case DiagnosticsErrorEvent.downgrade:
                const textColor = Color(severityColour(log.severity)).lighten(0.3)
                return {
                    color: String(textColor),
                }
            case DiagnosticsErrorEvent.assign:
            case DiagnosticsErrorEvent.unassign:
                return {
                    color: Colour.assign,
                }
            default:
                return {}
        }
    }

    getErrorText = (errorCode: ErrorCode): string | undefined => {
        const errorName = i18n.t('diagnosticsPage.errorNames.' + errorCode)
        return errorName
    }

    iconCellRenderer = (cellData: string): JSX.Element | null => {
        const event = cellData
        if (!event) {
            return null
        }

        let icon: JSX.Element | null = null
        switch (event) {
            case DiagnosticsErrorEvent.unresolved:
                icon = <Icon icon='issue' iconSize={ICON_SIZE} />
                break
            case DiagnosticsErrorEvent.new:
                icon = <Icon icon='warning-sign' iconSize={ICON_SIZE} />
                break
            case DiagnosticsErrorEvent.recur:
                icon = <Icon icon='refresh' />
                break
            case DiagnosticsErrorEvent.resolve:
                icon = <Icon icon='tick' iconSize={ICON_SIZE} color={Colour.resolve} />
                break
            case DiagnosticsErrorEvent.assign:
                icon = <Icon icon='eye-off' iconSize={ICON_SIZE} />
                break
            case DiagnosticsErrorEvent.unassign:
                icon = <Icon icon='eye-open' iconSize={ICON_SIZE} />
                break
            case DiagnosticsErrorEvent.upgrade:
                icon = <Icon icon='arrow-up' iconSize={ICON_SIZE} />
                break
            case DiagnosticsErrorEvent.downgrade:
                icon = <Icon icon='arrow-down' iconSize={ICON_SIZE} />
                break
            default:
                return null
        }

        return (
            <Tooltip
                boundary={'viewport'}
                content={i18n.t('diagnosticsPage.errorEvents.' + event)}
                hoverOpenDelay={300}
                position={Position.TOP}
                portalContainer={this.props.popoutDiv}
            >
                {icon}
            </Tooltip>
        )
    }

    dataCellRendererWithTitle = (key: string, cellData: any): JSX.Element | null => {
        if (!key || !cellData) {
            return null
        }

        return <span key={key}>{cellData}</span>
    }

    dataCellRenderer = (cellData: any): JSX.Element | null => {
        if (!cellData) {
            return null
        }

        const cellDataEntries: Array<[string, string | number | Array<string | number>]> = Object.entries(cellData)
        const currentScreenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration

        const formattedData = cellDataEntries.map(([k, v], i): JSX.Element | string | null => {
            switch (k) {
                case ErrorDataKey.fault:
                    return (
                        <span key={k}>
                            {i18n.t('diagnosticsPage.errorData.data.' + k)}: {getDataString(k, v)}
                        </span>
                    )
                case ErrorDataKey.input:
                case ErrorDataKey.filenames:
                    return null
                case ErrorDataKey.sections:
                    const sections = v as number[]
                    return (
                        <span key={k}>
                            {sections
                                .map(s => {
                                    const section = currentScreenConfiguration?.sections[s]
                                    return section
                                        ? (section.name || section.id) +
                                              ': ' +
                                              (cellData[ErrorDataKey.filenames][s] ??
                                                  i18n.t('placeholders.missingData'))
                                        : i18n.t('placeholders.missingData')
                                })
                                .join(', ')}
                        </span>
                    )
                default:
                    return (
                        <span key={k}>
                            {i18n.t('diagnosticsPage.errorData.data.' + k)}: {getDataString(k, v)}
                            {cellDataEntries.length > 1 && i < cellDataEntries.length - 1 ? ', ' : ' '}
                        </span>
                    )
            }
        })

        return (
            <Tooltip
                boundary='viewport'
                content={<React.Fragment>{formattedData}</React.Fragment>}
                hoverOpenDelay={300}
                position={Position.TOP}
                targetClassName='data-cell'
            >
                <React.Fragment>{formattedData}</React.Fragment>
            </Tooltip>
        )
    }

    render() {
        const { showSourceColumn, hideErrorColumn, dataColumnTitles, rowCount, rowsLoading } = this.props

        const logs = this.props.logTableData
        const tableData = logs.map(log => {
            const logData: LogData = {}
            // Build data keys if data column titles are provided
            if (dataColumnTitles) {
                if (log.data) {
                    for (const entry of Object.entries(log.data)) {
                        if (entry[0] === 'user') {
                            // Add user data to first data column
                            logData[dataColumnTitles[0]] = getDataString(entry[0], entry[1])
                        } else {
                            logData[entry[0]] = getDataString(entry[0], entry[1])
                        }
                    }
                } else {
                    // Set blank columns to avoid shifting any remaining data to incorrect column
                    dataColumnTitles.forEach(title => {
                        logData[title] = undefined
                    })
                }
            } else {
                logData.data = undefined
            }

            const rowData = {
                event: log.event,
                ...(showSourceColumn &&
                    log.errorSource && {
                        source:
                            i18n.t('diagnosticsPage.errorSources.' + log.errorSource) +
                            (log.errorLabel ? ': ' + log.errorLabel : ''),
                    }),
                ...(!hideErrorColumn && { error: this.getErrorText(log.code) }),
                ...(dataColumnTitles
                    ? {
                          ...logData,
                      }
                    : {
                          data: log.data,
                      }),
                timestamp: moment(log.time).format(this.props.store!.userStore.me?.shortDateTimeFormat),
                severity: log.severity,
            }
            return rowData
        })

        const cols: BaseVirtualizedMultiGridCol[] = [
            {
                dataKey: 'icon',
                label: '',
                width: 40,
                cellElement: (cellData: any) => this.iconCellRenderer(cellData),
                disableSort: true,
                disableTitle: true,
            },
        ]

        if (showSourceColumn) {
            cols.push({
                dataKey: 'source',
                label: 'Source',
                width: 160,
                disableSort: true,
            })
        }

        if (!hideErrorColumn) {
            cols.push({
                dataKey: 'error',
                label: 'Error',
                width: 300,
                disableSort: true,
            })
        }

        if (dataColumnTitles) {
            dataColumnTitles.forEach(dataTitle => {
                cols.push({
                    dataKey: dataTitle,
                    label: i18n.t('diagnosticsPage.errorData.data.' + dataTitle),
                    minWidth: 200,
                    cellElement: (cellData: any) => this.dataCellRendererWithTitle(dataTitle, cellData),
                    disableSort: true,
                })
            })
        } else {
            cols.push({
                dataKey: 'data',
                label: 'Data',
                minWidth: 200,
                cellElement: (cellData: any) => this.dataCellRenderer(cellData),
                disableSort: true,
                disableTitle: true,
            })
        }

        cols.push({
            dataKey: 'timestamp',
            label: 'Timestamp',
            width: 160,
            disableSort: true,
        })

        let rows: Array<Partial<LogTableData> & { timestamp: string }> = tableData
        if (rowCount && rowsLoading) {
            rows = [
                ...rows,
                ...new Array(rowCount - rows.length)
                    // Append empty rows to fill the table
                    .fill({
                        event: DiagnosticsErrorEvent.new,
                        code: ErrorCode.unknown,
                        severity: ErrorSeverity.noError,
                        timestamp: moment(unix(0)).format(this.props.store!.userStore.me?.shortDateTimeFormat),
                    }),
            ]
        }

        return (
            <div className='error-log-table'>
                <AutoSizer>
                    {({ width, height }) => (
                        <BaseVirtualizedMultiGrid
                            {...this.props}
                            cols={cols}
                            rows={rows}
                            width={width}
                            height={height}
                            rowHeight={30}
                            headerHeight={20}
                            overscanColumnCount={cols.length}
                            data={tableData}
                            includeHeaders
                            rowStyle={this.getRowStyle}
                            isRowLoading={this.isRowLoading}
                            showEndOfResults={!rowsLoading}
                        />
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default ErrorLogTable
