import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import { rootStore } from '../RootStore'

import ScreenErrorGroup from './ScreenErrorGroup'

class DiagnosticsErrorContainer {
    static newDiagnosticsErrorContainer(): DiagnosticsErrorContainer {
        return new DiagnosticsErrorContainer({
            subscriptionKey: '',
            subscriptionTTL: 0,
            diagnosticsErrors: new Map<string, ScreenErrorGroup>(),
        })
    }

    @observable subscriptionKey: string
    @observable subscriptionTTL: number
    @observable diagnosticsErrors: Map<string, ScreenErrorGroup>

    constructor(json: DiagnosticsErrorContainerJSON) {
        this.subscriptionKey = json.subscriptionKey
        this.subscriptionTTL = json.subscriptionTTL
        this.diagnosticsErrors = new Map<string, ScreenErrorGroup>()
        const errors: any = json.diagnosticsErrors // this isn't really a map, so force it to an any for parsing
        Object.keys(errors).forEach(key => {
            this.diagnosticsErrors.set(key, new ScreenErrorGroup(errors[key]))
        })
    }

    @computed get totalErrors(): number {
        let count = 0

        this.diagnosticsErrors.forEach((element: ScreenErrorGroup, screenId: string) => {
            const screen = rootStore.screenStore.findItem(screenId)
            if (screen && screen.controller && screen.controller.enabled) {
                count += element.totalErrors
            }
        })
        return count
    }

    @computed get totalAssignedErrors(): number {
        let count = 0

        this.diagnosticsErrors.forEach((element: ScreenErrorGroup, screenId: string) => {
            const screen = rootStore.screenStore.findItem(screenId)
            if (screen && screen.controller && screen.controller.enabled) {
                count += element.totalAssignedErrors
            }
        })
        return count
    }

    @computed get totalUnassignedErrors(): number {
        return this.totalErrors - this.totalAssignedErrors
    }
}

export type DiagnosticsErrorContainerJSON = Omit<
    DiagnosticsErrorContainer,
    'totalErrors' | 'totalAssignedErrors' | 'totalUnassignedErrors'
>

export default DiagnosticsErrorContainer
