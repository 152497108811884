import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import { rootStore } from '../RootStore'

import BaseModel from './BaseModel'
import Section from './Section'
import Screen from './Screen'
import Tile from './Tile'
import GridRect from '../utils/GridRect'

class ScreenConfiguration extends BaseModel {
    static newScreenConfiguration(): ScreenConfiguration {
        return new ScreenConfiguration({
            screenId: '',
            brightness: 0,
            gamma: 0,
            target: 0,
            x: 0,
            y: 0,
            sections: [],
        })
    }

    @observable screenId: string
    @observable brightness: number
    @observable gamma: number
    @observable target: number
    @observable x: number
    @observable y: number
    @observable sections: Section[] = []

    constructor(json: ScreenConfigurationJSON) {
        super(json)

        this.screenId = json.screenId
        this.brightness = json.brightness
        this.gamma = json.gamma
        this.target = json.target
        this.x = json.x
        this.y = json.y
        if (json.sections) {
            for (const section of json.sections) {
                this.sections.push(new Section(section, this))
            }
        }
    }

    @computed get screen(): Screen | undefined {
        return rootStore.screenStore.findItem(this.screenId)
    }

    @computed get hasLogoBox() {
        const sections = this.sections.filter(section => section.isLogoBox)

        return sections.length > 0
    }

    @computed get minScrapeX(): number {
        return this.sections.reduce((minValue, section) => {
            if (minValue === -1) {
                return section.scrapeX
            }
            if (section.scrapeX < minValue) {
                return section.scrapeX
            }
            return minValue
        }, -1)
    }

    @computed get minScrapeY(): number {
        return this.sections.reduce((minValue, section) => {
            if (minValue === -1) {
                return section.scrapeY
            }
            if (section.scrapeY < minValue) {
                return section.scrapeY
            }
            return minValue
        }, -1)
    }

    @computed get standardSections(): Section[] {
        return this.sections.filter(section => !section.isLogoBox)
    }

    @computed get logoBoxSections(): Section[] {
        return this.sections.filter(section => section.isLogoBox)
    }

    @computed get tiles(): Tile[] {
        return this.sections.flatMap(section => section.tileList)
    }

    @computed get tileMap(): Map<string, Tile> {
        const map = new Map<string, Tile>()
        for (const tile of this.tiles) {
            map.set(tile.id!, tile)
        }
        return map
    }

    @computed get tileCount(): number {
        return this.sections.reduce((sum, section) => sum + section.tileList.length, 0)
    }

    // Get the dimensions for sections
    gridDimensions(
        sections: Section[]
    ):
        | {
              width: number
              height: number
              gap: number
              gridArea: string[]
          }
        | undefined {
        if (!this.screen) {
            return undefined
        }
        if (this.screen.tileHeight === 0 || this.screen.tileWidth === 0) {
            console.error('Missing screen tile heights or widths')
            return undefined
        }

        // Create a rect object for each section
        const sectionRects: GridRect[] = sections.map(section => new GridRect(
                Math.floor((section.scrapeX - this.minScrapeX) / this.screen!.tileWidth),
                Math.floor((section.scrapeY - this.minScrapeY) / this.screen!.tileHeight),
                section.width,
                section.height
            ))

        // Check for intersections between sections and compensate accordingly so there is no overlap
        for (let i = 1; i < sectionRects.length; i++) {
            for (let j = i - 1; j >= 0; j--) {
                if (sectionRects[i].intersectsRect(sectionRects[j])) {
                    if (sectionRects[i].useXOffset(sectionRects[j])) {
                        sectionRects[i].x = sectionRects[j].x + sectionRects[j].width
                    } else {
                        sectionRects[i].y = sectionRects[j].y + sectionRects[j].height
                    }
                }
            }
        }

        // Get container grid widths/heighths by calculating from section dimensions
        const gridDimensions: [number, number] = sectionRects.reduce(
            (dimensions, rect) => [Math.max(dimensions[0], rect.x + rect.width), Math.max(dimensions[1], rect.y + rect.height)],
            [0, 0]
        )

        const gridArea = sectionRects.map(rect => rect.gridRepresentation())

        // Set grid gap
        const gap = Math.ceil(rootStore.tileViewUIStore.zoomLevel / 40)

        return { width: gridDimensions[0], height: gridDimensions[1], gap, gridArea }
    }

    tileForId = (id: string): Tile | undefined => this.tileMap.get(id)

    friendlyNameForAdapter = (adapterId: string): string | undefined => {
        const foundSection = this.sections.find(section => section.adapter === adapterId)
        if (!foundSection) {
            return undefined
        }
        return foundSection.adapterFriendlyName !== '' ? foundSection.adapterFriendlyName : adapterId
    }
}

export type ScreenConfigurationJSON = Omit<
    ScreenConfiguration,
    | 'toJSON'
    | 'isValid'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
    | 'screen'
    | 'hasLogoBox'
    | 'minScrapeX'
    | 'minScrapeY'
    | 'standardSections'
    | 'logoBoxSections'
    | 'tiles'
    | 'tileMap'
    | 'tileCount'
    | 'gridDimensions'
    | 'tileForId'
    | 'friendlyNameForAdapter'
>

export default ScreenConfiguration
