import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from '../../../../common/RootStore'

import i18n from 'src/i18n'

import Organisation, { OrganisationPermission } from 'src/common/models/Organisation'

import { eventPreventDefault, formIntegerValidation } from 'src/common/utils/EventUtils'

import Select from 'react-select'
import Toggle from 'react-toggle'
import { Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    ValueLabelPair,
} from '../../../../common/components/SelectComponents'

@inject('store')
@observer
class CreateOrgForm extends React.Component<{ store?: RootStore }> {
    lastItem: Organisation

    UNSAFE_componentWillMount = () => {
        if (!this.props.store!.adminUIStore.isEditMode) {
            this.props.store!.adminUIStore.assetData.org.setItem()
        }
    }

    handleFormValue = (event: any) => {
        this.props.store!.adminUIStore.assetData.org.updateItemForm(event.target.id, event.target.value)
    }

    handleFormSelect = (field: string, selectedOptions: ValueLabelPair[]) => {
        this.props.store!.adminUIStore.assetData.org.updateItemForm(
            field,
            selectedOptions.map(option => option.value)
        )
    }

    handlePermissionsCheckbox = (field: string, orgId: string, event: any) => {
        this.props.store!.adminUIStore.assetData.org.updateItemForm(field, {
            orgId,
            permissions: event.target.checked ? OrganisationPermission.write : OrganisationPermission.read,
        })
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return
        }

        const adminUIStore = this.props.store!.adminUIStore
        const orgStore = this.props.store!.orgStore

        const adminItem = adminUIStore.assetData.org

        if (adminItem.item) {
            this.lastItem = adminItem.item
        }
        const orgItem = adminItem.item || this.lastItem

        const associatedOrgOptions = adminUIStore.availableAssociatedOrgs.map(item => ({
            value: item.id,
            label: item.name,
        }))

        // NOTE: Get org from store since computed isn't available on object copy
        // If we want a mobx copy of the object, we could use https://github.com/mobxjs/mobx-utils#createviewmodel
        const orgItemFromStore = orgItem && orgStore.findItem(orgItem.id!)
        const defaultAssociatedOrgOption =
            orgItemFromStore && orgItemFromStore.associatedOrganisations
                ? orgItemFromStore.associatedOrganisations.map(org => ({ value: org.id, label: org.name }))
                : undefined

        // Build array of associated orgs for assigning permissions table
        const associatedOrgsForPermissions = adminUIStore.getAssociatedOrgsWithPermissions(orgItem)

        return (
            <React.Fragment>
                <Form className='admin-form' onSubmit={eventPreventDefault}>
                    <FormGroup>
                        <Label for='name'>Name</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='name'
                            autoComplete='off'
                            defaultValue={orgItem.name}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for='country'>Country</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='country'
                            autoComplete='off'
                            defaultValue={orgItem.country}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for='zipCode'>Zip Code</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='zipCode'
                            autoComplete='off'
                            defaultValue={orgItem.zipCode}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for='maxScreens'>Maximum Screen Allocation</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            onKeyDown={formIntegerValidation}
                            id='maxScreens'
                            autoComplete='off'
                            defaultValue={String(orgItem.maxScreens ?? 1)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Associated organisations</Label>
                        <Select
                            className='custom-select-wrapper custom-select-inverted'
                            classNamePrefix='custom-select'
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            onChange={this.handleFormSelect.bind(this, 'associatedOrganisations')}
                            options={associatedOrgOptions}
                            components={{
                                ValueContainer,
                                ClearIndicator,
                                DropdownIndicator,
                                Option,
                                NoOptionsMessage,
                            }}
                            defaultValue={defaultAssociatedOrgOption}
                        />
                        <FormText>
                            These organisations can later be assigned as operations managers or content owners.
                        </FormText>
                    </FormGroup>
                    {associatedOrgsForPermissions.length > 0 && (
                        <FormGroup>
                            <div className='assign-permissions'>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Label>Name</Label>
                                            </th>
                                            <th>
                                                <Label>Write access</Label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {associatedOrgsForPermissions.map(
                                            org =>
                                                org && (
                                                    <tr key={org.id}>
                                                        <td>
                                                            <h5>{org.name}</h5>
                                                        </td>
                                                        <td>
                                                            <Toggle
                                                                defaultChecked={
                                                                    org.permissions === OrganisationPermission.write
                                                                }
                                                                className='custom-toggle'
                                                                icons={false}
                                                                onChange={this.handlePermissionsCheckbox.bind(
                                                                    this,
                                                                    'writeAccess',
                                                                    org.id
                                                                )}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </FormGroup>
                    )}
                </Form>
                <div className='custom-panel-footer-actions'>
                    <Button
                        className='custom-button-large'
                        onClick={adminUIStore.handleFormSave.bind(this, true)}
                        color='primary'
                        disabled={!adminUIStore.assetData.org.createItemFormValid}
                    >
                        {i18n.t('actions.save')}
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateOrgForm
