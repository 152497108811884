import Controller, { ControllerJSON } from '../common/models/Controller'
import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'

class ControllerRouter extends BaseRouter<Controller, ControllerJSON, EmptySearchParams> {
    protected route = '/controller'

    protected flags = RouterFlag.create | RouterFlag.read | RouterFlag.update | RouterFlag.delete | RouterFlag.list

    protected newItem(json: ControllerJSON): Controller {
        return new Controller(json)
    }
}

const controllerRouter = new ControllerRouter()
export default controllerRouter
