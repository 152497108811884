import * as React from 'react'

declare global {
    interface Window {
        createLEDNet?: () => Promise<unknown>
    }
}

export interface ScreenSize {
    screenSize: number[]
    tileSizes: number[][]
}

interface LednetContextInterface {
    calculateBufferProofHashAsync: (buffer: ArrayBuffer, sizes: ScreenSize[]) => Promise<string[][] | void>
    calculateLuminance: (
        imageWidth: number,
        imageHeight: number,
        bitmap: Uint8Array | Uint8ClampedArray
    ) =>
        | {
              L: number
              R: number
              G: number
              B: number
          }
        | undefined
    isLoading: boolean
}

const LednetContext = React.createContext<LednetContextInterface>({
    calculateBufferProofHashAsync: async () => undefined,
    calculateLuminance: () => undefined,
    isLoading: false,
})

function LednetProvider(props: any) {
    const [hasScript, setHasScript] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    const [lednet, setLednet] = React.useState<any>(null)

    React.useEffect(() => {
        if (window.createLEDNet) {
            console.info('LEDNet module already loaded')
            setHasScript(true)
            return
        }

        const script = document.createElement('script')
        script.src = process.env.PUBLIC_URL + '/scripts/lednet.js'
        script.async = true
        script.onload = () => {
            setHasScript(true)
        }
        document.head.appendChild(script)
    }, [])

    React.useEffect(() => {
        if (hasScript && !lednet) {
            const init = async () => {
                try {
                    if (!window.createLEDNet) {
                        throw new Error('LEDNet module not ready!')
                    }
                    setLednet(await window.createLEDNet())
                } catch (error) {
                    console.error('Error loading LEDNet module:', error)
                } finally {
                    setIsLoading(false)
                }
            }
            init()
        }
    }, [hasScript, lednet])

    React.useEffect(() => {
        if (lednet) {
            lednet.init()
            console.info('LEDNet module ready')
            setIsLoading(false)
        }
    }, [lednet])

    /**
     * Returns an array of proof hashes for a given raw image buffer
     *
     * @param buffer A raw image buffer
     * @param sizes An array of objects containing screen size values to calculate a hash for
     * @returns An array of hashes
     */
    const calculateBufferProofHashAsync = async (
        buffer: ArrayBuffer,
        sizes: ScreenSize[]
    ): Promise<string[][] | undefined> => {
        if (isLoading) {
            console.error('LEDNet module not ready!')
            return
        }
        if (!lednet) {
            console.error('No LEDNet module!')
            return
        }

        const hashes = await lednet.proof(buffer, sizes)
        return hashes
    }

    /**
     * Returns luminance data for a given image
     *
     * @param imageWidth Width of the source image in px
     * @param imageHeight Height of the source image in px
     * @param bitmap A Uint8Array of the raw bitmap data
     * @returns An object containing L, R, G, B
     */
    const calculateLuminance = (
        imageWidth: number,
        imageHeight: number,
        bitmap: Uint8Array | Uint8ClampedArray
    ): { L: number; R: number; G: number; B: number } | undefined => {
        if (isLoading) {
            console.error('LEDNet module not ready!')
            return
        }
        if (!lednet) {
            console.error('No LEDNet module!')
            return
        }

        const { L, R, G, B } = lednet.luminance(imageWidth, imageHeight, bitmap)
        return { L, R, G, B }
    }

    return (
        <LednetContext.Provider
            value={{
                calculateBufferProofHashAsync,
                calculateLuminance,
                isLoading: !hasScript || isLoading,
            }}
            {...props}
        />
    )
}

export { LednetContext, LednetProvider }
