import { observable, action, computed, toJS, runInAction } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'
import RootStore from 'src/common/RootStore'

import User from 'src/common/models/User'
import phone from 'phone'
import { UserNotificationType, AlertMethod, UserPreferenceType, UserPreferences } from 'src/common/models/UserSettings'

import { AppToaster } from 'src/common/components/AppToaster'
import { Intent } from '@blueprintjs/core'
import _ from 'lodash'
import { StorageKey, browserStorageManager } from 'src/common/managers/BrowserStorageManager'

export enum SettingsPageName {
    myProfile = 'my-profile',
    organisationProfile = 'organisation-profile',
}

export enum SettingsTabName {
    details = 'details',
    preferences = 'preferences',
    notifications = 'notifications',
}

export default class SettingsUIStore {
    @observable activeSettingsPage?: SettingsPageName
    @observable activeSettingsTab?: SettingsTabName

    @observable isUserDetailsEditable: boolean = false
    @observable pendingUserDetails?: User & IViewModel<User>

    @observable phoneFieldFocused: boolean = false
    @observable phoneInputValid: boolean = true

    @observable currentPassword: string
    @observable newPassword: string
    @observable verifyNewPassword: string

    @observable pendingUserPreferences?: UserPreferences
    @observable pendingUserNotifications?: Map<UserNotificationType, AlertMethod[]>

    @observable isManageMFAOpen: boolean = false
    @observable MFASetupCode?: string
    @observable MFAVerificationCode?: string
    @observable isMFATextCodeOpen: boolean = false

    @observable isDemoMode: boolean = false

    emailVerificationCodeLength = 6
    minimumPasswordLength = 8
    MFAVerificationCodeLength = 6

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        this.isDemoMode = browserStorageManager.readLocalStorageString(StorageKey.demoMode) === 'true'
    }

    get settingsTabNames(): SettingsTabName[] {
        return Object.values(SettingsTabName)
    }

    get userNotifications(): Array<{ type: UserNotificationType; methods: AlertMethod[] }> {
        return Object.values(UserNotificationType).map(type => ({
            type,
            methods:
                type === UserNotificationType.digest
                    ? this.alertMethods.filter(
                          method =>
                              // Remove SMS method from digest notification
                              method !== AlertMethod.sms
                      )
                    : this.alertMethods,
        }))
    }

    get alertMethods(): AlertMethod[] {
        return Object.values(AlertMethod)
    }

    @computed get isPhoneValid(): boolean {
        return this.phoneFieldFocused || this.phoneInputValid
    }

    @computed get isChangePasswordValid(): boolean {
        if (!this.currentPassword || !this.newPassword || !this.verifyNewPassword) {
            return false
        }

        return this.newPassword.length >= this.minimumPasswordLength && this.newPassword === this.verifyNewPassword
    }

    @computed get hasUnsavedUserDetails(): boolean {
        return !!(this.pendingUserDetails && this.pendingUserDetails.isDirty)
    }

    @computed get hasUnsavedUserPreferences(): boolean {
        const me = this.rootStore.userStore.me
        if (!me || !this.pendingUserPreferences) {
            return false
        }
        return !_.isEqual(me.preferences, this.pendingUserPreferences)
    }

    @computed get hasUnsavedUserNotifications(): boolean {
        const me = this.rootStore.userStore.me
        if (!me || !this.pendingUserNotifications || (this.pendingUserNotifications.size === 0 && !me.notifications)) {
            return false
        }
        return !_.isEqual(toJS(me.notifications), toJS(this.pendingUserNotifications))
    }

    @computed get MFAQRCode(): string | undefined {
        const email = this.rootStore.userStore.me?.email
        if (!email || !this.MFASetupCode) {
            return undefined
        }
        const issuer = 'Candelic%20ON'
        return `otpauth://totp/${issuer}:${email}?secret=${this.MFASetupCode}&issuer=${issuer}`
    }

    @computed get isMFAVerificationValid(): boolean {
        return (
            !!this.MFASetupCode &&
            !!this.MFAVerificationCode &&
            this.MFAVerificationCode.length === this.MFAVerificationCodeLength
        )
    }

    handleChangePassword = () => {
        this.rootStore.authStore.changePassword(this.currentPassword, this.newPassword)
    }

    isNotificationMethodEnabled = (type: UserNotificationType, method: AlertMethod): boolean => {
        const me = this.rootStore.userStore.me
        if (!me) {
            return false
        }
        const enabledUserNotificationMethods = this.pendingUserNotifications
            ? this.pendingUserNotifications.get(type)
            : me.notifications
            ? me.notifications.get(type)
            : []
        return !!enabledUserNotificationMethods?.includes(method)
    }

    submitMFAVerification = async () => {
        if (!this.MFAVerificationCode) {
            return
        }
        try {
            await this.rootStore.authStore.verifyMFA(this.MFAVerificationCode)
            runInAction('closeMFADialog', () => {
                this.isManageMFAOpen = false
                this.MFASetupCode = undefined
                this.MFAVerificationCode = undefined
            })
        } catch (error) {
            console.error('Error verifying MFA', error)
            AppToaster.show({
                icon: 'warning-sign',
                message: 'Error verifying MFA',
                intent: Intent.DANGER,
            })
        }
    }

    handleRemoveMFA = async () => {
        try {
            await this.rootStore.authStore.removeMFA()
        } catch (error) {
            console.error('Error disabling MFA', error)
            AppToaster.show({
                icon: 'warning-sign',
                message: 'Error disabling MFA',
                intent: Intent.DANGER,
            })
        }
    }

    @action toggleUserDetailsEdit = () => {
        if (this.isUserDetailsEditable) {
            this.pendingUserDetails = undefined
        }
        this.isUserDetailsEditable = !this.isUserDetailsEditable
    }

    @action updateDetails = (key: string, value: any) => {
        const me = this.rootStore.userStore.me
        if (!me) {
            return
        }

        // If no existing user view model, create a new one from current user
        const newUserViewModel = this.pendingUserDetails || createViewModel(me)

        switch (key) {
            case 'phone':
                // Sanitise phone input
                newUserViewModel.phone = phone(value)[0]
                this.phoneInputValid = phone(value).length > 0 || value === ''
                break
            case 'firstName':
                newUserViewModel.firstName = value
                break
            case 'lastName':
                newUserViewModel.lastName = value
                break
            default:
                return
        }

        // Store pending details
        this.pendingUserDetails = newUserViewModel
    }

    // Set state of form field focus
    @action setFormFieldFocused = (field: string, value: boolean) => {
        switch (field) {
            case 'phone':
                this.phoneFieldFocused = value
                break
        }
    }

    @action updateActiveSettings = (params: { page?: SettingsPageName; tab?: SettingsTabName }) => {
        this.activeSettingsPage = params.page
        this.activeSettingsTab = params.tab
    }

    @action handleChangePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'currentPassword':
                this.currentPassword = event.target.value
                break
            case 'newPassword':
                this.newPassword = event.target.value
                break
            case 'verifyNewPassword':
                this.verifyNewPassword = event.target.value
                break
        }
    }

    @action saveUserDetails = () => {
        if (!this.pendingUserDetails) {
            return
        }

        this.rootStore.userStore.updateUserDetails(this.pendingUserDetails).then(() => {
            this.toggleUserDetailsEdit()
        })
    }

    @action updateUserPreferences = (type: UserPreferenceType, value: string) => {
        const me = this.rootStore.userStore.me
        if (!me) {
            return
        }

        const pendingPreferences = this.pendingUserPreferences ?? {
            language: null,
            timeZone: null,
            timeFormat: null,
            dateFormat: null,
        }
        pendingPreferences[type] = value

        // Set pending preference settings
        this.pendingUserPreferences = new UserPreferences(pendingPreferences).addSeed(me)
    }

    @action handleSaveUserPreferences = () => {
        if (!this.pendingUserPreferences) {
            return
        }
        this.rootStore.userStore.saveUserPreferences(this.pendingUserPreferences)
    }

    @action updateUserNotifications = (type: UserNotificationType, method: AlertMethod, enable: boolean) => {
        const me = this.rootStore.userStore.me
        if (!me) {
            return
        }

        // If no pending notifications, clone user notifications or create a new map
        const pendingNotifications =
            this.pendingUserNotifications ||
            (me.notifications ? new Map(me.notifications) : new Map<UserNotificationType, AlertMethod[]>())

        const hasNotification = pendingNotifications.get(type)
        let methodsToSet: AlertMethod[] = []
        if (hasNotification) {
            methodsToSet = enable
                ? [...hasNotification, method]
                : hasNotification.filter(alertMethod => alertMethod !== method)
        } else {
            methodsToSet = [method]
        }

        if (methodsToSet.length > 0) {
            pendingNotifications.set(type, methodsToSet)
        } else {
            pendingNotifications.delete(type)
        }

        // Store pending notification settings
        this.pendingUserNotifications = pendingNotifications
    }

    @action saveUserNotifications = () => {
        if (!this.pendingUserNotifications) {
            return
        }
        this.rootStore.userStore.saveUserNotifications(this.pendingUserNotifications)
    }

    @action toggleManageMFA = () => {
        if (this.isManageMFAOpen) {
            this.MFASetupCode = undefined
            this.MFAVerificationCode = undefined
        } else {
            this.handleSetupMFA()
        }
        this.isManageMFAOpen = !this.isManageMFAOpen
    }

    @action handleSetupMFA = async () => {
        try {
            const code = await this.rootStore.authStore.setupMFA()
            runInAction('setMFASetupCode', () => {
                this.MFASetupCode = code
            })
        } catch (error) {
            console.error('Error requesting MFA code', error)
        }
    }

    @action handleChangeMFAVerification = (value: string) => {
        this.MFAVerificationCode = value
    }

    @action toggleMFATextCode = () => {
        this.isMFATextCodeOpen = !this.isMFATextCodeOpen
    }

    @action handleUpdateDemoMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked
        this.isDemoMode = value
        browserStorageManager.updateLocalStorageItem(StorageKey.demoMode, String(value))
    }
}
