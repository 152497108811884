import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import ExpandImageDialog from '../reports/components/modals/ExpandImageDialog'

import ReportForm from './components/ReportForm'
import ReportResults from './components/ReportResults'

import { Dialog } from '@blueprintjs/core'
import { Button, Form, Input, InputGroup, Label } from 'reactstrap'
import Select from 'react-select'
import { DropdownIndicator, Option } from 'src/common/components/SelectComponents'

interface CreativesProps {
    store?: RootStore
}

function Creatives(props: CreativesProps) {
    const { store } = props
    const uiStore = store!.creativesUIStore

    const {
        isFetching,
        displayImages,
        isReportDialogOpen,
        exportName,
        exportOption,
        isExporting,
        setIsReportDialogOpen,
        setExportName,
        setExportOption,
        handleExport,
    } = uiStore

    const me = store!.userStore.me
    if (!me) {
        return null
    }

    return (
        <>
            <div className='creatives-container'>
                <main>
                    {!(displayImages.length > 0 || isFetching) ? (
                        <ReportForm store={store} />
                    ) : (
                        <ReportResults store={store} />
                    )}
                </main>
            </div>
            <ExpandImageDialog />
            {/* Generate report dialog */}
            <Dialog
                isOpen={isReportDialogOpen}
                className='custom-dialog'
                title='Create report'
                onClose={() => setIsReportDialogOpen(false)}
                style={{ width: '541px' }}
            >
                <div className='summary-export'>
                    <Form inline onSubmit={eventPreventDefault}>
                        <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                            <Label className='custom-label custom-label-inline align-middle'>
                                <h6 className='text-black'>{i18n.t('reportsPage.reportName')}</h6>
                            </Label>
                            <Input
                                className='form-control-alt'
                                onChange={e => setExportName(e.target.value)}
                                type='text'
                            />
                        </InputGroup>
                        <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                            <Label className='custom-label custom-label-inline align-middle'>
                                <h6 className='text-black'>{i18n.t('common.format')}</h6>
                            </Label>
                            <Select
                                className='custom-select-wrapper custom-select-inverted'
                                classNamePrefix='custom-select'
                                isSearchable={false}
                                blurInputOnSelect
                                onChange={option => setExportOption(option?.value)}
                                options={store!.reportsUIStore.exportOptions}
                                components={{ DropdownIndicator, Option }}
                            />
                        </InputGroup>
                    </Form>
                    <div className='create-button'>
                        <Button
                            onClick={() => handleExport()}
                            className='custom-button custom-button-large mt-4'
                            color='primary'
                            disabled={!exportName || !exportOption || isExporting}
                        >
                            {isExporting ? 'Generating report' : i18n.t('actions.create')}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default observer(Creatives)
