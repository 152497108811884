import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// Mobx
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { SignInStatus } from 'src/common/models/SignInStatus'

import { Form, Label, Input, Button, InputGroup, FormGroup, FormFeedback } from 'reactstrap'
import profileIcon from 'src/assets/images/auth/profileIcon.svg'
import passwordPadlockIcon from 'src/assets/images/auth/passwordPadlockIcon.svg'

@inject('store')
@observer
class SignInForm extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleSignIn = () => {
        this.props.store!.signInUIStore.signInUser()
    }

    handleOnKeyPress = (event: any) => {
        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Handle "enter" or "return" key press
                this.props.store!.signInUIStore.signInUser()
                break
            default:
                return
        }
    }

    handleForgotPassword = () => {
        // Reset username and change current status
        this.props.store!.signInUIStore.resetUserCredentials(false)
        this.props.store!.signInUIStore.changeStatus(SignInStatus.forgotPassword)
    }

    handleInputFocus = (event: any) => {
        switch (event.target.id) {
            case 'email':
                this.props.store!.signInUIStore.setSignInEmailFocused(true)
                break
            case 'password':
                this.props.store!.signInUIStore.setSignInPasswordFocused(true)
                break
            default:
                break
        }
    }

    handleInputBlur = (event: any) => {
        switch (event.target.id) {
            case 'email':
                this.props.store!.signInUIStore.setSignInEmailFocused(false)
                break
            case 'password':
                this.props.store!.signInUIStore.setSignInPasswordFocused(false)
                break
            default:
                break
        }
    }

    render() {
        if (this.props.store!.signInUIStore.signinStatus !== SignInStatus.signIn) {
            return null
        }

        return (
            <div className='auth-form-container'>
                <Form onKeyPress={this.handleOnKeyPress} className='auth-form'>
                    <div className='auth-form-header text-center'>{i18n.t('authPage.authHeaders.signIn')}</div>
                    <div className='auth-form-body'>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>{i18n.t('authPage.emailAddress')}</Label>
                            <InputGroup className='custom-input custom-input-prepend-icon'>
                                <img className='custom-input-icon-left' src={profileIcon} alt='Email address' />
                                <Input
                                    autoFocus
                                    id='email'
                                    type='email'
                                    autoComplete='username'
                                    defaultValue={this.props.store!.signInUIStore.username}
                                    invalid={
                                        !this.props.store!.signInUIStore.isSignInEmailInputValid ||
                                        this.props.store!.signInUIStore.isAuthAWSError
                                    }
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                />
                                <FormFeedback>
                                    {i18n.t(
                                        'awsErrors.' +
                                            (!this.props.store!.signInUIStore.isSignInEmailInputValid
                                                ? 'invalidEmailFormat'
                                                : this.props.store!.signInUIStore.authAWSError)
                                    )}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.password')}</h6>
                            </Label>
                            <InputGroup className='custom-input custom-input-prepend-icon'>
                                <img className='custom-input-icon-left' src={passwordPadlockIcon} alt='Password' />
                                <Input
                                    id='password'
                                    type='password'
                                    autoComplete='current-password'
                                    invalid={
                                        !this.props.store!.signInUIStore.isSignInPasswordValid ||
                                        this.props.store!.signInUIStore.isAuthAWSError
                                    }
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                />
                                <FormFeedback>
                                    {i18n.t(
                                        'awsErrors.' +
                                            (!this.props.store!.signInUIStore.isSignInPasswordValid
                                                ? 'invalidPasswordFormat'
                                                : this.props.store!.signInUIStore.authAWSError)
                                    )}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        <Button
                            className='auth-form-body-button'
                            color='primary'
                            disabled={!this.props.store!.signInUIStore.isSignInFormValid}
                            onClick={this.handleSignIn}
                        >
                            <h6>{i18n.t('authPage.signIn')}</h6>
                        </Button>
                    </div>
                </Form>
                <Button className='custom-button-link' color='link' onClick={this.handleForgotPassword}>
                    <span>{i18n.t('authPage.forgotPassword')}</span>
                </Button>
            </div>
        )
    }
}

export default withNamespaces()(SignInForm)
