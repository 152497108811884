import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import i18n from 'src/i18n'

import { sortByKey } from 'src/common/utils/SortByKey'
import { calcColumnWidth } from 'mui-virtualized-table/dist/utils'

import {
    MultiGrid,
    GridCellProps,
    GridProps,
    SortDirectionType,
    SortDirection,
    Index,
    ColumnProps,
    ScrollParams,
    SectionRenderedParams,
    IndexRange,
} from 'react-virtualized'
import 'react-virtualized/styles.css'
import sortArrowUpActive from 'src/assets/images/sortArrowUpActive.svg'
import sortArrowDownActive from 'src/assets/images/sortArrowDownActive.svg'
import _ from 'lodash'

export interface BaseVirtualizedMultiGridCol extends Partial<ColumnProps> {
    dataKey: string // Must match a key in row data
    label: string // Used as table header label text
    width?: number // Optional using Partial<T>
    minWidth?: number // Minimum column width
    disableSort?: boolean // Prevents sorting on a per column basis
    disableTitle?: boolean // Prevents title from being rendered on hover
    cellElement?: (cellData?: any, rowData?: any) => JSX.Element | string | null // Provides a custom cell element
}

export interface BaseVirtualizedMultiGridProps extends Partial<GridProps> {
    width: number // Forces type to number
    height: number // Forces type to number
    rows: any // Provide rows instead of list
    cols: BaseVirtualizedMultiGridCol[] // Provide cols to render columns
    data?: any // Signals to the table that something has changed when deleting or marking a row as added
    rowHeight?: number // Cell height for non header
    headerHeight?: number // Cell height for header
    isLoading?: boolean
    isSearching?: boolean
    includeHeaders?: boolean // Flag to enable header as first row
    enableBatchActions?: boolean // Flag to set if a checkbox is the first column
    showEndOfResults?: boolean // Flag to set if the table should render a "end of results" message
    rowStyle?: (rowData?: any) => React.CSSProperties // Inline style to attach to table rows
    rowClassname?: (rowData?: any) => string // Function to get row classname
    onRowsRendered?: (params: IndexRange) => void
    isRowLoading?: (rowIndex: number) => boolean
}

const DEFAULT_HEADER_ROW_HEIGHT = 20
const DEFAULT_ROW_HEIGHT = 50
const DEFAULT_COL_WIDTH = 200

@inject('store')
@observer
class BaseVirtualizedMultiGrid extends React.Component<BaseVirtualizedMultiGridProps & { store?: RootStore }> {
    baseMultiGridRef: any

    state: {
        scrollToRow?: number
        scrollToColumn?: number
        sortDirection?: SortDirectionType
        sortBy?: string
        sortedList: any[]
    } = {
        scrollToRow: undefined,
        scrollToColumn: undefined,
        sortedList: [],
    }

    constructor(props: BaseVirtualizedMultiGridProps) {
        super(props)
        this.state = {
            sortDirection: this.props.sortDirection,
            sortBy: this.props.sortBy,
            sortedList: this.props.rows,
        }
    }

    componentDidMount = () => {
        // Sort on initial mount if sortBy and sortDirection provided
        // This resets sorting when switching screens
        this.handleResetSort()

        // Resize detection
        window.addEventListener('resize', _.debounce(this.onResize, 500))
        // Trigger initial resize on mount
        this.onResize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    componentDidUpdate(prevProps: BaseVirtualizedMultiGridProps) {
        const { sortBy, sortDirection } = this.state
        const { rows } = this.props

        // Update state if data prop changes
        if (this.props.data !== prevProps.data) {
            this.setState({
                // Sort using params from state when new props received
                sortedList: this.sortList(rows, {
                    sortBy: sortBy || '',
                    sortDirection: sortDirection || SortDirection.ASC,
                }),
            })

            // Recompute new col widths
            this.baseMultiGridRef?.recomputeGridSize()
        }
    }

    onResize = () => {
        // Recompute new col widths
        this.baseMultiGridRef?.recomputeGridSize()
    }

    handleScroll = (params: ScrollParams) => {
        // Reset scroll position to prevent resetting when component rerenders
        if (this.state.scrollToRow !== undefined) {
            this.setState({ scrollToRow: undefined })
        }
        if (this.state.scrollToColumn !== undefined) {
            this.setState({ scrollToColumn: undefined })
        }
    }

    handleScrollToRow = (index: number) => {
        this.setState({ scrollToRow: index })
    }

    handleScrollToColumn = (index: number) => {
        this.setState({ scrollToColumn: index })
    }

    handleResetSort = () => {
        const { sortBy, sortDirection } = this.props
        if (sortBy && sortDirection) {
            this.handleSort({
                sortBy,
                sortDirection,
            })
        }
    }

    cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps): JSX.Element | null => {
        const isLastRow = rowIndex === this.props.rows.length
        if (isLastRow && this.props.showEndOfResults) {
            if (columnIndex === 0) {
                return (
                    <div key={key} className='end-of-results-row' style={style}>
                        <h4>{i18n.t('common.endOfResults')}</h4>
                    </div>
                )
            }
            return null
        }
        const { cols, includeHeaders, enableBatchActions, rowStyle } = this.props
        const { sortBy, sortDirection } = this.state

        const col = cols[columnIndex]
        const isHeader = includeHeaders && rowIndex === 0
        const headerOffset = includeHeaders ? 1 : 0

        const rowData = this.state.sortedList[rowIndex - headerOffset] || {}
        const cellData: any = Object.values(rowData)[columnIndex - (enableBatchActions ? 1 : 0)]

        let rowClassname: string
        if (isHeader) {
            rowClassname = 'ReactVirtualized__Table__headerRow'
        } else {
            rowClassname = 'ReactVirtualized__Table__row' + (rowIndex % 2 === 0 ? ' evenRow' : ' oddRow')
            if (this.props.rowClassname) {
                rowClassname = rowClassname + ' ' + this.props.rowClassname(rowIndex)
            }
        }

        const isRowLoading = this.props.isRowLoading && this.props.isRowLoading(rowIndex)

        return (
            <div
                onClick={this.handleRowClick.bind(this, { columnIndex, rowIndex })}
                key={key}
                style={{ ...style, ...(rowStyle && rowStyle(rowData)) }}
                className={rowClassname}
            >
                {isHeader && sortBy && sortDirection ? (
                    this.renderHeader(col.label, col.dataKey, sortBy, sortDirection, col.disableSort)
                ) : (
                    <div
                        className={
                            'ReactVirtualized__Table__rowColumn' +
                            (this.props.isLoading || isRowLoading ? ' bp3-skeleton' : '')
                        }
                        style={{
                            width:
                                this.props.isLoading || isRowLoading
                                    ? columnIndex > 1 && columnIndex < cols.length - 1
                                        ? // Render loading skeleton between 30% and 100% of the cell width, step by 10%
                                          String((Math.floor(Math.random() * (10 - 3 + 1)) + 3) * 10 + '%')
                                        : '100%'
                                    : undefined,
                        }}
                        role='gridcell'
                        title={
                            !col.disableTitle
                                ? cellData !== undefined
                                    ? String(cellData)
                                    : i18n.t('placeholders.missingData')
                                : undefined
                        }
                    >
                        {this.renderCellItem({ cellData, rowData }, col.cellElement)}
                    </div>
                )}
            </div>
        )
    }

    renderCellItem = (
        { cellData, rowData }: any,
        cellElement?: (cellData: any, rowData: any) => JSX.Element | string | null
    ) => {
        if (_.isEmpty(rowData)) {
            // Rowdata is an empty object while loading so ignore
            return
        }
        if (cellElement) {
            return cellElement(cellData, rowData)
        }
        // Return cellData as string if no cellElement defined
        // Only show placeholder if cellData is null or undefined, empty strings are acceptable
        return (cellData && String(cellData)) ?? i18n.t('placeholders.missingData')
    }

    renderHeader(
        label: string,
        dataKey: string,
        sortBy: string,
        sortDirection: SortDirectionType,
        disableSort?: boolean
    ): JSX.Element {
        let sortIcon
        switch (sortDirection) {
            case SortDirection.ASC:
                sortIcon = sortArrowUpActive
                break
            case SortDirection.DESC:
                sortIcon = sortArrowDownActive
                break
        }

        return (
            <div
                className={
                    'ReactVirtualized__Table__headerColumn' +
                    (!disableSort ? ' ReactVirtualized__Table__sortableHeaderColumn' : '')
                }
                role='columnheader'
            >
                {label}
                {!disableSort && sortBy === dataKey && (
                    <img className='sortable-header-icon' src={sortIcon} alt='Toggle sort' />
                )}
            </div>
        )
    }

    handleRowClick = ({ columnIndex, rowIndex }: GridCellProps) => {
        if (rowIndex === 0) {
            // Handle header click
            const col = this.props.cols[columnIndex]
            if (col.disableSort) {
                return
            }

            const { sortDirection, sortBy } = this.state

            // Toggle sort direction, prefer ASC
            let newSortDirection: SortDirectionType = SortDirection.ASC
            if (sortBy === col.dataKey) {
                newSortDirection = sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            }

            const info = { sortBy: col.dataKey, sortDirection: newSortDirection }
            this.handleSort(info)
        }

        // Update grid component before passing along to props
        this.baseMultiGridRef?.recomputeGridSize()
        return this.props.onRowClick ? this.props.onRowClick(rowIndex) : undefined
    }

    handleSort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
        const { rows, sortable, isSearching, onSort } = this.props
        if (!rows || sortable === false || isSearching) {
            return
        }
        const newlySortedList = this.sortList(rows, info)
        // Update grid component before passing along to props
        this.setState({ sortDirection: info.sortDirection, sortBy: info.sortBy, sortedList: newlySortedList })

        this.baseMultiGridRef?.recomputeGridSize()
        return onSort && onSort(newlySortedList)
    }

    sortList(listToSort: any[], info: { sortBy: string; sortDirection: SortDirectionType }): any[] {
        if (this.props.isSearching) {
            return listToSort
        }
        let list = listToSort
        list = sortByKey(list, info.sortBy)
        return info.sortDirection === SortDirection.DESC ? list.reverse() : list
    }

    getColumnWidth = ({ index }: Index): number => {
        this.props.cols.forEach(col => {
            if (!col.minWidth) {
                col.minWidth = DEFAULT_COL_WIDTH
            }
        })
        return calcColumnWidth(
            index,
            this.props.cols,
            this.props.width - 15 // Offset for scrollbar width
        )
    }

    getRowHeight = ({ index }: Index): number => {
        const headerHeight = this.props.headerHeight || DEFAULT_HEADER_ROW_HEIGHT
        const rowHeight = this.props.rowHeight || DEFAULT_ROW_HEIGHT

        return index === 0 ? headerHeight : rowHeight
    }

    getCellData = ({ index }: Index): any => this.state.sortedList[index]

    onSectionRendered = ({ rowStartIndex, rowStopIndex }: SectionRenderedParams) => (
            this.props.onRowsRendered &&
            this.props.onRowsRendered({
                startIndex: rowStartIndex,
                stopIndex: rowStopIndex,
            })
        )

    render() {
        const { data, cols, height, width, className, includeHeaders, noContentRenderer } = this.props
        const { scrollToRow, scrollToColumn, sortedList } = this.state

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <div className='bvm-wrapper custom-scrollbars'>
                <MultiGrid
                    {...this.props}
                    data={data}
                    ref={instance => (this.baseMultiGridRef = instance)}
                    cellRenderer={this.cellRenderer}
                    columnWidth={this.getColumnWidth}
                    columnCount={cols.length}
                    // Hide fixed row on mobile or if no cols provided
                    fixedRowCount={!isMobile && cols.length > 0 ? 1 : undefined}
                    height={height}
                    rowHeight={this.getRowHeight}
                    rowCount={sortedList.length + (includeHeaders ? 1 : 0)}
                    width={width}
                    scrollToRow={scrollToRow}
                    scrollToColumn={scrollToColumn}
                    classNameTopRightGrid='ReactVirtualized__Table bvm-table'
                    classNameBottomRightGrid={
                        'ReactVirtualized__Table ReactVirtualized__Table__Grid bvm-table' +
                        (className ? ' ' + className : '')
                    }
                    onRowClick={this.handleRowClick}
                    onScroll={this.handleScroll}
                    onSectionRendered={this.onSectionRendered}
                    noContentRenderer={noContentRenderer}
                />
            </div>
        )
    }
}

export default BaseVirtualizedMultiGrid
