import { action, observable, runInAction, computed } from 'mobx'

import RootStore from 'src/common/RootStore'

// Models
import { INamedBaseModel } from 'src/common/models/BaseModel'
import { RoleType } from 'src/common/models/Roles'
import Organisation, { OrganisationPermission } from 'src/common/models/Organisation'
import Screen from 'src/common/models/Screen'
import AssetData from '../models/AssetData'
import AdminStoreItem from '../models/AdminStoreItem'
import { AdminTabName } from '../container'
import { AdminTabData, OrgAdminTabData, UserAdminTabData, ScreenAdminTabData } from '../models/AdminTabData'

import { ValueLabelPair } from 'src/common/components/SelectComponents'
import { LednetVersion, AdminBatchAction } from 'src/common/stores/AdminStore'
import userRouter from 'src/api/userRouter'
import { AppToaster } from 'src/common/components/AppToaster'
import i18n from 'src/i18n'
import { Intent } from '@blueprintjs/core'
import { gte, lt, rcompare, satisfies, valid } from 'semver'

const { REACT_APP_CANDELIC_ORG_ID: CANDELIC_ORG_ID } = process.env

export default class AdminUIStore {
    @observable activeAdminTab: AdminTabName | undefined

    @observable isAdminPanelStackOpen: boolean = false
    @observable isCloseAlertOpen: boolean = false
    @observable isDeleteAlertOpen: boolean = false
    @observable isRestoreAlertOpen: boolean = false
    @observable isPermanentlyDeleteAlertOpen: boolean = false
    @observable permanentlyDeleteConfirmation?: string
    @observable isEditMode: boolean = false
    @observable isEditLogoDialogOpen: boolean = false

    @observable searchQueryValue?: string
    @observable showDeleted: boolean = false

    @observable availableLednetVersions: LednetVersion[]
    @observable selectedItemIds = new Set<string>()
    @observable batchActionValue: string
    @observable pendingBatchAction: string
    @observable isDisconnectedWarningOpen: boolean = false
    @observable isUpdateErrorOpen: boolean = false
    @observable updateErrorMessage: string = ''
    @observable updateTargetVersion: LednetVersion | undefined
    @observable isConfirmUpdateOpen: boolean = false
    @observable onConfirmUpdate: (() => void) | undefined
    @observable isSelfEditPermissionsAlertOpen: boolean = false
    @observable screenToCopyIdentifiers?: Screen

    @observable isSaving = false
    @observable isSaveErrorAlertOpen = false
    @observable saveAlertMessage?: string

    @observable private setOrg?: Organisation

    @observable adminTabData?: AdminTabData

    // RootStore
    rootStore: RootStore

    assetData: AssetData

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    get lednetVersionOptions(): ValueLabelPair[] {
        if (!this.availableLednetVersions) {
            return []
        }

        // Get the latest version of the selected screens
        const maxVersion = this.selectedScreens.map(c => c.controller?.version || '').sort(rcompare)[0]

        return this.availableLednetVersions.map(lednetVersion => ({
            value: lednetVersion.version,
            label: lednetVersion.version,
            // Only allow updating to a version that is greater than the current version
            isDisabled: maxVersion && lt(lednetVersion.version, maxVersion),
        }))
    }

    @computed get associatedOrgsForLocation(): Organisation[] | undefined {
        const me = this.rootStore.userStore.me
        const myOrg = this.rootStore.orgStore.myOrg
        const itemOrg = this.selectedOrg

        if (!me || !myOrg) {
            return undefined
        }

        // Get associated orgs from newly created organisation
        const newItemAssociatedOrgs: Organisation[] = []
        if (itemOrg && itemOrg.associatedOrganisationIds) {
            Array.from(itemOrg.associatedOrganisationIds.keys()).map(orgId => {
                const foundOrg = this.rootStore.orgStore.findItem(orgId)
                if (foundOrg) {
                    newItemAssociatedOrgs.push(foundOrg)
                }
                return undefined
            })
        }

        if (me.isSuperUser) {
            if (itemOrg) {
                // If org selected from dropdown, return associated orgs. Otherwise get from new item
                return itemOrg.associatedOrganisations && itemOrg.associatedOrganisations.length > 0
                    ? itemOrg.associatedOrganisations
                    : newItemAssociatedOrgs
            } else {
                return undefined
            }
        } else {
            return myOrg.associatedOrganisations
        }
    }

    @computed get availableAssociatedOrgs(): Organisation[] {
        const itemToUpdate = this.rootStore.adminUIStore.assetData.org.item
        return itemToUpdate
            ? this.rootStore.orgStore.items
                  .filter(organisation => organisation.id !== itemToUpdate.id && organisation.deletedAt === null)
                  .sort((a, b) => a.name.localeCompare(b.name))
            : []
    }

    // Helper functions
    // Returns array of roles available to use for logged in user
    @computed get selectableRoles(): string[] | undefined {
        const me = this.rootStore.userStore.me

        if (!me) {
            return undefined
        }

        if (!me.isSuperUser) {
            return Object.values(RoleType).filter(role => role !== RoleType.none && role !== RoleType.superUser)
        }

        return Object.values(RoleType).filter(role => role !== RoleType.none)
    }

    @computed get adminTabs(): AdminTabName[] | undefined {
        const me = this.rootStore.userStore.me
        if (!me) {
            return undefined
        }
        if (me.isSuperUser) {
            return Object.values(AdminTabName)
        }
        if (this.selectedOrg?.id !== me.organisationId) {
            return [AdminTabName.screens]
        }
        return [AdminTabName.users, AdminTabName.screens]
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    @computed get tabData(): OrgAdminTabData | UserAdminTabData | ScreenAdminTabData | undefined {
        const me = this.rootStore.userStore.me
        if (!me || !this.activeAdminTab) {
            return undefined
        }

        switch (this.activeAdminTab) {
            case AdminTabName.organisations:
                const orgTabData = new OrgAdminTabData()

                const orgItems = this.rootStore.orgStore.items.sort((a, b) => a.name.localeCompare(b.name))
                orgTabData.setRowData(orgItems, this.showDeleted)
                return orgTabData
            case AdminTabName.users:
                const userTabData = new UserAdminTabData()

                const userItems = this.rootStore.userStore.items
                    .filter(user => user.organisationId === this.selectedOrg?.id)
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                userTabData.setRowData(userItems, this.showDeleted)
                return userTabData
            case AdminTabName.screens:
                const screenTabData = new ScreenAdminTabData()

                const screenItems = this.rootStore.screenStore.items
                    .filter(screen => {
                        const organisationId = screen.controller?.location?.organisationId || screen.organisationId
                        return organisationId && organisationId === this.selectedOrg?.id
                    })
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                screenTabData.setRowData(screenItems, this.showDeleted)
                return screenTabData
            default:
                return undefined
        }
    }

    @computed get selectedScreens(): Screen[] {
        return [...this.selectedItemIds].map(id => this.rootStore.screenStore.findItem(id)).filter(Boolean) as Screen[]
    }

    getAssociatedOrgsWithPermissions = (
        orgItem: Organisation
    ): Array<{ id: string; name: string; permissions: OrganisationPermission }> => {
        if (!orgItem) {
            return []
        }
        // Build array of associated orgs for assigning permissions table
        const associatedOrgsWithPermissions = new Array<{
            id: string
            name: string
            permissions: OrganisationPermission
        }>()
        for (const orgMapping of orgItem.associatedOrganisationIds.entries()) {
            const foundOrg = this.rootStore.orgStore.findItem(orgMapping[0])
            if (foundOrg) {
                associatedOrgsWithPermissions.push({
                    id: orgMapping[0],
                    name: foundOrg.name,
                    permissions: orgMapping[1].permissions,
                })
            }
        }
        return associatedOrgsWithPermissions
    }

    getLednetVersions = () => {
        this.rootStore.adminStore.getLednetVersions().then(payload => {
            runInAction('updateLednetVersions', () => {
                // Ensure that all LEDNet versions in the payload are valid
                this.availableLednetVersions = payload.filter(v => valid(v.version))
                // Update initially selected LEDNet version in UI store
                // Select component will default to first item in available versions list
                if (this.availableLednetVersions.length > 0) {
                    this.updateBatchActionOption(this.availableLednetVersions[0].version)
                }
            })
        })
    }

    @action updateParams = (params: { org: string; tab?: string }) => {
        const { org, tab } = params

        if (org) {
            this.setOrg = this.rootStore.orgStore.findItem(org)
        }

        // Default to showing the screens tab, unless viewing as the Candelic org, where we show the orgs tab
        const tabName = tab
            ? AdminTabName[tab]
            : org === CANDELIC_ORG_ID
            ? AdminTabName.organisations
            : AdminTabName.screens
        this.activeAdminTab = tabName
        // Clear search query and show deleted toggle on tab change
        this.searchQueryValue = undefined
        this.showDeleted = false
    }

    @action handleRefresh = () => {
        const me = this.rootStore.userStore.me
        if (!me || !this.activeAdminTab) {
            return
        }

        this.rootStore.appStore.listsComplete = false
        if (!this.assetData) {
            this.assetData = new AssetData(this.rootStore)
        }
        const currentStore = this.storeItemForTab(this.activeAdminTab)

        currentStore!.store
            .refresh(undefined, me.showDeletedItems)
            .then(() => {
                runInAction('refreshTable', () => {
                    this.rootStore.appStore.listsComplete = true
                })
            })
            .catch(err => console.error(err))
    }

    @action updateSearchQuery = (value: string) => {
        this.searchQueryValue = value
    }

    @action toggleShowDeleted = () => {
        this.showDeleted = !this.showDeleted
    }

    @action updateSelectedRowIds = (selectedItemIds: Set<string>) => {
        this.selectedItemIds = selectedItemIds
    }

    @action clearSelectedRowIds = () => {
        this.selectedItemIds.clear()
    }

    @action updateBatchActionOption = (value: string) => {
        this.batchActionValue = value
    }

    @action submitBatchAction = (batchAction: string, forceUpdate?: boolean) => {
        try {
            switch (batchAction) {
                case AdminBatchAction.lednetVersion:
                    // Check if any selected screens are disconnected
                    const selectedScreensDisconnected = this.selectedScreens.filter(s => !s.isConnected).length > 0
                    if (!forceUpdate && selectedScreensDisconnected) {
                        this.pendingBatchAction = batchAction
                        this.toggleDisconnectedWarning()
                        break
                    }

                    // Check that the selected LEDNet version is valid for the update target
                    const targetVersion = this.availableLednetVersions.find(
                        version => version.version === this.batchActionValue
                    )

                    if (!targetVersion || !valid(targetVersion.version)) {
                        throw new Error(
                            `There was an error updating to the selected LEDNet version. Please contact Candelic support.`
                        )
                    }
                    this.updateTargetVersion = targetVersion
                    const selectedScreenVersions = this.selectedScreens.map(s => s.controller?.version || '')
                    if (selectedScreenVersions.some(version => !valid(version))) {
                        throw new Error(
                            `Could not determine version of one or more selected screens. Please ensure that all selected screens have LEDNet installed correctly.`
                        )
                    }
                    if (selectedScreenVersions.some(version => lt(this.batchActionValue, version))) {
                        throw new Error(
                            `Cannot downgrade a screen to a previous version. If this is intentional, please contact Candelic support.`
                        )
                    }
                    if (gte(targetVersion.version, '1.8.0')) {
                        // Any screens with a version <1.8.0 are not eligible to upgrade to 1.8.x
                        const ineligibleScreens = selectedScreenVersions.some(version => satisfies(version, '<1.8.0'))
                        if (ineligibleScreens) {
                            this.toggleUpdateError(
                                `Cannot upgrade to ${targetVersion.version} as one or more screens are running a version older than 1.8.0.

                                Please manually upgrade the screens to 1.8.0 or later before upgrading to ${targetVersion.version}.

                                For assistance, please contact Candelic support.`
                            )
                            break
                        }
                    }

                    this.promptConfirmUpdate(() => {
                        this.rootStore.adminStore
                            .updateLednetVersions(
                                this.selectedScreens.map(s => s.controllerId),
                                this.batchActionValue
                            )
                            .then(() => {
                                runInAction('updateLednetVersions', () => {
                                    this.closeConfirmUpdate()
                                    this.clearSelectedRowIds()
                                    this.updateTargetVersion = undefined
                                    this.onConfirmUpdate = undefined
                                })
                            })
                    })

                    break
                default:
                    break
            }
        } catch (error) {
            console.error(error)
            AppToaster.show({
                message: error.message,
                intent: Intent.DANGER,
                icon: 'error',
            })
        }
    }

    @action toggleDisconnectedWarning = (shouldContinue?: boolean) => {
        if (shouldContinue) {
            // Alert dismissed, continue updating
            this.submitBatchAction(this.pendingBatchAction, true)
        }

        this.isDisconnectedWarningOpen = !this.isDisconnectedWarningOpen
    }

    @action toggleUpdateError = (error?: string) => {
        this.isUpdateErrorOpen = !this.isUpdateErrorOpen
        if (error) {
            this.updateErrorMessage = error
        }
    }

    @action promptConfirmUpdate = (onConfirm: () => void) => {
        this.isConfirmUpdateOpen = true
        this.onConfirmUpdate = onConfirm
    }

    @action closeConfirmUpdate = () => {
        this.isConfirmUpdateOpen = false
    }

    @action toggleSelfEditPermissionsAlert = () => {
        this.isSelfEditPermissionsAlertOpen = !this.isSelfEditPermissionsAlertOpen
    }

    // Create
    @action toggleAdminPanelStack = () => {
        if (!this.isAdminPanelStackOpen) {
            this.assetData = new AssetData(this.rootStore)
        }

        this.isAdminPanelStackOpen = !this.isAdminPanelStackOpen
    }

    @action toggleCloseAlert = () => {
        this.isCloseAlertOpen = !this.isCloseAlertOpen
    }

    // Save
    handleFormSave = async (isNew: boolean) => {
        const data = this.assetData.data
        if (!data) {
            console.error('Bad data')
            return
        }

        try {
            runInAction('startedEditingOrUpdating', () => {
                this.isSaving = true
            })
            if (this.isEditMode) {
                await data.updateItem()
            } else if (isNew) {
                await data.createItem()
            } else {
                throw new Error('Unhandled')
            }
            if (this.assetData.user.selfPermissionsChanged) {
                this.toggleSelfEditPermissionsAlert()
            }
        } catch (err) {
            console.error(err)
            this.toggleSaveErrorAlert(err?.error)
        } finally {
            runInAction('finishedEditingOrUpdating', () => {
                this.isSaving = false
                if (!this.isSaveErrorAlertOpen) {
                    if (this.isEditMode) {
                        this.isEditMode = false
                    }
                    if (this.isAdminPanelStackOpen) {
                        this.isAdminPanelStackOpen = false
                    }
                }
            })
        }
    }

    @action toggleSaveErrorAlert = (errorMessage?: string) => {
        this.saveAlertMessage = !this.isSaveErrorAlertOpen && errorMessage ? errorMessage : undefined

        this.isSaveErrorAlertOpen = !this.isSaveErrorAlertOpen
    }

    // Delete
    @action toggleDeleteAlert = () => {
        this.isDeleteAlertOpen = !this.isDeleteAlertOpen
    }

    @action setItemToDelete = (tabValue: AdminTabName, idValue: string) => {
        this.setItemForUpdate(tabValue, idValue)
        this.toggleDeleteAlert()
    }

    @action confirmDelete = async (tabValue: AdminTabName) => {
        const me = this.rootStore.userStore.me
        if (!me) {
            return
        }
        this.toggleDeleteAlert()
        const storeItem = this.storeItemForTab(tabValue)
        if (storeItem) {
            await storeItem.deleteItem(false)
        }
    }

    // Edit
    @action toggleEditMode = () => {
        this.isEditMode = !this.isEditMode
    }

    @action setItemForUpdate = (tabValue: AdminTabName, idValue: string) => {
        this.assetData = new AssetData(this.rootStore)

        const storeItem = this.storeItemForTab(tabValue)
        if (storeItem) {
            const itemToUpdate = storeItem.store.findItem(idValue)
            if (itemToUpdate) {
                storeItem.setItemToUpdate(itemToUpdate)
            }
        }
    }

    @action setItemToEdit = (tabValue: AdminTabName, idValue: string) => {
        this.setItemForUpdate(tabValue, idValue)
        this.toggleEditMode()
    }

    @action setItemToEditLogo = (tabValue: AdminTabName, idValue: string) => {
        this.setItemForUpdate(tabValue, idValue)
        this.isEditLogoDialogOpen = true
    }

    @action closeEditLogoDialog = () => {
        this.isEditLogoDialogOpen = false
    }

    // Restore
    @action toggleRestoreAlert = () => {
        this.isRestoreAlertOpen = !this.isRestoreAlertOpen
    }

    @action setItemToRestore = (tabValue: AdminTabName, idValue: string) => {
        this.setItemForUpdate(tabValue, idValue)
        this.toggleRestoreAlert()
    }

    @action confirmRestore = async (tabValue: AdminTabName) => {
        this.toggleRestoreAlert()
        const storeItem = this.storeItemForTab(tabValue)
        if (storeItem) {
            await storeItem.restoreItem()
        }
    }

    // Permanently Delete
    @action togglePermanentlyDeleteAlert = () => {
        if (this.isPermanentlyDeleteAlertOpen) {
            this.permanentlyDeleteConfirmation = undefined
        }
        this.isPermanentlyDeleteAlertOpen = !this.isPermanentlyDeleteAlertOpen
    }

    @action setItemToPermanentlyDelete = (tabValue: AdminTabName, idValue: string) => {
        this.setItemForUpdate(tabValue, idValue)
        this.togglePermanentlyDeleteAlert()
    }

    @action setPermanentlyDeleteConfirmation = (event: any) => {
        this.permanentlyDeleteConfirmation = event.target.value
    }

    @action confirmPermanentlyDelete = async (tabValue: AdminTabName) => {
        this.togglePermanentlyDeleteAlert()
        const storeItem = this.storeItemForTab(tabValue)
        if (storeItem) {
            await storeItem.permanentlyDeleteItem()
        }
    }

    @action resendUserInvitation = async (userId: string) => {
        try {
            await userRouter.resendUserInvitation(userId)
            AppToaster.show({
                message: i18n.t('feedback.successes.successfullyResentUserInvitation'),
                intent: Intent.SUCCESS,
            })
        } catch (error) {
            console.error('Error resending user invitation:', error)
            AppToaster.show({
                message: i18n.t('feedback.errors.errorResendingUserInvitation'),
                intent: Intent.DANGER,
            })
        }
    }

    @action setScreenToCopyIdentifiers = (itemId: string) => {
        this.screenToCopyIdentifiers = this.rootStore.screenStore.findItem(itemId)
    }

    @action clearScreenToCopyIdentifiers = () => {
        this.screenToCopyIdentifiers = undefined
    }

    storeItemForTab(name: AdminTabName): AdminStoreItem<INamedBaseModel> | undefined {
        if (!this.assetData) {
            return undefined
        }
        switch (name) {
            case AdminTabName.organisations:
                return this.assetData.org
            case AdminTabName.users:
                return this.assetData.user
            case AdminTabName.screens:
                return this.assetData.screen
            default:
                return undefined
        }
    }
}
