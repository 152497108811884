import { observable, action } from 'mobx'
import RootStore from '../RootStore'
import _ from 'lodash'

export default class AppUIStore {
    @observable title = 'ON by Candelic'

    @observable isOrgSelectionOpen = false
    @observable orgSearchTerm?: string
    @observable isUserNavOpen = false
    @observable mobileSidenavOpen = false
    @observable isWelcomePopoverOpen = false
    @observable isWelcomePopoverDismissed = false

    @observable isSiteError = false

    @observable isMobile: boolean

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        // Resize detection
        window.addEventListener('resize', _.debounce(this.onResize, 500))
        this.onResize()
    }

    @action onResize = () => {
        this.isMobile = window.innerWidth < 768

        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`) // Set CSS variable
    }

    @action toggleOrgSelection = () => {
        if (this.isOrgSelectionOpen) {
            this.orgSearchTerm = undefined
        }
        this.isOrgSelectionOpen = !this.isOrgSelectionOpen
    }

    @action updateOrgSearchTerm = (value: string) => {
        this.orgSearchTerm = value
    }

    @action toggleUserNav = () => {
        this.isUserNavOpen = !this.isUserNavOpen
    }

    @action toggleMobileSidenav = () => {
        this.mobileSidenavOpen = !this.mobileSidenavOpen
    }

    @action closeMobileSidenav = () => {
        if (this.mobileSidenavOpen) {
            this.mobileSidenavOpen = false
        }
    }

    @action openWelcomePopover = () => {
        if (!this.isWelcomePopoverOpen && !this.isWelcomePopoverDismissed) {
            this.isWelcomePopoverOpen = true
        }
    }

    @action closeWelcomePopover = () => {
        this.isWelcomePopoverOpen = false
        this.isWelcomePopoverDismissed = true
    }
}
