import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import DiagnosticsErrorGroup from 'src/common/models/DiagnosticsErrorGroup'

import { Icon, Intent } from '@blueprintjs/core'
import { AutoSizer, ListRowProps, Index } from 'react-virtualized'
import BaseVirtualizedList from 'src/common/components/virtualized/BaseVirtualizedList'
import ErrorStatusCell from './components/tableComponents/ErrorStatusCell'
import GroupNameAssignCell from './components/tableComponents/GroupNameAssignCell'
import ErrorTable from './components/ErrorTable'
import chevronDown from 'src/assets/images/chevronDown.svg'

const DEFAULT_LIST_ROW_HEIGHT = 60
const DEFAULT_TABLE_HEADER_ROW_HEIGHT = 32
const DEFAULT_TABLE_ROW_HEIGHT = 34

@inject('store')
@observer
class ScreenCurrentErrors extends React.Component<{ store?: RootStore }> {
    errorGroupListRef: any

    recomputeRowHeights = () => {
        // Tell the base list to recompute row heights
        this.errorGroupListRef?.baseListRef?.recomputeRowHeights()
    }

    handleRowClick = (errorGroup: DiagnosticsErrorGroup) => {
        const screenCurrentErrorsManager = this.props.store!.screenDiagnosticsUIStore.screenCurrentErrorsManager
        if (!screenCurrentErrorsManager.isCompactView) {
            // Ignore if not in compact mode
            return
        }

        screenCurrentErrorsManager.toggleCurrentScreenErrorGroups(errorGroup)
        this.recomputeRowHeights()
    }

    rowRenderer = (list: DiagnosticsErrorGroup[], { key, index, style }: ListRowProps): JSX.Element | null => {
        const screenCurrentErrorsManager = this.props.store!.screenDiagnosticsUIStore.screenCurrentErrorsManager
        const errorGroup: DiagnosticsErrorGroup = list[index]
        const screenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!errorGroup || !screenConfiguration) {
            return null
        }

        const isCompactView = screenCurrentErrorsManager.isCompactView

        const isOpen = !!screenCurrentErrorsManager.openErrorGroups?.has(errorGroup)

        return (
            <div key={key} style={style} className='error-group-list-row-wrapper'>
                <div className='error-group-list-row'>
                    <div
                        className={'error-group-list-group' + (isCompactView ? ' collapsable' : '')}
                        onClick={this.handleRowClick.bind(this, errorGroup)}
                    >
                        <div style={{ width: 70, flexShrink: 0 }}>
                            <ErrorStatusCell errorGroup={errorGroup} />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <GroupNameAssignCell errorGroup={errorGroup} />
                        </div>
                        {isCompactView && (
                            <div className='custom-table-expander'>
                                <img
                                    style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
                                    src={chevronDown}
                                    alt='Toggle'
                                />
                            </div>
                        )}
                    </div>
                    {(!isCompactView || isOpen) && <ErrorTable errorGroup={errorGroup} />}
                </div>
            </div>
        )
    }

    getRowHeight = (list: DiagnosticsErrorGroup[], { index }: Index): number => {
        const screenCurrentErrorsManager = this.props.store!.screenDiagnosticsUIStore.screenCurrentErrorsManager
        const errorGroup = list[index]
        const isCompactView = screenCurrentErrorsManager.isCompactView
        const isOpen = !!screenCurrentErrorsManager.openErrorGroups?.has(errorGroup)
        if (isCompactView && !isOpen) {
            return DEFAULT_LIST_ROW_HEIGHT
        }

        return (
            DEFAULT_LIST_ROW_HEIGHT +
            DEFAULT_TABLE_HEADER_ROW_HEIGHT +
            DEFAULT_TABLE_ROW_HEIGHT * errorGroup.errorList.length
        )
    }

    render() {
        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        const screenCurrentErrorsManager = screenDiagnosticsUIStore.screenCurrentErrorsManager

        const list = screenDiagnosticsUIStore.currentScreen?.errorGroup?.errorGroupListForDiagnostics || []

        const data = {
            totalErrors: screenDiagnosticsUIStore.currentScreen?.errorGroup?.totalErrors, // Recompute when number of errors changes
            isCompactView: screenCurrentErrorsManager.isCompactView, // Recompute upon toggling compact view
            openErrorGroups: screenCurrentErrorsManager.openErrorGroups?.size, // Recompute upon collapsing all rows
            data: list,
        }

        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen

        return (
            <div className='screen-errors'>
                <div className='diagnostics-errors-content'>
                    <div className='diagnostics-error-group-container'>
                        {list && list.length > 0 ? (
                            <div
                                key={list.length} // Force re-render when list changes
                                className='error-group-list'
                            >
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <div>
                                            <BaseVirtualizedList
                                                ref={instance => (this.errorGroupListRef = instance)}
                                                data={data}
                                                width={width}
                                                height={height}
                                                list={list}
                                                rowCount={list.length}
                                                recomputeOnDataChange
                                                rowRenderer={this.rowRenderer.bind(this, list)}
                                                rowHeightGetter={this.getRowHeight.bind(this, list)}
                                            />
                                        </div>
                                    )}
                                </AutoSizer>
                            </div>
                        ) : (
                            <div className='diagnostics-non-ideal-state'>
                                {currentScreen?.isConnected ? (
                                    <>
                                        <h2>{i18n.t('diagnosticsPage.healthyScreen')}</h2>
                                        <Icon icon='tick-circle' iconSize={54} intent={Intent.SUCCESS} />
                                    </>
                                ) : (
                                    <>
                                        <h2>
                                            {currentScreen?.name} {i18n.t('diagnosticsPage.lostConnection')}
                                        </h2>
                                        <Icon icon='warning-sign' iconSize={54} intent={Intent.DANGER} />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default ScreenCurrentErrors
