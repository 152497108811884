import RootStore from 'src/common/RootStore'
import { observable, action, computed, runInAction, autorun } from 'mobx'

import i18n from 'src/i18n'
import uuid from 'uuid/v4'
import { subscriptionTopics } from '../utils/String'

import Controller from '../models/Controller'
import MqttSubscription from '../models/MqttSubscription'
import { ConsoleCommand } from '../models/ConsoleEnums'

import PopoutManager from './PopoutManager'
import TextLine from 'src/modules/diagnostics/screen/submodules/console/models/TextLine'
import consoleRouter from 'src/api/consoleRouter'

export default class ConsoleManager extends PopoutManager {
    @observable controllerId: string

    @observable remoteCommand = ''
    @observable prevCommand = ''
    @observable prevCommandList = new Array<string>()
    @observable commandIndex = 0

    @observable messageList: TextLine[] = []
    @observable value: string
    @observable level: string

    @observable consoleSubscription?: MqttSubscription

    @observable isConnecting: boolean = false

    constructor(rootStore: RootStore, controllerId: string) {
        super(rootStore, controllerId)

        if (!controllerId) {
            return
        }

        this.controllerId = controllerId

        // Clear console window
        this.messageList = []

        // React to controller connected
        autorun(() => {
            if (this.controllerConnected && !this.isConnecting && !this.consoleSubscription) {
                // Subscribe
                this.init()
            }
        })

        // React to dropped connection
        autorun(() => {
            if (this.connectionDropped) {
                // Invalidate subscription
                this.removeSubscription()
            }
        })
    }

    @computed get selectedController(): Controller | undefined {
        if (!this.controllerId) {
            return undefined
        }
        return this.rootStore.controllerStore.findItem(this.controllerId)
    }

    @computed get title(): string {
        const controller = this.rootStore.controllerStore.findItem(this.objectId)
        return (
            this.rootStore.appUIStore.title +
            ' | ' +
            (controller?.screen?.name ?? i18n.t('placeholders.missingData')) +
            ' Console'
        )
    }

    @computed get controllerConnected(): boolean {
        return !!this.selectedController && this.selectedController.isConnected
    }

    @computed get connectionEstablished(): boolean {
        return this.controllerConnected && !!this.consoleSubscription
    }

    @computed get connectionDropped(): boolean {
        return !this.controllerConnected
    }

    @computed get commandPublishTopic(): string | undefined {
        if (!this.selectedController || !this.consoleSubscription) {
            return undefined
        }
        return this.selectedController.supportsConsoleSubscription
            ? 'console/' + this.consoleSubscription.subscriptionKey + '/out'
            : 'console/' + this.controllerId
    }

    init = async () => {
        try {
            if (this.isConnecting) {
                // Ignore, already connecting
                throw new Error('Tried to init console connection while already connecting')
            }
            if (this.consoleSubscription) {
                // Ignore, already has active subscription
                throw new Error('Tried to init while subscription is already active')
            }
            runInAction('updateConnecting', () => {
                this.isConnecting = true
            })
            if (!this.selectedController?.screen?.id) {
                throw new Error('No screen id')
            }
            if (this.selectedController?.supportsConsoleSubscription) {
                // Topics are managed through a brokered subscription
                const { subscriptionKey, timeout: ttl } = await consoleRouter.subscribeToConsole(
                    this.selectedController?.screen.id
                )
                const topics = subscriptionTopics('console', subscriptionKey)

                const subscription = new MqttSubscription({
                    topics,
                    subscriptionKey,
                    callback: data => {
                        this.showMessage(data.value)
                    },
                })
                    .setTTL(ttl)
                    .setKeepAliveCommand(ConsoleCommand.ping)
                    .setOnClose(() =>
                        this.rootStore.mqttStore.publish(topics[1], [{ command: ConsoleCommand.stopConsoleFeed }])
                    )
                    .subscribe()

                runInAction('updateSubscription', () => {
                    this.consoleSubscription = subscription
                })
            } else {
                // Does not support console subscription, connection is direct
                const topic = 'console/' + this.controllerId
                const subscriptionKey = uuid()

                const subscription = new MqttSubscription({
                    topics: [topic],
                    subscriptionKey,
                    callback: data => {
                        this.showMessage(data.value)
                    },
                }).subscribe()

                runInAction('updateSubscription', () => {
                    this.consoleSubscription = subscription
                })
            }

            this.handleFocusCommandInput()
        } catch (error) {
            console.error(error)
        } finally {
            runInAction('updateConnecting', () => {
                this.isConnecting = false
            })
        }
    }

    handleFocusCommandInput = () => {
        if (!this.popout) {
            return
        }
        const commandInput = this.popout.child?.document.getElementById(this.controllerId + '-console-input')
        commandInput?.focus()
    }

    handleFocusPopout = () => {
        // Focus command input when refocusing popout
        this.handleFocusCommandInput()
    }

    @action removeSubscription = () => {
        // Close any MQTT subscriptions
        if (this.consoleSubscription) {
            // this.rootStore.mqttStore.removeSubscription(this.subscriptionKey)
            this.consoleSubscription.unsubscribe()
        }

        this.consoleSubscription = undefined
    }

    @action removeManager = () => {
        this.removeSubscription()

        this.rootStore.consoleStore.managerMap.delete(this.objectId)
    }

    // Unimplemented for now
    clearURL = () => {}

    // Operations when showing message
    @action showMessage(jsonArray: JSON) {
        // If payload is not JSON, Amplify PubSub will kill it before this point
        if (Array.isArray(jsonArray)) {
            // Iterate over array
            for (const json of jsonArray) {
                const textLine = new TextLine(json)
                if (textLine) {
                    this.messageList = [...this.messageList, textLine]
                }
            }
        } else {
            // If not an array, ignore and throw error
            console.error('Invalid message: ' + JSON.stringify(jsonArray))
        }
    }

    @action handleCommandHistory(arrowKey: any) {
        let arrowResult: number
        switch (arrowKey) {
            case 'ArrowDown':
                // Count down 1 when "down arrow" key is pressed
                arrowResult = this.commandIndex - 1
                break
            case 'ArrowUp':
                // Count up 1 when "up arrow" key is pressed
                arrowResult = this.commandIndex + 1
                break
            default:
                return
        }

        const index = arrowResult - 1
        if (index > this.prevCommandList.length || index < -1) {
            // Prevent index from going out of bounds
            return
        }
        if (this.prevCommandList.slice()[index] !== undefined) {
            // Update the value in the command input
            this.prevCommand = this.prevCommandList[index]
            this.commandIndex = arrowResult
        } else if (index === -1) {
            // Reset the input field
            this.prevCommand = ''
            this.commandIndex = 0
        }
    }

    // Handle sending message to MQTT server
    @action handleCommandPublish = () => {
        if (!this.commandPublishTopic) {
            console.error('No command publish topic')
            return
        }
        const command = this.prevCommand || this.remoteCommand

        // Ignore empty command
        if (command === '') {
            return
        }

        // Clear console if '!' is submitted
        if (command === '!') {
            this.messageList = []
        } else {
            // Publish the command to MQTT server
            this.rootStore.mqttStore.publish(this.commandPublishTopic, [{ remoteCommand: command }])

            if (this.selectedController?.supportsConsoleSubscription) {
                // Show message in console window
                this.showMessage(([{ remoteCommand: command }] as unknown) as JSON)
            }
        }

        // Add command into prevCommand array and clear input box
        this.prevCommandList = [command, ...this.prevCommandList]
        this.remoteCommand = ''

        // Reset command index
        this.prevCommand = ''
        this.commandIndex = 0
    }

    @action updateRemoteCommand = (value: any) => {
        // Reset the prevCommand value when inputting a new command
        this.prevCommand = ''
        this.remoteCommand = value
    }
}
