import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'
import { Link } from 'mobx-router'
import views from 'src/config/views'

import i18n from 'src/i18n'

import TileHistoryManager from 'src/common/managers/TileHistoryManager'

import { popouts } from 'src/common/components/popout/popouts'
import ErrorLogTable from 'src/common/components/ErrorLogTable'
import SectionTile from '../tile-view/components/SectionTile'
import { Button } from 'reactstrap'
import CandelicLoader from 'src/common/components/CandelicLoader'
import backChevron from 'src/assets/images/backChevron.svg'
import CollapsibleSection from 'src/common/components/CollapsibleSection'

interface TileHistoryProps {
    tileHistoryManager: TileHistoryManager
}

const DEFAULT_HEADER_ROW_HEIGHT = 40
const DEFAULT_ROW_HEIGHT = 30

@inject('store')
@observer
class TileHistory extends React.Component<TileHistoryProps & { store?: RootStore }> {
    render() {
        const isHistoryFetching = this.props.tileHistoryManager.isFetching
        if (isHistoryFetching) {
            return <CandelicLoader />
        }

        const tileHistoryManager = this.props.tileHistoryManager
        const tile = tileHistoryManager.tile
        const historicalErrors = tileHistoryManager.historicalErrors
        const historicalErrorLogs = tileHistoryManager.historicalErrorLogs
        const currentErrors = tileHistoryManager.currentErrors

        const router = this.props.store!.router

        let popoutDiv
        Object.entries(popouts).forEach(entry => {
            if (entry[0] === tileHistoryManager.tile.id && entry[1].child) {
                popoutDiv = entry[1].child.document.body
            }
        })

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <div className='error-history-container'>
                <div className='tile-title'>
                    {isMobile && (
                        <Button onClick={tileHistoryManager.toggleDrawer} color='link'>
                            <img className='diagnostics-back-arrow d-md-none' src={backChevron} alt='Back' />
                        </Button>
                    )}
                    <SectionTile tile={tile} width={60} height={60} isShowingHistory />
                    <div className='tile-info horizontal-scroll-shadows hide-scrollbars'>
                        <h2>{tileHistoryManager.title}</h2>
                        <div className='tile-details'>
                            <div className='details'>
                                <h4>Section</h4>
                                <h5>{tile.section.name}</h5>
                            </div>
                            <div className='details'>
                                <h4>X</h4>
                                <h5>{tile.tileX}</h5>
                            </div>
                            <div className='details'>
                                <h4>Y</h4>
                                <h5>{tile.tileY}</h5>
                            </div>
                            <div className='details selectable-text'>
                                <h4>UID</h4>
                                <h5>{tile.displayableId}</h5>
                            </div>
                        </div>
                    </div>
                    {!tileHistoryManager.isPopout && (
                        <div className='tile-history-actions'>
                            {!isMobile && (
                                <React.Fragment>
                                    <Button
                                        className='custom-button-small mr-2'
                                        onClick={this.props.store!.tileHistoryStore.launchWindow.bind(this, tile.id)}
                                        color='primary'
                                    >
                                        Open in popout window
                                    </Button>
                                    <Link
                                        view={views.diagnosticsScreen}
                                        params={router.params}
                                        store={this.props.store!}
                                        queryParams={{ tileView: true }}
                                    >
                                        <Button
                                            className='custom-button-small'
                                            color='primary'
                                            onClick={tileHistoryManager.toggleDrawer}
                                        >
                                            Back to tile view
                                        </Button>
                                    </Link>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
                <div className='tile-history'>
                    <CollapsibleSection title={<h2>Current errors</h2>}>
                        <div
                            className='error-log-wrapper'
                            style={{
                                height: DEFAULT_HEADER_ROW_HEIGHT + DEFAULT_ROW_HEIGHT * currentErrors.length + 15, // Allow offset for horizontal scrollbar
                            }}
                        >
                            <ErrorLogTable logTableData={currentErrors} popoutDiv={popoutDiv} />
                            {currentErrors && currentErrors.length === 0 && (
                                <div className='align-middle text-center m-3'>
                                    <h2>{i18n.t('diagnosticsPage.noCurrentTileErrors')}</h2>
                                </div>
                            )}
                        </div>
                    </CollapsibleSection>
                    <h2>Error history</h2>
                    <div className='error-log-wrapper'>
                        <ErrorLogTable logTableData={historicalErrorLogs} popoutDiv={popoutDiv} />
                        {historicalErrors && historicalErrors.length === 0 && (
                            <div className='align-middle text-center m-3'>
                                <h2>{i18n.t('diagnosticsPage.noTileHistoryFound')}</h2>
                            </div>
                        )}
                        {!historicalErrors && (
                            <div className='align-middle text-center m-3'>
                                <h2>{i18n.t('diagnosticsPage.errorFetchingTileLogs')}</h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default TileHistory
