import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { Button } from 'reactstrap'
import { Icon, Popover, Position } from '@blueprintjs/core'

import { Output } from 'src/common/models/Avior'

import StatusIndicator from './components/StatusIndicator'
import OutputControl from './components/OutputControl'
import OutputSettings from './components/OutputSettings'
import backChevron from 'src/assets/images/backChevron.svg'

import moment from 'moment'
import clsx from 'clsx'

const SIGNAL_STRENGTH_LOW = 25
const SIGNAL_STRENGTH_MEDIUM = 50
const BATTERY_CHARGED_VOLTAGE = 3700
const TEMPERATURE_MEDIUM = 60
const TEMPERATURE_HIGH = 80

interface PowerManagementProps {
    store?: RootStore
}

function PowerManagement(props: PowerManagementProps) {
    const [aviorOnline, setAviorOnline] = React.useState(false)

    const [outputToEdit, setOutputToEdit] = React.useState<Output | undefined>()
    const [outputToManage, setOutputToManage] = React.useState<Output | undefined>()

    const { store } = props

    const screenDiagnosticsUIStore = store!.screenDiagnosticsUIStore
    const { hasAvior, avior, aviorOutputs, handleRebootAvior, handleForceSyncAvior } = screenDiagnosticsUIStore

    const isMobile = store!.appUIStore.isMobile

    React.useEffect(() => {
        let interval: NodeJS.Timeout
        if (hasAvior) {
            // poll 15s
            interval = setInterval(() => {
                screenDiagnosticsUIStore.fetchAviorStatus()
            }, 15 * 1000)

            screenDiagnosticsUIStore.fetchAviorStatus()
        }
        return () => clearInterval(interval)
    }, [])

    React.useEffect(() => {
        if (avior) {
            setAviorOnline(moment().diff(moment(avior.connectivity.timestamp), 'seconds') < 60)
        }
    }, [avior])

    if (!avior) {
        return null
    }

    const isWifi = avior.device.network?.toUpperCase() === 'WIFI'
    const isCellular = ['2G', '3G', '4G'].includes(avior.device.network?.toUpperCase())
    const signal = isWifi ? avior.wifi.signal : isCellular ? avior.cellular.signal : 0

    return (
        <>
            <div className='power-management'>
                <div className='d-flex p-4'>
                    {isMobile && (
                        <div className='mr-3' onClick={() => screenDiagnosticsUIStore.setIsPowerManagementOpen(false)}>
                            <img className='diagnostics-back-arrow' src={backChevron} alt='Back' />
                        </div>
                    )}
                    <h1>Power Management</h1>
                </div>
                <div className='power-grid'>
                    <div className='grid-row'>
                        <div className='grid-node'>
                            <div className='node-icon-header'>
                                <Icon icon='lightning' size={22} color='#facc15' />
                                <h3>Main Power</h3>
                            </div>
                            <div className='mt-4'>
                                {avior.main.mode === 1 ? (
                                    <>
                                        {avior.main.status === 1 ? (
                                            <p className='text-green-500'>Connected</p>
                                        ) : (
                                            <p className='text-red-500'>Not Connected</p>
                                        )}
                                    </>
                                ) : (
                                    <p>Not active!</p>
                                )}
                            </div>
                        </div>
                        <div className='grid-node'>
                            <div className='node-icon-header'>
                                <Icon icon='cell-tower' size={22} color='#60a5fa' />
                                <h3>{avior.device.network ?? 'No connection'}</h3>
                            </div>
                            <div className='mt-4'>
                                {isWifi ? (
                                    <p>
                                        <span>{avior.wifi.ssid}</span>
                                        <span> &middot; </span>
                                        <span>{avior.wifi.ip}</span>
                                    </p>
                                ) : isCellular ? (
                                    <p>
                                        {avior.cellular.active === 1 ? (
                                            <>
                                                <span>{avior.cellular.sim1.ccid}</span>
                                                <span> &middot; </span>
                                                <span>{avior.cellular.sim1.apn ?? 'No APN!'}</span>
                                            </>
                                        ) : avior.cellular.active === 2 ? (
                                            <>
                                                <span>{avior.cellular.sim2.ccid}</span>
                                                <span> &middot; </span>
                                                <span>{avior.cellular.sim2.apn ?? 'No APN!'}</span>
                                            </>
                                        ) : (
                                            <span>No sim!</span>
                                        )}
                                        {avior.cellular.operator && (
                                            <>
                                                <span> &middot; </span>
                                                <span>{avior.cellular.operator}</span>
                                            </>
                                        )}
                                    </p>
                                ) : (
                                    <p>Not active!</p>
                                )}
                                <h4>Signal</h4>
                                {signal === 0 ? (
                                    <p>Unknown</p>
                                ) : (
                                    <p
                                        className={
                                            signal < SIGNAL_STRENGTH_LOW
                                                ? 'text-red-500'
                                                : signal < SIGNAL_STRENGTH_MEDIUM
                                                ? 'text-orange-500'
                                                : 'text-green-500'
                                        }
                                    >
                                        Strength {signal}%
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='grid-row'>
                        <div className='grid-line'>
                            <div className='grid-line-edge' />
                            <div className='grid-line-inner' />
                            <div className='grid-line-edge' />
                        </div>
                        <div className='grid-line'>
                            <div className='grid-line-edge' />
                            <div className='grid-line-inner' />
                            <div className='grid-line-edge' />
                        </div>
                    </div>
                    <div className='grid-row'>
                        <div className='grid-node'>
                            <div className='avior-header'>
                                <div className='node-icon-header'>
                                    <StatusIndicator active={aviorOnline} />
                                    <h3>Avior</h3>
                                </div>
                                <Popover
                                    content={
                                        <div className='avior-popover'>
                                            <p>
                                                <span>{avior.device.id || 'Unknown'}</span>
                                                <span> &middot; </span>
                                                <span>{avior.device.firmware ?? 'Unknown'}</span>
                                            </p>
                                            <Button
                                                onClick={() => handleForceSyncAvior()}
                                                className='custom-button-small text-blue-500'
                                                outline
                                                disabled={!aviorOnline}
                                                color='primary'
                                            >
                                                <Icon className='mr-2' icon='refresh' size={15} />
                                                Force Sync
                                            </Button>
                                            <Button
                                                onClick={() => handleRebootAvior()}
                                                className='custom-button-small text-red-500 mt-3'
                                                outline
                                                disabled={!aviorOnline}
                                                color='danger'
                                            >
                                                <Icon className='mr-2' icon='power' size={15} />
                                                Reboot
                                            </Button>
                                        </div>
                                    }
                                    position={Position.BOTTOM_RIGHT}
                                    minimal
                                >
                                    <Button>
                                        <Icon icon='more' size={15} />
                                    </Button>
                                </Popover>
                            </div>
                            <div className='mt-2'>
                                <p>
                                    Last seen{' '}
                                    {moment(avior.connectivity.timestamp).isValid()
                                        ? moment(avior.connectivity.timestamp).fromNow()
                                        : 'unknown'}
                                </p>
                                <div className='node-row'>
                                    <div>
                                        <h4>Aux Power Supply</h4>
                                        {avior.aux.mode === 1 ? (
                                            <>
                                                {avior.aux.status === 1 ? (
                                                    <p className='text-green-500'>Connected</p>
                                                ) : (
                                                    <p>Not Connected</p>
                                                )}
                                            </>
                                        ) : (
                                            <p>Not active!</p>
                                        )}
                                    </div>
                                    <div>
                                        <h4>Battery</h4>
                                        {avior.battery.mode === 1 ? (
                                            <>
                                                {avior.battery.avg === 0 ? (
                                                    <p>Unknown</p>
                                                ) : avior.battery.avg < BATTERY_CHARGED_VOLTAGE ? (
                                                    <p className='text-red-500'>Low Battery</p>
                                                ) : (
                                                    <p className='text-green-500'>Battery Charged</p>
                                                )}
                                            </>
                                        ) : (
                                            <p>Not active!</p>
                                        )}
                                    </div>
                                    <div>
                                        <h4>Temperature</h4>
                                        {avior.temp.mode === 1 ? (
                                            <>
                                                {avior.temp.avg === 0 ? (
                                                    <p>Unknown</p>
                                                ) : (
                                                    <p
                                                        className={
                                                            avior.temp.avg > TEMPERATURE_HIGH
                                                                ? 'text-red-500'
                                                                : avior.temp.avg > TEMPERATURE_MEDIUM
                                                                ? 'text-orange-500'
                                                                : 'text-green-500'
                                                        }
                                                    >
                                                        Average {avior.temp.avg}°C
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p>Not active!</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid-row'>
                        {aviorOutputs &&
                            aviorOutputs.map(({ ordinal }) => (
                                <div key={ordinal} className='grid-line'>
                                    <div className='grid-line-edge' />
                                    <div className='grid-line-inner' />
                                    <div className='grid-line-edge' />
                                </div>
                            ))}
                    </div>
                    <div className='outputs'>
                        {aviorOutputs &&
                            aviorOutputs.map(output => {
                                const { ordinal, mode, name, status, polarity } = output
                                return (
                                    <div key={ordinal} className='grid-node'>
                                        <div>
                                            <div className='node-actions'>
                                                <StatusIndicator
                                                    active={polarity ? status === 0 : status === 1}
                                                    disabled={mode === 0}
                                                />
                                                <div>
                                                    <Button
                                                        onClick={() => setOutputToManage(output)}
                                                        className='custom-button text-white mr-2 p-2'
                                                        disabled={!aviorOnline || mode === 0}
                                                        color='primary'
                                                    >
                                                        <Icon icon='settings' size={15} />
                                                    </Button>
                                                    <Button
                                                        className='p-2'
                                                        onClick={() => setOutputToEdit(output)}
                                                        disabled={!aviorOnline}
                                                    >
                                                        <Icon icon='cog' size={15} />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='node-icon-header mt-4'>
                                                <h3 className={clsx('ellipsis', mode === 0 ? 'text-gray-400' : '')}>
                                                    {name ?? 'Unknown'}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>

            {outputToManage && (
                <OutputControl store={store} output={outputToManage} onClose={() => setOutputToManage(undefined)} />
            )}

            {!!outputToEdit && (
                <OutputSettings store={store} output={outputToEdit} onClose={() => setOutputToEdit(undefined)} />
            )}
        </>
    )
}

export default observer(PowerManagement)
