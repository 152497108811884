import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import DomainStore from './DomainStore'
import orgRouter from 'src/api/orgRouter'
import { action, observable, runInAction, computed } from 'mobx'
import { subscriptionTopics } from '../utils/String'

import Organisation, { OrganisationPermission } from '../models/Organisation'
import User from '../models/User'
import Screen from '../models/Screen'
import Controller from '../models/Controller'
import Location from '../models/Location'
import MqttSubscription from '../models/MqttSubscription'
import { RenewCommand } from '../models/MqttEnums'
import ScreenStatusesContainer from '../models/ScreenStatusesContainer'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import { ValueLabelPair } from '../components/SelectComponents'

enum OrgCommands {
    orgScreensUpdate = 'orgScreensUpdate',
    orgUpdate = 'orgUpdate',
}

enum OrgUpdateObjectTypes {
    organisation = 'organisation',
    user = 'user',
    screen = 'screen',
    controller = 'controller',
    location = 'location',
}

enum OrgUpdateOperations {
    create = 'create',
    update = 'update',
    delete = 'delete',
    permanentDelete = 'permanentDelete',
    restore = 'restore',
}

export default class OrganisationStore extends DomainStore<Organisation> {
    @observable isLogoUploading = false

    @observable myOrg?: Organisation

    subscriptionTTLTimer: NodeJS.Timeout | undefined

    constructor(rootStore: RootStore) {
        super(rootStore)
        this.router = orgRouter
        this.storeName = 'Organisation'
    }

    @action fetchScreenStatuses = () => {
        const me = this.rootStore.userStore.me
        if (!me || !me.organisation) {
            return
        }
        const screenStatuses = me.organisation.screenStatuses
        if (!screenStatuses) {
            return
        }
        const { subscriptionKey, subscriptionTTL: ttl } = screenStatuses
        if (!subscriptionKey || !ttl) {
            return
        }

        new MqttSubscription({
            topics: subscriptionTopics('client', subscriptionKey),
            subscriptionKey,
            callback: data => {
                const command = data.value.command
                const payload = data.value.payload
                switch (command) {
                    case OrgCommands.orgScreensUpdate:
                        if (payload && payload.id) {
                            const org = this.findItem(payload.id)
                            if (org) {
                                const { id, ...screenStatuses } = payload
                                runInAction('updateOrgScreenStatuses', () => {
                                    const newScreenStatuses = new ScreenStatusesContainer({
                                        ...screenStatuses,
                                        organisationId: id,
                                    })
                                    // Only update ScreenStatusesContainer updatedAt timestamp when isConcern state changes
                                    if (
                                        org.screenStatuses &&
                                        org.screenStatuses.isConcern === newScreenStatuses.isConcern
                                    ) {
                                        newScreenStatuses.updatedAt = org.screenStatuses.updatedAt
                                    }
                                    org.screenStatuses = newScreenStatuses
                                    this.items.forEach((item, i) => {
                                        if (item.id === id) {
                                            this.items[i] = org
                                        }
                                    })
                                })
                            }
                        }
                        break
                    case OrgCommands.orgUpdate:
                        if (payload) {
                            const { objectId, objectType, operation } = payload
                            let store
                            switch (objectType) {
                                case OrgUpdateObjectTypes.organisation:
                                    store = this.rootStore.orgStore
                                    break
                                case OrgUpdateObjectTypes.user:
                                    store = this.rootStore.userStore
                                    break
                                case OrgUpdateObjectTypes.screen:
                                    store = this.rootStore.screenStore
                                    break
                                case OrgUpdateObjectTypes.controller:
                                    store = this.rootStore.controllerStore
                                    break
                                case OrgUpdateObjectTypes.location:
                                    store = this.rootStore.locationStore
                                    break
                                default:
                                    break
                            }
                            if (store) {
                                this.handleOrgUpdateItem(operation, objectId, store)
                            }
                        }
                        break
                    default:
                        break
                }
            },
        })
            .setTTL(ttl)
            .setKeepAliveCommand(RenewCommand.renewOrganisationsSubscription)
            .setOnStale(() => this.refresh(subscriptionKey, me.showDeletedItems))
            .subscribe()
    }

    @action handleOrgUpdateItem = async (
        operation: OrgUpdateOperations,
        objectId: string,
        store: DomainStore<Organisation | User | Screen | Controller | Location>
    ) => {
        switch (operation) {
            case OrgUpdateOperations.create:
            case OrgUpdateOperations.update:
            case OrgUpdateOperations.restore:
                try {
                    const item = await store.fetchById(objectId)
                    if (item) {
                        runInAction('update' + store.storeName, () => {
                            const index = store.items.findIndex(c => c.id === item.id)
                            if (index !== -1) {
                                store.items[index] = item
                            } else {
                                store.items.push(item)
                            }
                        })
                    }
                } catch (error) {
                    console.error('Error updating item: ' + objectId, error)
                }
                break
            case OrgUpdateOperations.delete:
            case OrgUpdateOperations.permanentDelete:
                const itemToDelete = store.findItem(objectId)
                if (itemToDelete) {
                    store.deleteItems([itemToDelete], operation === OrgUpdateOperations.permanentDelete)
                }
                break
            default:
                break
        }
    }

    async setMyOrg(orgId: string) {
        try {
            let org = this.findItem(orgId)
            if (!org) {
                org = await this.fetchById(orgId)
            }
            if (!org) {
                throw new Error('Could not set user org')
            }
            runInAction('setMyOrg', () => {
                this.myOrg = org
            })
            const me = this.rootStore.userStore.me
            if (me && !me.isSuperUser) {
                this.addItemsToList([org, ...(org.associatedByOrganisations || [])], false)
            }
            // Initialise screen statuses subscription to receive ScreenStatus updates
            this.fetchScreenStatuses()
            return Promise.resolve()
        } catch (error) {
            console.error('Error setting user org', error)
            throw error
        }
    }

    @computed get availableAssociatedByOrganisations(): Organisation[] | undefined {
        const me = this.rootStore.userStore.me
        if (!me || !this.myOrg) {
            return undefined
        }

        if (me.isSuperUser) {
            return this.items
                .slice()
                .filter(o => o.deletedAt === null)
                ?.sort((a, b) => a.name.localeCompare(b.name))
        }

        // Add user's own organisation to the list of available organisations
        return [...(this.myOrg.associatedByOrganisations || []), this.myOrg]
            .filter(o => o.deletedAt === null)
            ?.sort((a, b) => a.name.localeCompare(b.name))
    }

    @computed get availableAssociatedByOrganisationsOptions(): ValueLabelPair[] | undefined {
        return this.availableAssociatedByOrganisations?.map(org => ({ value: org.id, label: org.name }))
    }

    @computed get availableAssociatedByOrganisationsOptionsGroupedByCountry(): Array<{
        label: string
        options: ValueLabelPair[]
    }> {
        return Object.entries(
            this.availableAssociatedByOrganisations?.reduce(
                (acc, org) => ({
                    ...acc,
                    [org.country]: [...(acc[org.country] || []), org],
                }),
                {}
            ) ?? {}
        ).map(([country, orgs]: [string, Organisation[]]) => ({
                label: country,
                options: orgs.map(org => ({ value: org.id, label: org.name })),
            }))
    }

    @computed get writeOrgs(): Organisation[] | undefined {
        if (this.rootStore.userStore.me?.isSuperUser) {
            return this.items.slice().sort((a, b) => a.name.localeCompare(b.name))
        }

        return this.availableAssociatedByOrganisations?.filter(
            org => org === this.myOrg || org.permissions === OrganisationPermission.write
        )
    }

    @computed get hasMultipleWriteOrgs(): boolean {
        return this.rootStore.userStore.me?.isSuperUser || (!!this.writeOrgs && this.writeOrgs.length > 1)
    }

    @computed get hasMultipleOrgsAssigned(): boolean {
        if (this.rootStore.userStore.me?.isSuperUser) {
            return true
        }
        for (const item of this.rootStore.locationStore.items) {
            if (!item.isOwned) {
                return true
            }
        }
        return false
    }

    @computed get hasMultipleOpsOrganisations(): boolean {
        return (
            this.rootStore.userStore.me?.isSuperUser ||
            !!(this.myOrg?.opsOrganisations && this.myOrg.opsOrganisations?.length > 0)
        )
    }

    @computed get hasAssociatedByOrganisations(): boolean {
        return !!(this.myOrg?.associatedByOrganisations && this.myOrg.associatedByOrganisations?.length > 0)
    }

    @computed get associatedOrgsNames(): string | undefined {
        if (!this.myOrg || !this.myOrg.associatedOrganisations) {
            return undefined
        }

        return this.myOrg.associatedOrganisations.map(organisation => organisation.name).join(', ')
    }

    @computed get associatedByOrgsNames(): string | undefined {
        if (!this.myOrg || !this.myOrg.associatedByOrganisations) {
            return undefined
        }

        return this.myOrg.associatedByOrganisations.map(organisation => organisation.name).join(', ')
    }

    @action deleteItems(itemsToDelete: Organisation[]) {
        for (const item of itemsToDelete) {
            this.rootStore.locationStore.deleteItems(item.locations)
            this.rootStore.userStore.deleteItems(item.users)
        }
        super.deleteItems(itemsToDelete)
    }

    @action uploadNewLogo = async (file: File) => {
        const me = this.rootStore.userStore.me
        if (!me) {
            return
        }

        try {
            this.isLogoUploading = true
            const { uploadURL, fileURL } = await orgRouter.fetchImageURL(me.organisationId, file)
            const response = await orgRouter.uploadLogo(uploadURL, file)
            if (!response.ok) {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUploadingImage'),
                    intent: Intent.DANGER,
                })
                throw Error()
            }
            orgRouter.setLogo(me.organisationId, fileURL)
            // Refresh org for user
            const org = await this.rootStore.orgStore.fetchById(me.organisationId)
            runInAction('refreshOrg', () => {
                this.myOrg = org
                this.isLogoUploading = false
            })
            AppToaster.show({
                message: i18n.t('feedback.successes.imageUploadedSuccessfully'),
                intent: Intent.SUCCESS,
            })
        } catch (error) {
            console.error(error)
        }
    }
}
