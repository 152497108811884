import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import DiagnosticsOverview from './components/DiagnosticsOverview'
import ScreenTable from './components/ScreenTable'
import ScreenSummary from './components/ScreenSummary'
import ScreenTabViews from './components/ScreenTabViews'
import PowerManagement from './submodules/power-management/container'
import TileView from './submodules/tile-view/container'
import Console from './submodules/console/container'
import ErrorHistory from './submodules/error-history/container'
import TileHistory from './submodules/tile-error-history/container'
import { Drawer } from '@blueprintjs/core'

@inject('store')
@observer
export default class ScreenDiagnostics extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        screenDiagnosticsUIStore.initialiseSubscription()

        const isMobile = this.props.store!.appUIStore.isMobile
        if (isMobile) {
            // Show collapsed view by default on mobile
            screenDiagnosticsUIStore.screenCurrentErrorsManager.toggleCompactView()
        }
    }

    render() {
        const currentConsoleManager = this.props.store!.consoleStore.currentDrawerManager
        const currentErrorHistoryManager = this.props.store!.errorHistoryStore.currentDrawerManager
        const currentTileHistoryManager = this.props.store!.tileHistoryStore.currentDrawerManager
        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        const currentScreen = screenDiagnosticsUIStore.currentScreen
        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <React.Fragment>
                <div className='screen-diagnostics-container'>
                    <div
                        className={
                            'diagnostics-screen-sidebar ' +
                            (!screenDiagnosticsUIStore.currentScreen ? 'show-mobile' : 'hide-mobile')
                        }
                    >
                        <DiagnosticsOverview />
                        <ScreenTable />
                    </div>
                    <div className={'screen-main-view ' + (currentScreen ? 'show-mobile ' : 'hide-mobile ')}>
                        <ScreenSummary />
                        <ScreenTabViews />
                    </div>
                </div>
                {/* Tile view */}
                <Drawer
                    isOpen={this.props.store!.tileViewUIStore.tileViewOpen}
                    size='100%'
                    portalClassName='custom-drawer custom-drawer-with-nav'
                    canOutsideClickClose={false}
                >
                    <TileView />
                </Drawer>
                {/* Console */}
                {currentConsoleManager && (
                    <Drawer
                        isOpen={currentConsoleManager.isDrawerOpen}
                        size='100%'
                        portalClassName='custom-drawer tile-view-drawer'
                        canOutsideClickClose={false}
                    >
                        <Console controllerId={currentConsoleManager.objectId} />
                    </Drawer>
                )}
                {/* Error history */}
                {currentErrorHistoryManager && (
                    <Drawer
                        isOpen={currentErrorHistoryManager.isDrawerOpen}
                        size={!isMobile ? '80%' : '100%'}
                        portalClassName='custom-drawer custom-drawer-with-nav'
                        onClose={currentErrorHistoryManager.toggleDrawer}
                    >
                        <ErrorHistory errorHistoryManager={currentErrorHistoryManager} />
                    </Drawer>
                )}
                {/* Tile history */}
                {currentTileHistoryManager && (
                    <Drawer
                        isOpen={currentTileHistoryManager.isDrawerOpen}
                        size={!isMobile ? '80%' : '100%'}
                        portalClassName='custom-drawer custom-drawer-with-nav'
                        onClose={currentTileHistoryManager.toggleDrawer}
                    >
                        <TileHistory tileHistoryManager={currentTileHistoryManager} />
                    </Drawer>
                )}
                {/* Power management */}
                <Drawer
                    isOpen={screenDiagnosticsUIStore.isPowerManagementOpen}
                    size={!isMobile ? '800px' : '100%'}
                    portalClassName='custom-drawer custom-drawer-with-nav'
                    onClose={() => screenDiagnosticsUIStore.setIsPowerManagementOpen(false)}
                >
                    <PowerManagement store={this.props.store} />
                </Drawer>
            </React.Fragment>
        )
    }
}
