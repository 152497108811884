import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import { rootStore } from '../RootStore'
import DomainStore from '../stores/DomainStore'

import Organisation from './Organisation'
import Screen from './Screen'
import { ScreenState } from './ScreenState'

import { TileType } from './Tile'
import moment, { Moment } from 'moment'

class ScreenUpsert {
    static newScreenUpsert(): ScreenUpsert {
        return new ScreenUpsert({
            name: '',
            tilesWide: 0,
            tilesHigh: 0,
            tileWidth: 0,
            tileHeight: 0,
            tileType: null,
            aviorId: undefined,
            aviorPassword: undefined,
            enabled: true,
            isTest: false,
            resolution: '',
            organisationId: '',
            contentOwnerId: '',
            operationsManagerId: '',
        })
    }

    static get store(): DomainStore<Screen> | undefined {
        return rootStore.screenStore
    }

    @observable id?: string
    @observable name: string
    @observable tilesWide: number
    @observable tilesHigh: number
    @observable tileWidth: number
    @observable tileHeight: number
    @observable tileType: TileType | null
    @observable aviorId?: string
    @observable aviorPassword?: string
    @observable enabled: boolean
    @observable isTest: boolean
    @observable resolution?: string
    @observable organisationId?: string
    @observable contentOwnerId?: string | null
    @observable operationsManagerId?: string | null
    @observable readonly updatedAt?: Moment
    @observable readonly createdAt?: Moment
    @observable readonly deletedAt?: Moment | null

    constructor(json: ScreenUpsertJSON) {
        this.id = json.id
        this.name = json.name
        this.tilesWide = json.tilesWide
        this.tilesHigh = json.tilesHigh
        this.tileWidth = json.tileWidth
        this.tileHeight = json.tileHeight
        this.tileType = json.tileType
        this.aviorId = json.aviorId
        this.aviorPassword = json.aviorPassword
        this.enabled = json.enabled
        this.isTest = json.isTest
        this.resolution = json.resolution
        this.organisationId = json.organisationId
        this.contentOwnerId = json.contentOwnerId
        this.operationsManagerId = json.operationsManagerId
        this.updatedAt = moment(json.updatedAt)
        this.createdAt = moment(json.createdAt)
        this.deletedAt = json.deletedAt ? moment(json.deletedAt) : null
    }

    @computed get hasCompletedDataFields(): boolean {
        return (
            this.name !== '' &&
            this.tilesWide !== 0 &&
            this.tilesHigh !== 0 &&
            !!this.tileType &&
            (this.tileType !== TileType.unknown || (this.tileWidth > 0 && this.tileHeight > 0))
        )
    }

    @computed get organisation(): Organisation | undefined {
        return this.organisationId
            ? rootStore.orgStore.findItem(this.organisationId)
            : rootStore.adminUIStore.selectedOrg
    }

    @computed get contentOwner(): Organisation | undefined {
        const me = rootStore.userStore.me
        const myOrg = rootStore.orgStore.myOrg

        if (!me || !myOrg || !this.contentOwnerId) {
            return undefined
        }

        if (me.isSuperUser) {
            return rootStore.orgStore.findItem(this.contentOwnerId)
        } else if (myOrg.id === this.contentOwnerId) {
            return myOrg
        } else {
            return myOrg.associatedOrganisations?.find(organisation => organisation.id === this.contentOwnerId)
        }
    }

    @computed get operationsManager(): Organisation | undefined {
        const me = rootStore.userStore.me
        const myOrg = rootStore.orgStore.myOrg

        if (!me || !myOrg || !this.operationsManagerId) {
            return undefined
        }

        if (me.isSuperUser) {
            return rootStore.orgStore.findItem(this.operationsManagerId)
        } else if (myOrg.id === this.operationsManagerId) {
            return myOrg
        } else {
            return myOrg.associatedOrganisations?.find(organisation => organisation.id === this.operationsManagerId)
        }
    }

    @computed get isOwned(): boolean {
        if (!this.organisation) {
            return false
        }
        return rootStore.authStore.isAdminsOrg(this.organisationId)
    }

    toJSON(): ScreenUpsertJSON {
        const json = {} as ScreenUpsertJSON
        json.id = this.id
        json.organisationId = this.organisationId || this.organisation?.id
        json.name = this.name
        json.tilesWide = this.tilesWide
        json.tilesHigh = this.tilesHigh
        json.tileWidth = this.tileWidth
        json.tileHeight = this.tileHeight
        json.tileType = this.tileType
        json.aviorId = this.aviorId
        json.aviorPassword = this.aviorPassword
        json.enabled = this.enabled
        json.isTest = this.isTest
        if (this.contentOwnerId !== '') {
            json.contentOwnerId = this.contentOwnerId
        }
        if (this.operationsManagerId !== '') {
            json.operationsManagerId = this.operationsManagerId
        }
        return json
    }

    toScreen(): Screen {
        return new Screen({
            id: '',
            name: this.name,
            controllerId: '',
            resolution: '',
            state: ScreenState.unknown,
            tilesWide: this.tilesWide,
            tilesHigh: this.tilesHigh,
            tileWidth: this.tileWidth,
            tileHeight: this.tileHeight,
            tileType: this.tileType,
            aviorId: this.aviorId,
            updatedAt: this.updatedAt,
            createdAt: this.createdAt,
            deletedAt: this.deletedAt,
        })
    }
}

export type ScreenUpsertJSON = Omit<
    ScreenUpsert,
    'toJSON' | 'toScreen' | 'hasCompletedDataFields' | 'organisation' | 'contentOwner' | 'operationsManager' | 'isOwned'
>

export default ScreenUpsert
