import { observable, computed } from 'mobx'
import BaseModel from './BaseModel'
import { Omit } from 'react-router'
import Organisation, { OrganisationPermission } from './Organisation'
import { rootStore } from '../RootStore'
import Controller from './Controller'
import DomainStore from '../stores/DomainStore'

class Location extends BaseModel {
    static newLocation(): Location {
        return new Location({
            name: '',
            country: '',
            zipCode: '',
            address: '',
            organisationId: '',
            contentOwnerId: '',
            operationsManagerId: '',
        })
    }

    static get store(): DomainStore<Location> {
        return rootStore.locationStore
    }

    @observable name: string
    @observable zipCode: string
    @observable country: string
    @observable address?: string
    @observable organisationId: string
    @observable contentOwnerId?: string | null
    @observable operationsManagerId?: string | null

    constructor(json: LocationJSON) {
        super(json)
        this.organisationId = json.organisationId
        this.name = json.name
        this.zipCode = json.zipCode
        this.country = json.country
        this.address = json.address
        this.contentOwnerId = json.contentOwnerId
        this.operationsManagerId = json.operationsManagerId
    }

    @computed get hasCompletedDataFields(): boolean {
        return this.country !== '' && this.zipCode !== '' && this.name !== ''
    }

    @computed get organisation(): Organisation | undefined {
        return rootStore.orgStore.findItem(this.organisationId)
    }

    @computed get contentOwner(): Organisation | undefined {
        const me = rootStore.userStore.me
        const myOrg = rootStore.orgStore.myOrg

        if (!me || !myOrg || !this.contentOwnerId) {
            return undefined
        }

        if (me.isSuperUser) {
            return rootStore.orgStore.findItem(this.contentOwnerId)
        } else if (myOrg.id === this.contentOwnerId) {
            return myOrg
        } else {
            return myOrg.associatedOrganisations?.find(organisation => organisation.id === this.contentOwnerId)
        }
    }

    @computed get operationsManager(): Organisation | undefined {
        const me = rootStore.userStore.me
        const myOrg = rootStore.orgStore.myOrg

        if (!me || !myOrg || !this.operationsManagerId) {
            return undefined
        }

        if (me.isSuperUser) {
            return rootStore.orgStore.findItem(this.operationsManagerId)
        } else if (myOrg.id === this.operationsManagerId) {
            return myOrg
        } else {
            return myOrg.associatedOrganisations?.find(organisation => organisation.id === this.operationsManagerId)
        }
    }

    @computed get controller(): Controller | undefined {
        // NOTE: Assumes a 1:1 relationship between location and controller
        return rootStore.controllerStore.items.find(controller => controller.locationId === this.id)
    }

    @computed get isOwned(): boolean {
        return rootStore.authStore.isAdminsOrg(this.organisation?.id)
    }

    @computed get canEdit(): boolean {
        const organisation = rootStore.orgStore.findItem(this.organisationId)
        return this.isOwned || organisation?.permissions === OrganisationPermission.write
    }

    toJSON(includeDates?: boolean): LocationJSON {
        const json = super.toJSON(includeDates) as LocationJSON
        json.organisationId = this.organisationId
        json.name = this.name
        json.zipCode = this.zipCode
        json.country = this.country
        json.address = this.address
        if (this.contentOwnerId !== '') {
            json.contentOwnerId = this.contentOwnerId
        }
        if (this.operationsManagerId !== '') {
            json.operationsManagerId = this.operationsManagerId
        }
        return json
    }
}

export type LocationJSON = Omit<
    Location,
    | 'toJSON'
    | 'isValid'
    | 'hasCompletedDataFields'
    | 'organisation'
    | 'contentOwner'
    | 'operationsManager'
    | 'controller'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
>

export default Location
