import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import DisplayImageStats from 'src/common/models/DisplayImageStats'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Row, Col, Table, Form, Label, Button, Input, InputGroup } from 'reactstrap'
import { Drawer, Position } from '@blueprintjs/core'
import CandelicLoader from 'src/common/components/CandelicLoader'
import clearIcon from 'src/assets/images/clearIcon.svg'
import playsIcon from 'src/assets/images/playsIcon.svg'
import resolutionIcon from 'src/assets/images/resolutionIcon.svg'
import screensIcon from 'src/assets/images/screensIcon.svg'
import timeIcon from 'src/assets/images/timeIcon.svg'
import viewsIcon from 'src/assets/images/viewsIcon.svg'
import imageBroken from 'src/assets/images/imageBroken.svg'
import Select from 'react-select'
import { DropdownIndicator, Option, ValueLabelPair } from 'src/common/components/SelectComponents'
import moment from 'moment'
import 'moment-duration-format'

@inject('store')
@observer
class ImageSummaryDrawer extends React.Component<{ store?: RootStore } & WithNamespaces> {
    toggleImageSummaryDrawer = () => {
        this.props.store!.reportsUIStore.toggleImageSummaryDrawer()
    }

    handleExportNameUpdate = (event: any) => {
        this.props.store!.reportsUIStore.updateExportName(event.target.value)
    }

    handleExportOptionChange = (selectedOption: ValueLabelPair) => {
        this.props.store!.reportsUIStore.updateExportOption(selectedOption)
    }

    handleCreateReport = () => {
        this.props.store!.reportsUIStore.toggleExportReportDialog()
        this.props.store!.reportsUIStore.requestSummaryReport()
    }

    reportSummaryScreenRow = (displayImageStats: DisplayImageStats) => {
        if (displayImageStats) {
            // Convert total and average time into moment object
            const totalTime = moment.duration(displayImageStats.totalTime, 'seconds')
            const averageTime = moment.duration(displayImageStats.averageTime, 'seconds')

            const screen = this.props.store!.screenStore.findItem(displayImageStats.screenId!)

            return (
                <tr className='custom-summary-table-row' key={displayImageStats.screenId}>
                    <td className='p-0'>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={5} className='align-middle table-title'>
                                        {screen && screen.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle'>
                                        <img src={playsIcon} alt='Play count' height={10} />{' '}
                                        {displayImageStats.totalPlays} plays
                                    </td>
                                    <td className='align-middle'>
                                        <img src={timeIcon} alt='Play time' height={13} />
                                        Total time {totalTime.format('H[h] m[m] s[s]')} / {averageTime.format('s[s]')}{' '}
                                        {i18n.t('common.average')}
                                    </td>
                                    <td className='align-middle'>
                                        <img src={viewsIcon} alt='Last played' height={7.6} />
                                        Last played at{' '}
                                        {moment(displayImageStats.lastPlayed).format(
                                            this.props.store!.userStore.me?.dateTimeFormat
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
        } else {
            return null
        }
    }

    handleImageResponseError = (event: any) => {
        // Prevent infinte loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
    }

    reportSummaryImageRow = (displayImageId: string) => {
        const displayImage = this.props.store!.displayImageStore.findItem(displayImageId)

        if (displayImage) {
            return (
                <div key={displayImage.id} className='custom-summary-modal-content'>
                    <div className='custom-summary-image-container'>
                        <img
                            className='modal-image'
                            src={displayImage.thumbnailURL ? displayImage.thumbnailURL : imageBroken}
                            alt={displayImage.displayableFilename}
                            onError={this.handleImageResponseError}
                        />
                        <span className='modal-image-title align-top'>{displayImage.displayableFilename}</span>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    singleImageTotalsResolution = (displayImageId: string) => {
        const displayImage = this.props.store!.displayImageStore.findItem(displayImageId)

        if (displayImage) {
            return displayImage.resolution
        } else {
            return null
        }
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }
        const isDrawerOpen = this.props.store!.reportsUIStore.imageSummaryDrawerOpen
        const displayImageReportSummary = this.props.store!.reportsUIStore.displayImageReportSummary
        const newSearchParams = this.props.store!.reportsUIStore.newSearchParams

        return (
            <Drawer
                isOpen={isDrawerOpen}
                size='100%'
                portalClassName='custom-drawer report-summary-drawer'
                canOutsideClickClose={false}
                position={Position.TOP}
            >
                {!displayImageReportSummary ? (
                    <CandelicLoader />
                ) : (
                    <div className='custom-summary-modal-body custom-container'>
                        <img
                            className='report-summary-modal-close'
                            onClick={this.toggleImageSummaryDrawer}
                            src={clearIcon}
                            alt='Close'
                        />
                        <div className='image-summary-modal-scope-container'>
                            <div className='image-summary-modal-scope'>
                                <span className='image-summary-modal-scope-label'>
                                    {i18n.t('reportsPage.reportRange')}
                                </span>
                                <span>
                                    {newSearchParams.startDate
                                        ? newSearchParams.startDate.format(me.dateFormat)
                                        : undefined}{' '}
                                    -{' '}
                                    {newSearchParams.stopDate
                                        ? newSearchParams.stopDate.format(me.dateFormat)
                                        : undefined}
                                </span>
                            </div>
                            <div className='image-summary-modal-scope'>
                                <span className='image-summary-modal-scope-label'>{i18n.t('reportsPage.screens')}</span>
                                <span>
                                    {this.props.store!.reportsUIStore.selectedScreenNames.length > 0
                                        ? this.props.store!.reportsUIStore.selectedScreenNames.length +
                                          i18n.t('reportsPage.screensSelected')
                                        : i18n.t('common.all') + i18n.t('reportsPage.screensSelected')}
                                </span>
                            </div>
                        </div>
                        {!this.props.store!.reportsUIStore.isSingleImageSummary && (
                            <div className='image-summary-export'>
                                <Form inline onSubmit={eventPreventDefault}>
                                    <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-inverted'>
                                        <Label className='custom-label custom-label-inline align-middle'>
                                            <h6>{i18n.t('reportsPage.reportName')}</h6>
                                        </Label>
                                        {/* TODO: Clear input after report generated */}
                                        <Input
                                            className='image-summary-export-input'
                                            onChange={this.handleExportNameUpdate}
                                            type='text'
                                        />
                                    </InputGroup>
                                    <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-inverted'>
                                        <Label className='custom-label custom-label-inline align-middle'>
                                            <h6>{i18n.t('common.format')}</h6>
                                        </Label>
                                        <Select
                                            className='custom-select-wrapper'
                                            classNamePrefix='custom-select'
                                            isSearchable={false}
                                            blurInputOnSelect
                                            onChange={this.handleExportOptionChange}
                                            options={this.props.store!.reportsUIStore.exportOptions}
                                            value={this.props.store!.reportsUIStore.selectedExportOption}
                                            components={{ DropdownIndicator, Option }}
                                        />
                                    </InputGroup>
                                    <Button
                                        onClick={this.handleCreateReport}
                                        className='image-summary-export-submit'
                                        color='primary'
                                        disabled={
                                            !this.props.store!.reportsUIStore.selectedExportName ||
                                            !this.props.store!.reportsUIStore.selectedExportOption
                                        }
                                    >
                                        {i18n.t('actions.create')}
                                    </Button>
                                </Form>
                            </div>
                        )}
                        <Row>
                            <Col md={6} xs={12}>
                                <div className='custom-summary-modal-content'>
                                    <div className='image-summary-totals align-middle'>
                                        <span className='image-summary-totals-title'>{i18n.t('common.total')}</span>
                                        <img src={playsIcon} alt='Total plays' height={10} />
                                        <span>{displayImageReportSummary.totals.totalPlays} plays</span>
                                        {this.props.store!.reportsUIStore.isSingleImageSummary && (
                                            <React.Fragment>
                                                <img src={resolutionIcon} alt='Resolution' height={13} />
                                                <span>
                                                    {this.props.store!.reportsUIStore.summaryReportIds!.map(
                                                        (displayImageId: string) =>
                                                            this.singleImageTotalsResolution(displayImageId)
                                                    )}
                                                </span>
                                            </React.Fragment>
                                        )}
                                        <img src={screensIcon} alt='Screens' height={8} />
                                        <span>
                                            {displayImageReportSummary.totals.totalScreens}{' '}
                                            {i18n.t('reportsPage.screens')}
                                        </span>
                                        <img src={timeIcon} alt='Total time' height={13} />
                                        <span>
                                            Total time{' '}
                                            {moment
                                                .duration(displayImageReportSummary.totals.totalTime, 'seconds')
                                                .format('H[h] m[m] s[s]')}{' '}
                                            /{' '}
                                            {moment
                                                .duration(displayImageReportSummary.totals.averageTime, 'seconds')
                                                .format('s[s]')}{' '}
                                            {i18n.t('common.average')}
                                        </span>
                                        {this.props.store!.reportsUIStore.isSingleImageSummary && (
                                            <React.Fragment>
                                                <img src={viewsIcon} alt='Last played' height={7.6} />
                                                <span>
                                                    {moment(displayImageReportSummary.totals.lastPlayed).format(
                                                        me.dateTimeFormat
                                                    )}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                <div className='custom-summary-table ml-auto mr-auto'>
                                    <Table responsive>
                                        <tbody>
                                            {displayImageReportSummary.screens.map(reportSummaryScreen =>
                                                this.reportSummaryScreenRow(reportSummaryScreen)
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className='custom-summary-modal-image-row-container'>
                                    {this.props.store!.reportsUIStore.summaryReportIds!.map((displayImageId: string) =>
                                        this.reportSummaryImageRow(displayImageId)
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Drawer>
        )
    }
}

export default withNamespaces()(ImageSummaryDrawer)
