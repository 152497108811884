import AdminStoreItem from './AdminStoreItem'
import Screen from 'src/common/models/Screen'
import { action, computed, runInAction } from 'mobx'
import RootStore from 'src/common/RootStore'
import { TileType } from 'src/common/models/Tile'
import ScreenUpsert from 'src/common/models/ScreenUpsert'
import screenRouter from 'src/api/screenRouter'

import i18n from 'src/i18n'
import { AppToaster } from 'src/common/components/AppToaster'
import { Intent } from '@blueprintjs/core'

export default class AdminStoreScreen extends AdminStoreItem<Screen> {
    upsertItem: ScreenUpsert

    constructor(rootStore: RootStore) {
        super()
        this.store = rootStore.screenStore
    }

    @computed get isTileTypeUnknown(): boolean {
        return this.upsertItem?.tileType === TileType.unknown
    }

    // Override base createItem with upsert
    @action createItem = async (): Promise<Screen> => {
        if (!this.upsertItem) {
            Promise.reject('No item to create')
        }
        const newItem = await screenRouter.upsert(this.upsertItem)
        AppToaster.show({
            message: i18n.t('feedback.successes.successfullyCreatedNew') + i18n.t('stores.Screen'),
            intent: Intent.SUCCESS,
        })
        runInAction('createScreen', () => {
            this.store.items.push(newItem)
        })

        return newItem
    }

    // Override base updateItem with upsert
    @action updateItem = async (): Promise<Screen> => {
        if (!this.upsertItem) {
            Promise.reject('No item to update')
        }
        const updatedItem = await screenRouter.upsert(this.upsertItem)
        AppToaster.show({
            message: i18n.t('feedback.successes.successfullyUpdated') + i18n.t('stores.Screen'),
            intent: Intent.SUCCESS,
        })
        runInAction('updateScreen', () => {
            const index = this.store.items.findIndex(c => c.id === updatedItem.id)
            if (index !== -1) {
                this.store.items[index] = updatedItem
            }
        })
        return updatedItem
    }

    @action setNewItem = () => {
        this.item = Screen.newScreen()
        this.upsertItem = ScreenUpsert.newScreenUpsert()
    }

    @action setItemToUpdate = (screen: Screen) => {
        this.item = new Screen(screen)
        this.upsertItem = new ScreenUpsert(this.item!.toScreenUpsertJSON())
    }

    @action updateItemForm = (key: string, value: any) => {
        // Form was updated
        this.formUpdated = true
        switch (key) {
            case 'name':
                this.upsertItem!.name = value
                break
            case 'tilesWide':
                this.upsertItem!.tilesWide = Number(value)
                break
            case 'tilesHigh':
                this.upsertItem!.tilesHigh = Number(value)
                break
            case 'tileWidth':
                this.upsertItem!.tileWidth = Number(value)
                break
            case 'tileHeight':
                this.upsertItem!.tileHeight = Number(value)
                break
            case 'tileType':
                this.upsertItem!.tileType = value
                if (value === undefined) {
                    // Invalidate tile dimensions if select cleared
                    this.upsertItem!.tileWidth = 0
                    this.upsertItem!.tileHeight = 0
                }
                break
            case 'contentOwner':
                this.upsertItem!.contentOwnerId = value
                break
            case 'operationsManager':
                this.upsertItem!.operationsManagerId = value
                break
            case 'enabled':
                this.upsertItem!.enabled = value
                break
            case 'isTest':
                this.upsertItem!.isTest = value
                break
            case 'aviorId':
                this.upsertItem!.aviorId = value
                break
            case 'aviorPassword':
                this.upsertItem!.aviorPassword = value
                break
            default:
                return
        }

        this.item = this.upsertItem.toScreen()

        const originalItemAviorId =
            this.upsertItem && this.upsertItem.id && this.store.findItem(this.upsertItem.id)?.aviorId
        const aviorFormValid =
            this.upsertItem.aviorId && this.upsertItem.aviorId !== originalItemAviorId
                ? !!this.upsertItem.aviorPassword && this.upsertItem.aviorPassword !== ''
                : true

        // If all form fields are filled, form can be submitted
        this.createItemFormValid =
            this.item!.hasCompletedDataFields && this.upsertItem.hasCompletedDataFields && aviorFormValid
    }
}
