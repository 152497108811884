import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import { rootStore } from '../RootStore'

import BaseModel from './BaseModel'
import Controller from './Controller'
import ScreenErrorGroup from './ScreenErrorGroup'
import { ScreenState } from './ScreenState'
import { LogoBoxLocation } from './LogoBoxLocation'
import { ErrorSeverity } from './DiagnosticErrorEnums'
import { severityColour } from '../utils/Colour'
import DomainStore from '../stores/DomainStore'
import { OrganisationPermission } from './Organisation'
import { TileType } from './Tile'
import { ScreenUpsertJSON } from './ScreenUpsert'

class Screen extends BaseModel {
    static newScreen(): Screen {
        return new Screen({
            name: '',
            controllerId: '',
            resolution: '',
            state: ScreenState.unknown,
            tilesWide: 0,
            tilesHigh: 0,
            tileWidth: 0,
            tileHeight: 0,
            tileType: null,
            logoBoxLocation: null,
            sortIndex: 0,
            aviorId: undefined,
        })
    }

    static get store(): DomainStore<Screen> {
        return rootStore.screenStore
    }

    @observable name: string
    @observable controllerId: string
    @observable resolution?: string
    @observable state: ScreenState
    @observable tilesWide: number
    @observable tilesHigh: number
    @observable tileWidth: number
    @observable tileHeight: number
    @observable tileType: TileType | null
    @observable logoBoxLocation?: LogoBoxLocation | null
    @observable sortIndex?: number
    @observable organisationId?: string
    @observable aviorId?: string

    constructor(json: ScreenJSON) {
        super(json)

        this.name = json.name
        this.controllerId = json.controllerId
        this.resolution = json.resolution
        this.state = json.state
        this.tilesWide = json.tilesWide
        this.tilesHigh = json.tilesHigh
        this.tileWidth = json.tileWidth
        this.tileHeight = json.tileHeight
        this.tileType = json.tileType
        this.logoBoxLocation = json.logoBoxLocation
        this.organisationId = json.organisationId
        this.aviorId = json.aviorId
    }

    @computed get controller(): Controller | undefined {
        return rootStore.controllerStore.findItem(this.controllerId)
    }

    @computed get hasCompletedDataFields(): boolean {
        return (
            this.name !== '' &&
            this.tilesWide !== 0 &&
            this.tilesHigh !== 0 &&
            !!this.tileType &&
            (this.tileType !== TileType.unknown || (this.tileWidth > 0 && this.tileHeight > 0))
        )
    }

    @computed get width(): number {
        const split = this.resolution!.split('x', 2)
        return Number(split[0])
    }

    @computed get height(): number {
        const split = this.resolution!.split('x', 2)
        return Number(split[1])
    }

    @computed get aspectRatio(): number {
        return this.width / this.height
    }

    @computed get errorGroup(): ScreenErrorGroup | undefined {
        if (!this.id || !rootStore.diagnosticsStore.errorsContainer) {
            return undefined
        }
        return rootStore.diagnosticsStore.errorsContainer.diagnosticsErrors.get(this.id)
    }

    @computed get isAllErrorsAssigned(): boolean {
        return this.errorGroup !== undefined && this.errorGroup.totalAssignedErrors === this.errorGroup.totalErrors
    }

    @computed get errorCount(): number {
        if (!this.errorGroup) {
            return 0
        }
        return this.errorGroup.totalErrors
    }

    @computed get maxSeverity(): ErrorSeverity {
        if (!this.errorGroup) {
            return ErrorSeverity.noError
        }
        return this.errorGroup.maxSeverity
    }

    @computed get maxUnassignedSeverity(): ErrorSeverity {
        if (!this.errorGroup) {
            return ErrorSeverity.noError
        }
        return this.errorGroup.maxUnassignedSeverity
    }

    @computed get severityColour(): string {
        if (!this.isConnected) {
            return severityColour(ErrorSeverity.noConnection)
        }

        return severityColour(this.maxUnassignedSeverity)
    }

    @computed get isConnected(): boolean {
        return this.controller?.isConnected ?? false
    }

    @computed get brightness(): number {
        return this.errorGroup?.brightness ?? 0
    }

    @computed get isOwned(): boolean {
        if (!this.controller) {
            return false
        }
        return rootStore.authStore.isAdminsOrg(this.controller.location?.organisation?.id)
    }

    @computed get canEdit(): boolean {
        return this.isOwned || this.controller?.location?.organisation?.permissions === OrganisationPermission.write
    }

    @computed get isEnabled(): boolean {
        return (
            !!this.controller &&
            !!this.controller.location &&
            !!this.controller.location.organisation &&
            this.controller.enabled
        )
    }

    @computed get isEnabledAndReady(): boolean {
        return this.isEnabled && !!this.errorGroup
    }

    @computed get hasAvior(): boolean {
        return !!this.aviorId
    }

    toJSON(includeDates?: boolean): ScreenJSON {
        const json = super.toJSON(includeDates) as ScreenJSON
        json.name = this.name
        json.tilesWide = this.tilesWide
        json.tilesHigh = this.tilesHigh
        json.tileWidth = this.tileWidth
        json.tileHeight = this.tileHeight
        json.tileType = this.tileType
        json.controllerId = this.controllerId
        return json
    }

    toScreenUpsertJSON(): ScreenUpsertJSON {
        return {
            id: this.id,
            name: this.name,
            tilesWide: this.tilesWide,
            tilesHigh: this.tilesHigh,
            tileWidth: this.tileWidth,
            tileHeight: this.tileHeight,
            tileType: this.tileType,
            resolution: this.resolution,
            organisationId: this.controller?.location?.organisationId || this.organisationId,
            contentOwnerId: this.controller?.location?.contentOwnerId,
            operationsManagerId: this.controller?.location?.operationsManagerId,
            enabled: this.controller ? this.controller.enabled : true,
            isTest: this.controller ? this.controller.isTest : false,
            updatedAt: this.updatedAt,
            createdAt: this.createdAt,
            deletedAt: this.deletedAt,
            aviorId: this.aviorId,
        } as ScreenUpsertJSON
    }
}

export type ScreenJSON = Omit<
    Screen,
    | 'toJSON'
    | 'toScreenUpsertJSON'
    | 'isValid'
    | 'hasCompletedDataFields'
    | 'width'
    | 'height'
    | 'aspectRatio'
    | 'controller'
    | 'errorGroup'
    | 'isAllErrorsAssigned'
    | 'errorCount'
    | 'maxSeverity'
    | 'maxUnassignedSeverity'
    | 'severityColour'
    | 'isConnected'
    | 'brightness'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
    | 'isEnabled'
    | 'isEnabledAndReady'
    | 'hasAvior'
>

export default Screen
