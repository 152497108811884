import Organisation, { OrganisationJSON } from '../common/models/Organisation'
import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'
import onAPI, { HTTPMethod } from './onAPI'

class OrgRouter extends BaseRouter<Organisation, OrganisationJSON, EmptySearchParams> {
    protected route = '/organisation'

    protected flags =
        RouterFlag.create |
        RouterFlag.read |
        RouterFlag.update |
        RouterFlag.delete |
        RouterFlag.list |
        RouterFlag.restore |
        RouterFlag.permanentlyDelete

    async fetchImageURL(orgId: string, file: File) {
        const json: any = await onAPI.fetchJSON(this.route + '/' + orgId + '/image-upload-url?filename=' + file.name)
        return json
    }

    async uploadLogo(uploadURL: string, file: File): Promise<Response> {
        try {
            const response = await fetch(uploadURL, {
                headers: {
                    'Content-Type': 'image',
                },
                method: HTTPMethod.put,
                body: file,
            })
            if (!response.ok) {
                return Promise.reject(response.statusText)
            }
            return response
        } catch (err) {
            return Promise.reject(err)
        }
    }

    async setLogo(orgId: string, fileURL: string) {
        const json: any = await onAPI.fetchJSON(this.route + '/' + orgId + '/image-url', { url: fileURL })
        return json
    }

    protected newItem(json: OrganisationJSON): Organisation {
        return new Organisation(json)
    }
}

const orgRouter = new OrgRouter()
export default orgRouter
