import moment from 'moment'
import 'moment-duration-format'

export const secondsDurationToString = (seconds: number): string => moment.duration(seconds, 'seconds').format('d[d] h[h] m[m] s[s]')

export const millisecondsDiffToString = (start: moment.Moment, end: moment.Moment): string => moment
        .duration(end.diff(start), 'ms')
        .format('w[w] d[d] H[h] m[m] s[s]')
        .split(' ')
        .slice(0, 2)
        .join(' ')
