import { observable, computed } from 'mobx'
import BaseModel from './BaseModel'
import { Omit } from 'react-router'
import Location from './Location'
import { rootStore } from '../RootStore'
import User from './User'
import Screen from './Screen'
import DomainStore from '../stores/DomainStore'
import ScreenStatusesContainer from './ScreenStatusesContainer'

export enum OrganisationPermission {
    read = 'read',
    write = 'write',
}

export type AssociatedOrgIdMap = Map<string, { permissions: OrganisationPermission }>

class Organisation extends BaseModel {
    static newOrg(): Organisation {
        return new Organisation({
            name: '',
            zipCode: '',
            country: '',
            imageURL: '',
            maxScreens: 1,
            associatedOrganisationIds: new Map<string, { permissions: OrganisationPermission }>(),
            permissions: OrganisationPermission.read,
        })
    }

    static get store(): DomainStore<Organisation> {
        return rootStore.orgStore
    }

    @observable name: string
    @observable zipCode: string
    @observable country: string
    @observable imageURL: string
    @observable maxScreens: number
    @observable associatedOrganisationIds: AssociatedOrgIdMap
    // Organisations who have been granted permissions for either diagnostics
    // or content
    @observable associatedOrganisations?: Organisation[]
    // Organisations who are the assignees of associated organisations
    @observable associatedByOrganisations?: Organisation[]
    // Organisations which have granted operations management permissions
    // on at least one location for this organisation
    @observable opsOrganisations?: Organisation[]
    @observable permissions: OrganisationPermission
    @observable screenStatuses?: ScreenStatusesContainer

    constructor(json: OrganisationJSON) {
        super(json)
        this.name = json.name
        this.zipCode = json.zipCode
        this.country = json.country
        this.imageURL = json.imageURL
        this.maxScreens = json.maxScreens
        this.associatedOrganisations = []
        if (json.associatedOrganisations) {
            for (const organisation of json.associatedOrganisations) {
                this.associatedOrganisations.push(new Organisation(organisation))
            }
        }
        this.associatedByOrganisations = []
        if (json.associatedByOrganisations) {
            for (const organisation of json.associatedByOrganisations) {
                this.associatedByOrganisations.push(new Organisation(organisation))
            }
        }
        this.opsOrganisations = []
        if (json.opsOrganisations) {
            for (const organisation of json.opsOrganisations) {
                this.opsOrganisations.push(new Organisation(organisation))
            }
        }
        this.associatedOrganisationIds = new Map<string, { permissions: OrganisationPermission }>()
        this.permissions = json.permissions
        if (this.id) {
            this.screenStatuses = new ScreenStatusesContainer({ ...json.screenStatuses, organisationId: this.id })
        }
    }

    @computed get hasCompletedDataFields(): boolean {
        return this.name !== '' && this.country !== '' && this.zipCode !== '' && this.maxScreens !== null
    }

    @computed get locations(): Location[] {
        return rootStore.locationStore.items
            .filter(item => item.organisationId === this.id)
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    @computed get screens(): Screen[] {
        return rootStore.screenStore.items.filter(item => item.controller && item.controller.location && item.controller.location.organisationId === this.id)
    }

    @computed get users(): User[] {
        return rootStore.userStore.items.filter(item => item.organisationId === this.id)
    }

    @computed get isOwned(): boolean {
        return rootStore.authStore.isAdminsOrg(this.id)
    }

    @computed get canEdit(): boolean {
        return this.isOwned || this.permissions === OrganisationPermission.write
    }

    toJSON(includeDates?: boolean): OrganisationJSON {
        const json = super.toJSON(includeDates) as OrganisationJSON
        json.name = this.name
        json.country = this.country
        json.zipCode = this.zipCode
        json.imageURL = this.imageURL
        json.maxScreens = this.maxScreens
        json.associatedOrganisationIds = this.associatedOrganisationIds
        json.screenStatuses = this.screenStatuses
        return json
    }
}

export type OrganisationJSON = Omit<
    Organisation,
    | 'toJSON'
    | 'isValid'
    | 'hasCompletedDataFields'
    | 'locations'
    | 'screens'
    | 'users'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
>

export default Organisation
