import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { ZoomLevel, startZoomLevel } from '../stores/LiveUIStore'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Form, FormGroup, Label, InputGroup } from 'reactstrap'
import Select, { ActionMeta } from 'react-select'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    ValueLabelPair,
    GroupHeading,
} from '../../../common/components/SelectComponents'
import ZoomSlider from 'src/common/components/ZoomSlider'
import Checkbox from 'src/common/components/Checkbox'
import BackToTopButton from 'src/common/components/BackToTopButton'

@inject('store')
@observer
class LiveFilters extends React.Component<{ store?: RootStore } & WithNamespaces> {
    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        this.props.store!.liveUIStore.setPageScrolled(
            window.pageYOffset >= this.props.store!.liveUIStore.PAGE_SCROLL_THRESHOLD
        )
    }

    handleZoomLevelChange = (value: number) => {
        let zoom = startZoomLevel

        switch (value) {
            case 1:
                zoom = ZoomLevel.one
                break
            case 2:
                zoom = ZoomLevel.two
                break
            case 3:
                zoom = ZoomLevel.three
                break
            case 4:
                zoom = ZoomLevel.four
                break
            default:
                return
        }

        this.props.store!.liveUIStore.changeZoomLevel(zoom)
    }

    handleUpdateLiveFilter = (
        filter: string,
        selectedOption: ValueLabelPair | ValueLabelPair[],
        actionMeta?: ActionMeta<any>
    ) => {
        this.props.store!.liveUIStore.updateFilters(filter, selectedOption)

        // Handle clearing input
        if (actionMeta?.action === 'clear') {
            this.handleFilterDropdownClosed()
        }
    }

    handleFilterDropdownClosed = () => {
        this.props.store!.liveUIStore.updateLiveSubscription()
    }

    render() {
        const liveUIStore = this.props.store!.liveUIStore
        const liveFilters = liveUIStore.filters
        const screenStore = this.props.store!.screenStore
        const resolutionOptions = liveUIStore.resolutionOptions
        // NOTE: Grouping screens by selected org to enable "select/unselect all" button
        // Ommiting group label as its redundant with single org
        const screenOptionsGrouped = liveUIStore.screenOptionsGrouped

        return (
            <Form className='refine-live-results' onSubmit={eventPreventDefault}>
                <InputGroup className='custom-input-group custom-input-group-inline'>
                    <Label className='custom-label custom-label-inline'>
                        <h5>{i18n.t('livePage.screens')}</h5>
                    </Label>
                    <Select
                        className='custom-select-wrapper'
                        classNamePrefix='custom-select'
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        onChange={this.handleUpdateLiveFilter.bind(this, 'screen')}
                        onMenuClose={this.handleFilterDropdownClosed}
                        options={screenOptionsGrouped}
                        placeholder={i18n.t('common.all')}
                        value={
                            (liveFilters?.screens?.map(screenId => {
                                const screen = screenStore.findItem(screenId)
                                return screen && { value: screenId, label: screen.name }
                            }) ?? []) as ValueLabelPair[]
                        }
                        components={{
                            ValueContainer,
                            ClearIndicator,
                            DropdownIndicator,
                            Option,
                            NoOptionsMessage,
                            GroupHeading,
                        }}
                    />
                </InputGroup>
                <InputGroup className='custom-input-group custom-input-group-inline'>
                    <Label className='custom-label custom-label-inline'>
                        <h5>{i18n.t('livePage.resolutions')}</h5>
                    </Label>
                    <Select
                        className='custom-select-wrapper'
                        classNamePrefix='custom-select'
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        onChange={this.handleUpdateLiveFilter.bind(this, 'resolution')}
                        onMenuClose={this.handleFilterDropdownClosed}
                        options={resolutionOptions}
                        value={liveUIStore.filters?.resolutions?.map(resolution => ({
                            value: resolution,
                            label: resolution,
                        }))}
                        components={{
                            ValueContainer,
                            ClearIndicator,
                            DropdownIndicator,
                            Option,
                            NoOptionsMessage,
                        }}
                    />
                </InputGroup>
                <FormGroup className='custom-form-group custom-form-group-inline checkbox-form-group'>
                    <Label className='custom-label custom-label-inline'>
                        <h5>{i18n.t('livePage.rememberFilters')}</h5>
                    </Label>
                    <Checkbox
                        isChecked={liveUIStore.rememberFilters}
                        handleOnClick={liveUIStore.toggleRememberFilters}
                    />
                </FormGroup>
                <div className='live-zoom-slider-container d-none d-md-flex'>
                    <ZoomSlider
                        handleZoomLevelChange={this.handleZoomLevelChange}
                        defaultZoomLevel={3}
                        minZoom={1}
                        maxZoom={4}
                    />
                </div>
                {this.props.store!.liveUIStore.pageScrolled && <BackToTopButton />}
            </Form>
        )
    }
}

export default withNamespaces()(LiveFilters)
