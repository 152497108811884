import * as React from 'react'

// i18n
import i18n from 'src/i18n'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import MediaManager from 'src/common/managers/MediaManager'

import { Colour } from 'src/common/utils/Colour'

import { Button, Col } from 'reactstrap'
import DatePicker from 'src/common/components/DatePicker'
import { Dialog, Boundary } from '@blueprintjs/core'
import moment from 'moment'
import 'moment-timezone'

interface ScheduleDialogProps {
    mediaManager: MediaManager
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class ScheduleDialog extends React.Component<ScheduleDialogProps & { store?: RootStore }> {
    state: {
        boundary: Boundary | undefined
        scheduledDates: [moment.Moment | undefined, moment.Moment | undefined]
    } = {
        boundary: undefined,
        scheduledDates: [undefined, undefined],
    }

    private mediaManager = this.props.mediaManager

    handleOpenDialog = () => {
        const media = this.mediaManager.openScheduleDialog
        this.setState({
            boundary: undefined,
            scheduledDates: [media ? media.startDate : undefined, media ? media.stopDate : undefined],
        })
    }

    handleCloseDialog = () => {
        const media = this.mediaManager.openScheduleDialog
        if (!media) {
            return
        }
        // Clear state on close
        this.setState({ boundary: undefined, scheduledDates: [undefined, undefined] })
        this.mediaManager.toggleScheduleDialog(media, true)
    }

    handleSetSchedule = () => {
        const media = this.mediaManager.openScheduleDialog
        if (!media) {
            return
        }
        this.mediaManager.saveScheduledDates(media, this.state.scheduledDates)
    }

    handleRangeChange = (scheduledDates: [moment.Moment | undefined, moment.Moment | undefined]) => {
        this.setState({ scheduledDates })
    }

    handleTagFocus = (boundary: Boundary | undefined) => {
        this.setState({ boundary })
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }
        const media = this.mediaManager.openScheduleDialog
        const scheduledDates = this.state.scheduledDates
        const startDate = scheduledDates[0]
        const stopDate = scheduledDates[1]
        const minDate = this.state.boundary === Boundary.END && startDate ? startDate : moment()
        const hasChanges = media && (startDate !== media.startDate || stopDate !== media.stopDate)
        const isInvalid = startDate && stopDate && (stopDate.isBefore(startDate) || startDate.isAfter(stopDate))

        return (
            <Dialog
                isOpen={!!media}
                portalContainer={this.props.playerDiv}
                usePortal={false} // Prevent locking the main UI scroll
                className='custom-dialog player-schedule-dialog'
                onOpening={this.handleOpenDialog}
                onClose={this.handleCloseDialog}
                canOutsideClickClose={false}
                title={'Schedule media (' + moment.tz(me.timeZone).format('z') + ')'}
            >
                <div className='player-schedule-dialog-content'>
                    <div className='schedule-datepicker-subheaders'>
                        <Col md={6}>
                            <h4>Start playing media</h4>
                        </Col>
                        <Col md={6}>
                            <h4>Stop playing media</h4>
                        </Col>
                    </div>
                    <div className='schedule-datepicker'>
                        <DatePicker
                            handleChange={this.handleRangeChange}
                            handleTagFocus={this.handleTagFocus}
                            startDate={startDate}
                            endDate={stopDate}
                            minDate={minDate}
                            // NOTE: A max date must be supplied or the widget defaults to end of current year
                            maxDate={moment()
                                .add(3, 'years')
                                .endOf('year')}
                            beforeSelectStartText='Now'
                            beforeSelectEndText='Indefinite'
                            startColour={Colour.success}
                            endColour={Colour.danger}
                            startOptional
                            endOptional
                            tagsInverted
                            usePopover={false}
                            disableAutoBoundary
                        />
                    </div>
                    <p>
                        {`Schedule media to start playing or to be removed after a certain date. Once an image is removed,
                        it will no longer be shown in the list. Don't forget to save your media changes after setting
                        scheduled dates for your content.`}
                    </p>
                    <div className='dialog-actions'>
                        <Button
                            onClick={this.handleSetSchedule}
                            className='custom-button-large'
                            color='primary'
                            disabled={!hasChanges || isInvalid}
                        >
                            {i18n.t('livePage.player.setSchedule')}
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default ScheduleDialog
