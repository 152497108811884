import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Media from 'src/common/models/Media'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Tooltip, Position, Intent, Popover, PopoverInteractionKind } from '@blueprintjs/core'
import imageBroken from 'src/assets/images/imageBroken.svg'
import errorDangerIcon from 'src/assets/images/errorDangerIcon.svg'
import errorWarningIcon from 'src/assets/images/errorWarningIcon.svg'
import moment from 'moment'

interface ImageCellProps {
    formatDateAdded: (lastModified: string | moment.Moment) => string | undefined
    cellData: any
    media: Media
    screenId: string
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class ImageCell extends React.Component<{ store?: RootStore } & ImageCellProps> {
    handleImageResponseError = (event: any) => {
        // Prevent infinte loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
    }

    render() {
        const media = this.props.media
        const screen = this.props.store!.screenStore.findItem(this.props.screenId)

        if (!media || !screen) {
            return null
        }

        let intent: Intent = Intent.DANGER
        let errorIconSrc: any
        let warningDetails
        if (media.aspectRatio !== screen.aspectRatio) {
            intent = Intent.DANGER
            errorIconSrc = errorDangerIcon
            warningDetails = 'Aspect ratio mismatch'
        } else if (media.dimensions !== screen.resolution) {
            intent = Intent.WARNING
            errorIconSrc = errorWarningIcon
            warningDetails = 'Size mismatch'
        }

        return (
            <Popover
                content={
                    <div className='player-img-popover-content'>
                        <div className='zoom-img checkered-background'>
                            <img
                                src={media.file ? URL.createObjectURL(media.file) : media.signedGetURL}
                                alt={media.file?.name || ''}
                                // Handles if response not 200
                                onError={this.handleImageResponseError}
                                draggable={false}
                                onDragStart={eventPreventDefault}
                            />
                        </div>
                        <div className='img-details'>
                            <h5>Last modified</h5>
                            <h4>{this.props.formatDateAdded(media.lastModified)}</h4>
                            <h5>Dimensions</h5>
                            <h4>{media.dimensions}</h4>
                        </div>
                    </div>
                }
                hoverOpenDelay={300}
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.RIGHT_TOP}
                minimal
                portalContainer={this.props.playerDiv}
            >
                <React.Fragment>
                    {warningDetails && (
                        <Tooltip
                            boundary='window'
                            content={warningDetails}
                            position={Position.TOP}
                            intent={intent}
                            portalContainer={this.props.playerDiv}
                            targetClassName='warning-img'
                        >
                            <img
                                src={errorIconSrc}
                                alt='Error'
                                height={20}
                                draggable={false}
                                onDragStart={eventPreventDefault}
                            />
                        </Tooltip>
                    )}
                    <img
                        className='media-img checkered-background'
                        src={media.file ? URL.createObjectURL(media.file) : media.signedGetURL}
                        alt={media.file?.name || ''}
                        // Handles if response not 200
                        onError={this.handleImageResponseError}
                        draggable={false}
                        onDragStart={eventPreventDefault}
                    />
                </React.Fragment>
            </Popover>
        )
    }
}

export default ImageCell
