import { observable, action, runInAction } from 'mobx'

import i18n from 'src/i18n'

import RootStore from '../RootStore'

// AWS
import Amplify, { Auth } from 'aws-amplify'
import CustomIoTProvider from '../helpers/CustomIoTProvider'

// Models
import views, { RoutePath, staticRoutes } from 'src/config/views'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'

import { config, iot } from '../../aws-config'

export default class AuthStore {
    @observable isAuthenticating = false
    @observable isFetching = false
    @observable fetchComplete = false

    @observable loadingValue: number

    @observable emailVerificationCode: string

    @observable requestedPath: { routePath: RoutePath; params: any; queryParams: any } | undefined

    @observable mfaEnabled: boolean = false
    @observable isFetchingMFAStatus: boolean = false

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        Amplify.configure(config)
        Amplify.addPluggable(new CustomIoTProvider(iot, rootStore))
    }

    @action async authenticate() {
        // Skip authentication for static routes
        if (staticRoutes.includes(this.rootStore.router.currentPath)) {
            return
        }

        this.isFetching = true
        this.fetchComplete = false
        this.loadingValue = 0

        try {
            runInAction('loadingUserProfile', () => {
                // Fetching user details from On API
                this.isAuthenticating = true
                this.loadingValue = 0.25
            })

            await this.rootStore.userStore.fetchLoggedInUser()

            runInAction('loadingUserProfileFinished', () => {
                // Authentication check has finished
                this.isAuthenticating = false
                this.loadingValue = 0.5
            })

            // Populate app data
            await this.rootStore.appStore.populateLists()

            // Continue to auth success route
            this.rootStore.router.goTo(views.authSuccess, undefined, this.rootStore)

            // Fetch MFA status
            this.fetchMFAStatus()
        } catch (err) {
            // Only show error message if legitimate, i.e. backend not available
            if (err !== 'The user is not authenticated') {
                console.error('Error signing in', err)
                AppToaster.show({ message: i18n.t('feedback.errors.errorSigningIn'), intent: Intent.DANGER })
            }

            this.rootStore.userStore.clearUser()
        } finally {
            runInAction('authenticationFinished', () => {
                this.isAuthenticating = false
                this.isFetching = false
                this.fetchComplete = true
                this.loadingValue = 1
            })
        }
    }

    @action updateRequestedPath = (routePath: RoutePath, params: any, queryParams: any) => {
        this.requestedPath = { routePath, params, queryParams }
    }

    // Sign out user
    // If isGlobal passed as true, signs out user from all devices
    signOut = async (isGlobal?: boolean) => {
        await this.forgetDevice()

        // Trigger amplify signOut function
        // NOTE: If global, you are revoking all the auth tokens(id token, access token and refresh token)
        // which means the user is signed out from all the devices
        // Although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
        Auth.signOut({ global: isGlobal })
            .then(() => {
                this.rootStore.userStore.clearUser()

                // Refresh browser to clear out React state
                window.location.reload()
            })
            // Signout failed
            .catch(err => console.error(err))
    }

    changePassword = (currentPassword: string, newPassword: string) => {
        Auth.currentAuthenticatedUser()
            .then(user => Auth.changePassword(user, currentPassword, newPassword))
            .then(() => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.passwordChangedSuccessfully'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorChangingPassword') + '. ' + i18n.t('awsErrors.' + err.code),
                    intent: Intent.DANGER,
                })
            })
    }

    isAdminsOrg(orgId?: string): boolean {
        const me = this.rootStore.userStore.me
        const myOrg = this.rootStore.orgStore.myOrg
        if (!me || !orgId || !myOrg) {
            return false
        }
        return me.isSuperUser || myOrg.id === orgId
    }

    fetchMFAStatus = async () => {
        try {
            runInAction('setIsFetchingMFAStatus', () => {
                this.isFetchingMFAStatus = true
            })
            const user = await Auth.currentAuthenticatedUser()
            const type = await Auth.getPreferredMFA(user, { bypassCache: true })
            const mfaEnabled = type === 'SOFTWARE_TOKEN_MFA'
            runInAction('updateMFAEnabled', () => {
                this.mfaEnabled = mfaEnabled
            })
        } catch (error) {
            console.error('Error fetching MFA status', error)
        } finally {
            runInAction('setIsFetchingMFAStatus', () => {
                this.isFetchingMFAStatus = false
            })
        }
    }

    setupMFA = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            const code = await Auth.setupTOTP(user)
            return code
        } catch (error) {
            console.error('Error setting up MFA', error)
            throw error
        }
    }

    verifyMFA = async (code: string) => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            await Auth.verifyTotpToken(user, code)
            await Auth.setPreferredMFA(user, 'TOTP')
            this.fetchMFAStatus()
        } catch (error) {
            console.error('Error verifying MFA', error)
            throw error
        }
    }

    removeMFA = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            await Auth.setPreferredMFA(user, 'NOMFA')
            this.fetchMFAStatus()
        } catch (error) {
            console.error('Error disabling MFA', error)
            throw error
        }
    }

    rememberDevice = async () => {
        try {
            await Auth.rememberDevice()
        } catch (error) {
            console.error('Error remembering device', error)
            throw error
        }
    }

    forgetDevice = async () => {
        try {
            await Auth.forgetDevice()
        } catch (error) {
            console.error('Error forgetting device', error)
            throw error
        }
    }
}
