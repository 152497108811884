import AdminStore from './stores/AdminStore'
import AppStore from './stores/AppStore'
import AppUIStore from './stores/AppUIStore'
import AuthStore from './stores/AuthStore'
import ConsoleStore from './stores/ConsoleStore'
import ControllerStore from './stores/ControllerStore'
import DiagnosticsStore from './stores/DiagnosticsStore'
import DisplayImageStore from './stores/DisplayImageStore'
import ErrorHistoryStore from './stores/ErrorHistoryStore'
import LiveStore from './stores/LiveStore'
import LocationStore from './stores/LocationStore'
import MediaStore from './stores/MediaStore'
import MqttStore from './stores/MqttStore'
import OrgStore from './stores/OrgStore'
import PlayerStore from './stores/PlayerStore'
import ScreenStore from './stores/ScreenStore'
import TileHistoryStore from './stores/TileHistoryStore'
import TileViewStore from './stores/TileViewStore'
import UserStore from './stores/UserStore'

import MissionControlUIStore from '../modules/mission-control/stores/MissionControlUIStore'
import AdminUIStore from '../modules/admin/stores/AdminUIStore'
import SignInUIStore from 'src/modules/auth/submodules/sign-in/stores/SignInUIStore'
import GlobalDiagnosticsUIStore from '../modules/diagnostics/global/stores/GlobalDiagnosticsUIStore'
import ScreenDiagnosticsUIStore from '../modules/diagnostics/screen/stores/ScreenDiagnosticsUIStore'
import LiveUIStore from '../modules/live/stores/LiveUIStore'
import ReportsUIStore from '../modules/reports/stores/ReportsUIStore'
import CreativesUIStore from '../modules/creatives/stores/CreativesUIStore'
import SettingsUIStore from '../modules/settings/stores/SettingsUIStore'

import TileViewUIStore from '../modules/diagnostics/screen/submodules/tile-view/stores/TileViewUIStore'

import { RouterStore } from 'mobx-router'

import { browserStorageManager } from './managers/BrowserStorageManager'

export default class RootStore {
    // Common
    adminStore: AdminStore
    appStore: AppStore
    appUIStore: AppUIStore
    authStore: AuthStore
    consoleStore: ConsoleStore
    controllerStore: ControllerStore
    diagnosticsStore: DiagnosticsStore
    displayImageStore: DisplayImageStore
    locationStore: LocationStore
    mediaStore: MediaStore
    errorHistoryStore: ErrorHistoryStore
    liveStore: LiveStore
    mqttStore: MqttStore
    orgStore: OrgStore
    playerStore: PlayerStore
    screenStore: ScreenStore
    tileHistoryStore: TileHistoryStore
    tileViewStore: TileViewStore
    userStore: UserStore

    // Modules
    missionControlUIStore: MissionControlUIStore
    adminUIStore: AdminUIStore
    signInUIStore: SignInUIStore
    globalDiagnosticsUIStore: GlobalDiagnosticsUIStore
    screenDiagnosticsUIStore: ScreenDiagnosticsUIStore
    liveUIStore: LiveUIStore
    reportsUIStore: ReportsUIStore
    creativesUIStore: CreativesUIStore
    settingsUIStore: SettingsUIStore

    // Submodules
    tileViewUIStore: TileViewUIStore

    // Router
    router: any

    constructor() {
        // Run migrations on app creation
        browserStorageManager.runMigrations()

        // Common
        this.adminStore = new AdminStore(this)
        this.appStore = new AppStore(this)
        this.appUIStore = new AppUIStore(this)
        this.authStore = new AuthStore(this)
        this.consoleStore = new ConsoleStore(this)
        this.controllerStore = new ControllerStore(this)
        this.diagnosticsStore = new DiagnosticsStore(this)
        this.displayImageStore = new DisplayImageStore(this)
        this.locationStore = new LocationStore(this)
        this.mediaStore = new MediaStore(this)
        this.errorHistoryStore = new ErrorHistoryStore(this)
        this.liveStore = new LiveStore(this)
        this.mqttStore = new MqttStore(this)
        this.orgStore = new OrgStore(this)
        this.playerStore = new PlayerStore(this)
        this.screenStore = new ScreenStore(this)
        this.tileHistoryStore = new TileHistoryStore(this)
        this.tileViewStore = new TileViewStore(this)
        this.userStore = new UserStore(this)

        // Modules
        this.missionControlUIStore = new MissionControlUIStore(this)
        this.adminUIStore = new AdminUIStore(this)
        this.signInUIStore = new SignInUIStore(this)
        this.globalDiagnosticsUIStore = new GlobalDiagnosticsUIStore(this)
        this.screenDiagnosticsUIStore = new ScreenDiagnosticsUIStore(this)
        this.liveUIStore = new LiveUIStore(this)
        this.reportsUIStore = new ReportsUIStore(this)
        this.creativesUIStore = new CreativesUIStore(this)
        this.settingsUIStore = new SettingsUIStore(this)

        // Submodules
        this.tileViewUIStore = new TileViewUIStore(this)

        // Router
        this.router = new RouterStore()
    }

    resetRouterQueryParams = (view: any) => {
        this.router.goTo(view, this.router.params, this)
    }
}

export const rootStore = new RootStore()
