import * as React from 'react'

import { copyToClipboard } from '../utils/Clipboard'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// mobx
import { observer, inject } from 'mobx-react'
import { Link } from 'mobx-router'
import RootStore from '../RootStore'
import views from '../../config/views'

// models
import { navRoutes, RoutePath } from '../../config/views'
import User from '../models/User'
import Organisation from '../models/Organisation'
import Screen from '../models/Screen'

import { ScreenTabName } from 'src/modules/diagnostics/screen/stores/ScreenDiagnosticsUIStore'
import { SettingsPageName, SettingsTabName } from 'src/modules/settings/stores/SettingsUIStore'

// UI imports
import OverviewTags from './OverviewTags'
import {
    Navbar,
    Nav,
    NavItem,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Button,
    Input,
} from 'reactstrap'
import { Drawer, Position, Popover, Overlay, Classes, Icon } from '@blueprintjs/core'
import onLogo from '../../assets/images/onLogo.svg'
import menuIcon from '../../assets/images/menuIcon.svg'
import chevronDown from '../../assets/images/chevronDown.svg'
import closeIcon from '../../assets/images/closeIcon.svg'
import searchIcon from '../../assets/images/searchIcon.svg'
import FormGroup from 'reactstrap/lib/FormGroup'
import matchSorter from 'match-sorter'

const { REACT_APP_CANDELIC_ORG_ID: CANDELIC_ORG_ID } = process.env

@inject('store')
@observer
class AppNavbar extends React.Component<{ store?: RootStore } & WithNamespaces> {
    private router = this.props.store!.router

    onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
            // Prevent browser hotkey
            event.preventDefault()
            this.props.store!.appUIStore.toggleOrgSelection()
        }
    }

    componentDidMount = () => {
        window.addEventListener('keydown', this.onKeyDown)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.onKeyDown)
    }

    componentDidUpdate = () => {
        if (this.props.store!.userStore.me?.firstLogin) {
            // Open welcome popover if first session
            this.props.store!.appUIStore.openWelcomePopover()
        }
    }

    closeMobileNav = () => {
        this.props.store!.appUIStore.closeMobileSidenav()
    }

    handleDismissWelcomePopover = () => {
        this.props.store!.appUIStore.closeWelcomePopover()
    }

    navItemForRole = (routePath: RoutePath, me: User | null) => {
        if (!me) {
            return null
        }
        const route = navRoutes.get(routePath)
        const params = {
            org: this.router.params?.org,
        }

        const currentView = this.router.currentView

        if (me.userRole.allowedPaths.includes(routePath)) {
            return (
                <NavItem key={routePath}>
                    <Link view={route} store={this.props.store!} params={params}>
                        <div
                            className={
                                'nav-link' +
                                (!route.subroute
                                    ? currentView && currentView.rootPath === routePath
                                        ? ' active'
                                        : ''
                                    : ' nav-sublink' +
                                      (currentView && currentView.path === route.path ? ' active' : ''))
                            }
                            onClick={this.closeMobileNav}
                        >
                            <h3>{i18n.t('titles.' + route.title)}</h3>
                        </div>
                    </Link>
                </NavItem>
            )
        } else {
            return null
        }
    }

    welcomePopoverContent = (me: User | null, manualURL: string): JSX.Element | undefined => {
        if (!me) {
            return undefined
        }
        return (
            <div className='welcome-popover-content'>
                <h3>Welcome to ON, {me.name}!</h3>
                <p>
                    If you need assistance, our manual can be found by clicking the user dropdown. You can also view and
                    update your profile details from this dropdown by clicking My Profile.
                </p>
                <div className='welcome-buttons'>
                    <a href={manualURL} target='_blank' rel='noopener noreferrer'>
                        <Button
                            onClick={this.handleDismissWelcomePopover}
                            className='custom-button-link custom-button-link-sm custom-button-link-black mr-2'
                            color='link'
                        >
                            <span>View manual</span>
                        </Button>
                    </a>
                    <Button onClick={this.handleDismissWelcomePopover} className='custom-button-small' color='primary'>
                        Got it
                    </Button>
                </div>
            </div>
        )
    }

    handleChangeOrg = (org: string, screenId?: string) => {
        this.props.store!.appUIStore.toggleOrgSelection()
        this.router.goTo(
            screenId ? views.diagnosticsScreen : this.router.currentView,
            screenId ? { org, screen: screenId, tab: ScreenTabName.current } : { org },
            this.props.store,
            this.router.queryParams
        )
    }

    handleOrgSearchTermChange = (event: any) => {
        this.props.store!.appUIStore.updateOrgSearchTerm(event.target.value)
    }

    render() {
        const me = this.props.store!.userStore.me
        const myOrg = this.props.store!.orgStore.myOrg

        if (!me || !myOrg || this.props.store!.authStore.isFetching) {
            return null
        }

        const manualURL = process.env.REACT_APP_ON_ONBOARDING_DOCS ?? ''
        const routePaths = [
            RoutePath.missionControl,
            RoutePath.diagnostics,
            RoutePath.live,
            RoutePath.reports,
            RoutePath.creatives,
            RoutePath.admin,
        ]
        const mobilePaths = [
            RoutePath.missionControl,
            RoutePath.diagnostics,
            RoutePath.diagnosticsScreen,
            RoutePath.diagnosticsGlobal,
            RoutePath.live,
            RoutePath.reports,
            RoutePath.creatives,
            RoutePath.admin,
        ]
        const gitSHA = process.env.REACT_APP_CURRENT_GIT_SHA

        const appUIStore = this.props.store!.appUIStore
        const isMobile = appUIStore.isMobile

        const orgSearchResults: Organisation[] = matchSorter(
            this.props.store?.orgStore.availableAssociatedByOrganisations?.sort((a, b) =>
                a.id === me.organisationId ? -1 : b.id === me.organisationId ? 1 : 0
            ),
            appUIStore.orgSearchTerm && appUIStore.orgSearchTerm.length >= 3 && appUIStore.orgSearchTerm,
            {
                keys: [
                    {
                        threshold: matchSorter.rankings.EQUAL,
                        key: 'id',
                    },
                    {
                        key: 'name',
                        threshold: matchSorter.rankings.ACRONYM,
                    },
                    {
                        key: (organisation: Organisation) => organisation.screens.map(i => i.id),
                        threshold: matchSorter.rankings.EQUAL,
                    },
                    {
                        key: (organisation: Organisation) => organisation.screens.map(i => i.name),
                        threshold: matchSorter.rankings.CONTAINS,
                    },
                ],
            }
        )
        const orgOptions = orgSearchResults.map(org => ({ value: org.id, label: org.name }))

        const selectedOrg = orgOptions?.find(o => o.value === this.router?.params?.org)

        const recommendMFA =
            this.props.store!.userStore.me?.organisationId === CANDELIC_ORG_ID &&
            !this.props.store!.authStore.isFetchingMFAStatus &&
            !this.props.store!.authStore.mfaEnabled

        const notifications = [
            ...(recommendMFA
                ? [
                      {
                          view: views.settings,
                          params: {
                              org: this.router.params?.org,
                              page: SettingsPageName.myProfile,
                              tab: SettingsTabName.details,
                          },
                          message: 'MFA not enabled',
                      },
                  ]
                : []),
        ]

        const isDemoMode = this.props.store!.settingsUIStore.isDemoMode

        return (
            <React.Fragment>
                <div className='application-navbar'>
                    <Navbar className='navbar' expand='md'>
                        <div className='navbar-brand-links'>
                            {/* Burger menu */}
                            <Popover
                                isOpen={isMobile && appUIStore.isWelcomePopoverOpen}
                                content={this.welcomePopoverContent(me, manualURL!)}
                                autoFocus
                                hasBackdrop
                                backdropProps={{ className: 'welcome-popover-backdrop' }}
                                popoverClassName='welcome-popover'
                                position={Position.BOTTOM}
                            >
                                <NavLink className='navbar-toggle' onClick={appUIStore.toggleMobileSidenav}>
                                    <img src={menuIcon} alt='Menu icon' height={12} width={20} />
                                    {notifications.length > 0 && (
                                        <span className='ml-2 bp3-tag bp3-round bp3-intent-danger'>
                                            {notifications.length}
                                        </span>
                                    )}
                                </NavLink>
                            </Popover>
                            {/* On Logo */}
                            <Link className='navbar-brand' view={views.home} store={this.props.store!}>
                                <img src={onLogo} alt='ON Logo' />
                            </Link>
                            {/* Desktop nav links */}
                            <Nav className='d-none d-md-flex' navbar>
                                {routePaths.map(routePath => this.navItemForRole(routePath, me))}
                            </Nav>
                        </div>
                        {/* Organisation selection */}
                        {this.router.currentView !== views.missionControl &&
                            this.router.currentView !== views.settings &&
                            this.props.store!.orgStore.hasMultipleOpsOrganisations && (
                                <Nav className='d-md-flex navbar-org-selection' navbar>
                                    <div
                                        className={
                                            'custom-dropdown-toggle navbar-org-dropdown-toggle' +
                                            (!isDemoMode && selectedOrg ? ' active' : '')
                                        }
                                        onClick={() => appUIStore.toggleOrgSelection()}
                                    >
                                        <div className='navbar-org-dropdown-title'>
                                            {isDemoMode ? (
                                                <h2>Organisation</h2>
                                            ) : (
                                                <h2>{selectedOrg?.label || i18n.t('common.organisation')}</h2>
                                            )}
                                        </div>
                                        <div className='custom-dropdown-chevron'>
                                            <img
                                                style={{
                                                    transform: appUIStore.isOrgSelectionOpen ? 'rotate(180deg)' : '',
                                                }}
                                                src={chevronDown}
                                                alt='Toggle'
                                            />
                                        </div>
                                    </div>
                                </Nav>
                            )}
                        {/* Navbar user section */}
                        <Nav className='navbar-user d-none d-md-flex ml-auto' navbar>
                            {myOrg.imageURL && (
                                <div className='org-logo'>
                                    <img className='img-fluid' src={myOrg.imageURL} alt='Organisation Logo' />
                                </div>
                            )}
                            <Popover
                                isOpen={!isMobile && appUIStore.isWelcomePopoverOpen}
                                content={this.welcomePopoverContent(me, manualURL!)}
                                autoFocus
                                hasBackdrop
                                backdropProps={{ className: 'welcome-popover-backdrop' }}
                                popoverClassName='welcome-popover'
                                position={Position.BOTTOM}
                            >
                                <Dropdown isOpen={appUIStore.isUserNavOpen} toggle={appUIStore.toggleUserNav}>
                                    <DropdownToggle className='custom-dropdown-toggle'>
                                        <h6>
                                            {me.name}
                                            {notifications.length > 0 && (
                                                <span className='ml-2 bp3-tag bp3-round bp3-intent-danger'>
                                                    {notifications.length}
                                                </span>
                                            )}
                                        </h6>
                                        <div className='custom-dropdown-chevron'>
                                            <img
                                                style={{
                                                    transform: appUIStore.isUserNavOpen ? 'rotate(180deg)' : '',
                                                }}
                                                src={chevronDown}
                                                alt='Toggle'
                                            />
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu className='custom-dropdown-menu' right>
                                        {notifications.length > 0 &&
                                            notifications.map((notification, i) => (
                                                <Link
                                                    key={i}
                                                    view={notification!.view}
                                                    params={notification!.params}
                                                    store={this.props.store!}
                                                >
                                                    <DropdownItem className='text-danger'>
                                                        <Icon icon='warning-sign' size={15} className='mr-2' />
                                                        {notification!.message}
                                                    </DropdownItem>
                                                </Link>
                                            ))}
                                        {notifications.length > 0 && <DropdownItem divider />}
                                        <Link
                                            view={views.settings}
                                            store={this.props.store!}
                                            params={{
                                                org: this.router.params?.org,
                                                page: SettingsPageName.myProfile,
                                                tab: SettingsTabName.details,
                                            }}
                                        >
                                            <DropdownItem>{i18n.t('titles.MyProfile')}</DropdownItem>
                                        </Link>
                                        {(me.isSuperUser || me.isAdmin) && (
                                            <Link
                                                view={views.settings}
                                                store={this.props.store!}
                                                params={{
                                                    org: this.router.params?.org,
                                                    page: SettingsPageName.organisationProfile,
                                                }}
                                            >
                                                <DropdownItem>{i18n.t('titles.OrganisationProfile')}</DropdownItem>
                                            </Link>
                                        )}
                                        <a href={manualURL} target='_blank' rel='noopener noreferrer'>
                                            <DropdownItem>{i18n.t('titles.Manual')}</DropdownItem>
                                        </a>
                                        <DropdownItem divider />
                                        <Link
                                            view={views.auth}
                                            store={this.props.store!}
                                            queryParams={{ signOut: true }}
                                        >
                                            <DropdownItem className='text-danger'>
                                                {i18n.t('actions.signOut')}
                                            </DropdownItem>
                                        </Link>
                                        {/* Show Git SHA for internal testing */}
                                        {me.isSuperUser && gitSHA && (
                                            <DropdownItem
                                                onClick={copyToClipboard.bind(this, gitSHA)}
                                                className='text-muted'
                                            >
                                                SHA {gitSHA}
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </Popover>
                        </Nav>
                    </Navbar>
                </div>
                {/* Show mobile nav drawer if open */}
                <Drawer
                    isOpen={isMobile && appUIStore.mobileSidenavOpen}
                    onClose={appUIStore.toggleMobileSidenav}
                    position={Position.LEFT}
                    size={Drawer.SIZE_LARGE}
                    portalClassName='custom-drawer mobile-nav-drawer'
                >
                    <div className='mobile-nav-header'>
                        <img onClick={appUIStore.toggleMobileSidenav} src={closeIcon} alt='Close icon' height={15} />
                        {/* On Logo */}
                        <Link className='navbar-brand' view={views.home} store={this.props.store!}>
                            <div onClick={this.closeMobileNav}>
                                <img src={onLogo} alt='ON Logo' />
                            </div>
                        </Link>
                    </div>
                    {/* Mobile nav links */}
                    <Nav className='mobile-nav-navlinks'>
                        {mobilePaths.map(routePath => this.navItemForRole(routePath, me))}
                    </Nav>
                    <Nav className='mobile-nav-user-navlinks'>
                        <div className='nav-link'>
                            <h6>{me.name}</h6>
                        </div>
                        {notifications.length > 0 &&
                            notifications.map((notification, i) => (
                                <Link
                                    key={i}
                                    view={notification!.view}
                                    params={notification!.params}
                                    store={this.props.store!}
                                >
                                    <div className='nav-link' onClick={this.closeMobileNav}>
                                        <h3 className='text-danger'>
                                            <Icon icon='warning-sign' size={15} className='mr-2' />
                                            {notification!.message}
                                        </h3>
                                    </div>
                                </Link>
                            ))}
                        <hr />
                        <NavItem>
                            <Link
                                view={views.settings}
                                store={this.props.store!}
                                params={{ page: SettingsPageName.myProfile, tab: SettingsTabName.details }}
                            >
                                <div
                                    className={
                                        'nav-link' +
                                        (this.props.store!.router.params &&
                                        this.props.store!.router.params.page === SettingsPageName.myProfile
                                            ? ' active'
                                            : '')
                                    }
                                    onClick={this.closeMobileNav}
                                >
                                    <h3>{i18n.t('titles.MyProfile')}</h3>
                                </div>
                            </Link>
                        </NavItem>
                        {(me.isSuperUser || me.isAdmin) && (
                            <NavItem>
                                <Link
                                    view={views.settings}
                                    store={this.props.store!}
                                    params={{ page: SettingsPageName.organisationProfile }}
                                >
                                    <div
                                        className={
                                            'nav-link' +
                                            (this.props.store!.router.params &&
                                            this.props.store!.router.params.page ===
                                                SettingsPageName.organisationProfile
                                                ? ' active'
                                                : '')
                                        }
                                        onClick={this.closeMobileNav}
                                    >
                                        <h3>{i18n.t('titles.OrganisationProfile')}</h3>
                                    </div>
                                </Link>
                            </NavItem>
                        )}
                        <NavItem>
                            <a className='nav-link' href={manualURL} target='_blank' rel='noopener noreferrer'>
                                <h3>{i18n.t('titles.Manual')}</h3>
                            </a>
                        </NavItem>
                        <NavItem>
                            <Link view={views.auth} store={this.props.store!} queryParams={{ signOut: true }}>
                                <h3 className='nav-link text-danger'>{i18n.t('actions.signOut')}</h3>
                            </Link>
                        </NavItem>
                    </Nav>
                </Drawer>
                {/* Select org overlay */}
                <Overlay
                    isOpen={appUIStore.isOrgSelectionOpen}
                    onClose={() => appUIStore.toggleOrgSelection()}
                    className={Classes.OVERLAY_SCROLL_CONTAINER + ' custom-overlay navbar-org-select-overlay'}
                >
                    <div className='navbar-org-select-menu'>
                        <FormGroup className='custom-form-group custom-search'>
                            <img src={searchIcon} alt='Search' className='custom-search-icon' />
                            <Input
                                onChange={this.handleOrgSearchTermChange}
                                type='search'
                                placeholder={i18n.t('actions.searchOrgScreen')}
                                autoComplete='off'
                                autoFocus
                                value={appUIStore.orgSearchTerm || ''}
                            />
                        </FormGroup>
                        <div
                            className='navbar-org-grid'
                            style={{
                                gridTemplateRows: `repeat(${Math.ceil(orgOptions.length / 3)}, 1fr)`,
                            }}
                        >
                            {orgOptions?.length > 0 ? (
                                orgOptions.map(option => {
                                    const org = !!option.value && this.props.store!.orgStore.findItem(option.value)
                                    if (!org || !org.id) {
                                        return null
                                    }
                                    return (
                                        <div
                                            key={org.id}
                                            className='navbar-org-grid-item'
                                            onClick={() => this.handleChangeOrg(org.id!)}
                                        >
                                            <div className='org-title-details'>
                                                <h2>{org.name}</h2>
                                                <OverviewTags
                                                    statuses={org.screenStatuses}
                                                    showWarnings
                                                    showNonErrors
                                                />
                                            </div>
                                            {org &&
                                                org.screens.length > 0 &&
                                                appUIStore.orgSearchTerm &&
                                                appUIStore.orgSearchTerm.length >= 3 && (
                                                    <>
                                                        {matchSorter(org.screens, appUIStore.orgSearchTerm, {
                                                            keys: [
                                                                {
                                                                    threshold: matchSorter.rankings.EQUAL,
                                                                    key: 'id',
                                                                },
                                                                {
                                                                    threshold: matchSorter.rankings.CONTAINS,
                                                                    key: 'name',
                                                                },
                                                            ],
                                                        }).map((screen: Screen) => (
                                                            <span
                                                                key={screen.id}
                                                                className='bp3-tag bp3-interactive screen-tag'
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    this.handleChangeOrg(org.id!, screen.id)
                                                                }}
                                                            >
                                                                {screen.name}
                                                            </span>
                                                        ))}
                                                    </>
                                                )}
                                        </div>
                                    )
                                })
                            ) : (
                                <span className='navbar-org-select-no-results'>{i18n.t('common.noResults')}</span>
                            )}
                        </div>
                    </div>
                </Overlay>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(AppNavbar)
