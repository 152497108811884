import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'
import onAPI, { HTTPMethod } from './onAPI'

import { rootStore } from 'src/common/RootStore'
import { runInAction } from 'mobx'

import Screen, { ScreenJSON } from '../common/models/Screen'
import { LogoBoxLocation } from 'src/common/models/LogoBoxLocation'
import ScreenUpsert from 'src/common/models/ScreenUpsert'
import Location from 'src/common/models/Location'
import Controller from 'src/common/models/Controller'

class ScreenRouter extends BaseRouter<Screen, ScreenJSON, EmptySearchParams> {
    protected route = '/screen'

    protected flags =
        RouterFlag.create |
        RouterFlag.read |
        RouterFlag.update |
        RouterFlag.delete |
        RouterFlag.list |
        RouterFlag.restore |
        RouterFlag.permanentlyDelete

    async populateScreenConfiguration(screenId: string) {
        const json: any = await onAPI.fetchJSON('/screen-configuration/' + screenId)
        return json
    }

    async setLogoBoxLocation(screenId: string, location: LogoBoxLocation | null) {
        const json: any = await onAPI.fetchJSON('/screen-configuration/' + screenId + '/logo-box-location', {
            logoBoxLocation: location,
        })
        return json
    }

    protected newItem(json: ScreenJSON): Screen {
        return new Screen(json)
    }

    protected updateHierarchy(json: any) {
        const { controller, ...screen } = json
        const { location, ...rest } = controller
        runInAction('pushRelatedScreenItems', () => {
            rootStore.controllerStore.items.push(new Controller(rest))
            rootStore.locationStore.items.push(new Location(location))
        })
        return this.newItem({
            ...screen,
            organisationId: controller.location.organisationId,
            controllerId: controller.id,
        })
    }

    async upsert(item: ScreenUpsert): Promise<any> {
        const returnedJSON: any = await onAPI.fetchJSON('/screen-hierarchy', item.toJSON())
        return this.updateHierarchy(returnedJSON)
    }

    // Override base read route
    async read(id: string): Promise<Screen> {
        const returnedJSON: any = await onAPI.fetchJSON('/screen-hierarchy/' + id)
        return this.updateHierarchy(returnedJSON)
    }

    // Override base delete route
    async delete(item: Screen): Promise<boolean> {
        return onAPI.fetchSuccess(HTTPMethod.delete, '/screen-hierarchy/' + item.id)
    }

    // Override base restore route
    async restore(item: Screen): Promise<boolean> {
        return onAPI.fetchSuccess(HTTPMethod.post, '/screen-hierarchy/' + item.id + '/restore')
    }

    // Override base permanently delete route
    async permanentlyDelete(item: Screen): Promise<boolean> {
        return onAPI.fetchSuccess(HTTPMethod.delete, '/screen-hierarchy/' + item.id + '/permanent')
    }

    async avior(id: string, domain: string, payload?: string): Promise<any> {
        const json: any = await onAPI.fetchJSON('/screen-hierarchy/' + id + '/avior', {
            domain,
            payload,
        })
        return json
    }
}

const screenRouter = new ScreenRouter()
export default screenRouter
