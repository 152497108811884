import * as React from 'react'

import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import DiagnosticsErrorGroup from 'src/common/models/DiagnosticsErrorGroup'
import { ErrorSeverity } from 'src/common/models/DiagnosticErrorEnums'
import { eventStopPropagation } from 'src/common/utils/EventUtils'

import { Button } from 'reactstrap'
import { Icon } from '@blueprintjs/core'

interface GroupNameAssignCellProps {
    errorGroup: DiagnosticsErrorGroup
}

@inject('store')
@observer
class GroupNameAssignCell extends React.Component<{ store?: RootStore } & GroupNameAssignCellProps> {
    handleAssignGroup = (event: any) => {
        eventStopPropagation(event)
        this.props.store!.diagnosticsStore.assignDiagnosticsErrorGroup(this.props.errorGroup)
    }

    render() {
        const errorGroup = this.props.errorGroup
        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <div className='group-name-assign-cell'>
                <h6>{i18n.t('diagnosticsPage.errorNames.' + errorGroup.error)}</h6>
                {[ErrorSeverity.warning, ErrorSeverity.criticalWarning].includes(errorGroup.severity) &&
                    errorGroup.totalErrors !== errorGroup.totalAssignedErrors && (
                        <Button
                            className='custom-button-link custom-button-link-sm'
                            onClick={this.handleAssignGroup}
                            color='link'
                        >
                            {!isMobile ? <span>Assign all outstanding</span> : <Icon icon='confirm' iconSize={16} />}
                        </Button>
                    )}
            </div>
        )
    }
}

export default GroupNameAssignCell
