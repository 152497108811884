import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import moment from 'moment'

import BaseModel from './BaseModel'
import ErrorData from './ErrorData'
import DiagnosticsErrorGroup from './DiagnosticsErrorGroup'
import { rootStore } from '../RootStore'
import { ErrorSource, ErrorSeverity, ErrorCode } from './DiagnosticErrorEnums'
import DiagnosticsErrorLog, { DiagnosticsErrorEvent } from './DiagnosticsErrorLog'
import { LogTableData } from '../components/ErrorLogTable'
import Controller from './Controller'

export enum ErrorItemSource {
    controller = 'controller',
    adapter = 'adapter',
    screen = 'screen',
    tile = 'tile',
}

class DiagnosticsError extends BaseModel implements LogTableData {
    @observable adapterId?: string
    @observable controllerId: string
    @observable screenId?: string
    @observable tileId?: string
    @observable data?: ErrorData
    @observable time: moment.Moment
    @observable isAssigned: boolean
    @observable group?: DiagnosticsErrorGroup

    @observable logs?: DiagnosticsErrorLog[]

    // conform to LogTableData
    event: DiagnosticsErrorEvent = DiagnosticsErrorEvent.unresolved
    private pCode: ErrorCode
    private pSeverity: ErrorSeverity
    private pSource?: ErrorSource

    constructor(json: DiagnosticsErrorJSON, group?: DiagnosticsErrorGroup) {
        super(json)

        this.group = group
        this.adapterId = json.adapterId
        this.controllerId = json.controllerId
        this.screenId = json.screenId
        this.tileId = json.tileId
        this.data = json.data
        this.time = moment(json.time)
        this.isAssigned = json.isAssigned
        this.pCode = json.code
        this.pSeverity = json.severity
        this.pSource = json.source
        if (json.logs) {
            this.logs = []
            for (const log of json.logs) {
                this.logs.push(new DiagnosticsErrorLog(log, this))
            }
        }
    }

    @computed get code(): ErrorCode {
        return this.pCode || (this.group ? this.group.error : ErrorCode.unknown)
    }

    @computed get severity(): ErrorSeverity {
        return this.pSeverity || (this.group ? this.group.severity : ErrorSeverity.noError)
    }

    @computed get source(): ErrorSource {
        return this.pSource || (this.group ? this.group.source : ErrorSource.cloud)
    }

    @computed get controller(): Controller | undefined {
        return rootStore.controllerStore.findItem(this.controllerId)
    }

    @computed get associatedId(): string {
        if (this.tileId) {
            return this.tileId
        } else if (this.screenId) {
            return this.screenId
        } else if (this.adapterId) {
            return this.adapterId
                .replace('{', '')
                .replace('}', '')
                .toLowerCase()
        } else {
            return this.controllerId
        }
    }

    @computed get displayableTileId(): string {
        if (this.tileId) {
            return this.tileId
                .split(/-(.+)/)[1]
                .replace(/-/g, '')
                .match(/.{1,8}/g)!
                .join('-')
        }
        return ''
    }

    @computed get hash(): string {
        return (this.code || this.group!.error) + ':' + this.associatedId
    }

    @computed get errorSource(): ErrorItemSource | undefined {
        if (this.tileId) {
            return ErrorItemSource.tile
        } else if (this.screenId || this.controllerId) {
            return ErrorItemSource.screen
        } else if (this.adapterId) {
            return ErrorItemSource.adapter
        }
        return undefined
    }

    @computed get errorLabel(): string | undefined {
        if (this.tileId) {
            if (!rootStore.screenDiagnosticsUIStore.currentScreenConfiguration) {
                return undefined
            }
            return (
                rootStore.screenDiagnosticsUIStore.currentScreenConfiguration?.tileForId(this.tileId)?.tnl ??
                this.tileId
            )
        } else if (this.screenId) {
            return rootStore.screenStore.findItem(this.screenId)?.name ?? this.screenId
        } else if (this.adapterId) {
            if (!rootStore.screenDiagnosticsUIStore.currentScreenConfiguration) {
                return undefined
            }
            return rootStore.screenDiagnosticsUIStore.currentScreenConfiguration.friendlyNameForAdapter(this.adapterId)
        } else if (this.controllerId) {
            return rootStore.controllerStore.findItem(this.controllerId)?.name ?? this.controllerId
        }
        return undefined
    }
}

export type DiagnosticsErrorJSON = Omit<
    DiagnosticsError,
    'controller' | 'associatedId' | 'displayableTileId' | 'hash' | 'errorSource' | 'errorLabel'
>

export default DiagnosticsError
