import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import ErrorHistoryManager from 'src/common/managers/ErrorHistoryManager'
import { DiagnosticsErrorEvent } from 'src/common/models/DiagnosticsErrorLog'

import { popouts } from 'src/common/components/popout/popouts'
import ErrorLogTable from 'src/common/components/ErrorLogTable'
import ErrorStatusIcon from '../../components/ErrorStatusIcon'
import { Button } from 'reactstrap'
import CandelicLoader from 'src/common/components/CandelicLoader'
import backChevron from 'src/assets/images/backChevron.svg'

interface ErrorHistoryProps {
    errorHistoryManager: ErrorHistoryManager
}

@inject('store')
@observer
class ErrorHistory extends React.Component<ErrorHistoryProps & { store?: RootStore }> {
    private error = this.props.errorHistoryManager.diagnosticsError

    render() {
        const screenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration

        if (!screenConfiguration || !this.error) {
            return null
        }

        const errorHistoryManager = this.props.errorHistoryManager

        const isHistoryFetching = errorHistoryManager.isFetching
        if (isHistoryFetching) {
            return <CandelicLoader />
        }

        const history = errorHistoryManager.history
        // Get any available data columns, check against either first new or recurring event
        const firstDataEvent =
            history &&
            history.length > 0 &&
            history.find(
                errorLog =>
                    errorLog.event === DiagnosticsErrorEvent.new || errorLog.event === DiagnosticsErrorEvent.recur
            )
        const dataColumnTitles = firstDataEvent ? firstDataEvent.dataColumnTitles : ['']

        let popoutDiv
        Object.entries(popouts).forEach(entry => {
            if (entry[0] === this.error.hash && entry[1].child) {
                popoutDiv = entry[1].child.document.body
            }
        })

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <div className='error-history-container'>
                <div className='error-title'>
                    {isMobile && (
                        <Button onClick={errorHistoryManager.toggleDrawer} color='link'>
                            <img className='diagnostics-back-arrow d-md-none' src={backChevron} alt='Back' />
                        </Button>
                    )}
                    <ErrorStatusIcon errorGroup={this.error.group!} />
                    <h2>{errorHistoryManager.title}</h2>
                    {!errorHistoryManager.isPopout && (
                        <div className='error-history-actions'>
                            {!isMobile && (
                                <React.Fragment>
                                    <Button
                                        className='custom-button-small mr-2'
                                        onClick={this.props.store!.errorHistoryStore.launchWindow.bind(
                                            this,
                                            this.error.hash
                                        )}
                                        color='primary'
                                    >
                                        Open in popout window
                                    </Button>
                                    <Button
                                        className='custom-button-small'
                                        onClick={errorHistoryManager.toggleDrawer}
                                        color='primary'
                                    >
                                        Back to error list
                                    </Button>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
                <div className='error-history'>
                    <ErrorLogTable
                        logTableData={history || []}
                        dataColumnTitles={dataColumnTitles}
                        hideErrorColumn
                        popoutDiv={popoutDiv}
                    />
                    {history && history.length === 0 && (
                        <div className='align-middle text-center m-3'>
                            <h2>{i18n.t('diagnosticsPage.noLogsFound')}</h2>
                        </div>
                    )}
                    {!history && (
                        <div className='align-middle text-center m-3'>
                            <h2>{i18n.t('diagnosticsPage.errorFetchingLogs')}</h2>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default ErrorHistory
