import * as React from 'react'
import { inject, observer } from 'mobx-react'

import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import ErrorLogTable from 'src/common/components/ErrorLogTable'
import { InfiniteLoader, Index } from 'react-virtualized'
import DiagnosticsErrorLog from 'src/common/models/DiagnosticsErrorLog'
import candelicLoader from 'src/assets/images/candelicLoader.gif'

interface ScreenErrorHistoryLogsProps {
    history: DiagnosticsErrorLog[]
    hasNextPage: boolean
    isNextPageLoading: boolean
    errorFetching: boolean
}

const LOADING_PLACEHOLDER_ROW_COUNT = 5

@inject('store')
@observer
class ScreenErrorHistoryLogs extends React.Component<ScreenErrorHistoryLogsProps & { store?: RootStore }> {
    isRowLoaded = ({ index }: Index) => index < this.props.history.length

    loadNextPage = (): Promise<any> => {
        if (this.props.isNextPageLoading) {
            // Ignore, already fetching
            return Promise.resolve()
        }

        // Threshold reached, load next page
        return this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager.searchHistory(true)
    }

    render() {
        const { history, hasNextPage, isNextPageLoading, errorFetching } = this.props

        // Add extra rows for loading state
        const rowCount = hasNextPage ? history.length + LOADING_PLACEHOLDER_ROW_COUNT : history.length

        return (
            <div className='error-log-wrapper'>
                {history && history.length > 0 ? (
                    <InfiniteLoader isRowLoaded={this.isRowLoaded} loadMoreRows={this.loadNextPage} rowCount={rowCount}>
                        {({ onRowsRendered, registerChild }) => (
                            <ErrorLogTable
                                onRowsRendered={onRowsRendered}
                                ref={registerChild}
                                logTableData={history}
                                rowCount={rowCount}
                                showSourceColumn
                                rowsLoading={hasNextPage}
                            />
                        )}
                    </InfiniteLoader>
                ) : errorFetching ? (
                    <div className='diagnostics-non-ideal-state'>
                        <h2>{i18n.t('diagnosticsPage.errorFetchingScreenHistoryErrors')}</h2>
                    </div>
                ) : isNextPageLoading ? (
                    <div className='diagnostics-non-ideal-state'>
                        <img src={candelicLoader} alt='Loading' height={58} />
                    </div>
                ) : (
                    <div className='diagnostics-non-ideal-state'>
                        <h2>{i18n.t('diagnosticsPage.noScreenHistoryErrors')}</h2>
                    </div>
                )}
            </div>
        )
    }
}

export default ScreenErrorHistoryLogs
