const config = {
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
        mandatorySignIn: true,
    },
}

const iot = {
    aws_pubsub_endpoint: process.env.REACT_APP_IOT_PUBSUB_ENDPOINT,
    aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
}

export { config, iot }
