import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// Mobx
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { SignInStatus } from 'src/common/models/SignInStatus'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Form, FormGroup, Label, Input, Button, InputGroup, FormFeedback, FormText } from 'reactstrap'
import Checkbox from 'src/common/components/Checkbox'

@inject('store')
@observer
class VerifyMFA extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleOnKeyPress = (event: any) => {
        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Handle "enter" or "return" key press
                this.props.store!.signInUIStore.handleConfirmSignIn()
                break
            default:
                return
        }
    }

    render() {
        if (this.props.store!.signInUIStore.signinStatus !== SignInStatus.requireMFA) {
            return null
        }

        return (
            <div className='auth-form-container'>
                <Form className='auth-form' onKeyPress={this.handleOnKeyPress} onSubmit={eventPreventDefault}>
                    <div className='auth-form-header text-center'>{i18n.t('authPage.authHeaders.requireMFA')}</div>
                    <div className='auth-form-body'>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.verificationCode')}</h6>
                            </Label>
                            <InputGroup className='custom-input'>
                                <Input
                                    autoFocus
                                    id='mfaCode'
                                    name='mfaCode'
                                    type='text'
                                    placeholder='6-digit code'
                                    pattern='[0-9]{6}'
                                    maxLength={6}
                                    autoComplete='off'
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    invalid={this.props.store!.signInUIStore.isAuthAWSError}
                                />
                                <FormFeedback>
                                    {i18n.t('awsErrors.' + this.props.store!.signInUIStore.authAWSError)}
                                </FormFeedback>
                                <FormText>{i18n.t('authPage.mfaCodeHelp')}</FormText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className='mb-4 custom-form-group custom-form-group-inline checkbox-form-group'>
                            <Label className='custom-label custom-label-inline'>
                                <h5>{i18n.t('authPage.rememberDevice')}</h5>
                            </Label>
                            <Checkbox
                                isChecked={this.props.store!.signInUIStore.rememberDevice}
                                handleOnClick={this.props.store!.signInUIStore.handleRememberDevice}
                            />
                        </FormGroup>
                        <Button
                            className='auth-form-body-button'
                            color='primary'
                            disabled={!this.props.store!.signInUIStore.isConfirmSignInFormValid}
                            onClick={() => this.props.store!.signInUIStore.handleConfirmSignIn()}
                        >
                            <h6>{i18n.t('actions.submit')}</h6>
                        </Button>
                    </div>
                </Form>
                <Button
                    className='custom-button-link'
                    color='link'
                    onClick={this.props.store!.signInUIStore.handleBackToSignIn}
                >
                    <span>{i18n.t('authPage.backToSignIn')}</span>
                </Button>
            </div>
        )
    }
}

export default withNamespaces()(VerifyMFA)
