import { observable, action, runInAction } from 'mobx'
import RootStore from '../RootStore'

export default class AppStore {
    @observable listsComplete = false

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action async populateLists() {
        const me = this.rootStore.userStore.me

        if (!me) {
            return
        }

        try {
            // Check for errors maybe? But its already should be handled in populateItemList()'s
            await Promise.all([
                me.isSuperUser ? this.rootStore.orgStore.populateItemList(false, false, undefined, true) : undefined,
                this.rootStore.locationStore.populateItemList(),
                this.rootStore.controllerStore.populateItemList(),
                this.rootStore.userStore.populateItemList(false, false, undefined, me.showDeletedItems),
                this.rootStore.screenStore.populateItemList(false, false, undefined, me.showDeletedItems),
            ])
            await this.rootStore.orgStore.setMyOrg(me.organisationId)
            runInAction('listComplete', () => {
                this.listsComplete = true
            })
        } catch (error) {
            console.error('Error populating lists', error)
        }
    }
}
