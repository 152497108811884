import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'
import i18n from 'src/i18n'

import LiveImage from 'src/common/models/LiveImage'
import { ScreenState } from 'src/common/models/ScreenState'

import { Transition } from 'react-spring/renderprops'
import Button from 'reactstrap/lib/Button'
import { Icon } from '@blueprintjs/core'
import LoadingSpinner from 'src/common/components/LoadingSpinner'
import playsIcon from '../../../assets/images/playsIcon.svg'
import resolutionIcon from '../../../assets/images/resolutionIcon.svg'
import imageBroken from '../../../assets/images/imageBroken.svg'
import LockedOverlay from 'src/common/components/LockedOverlay'
import SyncState from 'src/common/components/SyncState'
import { PlayerName } from 'src/common/models/PlayerSyncState'

@inject('store')
@observer
class LiveGrid extends React.Component<{ store?: RootStore }> {
    onLoad = (feedItem: LiveImage) => {
        this.props.store!.liveStore.updateLoadedImages(feedItem)
    }

    handleLiveImageClick = (liveImage: LiveImage) => {
        // Ignore if placeholder clicked
        if (!liveImage.plays) {
            return
        }

        this.props.store!.liveUIStore.newScreenSummary(liveImage)
    }

    handleMouseHover = (screenId?: string) => {
        this.props.store!.liveUIStore.setScreenHovered(screenId)
    }

    handleOpenPlayer = (screenId: string) => {
        this.props.store!.playerStore.launchWindow(screenId)
    }

    liveImageCard = (loadedImage: LiveImage | undefined, i: number) => {
        const loadingImages = this.props.store!.liveStore.loadingImagesForGrid
        if (!loadingImages) {
            return
        }

        const loadingImage = loadingImages[i]
        const screen = this.props.store!.screenStore.findItem(loadingImage.screenId)
        if (!screen) {
            return
        }

        let liveImage = loadedImage
        if (!liveImage) {
            liveImage = new LiveImage({
                screenId: screen.id!,
                // Prevent broken image for initial load
                URL: loadingImage && !loadingImage.URL ? imageBroken : '',
                filename: '',
            })
        }

        const zoomLevel = this.props.store!.liveUIStore.zoomLevel
        const screenPlaying = screen.state === ScreenState.started
        const screenConnected = screen.controller!.isConnected
        const actionWord = !screenConnected ? 'connected' : 'playing'

        const liveImageTransitionQueue: LiveImage[] = []
        liveImageTransitionQueue.push(liveImage)

        return (
            <div key={i} className='screen-card' style={{ width: zoomLevel + 'px', maxHeight: zoomLevel + 'px' }}>
                <div className='screen-card-name'>
                    <span>{screen.name}</span>
                </div>
                <div className='screen-card-data'>
                    <div>
                        <img src={resolutionIcon} alt='Resolution' height={15} />
                        <span className='screen-card-resolution'>{screen.resolution}</span>
                    </div>
                    <div className='screen-card-player-name'>
                        {screen.controller!.playerName === PlayerName.candelic && (
                            <SyncState size={10} controller={screen.controller} />
                        )}
                        <span>{i18n.t('livePage.playerName.' + screen.controller!.playerName)}</span>
                    </div>
                </div>
                <div
                    className='screen-card-image-container'
                    onMouseEnter={this.handleMouseHover.bind(this, screen.id)}
                    onMouseLeave={this.handleMouseHover.bind(this, undefined)}
                >
                    {!(screenPlaying && screenConnected) && (
                        <LockedOverlay style={{ borderRadius: '5px' }}>
                            <div className='screen-not-playing'>
                                <h6>Not {actionWord}</h6>
                            </div>
                        </LockedOverlay>
                    )}
                    {/* NOTE: Tallest div will decide height for row */}
                    {this.props.store!.liveUIStore.screenHovered === screen.id && (
                        <Button
                            className='player-toggle d-none d-md-flex'
                            color='primary'
                            onClick={this.handleOpenPlayer.bind(this, screen.id)}
                        >
                            <Icon icon='settings' iconSize={16} />
                        </Button>
                    )}
                    <div
                        className='screen-card-image'
                        style={{ height: zoomLevel / screen.aspectRatio + 'px' }}
                        onClick={this.handleLiveImageClick.bind(this, liveImage)}
                    >
                        <Transition
                            items={liveImageTransitionQueue}
                            keys={item => item.displayableFilename}
                            from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}
                            config={{ duration: 2000 }}
                        >
                            {/* eslint-disable-next-line react/display-name */}
                            {item => props => (
                                <div style={props} className='live-crossfade-container'>
                                    <img src={item.smallURL || item.URL} alt={item.displayableFilename} />
                                </div>
                            )}
                        </Transition>
                    </div>
                </div>
                <div className='screen-card-data'>
                    <Transition
                        items={liveImageTransitionQueue}
                        keys={item => item.displayableFilename}
                        initial={{ position: 'absolute' } as React.CSSProperties}
                        from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                        enter={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        config={{ duration: 2000 }}
                    >
                        {/* eslint-disable-next-line react/display-name */}
                        {item => props => (
                            <div style={props} className='screen-card-liveimage-info'>
                                <img src={playsIcon} alt='Play count' height={10} />
                                <span>{item.plays || 'No play data'}</span>
                                {this.props.store!.liveUIStore.zoomDetail && item.plays && (
                                    <span className='screen-card-image-name'>{item.displayableFilename}</span>
                                )}
                            </div>
                        )}
                    </Transition>
                </div>
            </div>
        )
    }

    render() {
        const liveUIStore = this.props.store!.liveUIStore
        const liveStore = this.props.store!.liveStore

        if (!liveUIStore.selectionMatchesDisplayedImages) {
            return null
        }

        const isFetching = liveStore.isFetching
        if (isFetching) {
            return <LoadingSpinner />
        }

        const loadingImages = liveStore.loadingImagesForGrid
        const loadedImages = liveStore.loadedImagesForGrid

        if (!isFetching && loadingImages.length === 0) {
            return (
                <div className='live-grid'>
                    <h2>{i18n.t('common.noResults')}</h2>
                </div>
            )
        }

        return (
            <div className='live-grid'>
                {/* Load hidden images into DOM to detect if finished downloading */}
                {loadingImages &&
                    loadingImages.map((liveImage, i) => (
                        <img
                            key={i}
                            className='d-none'
                            onLoad={this.onLoad.bind(this, liveImage)}
                            src={liveImage.smallURL || liveImage.URL}
                            alt={''}
                        />
                    ))}
                {/* Render image cards for each screen */}
                {loadedImages?.map((loadedImage, i) => this.liveImageCard(loadedImage, i))}
            </div>
        )
    }
}

export default LiveGrid
