import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import i18n from 'src/i18n'

import { getDataString } from 'src/common/helpers/ErrorDataParser'
import { capitalizeFirstLetter } from 'src/common/utils/String'
import { Colour, tagColour } from 'src/common/utils/Colour'

import Color from 'color'
import GlobalDigestScope from './GlobalDigestScope'
import CollapsibleSection from 'src/common/components/CollapsibleSection'
import DiagnosticsDigest, {
    DigestError,
    DigestErrorGroup,
    DigestWarningSummary,
    SummaryError,
} from 'src/common/models/DiagnosticsDigest'
import { Icon, Intent, Tag } from '@blueprintjs/core'
import candelicLoader from 'src/assets/images/candelicLoader.gif'
import { Button } from 'reactstrap'

export enum Outage {
    outages = 'outages',
    stoppages = 'stoppages',
}

export enum Error {
    faults = 'faults',
    critical = 'critical', // Used by digest
    criticalWarnings = 'criticalWarnings',
    warnings = 'warnings',
}

export enum Info {
    healthy = 'healthy',
}

export enum ErrorState {
    newErrors = 'newErrors',
    recurringErrors = 'recurringErrors',
}

export enum RecurringError {
    recurringFaults = 'recurringFaults',
    recurringCritical = 'recurringCritical',
    recurringWarnings = 'recurringWarnings',
}

@inject('store')
@observer
export default class DiagnosticsOverview extends React.Component<{ store?: RootStore }> {
    componentDidMount = () => {
        this.props.store!.globalDiagnosticsUIStore.getInitialDigest()
    }

    handleToggleSection = (id: string) => {
        this.props.store!.globalDiagnosticsUIStore.collapseSection(id)
    }

    handleCollapseAll = () => {
        this.props.store!.globalDiagnosticsUIStore.collapseAll()
    }

    handleExpandAll = (digest: DiagnosticsDigest) => {
        this.props.store!.globalDiagnosticsUIStore.expandAll(digest)
    }

    sectionHeader = (
        count: number,
        errorType: Error | RecurringError,
        errorState: ErrorState,
        contents: JSX.Element | JSX.Element[]
    ) => {
        const isSectionOpen = this.props.store!.globalDiagnosticsUIStore.isSectionOpen

        return (
            <CollapsibleSection
                key={errorType}
                title={
                    <span className='digest-collapsible-header'>
                        <h3>{i18n.t('diagnosticsPage.digest.' + errorType)}</h3>
                        <Tag>{count}</Tag>
                    </span>
                }
                titleStyle={{
                    backgroundColor: tagColour(errorType),
                }}
                style={{
                    border: '1px solid ' + tagColour(errorType),
                }}
                className='error-section'
                isOpen={isSectionOpen(errorState + errorType)}
                onClick={this.handleToggleSection.bind(this, errorState + errorType)}
            >
                {contents}
            </CollapsibleSection>
        )
    }

    errorSectionRenderer = (group: DigestErrorGroup, errorType: Error | RecurringError, errorState: ErrorState) => {
        const isSectionOpen = this.props.store!.globalDiagnosticsUIStore.isSectionOpen

        const contents = group.errorList.map(screenErrors => {
            const screen = screenErrors[0]
            const digestErrors = screenErrors[1]

            return (
                <div key={screen.name} className='digest-table-container'>
                    <CollapsibleSection
                        title={
                            <span className='digest-collapsible-header'>
                                <h5>{screen.name}</h5>
                                <Tag>{digestErrors.length}</Tag>
                            </span>
                        }
                        titleStyle={{
                            backgroundColor: Color(tagColour(errorType))
                                .alpha(0.625)
                                .toString(),
                        }}
                        isOpen={isSectionOpen(errorState + errorType + screen.name)}
                        onClick={this.handleToggleSection.bind(this, errorState + errorType + screen.name)}
                    >
                        <table className='digest-table'>
                            <thead>
                                <tr>
                                    <th style={{ width: 250, maxWidth: 250, flexShrink: 0 }}>
                                        {i18n.t('diagnosticsPage.digest.tableHeaders.source')}
                                    </th>
                                    <th style={{ width: 250, flexShrink: 0 }}>
                                        {i18n.t('diagnosticsPage.digest.tableHeaders.error')}
                                    </th>
                                    <th style={{ flexGrow: 1 }}>
                                        {i18n.t('diagnosticsPage.digest.tableHeaders.data')}
                                    </th>
                                    <th style={{ width: 150, maxWidth: 150, flexShrink: 0 }}>
                                        {i18n.t('diagnosticsPage.digest.tableHeaders.timestamp')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {digestErrors.map((error: DigestError, j: number) => {
                                    const dataEntries = error.data && Object.entries(error.data)
                                    return (
                                        <tr key={j}>
                                            <td style={{ width: 250, maxWidth: 250, flexShrink: 0 }}>
                                                {i18n.t('diagnosticsPage.errorSources.' + error.errorSource) +
                                                    (error.sourceName ? ': ' + error.sourceName : '')}
                                            </td>
                                            <td style={{ width: 250, maxWidth: 250, flexShrink: 0 }}>
                                                {i18n.t('diagnosticsPage.errorNames.' + error.code)}
                                            </td>
                                            <td style={{ flexGrow: 1 }}>
                                                {dataEntries &&
                                                    dataEntries.map(([k, v], l) => (
                                                        <span key={k}>
                                                            {i18n.t('diagnosticsPage.errorData.data.' + k)}:{' '}
                                                            {getDataString(k, v)}
                                                            {dataEntries.length > 1 &&
                                                                l < dataEntries.length - 1 &&
                                                                ', '}
                                                        </span>
                                                    ))}
                                            </td>
                                            <td style={{ width: 150, maxWidth: 150, flexShrink: 0 }}>
                                                {error.time.format(this.props.store!.userStore.me?.dateTimeFormat)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </CollapsibleSection>
                </div>
            )
        })

        return this.sectionHeader(group.length, errorType, errorState, contents)
    }

    warningSectionRenderer = (
        summary: DigestWarningSummary,
        errorType: Error | RecurringError,
        errorState: ErrorState
    ) => {
        const isSectionOpen = this.props.store!.globalDiagnosticsUIStore.isSectionOpen

        const contents = summary.errorList.map(screenErrors => {
            const screen = screenErrors[0]
            const summaryErrors = screenErrors[1]

            return (
                <div key={screen.name} className='digest-table-container'>
                    <CollapsibleSection
                        title={
                            <span className='digest-collapsible-header'>
                                <h5>{screen.name}</h5>
                                <Tag>{summaryErrors.length}</Tag>
                            </span>
                        }
                        titleStyle={{
                            backgroundColor: Color(tagColour(errorType))
                                .alpha(0.625)
                                .toString(),
                        }}
                        isOpen={isSectionOpen(errorState + errorType + screen.name)}
                        onClick={this.handleToggleSection.bind(this, errorState + errorType + screen.name)}
                    >
                        <table className='digest-table'>
                            <thead>
                                <tr>
                                    <th style={{ flexGrow: 1 }}>
                                        {i18n.t('diagnosticsPage.digest.tableHeaders.error')}
                                    </th>
                                    <th style={{ width: 250, flexShrink: 0 }}>
                                        {i18n.t('diagnosticsPage.digest.tableHeaders.count')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaryErrors.map((error: SummaryError, j: number) => (
                                        <tr key={j}>
                                            <td style={{ flexGrow: 1 }}>
                                                {i18n.t('diagnosticsPage.errorNames.' + error.code)}
                                            </td>
                                            <td style={{ width: 250, maxWidth: 250, flexShrink: 0 }}>{error.count}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </CollapsibleSection>
                </div>
            )
        })

        return this.sectionHeader(summary.length, errorType, errorState, contents)
    }

    render() {
        const globalDiagnosticsUIStore = this.props.store!.globalDiagnosticsUIStore
        const fetching = globalDiagnosticsUIStore.fetching
        const digest = globalDiagnosticsUIStore.digest
        if (fetching) {
            return (
                <div className='diagnostics-non-ideal-state mt-4'>
                    <img src={candelicLoader} alt='Loading' height={58} />
                </div>
            )
        } else if (!digest) {
            return (
                <div className='diagnostics-non-ideal-state'>
                    <h2>{i18n.t('diagnosticsPage.errorFetchingDigest')}</h2>
                </div>
            )
        }

        const isSectionOpen = globalDiagnosticsUIStore.isSectionOpen

        return (
            <div className='diagnostics-global-digest'>
                <GlobalDigestScope />
                <br />
                <div>
                    <h2 className='digest-title'>{i18n.t('diagnosticsPage.overview')}</h2>
                    <div className='digest-overview-tag-container'>
                        {[...Object.values(Outage), Error.critical, Error.faults, Error.warnings].map(tag => {
                            const count = digest?.[tag]?.length ?? 0
                            return (
                                <div
                                    key={tag}
                                    className='overview-tag'
                                    style={{ backgroundColor: count > 0 ? tagColour(tag) : Colour.grey }}
                                >
                                    {(count ?? '0') + ' ' + i18n.t('diagnosticsPage.digest.overviewTags.' + tag)}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <br />
                <div className='collapse-expand-all'>
                    <Button
                        className='custom-button-link custom-button-link-sm'
                        onClick={this.handleExpandAll.bind(this, digest)}
                        color='link'
                    >
                        <span>{i18n.t('diagnosticsPage.expandAll')}</span>
                    </Button>
                    <Button
                        className='custom-button-link custom-button-link-sm'
                        onClick={this.handleCollapseAll}
                        color='link'
                    >
                        <span>{i18n.t('diagnosticsPage.collapseAll')}</span>
                    </Button>
                </div>
                {Object.values(Outage).map(outageType => {
                    const outageList = digest[outageType]
                    return (
                        <React.Fragment key={outageType}>
                            <div className='digest-table-container'>
                                <CollapsibleSection
                                    title={
                                        <span className='digest-collapsible-header'>
                                            <h2>{i18n.t('diagnosticsPage.digest.' + outageType)}</h2>
                                            <Tag>{outageList.length}</Tag>
                                        </span>
                                    }
                                    isOpen={isSectionOpen(outageType)}
                                    onClick={this.handleToggleSection.bind(this, outageType)}
                                >
                                    {outageList.length < 1 ? (
                                        <div className='diagnostics-non-ideal-state'>
                                            <h2>
                                                <Icon
                                                    className='mr-2'
                                                    icon='tick-circle'
                                                    iconSize={22}
                                                    intent={Intent.SUCCESS}
                                                />
                                                {i18n.t(
                                                    'diagnosticsPage.no' + capitalizeFirstLetter(String(outageType))
                                                )}
                                            </h2>
                                        </div>
                                    ) : (
                                        <table className='digest-table'>
                                            <thead>
                                                <tr>
                                                    <th>{i18n.t('diagnosticsPage.digest.tableHeaders.screen')}</th>
                                                    <th className='time-column'>
                                                        {i18n.t('diagnosticsPage.digest.tableHeaders.start')}
                                                    </th>
                                                    <th className='time-column'>
                                                        {i18n.t('diagnosticsPage.digest.tableHeaders.end')}
                                                    </th>
                                                    <th className='time-column'>
                                                        {i18n.t('diagnosticsPage.digest.tableHeaders.duration')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {outageList?.map((outage, j: number) => (
                                                        <tr key={j} className={!outage.duration ? 'ongoing-row' : ''}>
                                                            <td>{outage.name}</td>
                                                            <td>{outage.start}</td>
                                                            <td>{outage.end}</td>
                                                            <td>{outage.duration ?? 'Ongoing...'}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    )}
                                </CollapsibleSection>
                            </div>
                        </React.Fragment>
                    )
                })}
                <div className='digest-table-container'>
                    <CollapsibleSection
                        title={
                            <span className='digest-collapsible-header'>
                                <h2>
                                    {i18n.t('diagnosticsPage.digest.new')}
                                    <Tag>{digest.newErrorsCount}</Tag>
                                </h2>
                            </span>
                        }
                        isOpen={isSectionOpen(ErrorState.newErrors)}
                        onClick={this.handleToggleSection.bind(this, ErrorState.newErrors)}
                    >
                        {digest.newFaults.length < 1 && digest.newCritical.length < 1 && digest.newWarnings.length < 1 && (
                            <div className='diagnostics-non-ideal-state'>
                                <h2>
                                    <Icon className='mr-2' icon='tick-circle' iconSize={22} intent={Intent.SUCCESS} />
                                    {i18n.t('diagnosticsPage.noNewErrors')}
                                </h2>
                            </div>
                        )}
                        {[Error.faults, Error.critical].map(errorType => {
                            let group
                            switch (errorType) {
                                case Error.faults:
                                    group = digest.newFaults
                                    break
                                case Error.critical:
                                    group = digest.newCritical
                                    break
                                default:
                                    break
                            }
                            if (!group || group.length < 1) {
                                return null
                            }
                            return this.errorSectionRenderer(group, errorType, ErrorState.newErrors)
                        })}
                        {digest.newWarnings.length > 1 &&
                            this.warningSectionRenderer(digest.newWarnings, Error.warnings, ErrorState.newErrors)}
                    </CollapsibleSection>
                </div>
                <div className='digest-table-container'>
                    <CollapsibleSection
                        title={
                            <span className='digest-collapsible-header'>
                                <h2>{i18n.t('diagnosticsPage.digest.recurring')}</h2>
                                <Tag>{digest.recurringErrorsCount}</Tag>
                            </span>
                        }
                        isOpen={isSectionOpen(ErrorState.recurringErrors)}
                        onClick={this.handleToggleSection.bind(this, ErrorState.recurringErrors)}
                    >
                        {digest.recurringFaults.length < 1 &&
                            digest.recurringCritical.length < 1 &&
                            digest.recurringWarnings.length < 1 && (
                                <div className='diagnostics-non-ideal-state'>
                                    <h2>
                                        <Icon
                                            className='mr-2'
                                            icon='tick-circle'
                                            iconSize={22}
                                            intent={Intent.SUCCESS}
                                        />
                                        {i18n.t('diagnosticsPage.noRecurringErrors')}
                                    </h2>
                                </div>
                            )}
                        {[RecurringError.recurringFaults, RecurringError.recurringCritical].map(errorType => {
                            let group
                            switch (errorType) {
                                case RecurringError.recurringFaults:
                                    group = digest.recurringFaults
                                    break
                                case RecurringError.recurringCritical:
                                    group = digest.recurringCritical
                                    break
                                default:
                                    break
                            }
                            if (!group || group.length < 1) {
                                return null
                            }
                            return this.errorSectionRenderer(group, errorType, ErrorState.recurringErrors)
                        })}
                        {digest.recurringWarnings.length > 1 &&
                            this.warningSectionRenderer(
                                digest.recurringWarnings,
                                RecurringError.recurringWarnings,
                                ErrorState.recurringErrors
                            )}
                    </CollapsibleSection>
                </div>
            </div>
        )
    }
}
