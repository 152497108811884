import React from 'react'

import i18n from 'src/i18n'

import Controller from '../models/Controller'

import syncComplete from 'src/assets/images/sync/sync-complete.svg'
import syncing from 'src/assets/images/sync/syncing.svg'
import syncError from 'src/assets/images/sync/sync-error.svg'
import syncInfo from 'src/assets/images/sync/sync-info.svg'
import syncUnknown from 'src/assets/images/sync/sync-unknown.svg'
import { PlayerSyncState } from '../models/PlayerSyncState'
import { Position, Tooltip } from '@blueprintjs/core'

interface SyncStateProps {
    size: number
    controller?: Controller
    playerDiv?: HTMLElement
}

export default function SyncState(props: SyncStateProps) {
    const { size, controller, playerDiv } = props
    const state = controller?.playerSyncState
    let iconImg
    switch (state) {
        case PlayerSyncState.inSync:
            iconImg = syncComplete
            break
        case PlayerSyncState.syncing:
            iconImg = syncing
            break
        case PlayerSyncState.outOfSync:
            iconImg = syncError
            break
        case PlayerSyncState.notPlaying:
            iconImg = syncInfo
            break
        case PlayerSyncState.unknown:
        default:
            iconImg = syncUnknown
            break
    }
    return (
        <>
            {controller?.isConnected && controller?.playerName && (
                <Tooltip
                    boundary='viewport'
                    content={
                        <>
                            <span>{i18n.t('livePage.syncState.' + state)}</span>
                            {state === PlayerSyncState.notPlaying && (
                                <span>({i18n.t('livePage.playerName.' + controller.playerName)})</span>
                            )}
                        </>
                    }
                    position={Position.TOP}
                    className='sync-state'
                    popoverClassName='sync-state-popover'
                    portalContainer={playerDiv}
                >
                    <img
                        className={state === PlayerSyncState.syncing ? 'rotate-center' : ''}
                        style={{ width: size + 'px', height: size + 'px' }}
                        src={iconImg}
                        alt='Sync state icon'
                    />
                </Tooltip>
            )}
        </>
    )
}
