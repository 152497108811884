import { action, observable, runInAction, computed } from 'mobx'
import RootStore from 'src/common/RootStore'
import liveRouter from 'src/api/liveRouter'

import { subscriptionTopics } from '../utils/String'

import LiveSnapshot, { LiveSnapshotParameters } from '../models/LiveSnapshot'
import MqttSubscription from '../models/MqttSubscription'
import { RenewCommand } from 'src/common/models/MqttEnums'
import LiveImage from '../models/LiveImage'
import { ScreenState } from '../models/ScreenState'
import LiveImageStats from '../models/LiveImageStats'

enum LiveCommands {
    liveUpdate = 'liveUpdate',
    liveScreenState = 'liveScreenState',
}

export default class LiveStore {
    @observable subscriptionKey: string
    @observable isFetching: boolean

    subscriptionTTLTimer?: NodeJS.Timeout
    expectedScreenIds = new Set<string>()

    liveRouter = liveRouter
    rootStore: RootStore

    @observable private loadingImages: LiveImage[]
    @observable private loadedImages?: Array<LiveImage | undefined>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get loadingImagesForGrid(): LiveImage[] {
        if (!this.loadingImages) {
            return []
        }

        const screenStore = this.rootStore.screenStore
        const sorted = this.loadingImages.slice()

        // Only sort if multiple screens
        if (sorted.length > 1) {
            sorted.sort((a, b): number => {
                // Sort by if screen is playing, then by connection status, then by screen name. Screens with no play data are pushed to the bottom
                const aScreen = screenStore.findItem(a.screenId)
                const bScreen = screenStore.findItem(b.screenId)
                if (aScreen && bScreen) {
                    if (a.hasProofData === b.hasProofData) {
                        if (aScreen.isConnected === bScreen.isConnected) {
                            if (aScreen.state === bScreen.state) {
                                return aScreen.name.localeCompare(bScreen.name)
                            } else {
                                return aScreen.state === ScreenState.started ? -1 : 1
                            }
                        } else {
                            return aScreen.isConnected ? -1 : 1
                        }
                    } else {
                        return a.hasProofData ? -1 : 1
                    }
                } else {
                    // Handle screens not returned from store
                    return 0
                }
            })
        }
        return sorted || []
    }

    @computed get loadedImagesForGrid(): Array<LiveImage | undefined> {
        return this.loadingImagesForGrid.map(liveImage => (liveImage.URL ? liveImage : undefined))
    }

    fetchLiveSubscription = (params: LiveSnapshotParameters): Promise<LiveSnapshot> => this.liveRouter.subscribe(params)

    screensForLive(locationIds: string[], resolutions: string[]): string[] {
        return locationIds
            .map(id => {
                const screen = this.rootStore.locationStore.findItem(id)?.controller?.screen
                if (screen && screen.id && screen.resolution && resolutions.includes(screen.resolution)) {
                    return screen.id
                }
                return undefined
            })
            .filter(screenId => !!screenId) as string[]
    }

    validateLiveResponse(expected: Set<string>, received: Set<string>): boolean {
        if (expected.size !== 0) {
            // This is the "all" case which is too tricky to handle since we don't know the expected number received
            if (expected.size !== received.size) {
                return false
            }
            const setsUnion = new Set([...expected, ...received])
            if (setsUnion.size !== received.size) {
                return false
            }
        }
        return true
    }

    fetchLiveImageStats = async (screenId: string, displayImageId: string): Promise<LiveImageStats> => this.liveRouter.fetchLiveImageStats({ screenId, displayImageId })

    @action getLiveSubscription = (locationIds: string[], resolutions: string[]) => {
        // Fetch live subscription
        if (this.subscriptionTTLTimer) {
            clearInterval(this.subscriptionTTLTimer)
            this.subscriptionTTLTimer = undefined
        }

        this.expectedScreenIds = new Set<string>(this.rootStore.liveStore.screensForLive(locationIds, resolutions))

        this.isFetching = true
        this.rootStore.liveStore
            .fetchLiveSubscription({
                locationIds,
                resolutions,
                subscribe: true,
                subscriptionKey: this.subscriptionKey,
            })
            .then(response => {
                if (
                    !this.validateLiveResponse(
                        this.expectedScreenIds,
                        new Set<string>(response.liveScreenItems?.map(item => item.screenId))
                    )
                ) {
                    return
                }

                this.populateInitialLoadingImages(
                    response.liveScreenItems?.map(payloadItem => new LiveImage(payloadItem))
                )
                const { subscriptionKey, subscriptionTTL: ttl } = response
                if (!subscriptionKey) {
                    console.error('Subscription key missing')
                    return
                }
                this.subscriptionKey = subscriptionKey

                new MqttSubscription({
                    topics: subscriptionTopics('client', subscriptionKey),
                    subscriptionKey,
                    callback: data => {
                        const command = data.value.command
                        switch (command) {
                            case LiveCommands.liveUpdate:
                                const liveImage = new LiveImage(data.value.payload)
                                this.updateLoadingImages(liveImage)
                                break
                            case LiveCommands.liveScreenState:
                                this.rootStore.controllerStore.updateScreensState(
                                    data.value.controllerId,
                                    data.value.payload
                                )
                                break
                            default:
                                break
                        }
                    },
                })
                    .setTTL(ttl)
                    .setKeepAliveCommand(RenewCommand.renewLiveSubscription)
                    .setOnStale(() => this.getLiveSubscription(locationIds, resolutions))
                    .subscribe()
            })
            .finally(() => {
                runInAction('isLiveFetchingFinished', () => {
                    this.isFetching = false
                })
            })
    }

    @action populateInitialLoadingImages = (payload?: LiveImage[]): void => {
        this.loadingImages = payload?.slice() || []
    }

    @action updateLoadingImages = (payload: LiveImage) => {
        // Find and replace liveImage by screenId
        const index = this.loadingImages.findIndex(liveImage => liveImage.screenId === payload.screenId)

        if (index > -1) {
            this.loadingImages[index] = payload
        }
    }

    @action updateLoadedImages = (feedItem: LiveImage) => {
        if (!this.loadedImages) {
            return
        }
        const index = this.loadingImages.findIndex(liveImage => liveImage.screenId === feedItem.screenId)

        if (index > -1) {
            // If screenId exists, replace item in loaded images array
            this.loadedImages[index] = feedItem
        }
    }
}
