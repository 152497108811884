import * as React from 'react'
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Screen from 'src/common/models/Screen'
import { ScreenInfoType } from '../../../../stores/ScreenDiagnosticsUIStore'

import { Transition } from 'react-spring/renderprops'
import brightnessIcon from 'src/assets/images/brightnessIcon.svg'
import controllerIcon from 'src/assets/images/controllerIcon.svg'
import tileIcon from 'src/assets/images/tileIcon.svg'
import { capitalizeFirstLetter } from 'src/common/utils/String'
import { millisecondsToString } from 'src/common/utils/Number'
import { Icon } from '@blueprintjs/core'
import { secondsDurationToString } from 'src/common/utils/Duration'

interface ScreenNameCellProps {
    name: string
    screen: Screen
}

@inject('store')
@observer
class ScreenNameCell extends React.Component<{ store?: RootStore } & ScreenNameCellProps> {
    state: { cellScreenConnected: string } = {
        cellScreenConnected: '',
    }

    constructor(props: ScreenNameCellProps) {
        super(props)
        this.state = {
            cellScreenConnected: this.props.screen.isConnected + this.props.screen.state,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: ScreenNameCellProps) => {
        // Update state if sort index changes
        if (nextProps.screen.sortIndex !== this.props.screen.sortIndex) {
            this.setState({ cellScreenConnected: nextProps.screen.isConnected + nextProps.screen.state })
        }
    }

    render() {
        const name = this.props.name
        const screen = this.props.screen
        if (!name || !screen) {
            return null
        }

        const screenInfoValue = this.props.store!.screenDiagnosticsUIStore.screenInfoValue

        const transitionItems = []

        let cellIcon: JSX.Element | null = null
        let cellText = ''
        if (!screen.isConnected) {
            cellIcon = <img src={controllerIcon} alt='Source' />
            cellText = String(screen.controller?.version) || ''
        } else {
            switch (screenInfoValue) {
                case ScreenInfoType.brightness:
                    cellIcon = <img src={brightnessIcon} alt='Source' />
                    cellText = String(screen.brightness)
                    break
                case ScreenInfoType.version:
                    cellIcon = <img src={controllerIcon} alt='Source' />
                    cellText = screen.controller?.[screenInfoValue] || 'Unknown'
                    break
                case ScreenInfoType.daemon:
                    cellIcon = <img src={controllerIcon} alt='Source' />
                    cellText = screen.controller?.daemonStatus || ''
                    break
                case ScreenInfoType.firmwareVersion:
                    cellIcon = <img src={tileIcon} alt='Source' />
                    cellText = screen.controller?.firmwareRange || i18n.t('diagnosticsPage.upgradeLednet')
                    break
                case ScreenInfoType.uptime:
                    cellIcon = <img src={controllerIcon} alt='Source' />
                    cellText = secondsDurationToString(screen.controller?.uptime || 0)
                    break
                case ScreenInfoType.restart:
                    cellIcon = <img src={controllerIcon} alt='Source' />
                    cellText = capitalizeFirstLetter(String(screen.controller?.[screenInfoValue]))
                    break
                case ScreenInfoType.jitter:
                case ScreenInfoType.hardwareVsync:
                case ScreenInfoType.threadVsync:
                case ScreenInfoType.threadEngine:
                case ScreenInfoType.threadQueue:
                    cellIcon = <Icon icon='time' iconSize={15} />
                    cellText = millisecondsToString(screen.controller?.[screenInfoValue] || 0)
                    break
                case ScreenInfoType.vsyncDelays:
                case ScreenInfoType.vsyncDrops:
                    cellIcon = <Icon icon='time' iconSize={15} />
                    cellText = String(screen.controller?.[screenInfoValue])
                    break
                default:
                    break
            }
        }

        transitionItems.push({ cellIcon, cellText })

        return (
            <div className='diagnostics-overview-screen-heading'>
                <h6 className='ellipsis'>{name}</h6>
                <Transition
                    items={transitionItems}
                    keys={
                        screen.isConnected + screen.state !== this.state.cellScreenConnected ? screen.state : undefined
                    }
                    initial={{ position: 'absolute' } as React.CSSProperties}
                    from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                    config={{ duration: 1000 }}
                >
                    {/* eslint-disable-next-line react/display-name */}
                    {item => props => (
                        <div style={props} className='screen-subheading'>
                            <div className='screen-subheading-icon'>{item.cellIcon}</div>
                            <h6>{item.cellText}</h6>
                        </div>
                    )}
                </Transition>
            </div>
        )
    }
}

export default ScreenNameCell
