import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import Organisation from './Organisation'
import moment from 'moment'
import DisplayImageStats from './DisplayImageStats'
import i18n from 'src/i18n'

export enum DisplayImageStatus {
    normal = 'normal',
    unnamed = 'unnamed',
    candelic = 'candelic',
    black = 'black',
}

class DisplayImage extends DisplayImageStats {
    static newDisplayImage(): DisplayImage {
        return new DisplayImage({
            fileName: '',
            URL: '',
            thumbnailURL: '',
            resolution: '',
            hash: '',
            status: DisplayImageStatus.normal,
            totalTime: 0,
            averageTime: 0,
            totalPlays: 0,
            totalScreens: 0,
            firstPlayed: moment(),
            lastPlayed: moment(),
            organisation: Organisation.newOrg(),
        })
    }

    @observable fileName: string
    @observable URL: string
    @observable thumbnailURL: string
    @observable resolution: string
    @observable hash: string
    @observable organisation?: Organisation
    @observable status: DisplayImageStatus

    constructor(json: DisplayImageJSON) {
        super(json)
        this.fileName = json.fileName
        this.URL = json.URL
        this.thumbnailURL = json.thumbnailURL !== '' ? json.thumbnailURL : json.URL
        this.resolution = json.resolution
        this.hash = json.hash
        this.status = json.status ?? DisplayImageStatus.unnamed
        if (json.organisation) {
            this.organisation = new Organisation(json.organisation)
        }
    }

    @computed get hasCompletedDataFields(): boolean {
        return true
    }

    @computed get width(): number {
        const split = this.resolution!.split('x', 2)
        return Number(split[0])
    }

    @computed get height(): number {
        const split = this.resolution!.split('x', 2)
        return Number(split[1])
    }

    @computed get aspectRatio(): number {
        return this.width / this.height
    }

    @computed get hasError(): boolean {
        return this.status === DisplayImageStatus.unnamed || !this.URL
    }

    @computed get displayableFilename(): string {
        if (!this.fileName) {
            return ''
        }

        return this.status === DisplayImageStatus.normal
            ? this.fileName
            : i18n.t('reportsPage.unnamedImage') + '(' + this.fileName + ')'
    }

    @computed get unnamed(): boolean {
        return this.status === DisplayImageStatus.unnamed
    }

    toJSON(includeDates?: boolean): DisplayImageJSON {
        const json = super.toJSON(includeDates) as DisplayImageJSON
        json.fileName = this.fileName
        json.URL = this.URL
        json.thumbnailURL = this.thumbnailURL
        json.resolution = this.resolution
        json.hash = this.hash
        json.status = this.status
        if (this.organisation) {
            json.organisation = this.organisation.toJSON() as Organisation
        }
        return json
    }
}

export type DisplayImageJSON = Omit<
    DisplayImage,
    | 'toJSON'
    | 'isValid'
    | 'hasCompletedDataFields'
    | 'width'
    | 'height'
    | 'aspectRatio'
    | 'hasError'
    | 'displayableFilename'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
    | 'unnamed'
>

export interface DisplayImageSearchParams {
    unnamed?: boolean
    resolutions?: string[]
    startDate?: moment.Moment
    stopDate?: moment.Moment
    searchTerm?: string
    screenIds?: string[]
}

export interface DisplayImageSummaryParams {
    organisationId?: string
    startDate?: moment.Moment
    stopDate?: moment.Moment
    screenIds?: string[]
    displayImageIds?: string[]
}

export interface DisplayImageHashParams {
    organisationId?: string
    startDate?: moment.Moment
    stopDate?: moment.Moment
    hashes?: string[]
}

export interface ResolutionSearchParams {
    screenIds?: string[]
}

export default DisplayImage
