import { observable, runInAction, action } from 'mobx'
import RootStore from '../RootStore'

import i18n from 'src/i18n'
import { subscriptionTopics } from '../utils/String'

import diagnosticsRouter from 'src/api/diagnosticsRouter'
import errorLogRouter from 'src/api/errorLogRouter'

import { ErrorSeverity, DiagnosticsUpdateCommand } from '../models/DiagnosticErrorEnums'
import DiagnosticsError from '../models/DiagnosticsError'
import DiagnosticsErrorGroup from '../models/DiagnosticsErrorGroup'
import DiagnosticsErrorContainer from '../models/DiagnosticsErrorContainer'
import DiagnosticsErrorLog from '../models/DiagnosticsErrorLog'
import MqttSubscription from '../models/MqttSubscription'
import { RenewCommand } from '../models/MqttEnums'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'

export default class DiagnosticsStore {
    @observable locations: string[]

    @observable errorsContainer: DiagnosticsErrorContainer

    @observable isErrorsFetching = false

    @observable errorAssigningSet = new Set<DiagnosticsError>()

    subscriptionTTLTimer: NodeJS.Timeout | undefined

    rootStore: RootStore
    router = diagnosticsRouter

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action refresh = () => {
        const selectedOrg = this.rootStore.screenDiagnosticsUIStore.selectedOrg
        if (!selectedOrg) {
            return
        }
        this.fetchErrors({ organisations: [selectedOrg.id!] })
    }

    @action fetchErrors = async (filterParams: { organisations?: string[]; minSeverity?: ErrorSeverity }) => {
        this.isErrorsFetching = true
        return diagnosticsRouter
            .fetchErrors(
                filterParams && filterParams.organisations && filterParams.organisations.length > 0
                    ? filterParams.organisations
                    : [],
                filterParams && filterParams.minSeverity ? filterParams.minSeverity : ErrorSeverity.noError,
                this.errorsContainer?.subscriptionKey
            )
            .then(container => {
                runInAction('fetchErrors', () => {
                    this.errorsContainer = container
                    this.updateScreenStates()
                    // Fetch live subscription
                    this.getDiagnosticsSubscription()

                    this.isErrorsFetching = false
                })
            })
    }

    @action fetchErrorHistory = async (error: DiagnosticsError): Promise<DiagnosticsErrorLog[]> =>
        errorLogRouter.fetchErrorHistory(error)

    @action updateScreenStates = () => {
        this.errorsContainer.diagnosticsErrors.forEach((value, key) => {
            const screen = this.rootStore.screenStore.findItem(key)
            if (screen) {
                screen.state = value.state
                if (screen.controller) {
                    screen.controller.isConnected = value.isConnected
                }
            }
        })
    }

    @action getDiagnosticsSubscription = () => {
        // Fetch live subscription
        if (this.subscriptionTTLTimer) {
            clearInterval(this.subscriptionTTLTimer)
            this.subscriptionTTLTimer = undefined
        }

        if (!this.errorsContainer || !this.errorsContainer.subscriptionKey) {
            return
        }

        const { subscriptionKey, subscriptionTTL: ttl } = this.errorsContainer

        new MqttSubscription({
            topics: subscriptionTopics('client', subscriptionKey),
            subscriptionKey,
            callback: data => {
                switch (data.value.command) {
                    case DiagnosticsUpdateCommand.newErrors:
                        this.addErrors(new DiagnosticsErrorContainer(data.value.payload))
                        break
                    case DiagnosticsUpdateCommand.resolvedErrors:
                        this.removeErrors(data.value.controllerId, data.value.payload)
                        break
                    case DiagnosticsUpdateCommand.screenState:
                        this.rootStore.controllerStore.updateScreensState(data.value.controllerId, data.value.payload)
                        break
                    default:
                        break
                }
            },
        })
            .setTTL(ttl)
            .setKeepAliveCommand(RenewCommand.renewDiagnosticsSubscription)
            .setOnStale(() => this.refresh())
            .subscribe()
    }

    @action addErrors(container: DiagnosticsErrorContainer) {
        container.diagnosticsErrors.forEach((screenErrorGroup, screenId) => {
            const existingScreenGroup = this.errorsContainer.diagnosticsErrors.get(screenId)
            if (existingScreenGroup) {
                screenErrorGroup.errors.forEach((errorGroup, code) => {
                    const existingDiagnosticsGroup = existingScreenGroup.errors.get(code)
                    if (existingDiagnosticsGroup) {
                        existingDiagnosticsGroup.errorList.push(...errorGroup.errorList)
                    } else {
                        existingScreenGroup.errors.set(code, errorGroup)
                    }
                })
            } else {
                this.errorsContainer.diagnosticsErrors.set(screenId, screenErrorGroup)
            }
        })
    }

    @action removeErrors(controllerId: string, hashes: string[]) {
        const controller = this.rootStore.controllerStore.findItem(controllerId)
        if (!controller) {
            return
        }
        hashes.forEach(hash => {
            const code = hash.split(':')[0]
            if (!controller.screen?.errorGroup) {
                return
            }
            const group = controller.screen.errorGroup.errors.get(code)
            if (!group) {
                return
            }
            group.removeHash(hash)
            if (group.errorList.length === 0) {
                // Remove empty lists when entire error group is resolved
                controller.screen.errorGroup.errors.delete(code)
            }
        })
    }

    @action assignDiagnosticsError(error: DiagnosticsError, setAssigned: boolean) {
        if (!error.id) {
            return
        }

        this.errorAssigningSet.add(error)

        diagnosticsRouter
            .assignDiagnosticsError(error.id, setAssigned)
            .then(() => {
                runInAction('updateError', () => {
                    error.isAssigned = setAssigned
                })
            })
            .catch(err => {
                console.error(err)
                AppToaster.show({
                    icon: 'warning-sign',
                    message: i18n.t('feedback.errors.errorAssigningDiagnosticsError'),
                    intent: Intent.DANGER,
                })
            })
            .finally(() => {
                this.errorAssigningSet.delete(error)
            })
    }

    @action assignDiagnosticsErrorGroup(errorGroup: DiagnosticsErrorGroup) {
        const errorList = errorGroup.errorList.filter(error => !error.isAssigned)

        for (const error of errorList) {
            this.errorAssigningSet.add(error)
        }

        diagnosticsRouter
            .assignDiagnosticsErrorGroup(errorList.map(error => error.id!))
            .then(() => {
                runInAction('updateError', () => {
                    for (const error of errorList) {
                        error.isAssigned = true
                    }
                })
            })
            .catch(err => {
                console.error(err)
                AppToaster.show({
                    icon: 'warning-sign',
                    message: i18n.t('feedback.errors.errorAssigningDiagnosticsErrorGroup'),
                    intent: Intent.DANGER,
                })
            })
            .finally(() => {
                for (const error of errorList) {
                    this.errorAssigningSet.delete(error)
                }
            })
    }
}
