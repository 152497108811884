import * as React from 'react'

// i18n
import i18n from 'src/i18n'
// import { withNamespaces, WithNamespaces } from 'react-i18next'

import { Position, Tooltip } from '@blueprintjs/core'

import { Outage, Error, Info } from '../../modules/diagnostics/global/submodules/digest/GlobalDigest'
import { Colour, tagColour } from '../utils/Colour'
import ScreenStatusesContainer from '../models/ScreenStatusesContainer'

interface OverviewTagsProps {
    statuses?: ScreenStatusesContainer
    fullTags?: boolean
    showWarnings?: boolean
    showNonErrors?: boolean
    usePortal?: boolean
}

class OverviewTags extends React.Component<OverviewTagsProps> {
    render() {
        const { statuses, fullTags, showWarnings, showNonErrors, usePortal } = this.props

        return (
            <div className='overview-tag-container'>
                {[
                    ...Object.values(Outage),
                    Error.faults,
                    ...(showWarnings ? [Error.criticalWarnings, Error.warnings] : []),
                    ...(showNonErrors ? Object.values(Info) : []),
                ].map(tag => {
                    const count = tag === Info.healthy ? statuses?.noErrorCount : statuses?.[tag] ?? 0
                    if (count === 0 && !Object.values(Info).includes(Info[tag])) {
                        return null
                    }
                    const countString = count === 1 ? 'singular.' : 'plural.'
                    const tooltipContent = count + ' ' + i18n.t('global.orgScreenOverviewTags.' + countString + tag)
                    return !fullTags ? (
                        <Tooltip
                            key={tag}
                            boundary='viewport'
                            content={tooltipContent}
                            position={Position.TOP_LEFT}
                            usePortal={usePortal ?? false}
                        >
                            <div
                                className='overview-tag'
                                style={{
                                    backgroundColor:
                                        count > 0 || Object.values(Info).includes(Info[tag])
                                            ? tagColour(tag)
                                            : Colour.grey,
                                }}
                            >
                                {count}
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            key={tag}
                            boundary='viewport'
                            content={tooltipContent}
                            position={Position.TOP_LEFT}
                            usePortal={usePortal ?? false}
                        >
                            <div
                                className='overview-tag'
                                style={{
                                    backgroundColor:
                                        count > 0 || Object.values(Info).includes(Info[tag])
                                            ? tagColour(tag)
                                            : Colour.grey,
                                }}
                            >
                                {count + ' ' + i18n.t('global.orgScreenShortTags.' + countString + tag)}
                            </div>
                        </Tooltip>
                    )
                })}
            </div>
        )
    }
}

export default OverviewTags
