import DiagnosticsErrorContainer, { DiagnosticsErrorContainerJSON } from 'src/common/models/DiagnosticsErrorContainer'
import { ErrorSeverity } from 'src/common/models/DiagnosticErrorEnums'
import onAPI from './onAPI'
import moment from 'moment'
import DiagnosticsDigest from 'src/common/models/DiagnosticsDigest'

class DiagnosticsRouter {
    protected route = '/diagnostics-error'

    async fetchErrors(
        organisationIds: string[],
        minSeverity: ErrorSeverity,
        subscriptionKey?: string
    ): Promise<DiagnosticsErrorContainer> {
        const json: any = await onAPI.fetchJSON(this.route + 's', {
            organisationIds,
            minSeverity,
            subscribe: true,
            subscriptionKey,
        })
        return this.newItem(json)
    }

    async fetchDigest(orgId: string, dateRange: [moment.Moment, moment.Moment]): Promise<DiagnosticsDigest> {
        const json: any = await onAPI.fetchJSONWithQueryString('/digest', {
            startTime: dateRange[0].toISOString(),
            endTime: dateRange[1].toISOString(),
            organisationId: orgId,
        })
        return new DiagnosticsDigest(json)
    }

    async assignDiagnosticsError(errorId: string, setAssigned: boolean): Promise<any> {
        const json: any = await onAPI.fetchJSON(this.route + '/' + errorId + '/assigned', { assigned: setAssigned })
        return json
    }

    async assignDiagnosticsErrorGroup(diagnosticsErrorIds: string[]): Promise<any> {
        const json: any = await onAPI.fetchJSON(this.route + 's/assign-all', { diagnosticsErrorIds })
        return json
    }

    protected newItem(json: DiagnosticsErrorContainerJSON): DiagnosticsErrorContainer {
        return new DiagnosticsErrorContainer(json)
    }
}

const diagnosticsRouter = new DiagnosticsRouter()
export default diagnosticsRouter
