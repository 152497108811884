import RootStore from 'src/common/RootStore'
import { action, computed } from 'mobx'

import locationRouter from 'src/api/locationRouter'

import DomainStore from './DomainStore'
import Location from '../models/Location'
import Screen from '../models/Screen'

import { ValueLabelPair } from '../components/SelectComponents'
import { OptionsType, GroupedOptionsType } from 'react-select'

export default class LocationStore extends DomainStore<Location> {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        super(rootStore)
        this.rootStore = rootStore
        this.router = locationRouter
        this.storeName = 'Location'
    }

    @computed get availableAssociatedLocationsGroupedByOrg():
        | OptionsType<ValueLabelPair>
        | GroupedOptionsType<ValueLabelPair>
        | undefined {
        return this.rootStore.orgStore.availableAssociatedByOrganisations?.map(organisation => ({
                label: organisation.name,
                options: organisation.locations
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(location => ({ value: location.id!, label: location.name })),
            }))
    }

    @action deleteItems(itemsToDelete: Location[]) {
        for (const item of itemsToDelete) {
            if (item.controller) {
                this.rootStore.controllerStore.deleteItems([item.controller])
            }
        }
        super.deleteItems(itemsToDelete)
    }

    screensForLocations(locationIds: string[]): Screen[] {
        return locationIds
            .map(id => this.rootStore.locationStore.findItem(id)?.controller?.screen)
            .filter(screenId => !!screenId) as Screen[]
    }
}
