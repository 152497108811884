import * as React from 'react'
import { inject, observer } from 'mobx-react'

import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import { AdminTabName } from '../container'
import { AdminBatchAction } from 'src/common/stores/AdminStore'

import { Button, Input, FormGroup, Label, Form } from 'reactstrap'
import Select from 'react-select'
import {
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    ValueLabelPair,
} from '../../../common/components/SelectComponents'
import searchIcon from '../../../assets/images/searchIcon.svg'
import addIcon from '../../../assets/images/addIcon.svg'
import refreshIcon from '../../../assets/images/refreshIcon.svg'
import clearInput from '../../../assets/images/clearInput.svg'
import Checkbox from 'src/common/components/Checkbox'

interface AdminTabProps {
    tabName: AdminTabName
}

@inject('store')
@observer
class AdminTab extends React.Component<AdminTabProps & { store?: RootStore } & WithNamespaces> {
    handleSearchQueryChange = (event: any) => {
        this.props.store!.adminUIStore.updateSearchQuery(event.target.value)
    }

    handleClearSelectedItems = () => {
        this.props.store!.adminUIStore.clearSelectedRowIds()
    }

    handleChangeActionValue = (option: ValueLabelPair) => {
        this.props.store!.adminUIStore.updateBatchActionOption(option.value)
    }

    handleSubmitActionValue = (batchAction: string) => {
        this.props.store!.adminUIStore.submitBatchAction(batchAction)
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }

        const tabName = this.props.tabName
        const adminUIStore = this.props.store!.adminUIStore
        const lednetVersionOptions = adminUIStore.lednetVersionOptions

        let action: AdminBatchAction | undefined
        let dropdownLabel = ''
        let dropdownOptions: ValueLabelPair[] = []
        switch (tabName) {
            case AdminTabName.screens:
                action = AdminBatchAction.lednetVersion
                dropdownLabel = 'LEDNet version'
                dropdownOptions = lednetVersionOptions
                break
            default:
                break
        }

        const isItemsSelected = adminUIStore.selectedItemIds && adminUIStore.selectedItemIds.size > 0

        return (
            <>
                <Form className='admin-table-banner'>
                    <FormGroup className='custom-form-group custom-form-group-inline custom-search custom-search-large admin-search'>
                        <img src={searchIcon} alt='Search' className='custom-search-icon' />
                        <Input
                            onChange={this.handleSearchQueryChange}
                            type='search'
                            placeholder={i18n.t('actions.search')}
                            autoComplete='off'
                            value={adminUIStore.searchQueryValue || ''}
                        />
                    </FormGroup>
                    {me.showDeletedItems && (
                        <FormGroup className='custom-form-group custom-form-group-inline checkbox-form-group show-deleted'>
                            <Label className='custom-label custom-label-inline'>
                                <h5>Show deleted</h5>
                            </Label>
                            <Checkbox
                                isChecked={adminUIStore.showDeleted}
                                handleOnClick={adminUIStore.toggleShowDeleted}
                            />
                        </FormGroup>
                    )}
                    <div className='admin-action-buttons d-none d-md-block'>
                        {adminUIStore.selectedOrg?.canEdit && (
                            <Button
                                className='custom-button-large mr-3'
                                onClick={adminUIStore.toggleAdminPanelStack}
                                color='primary'
                            >
                                {i18n.t('common.new')} {i18n.t('adminPage.adminItems.' + tabName)}
                            </Button>
                        )}
                        <Button className='custom-button-large' onClick={adminUIStore.handleRefresh} color='primary'>
                            {i18n.t('actions.refresh')} <img src={refreshIcon} alt='Refresh' height={15} />
                        </Button>
                    </div>
                    <div className='admin-action-buttons d-md-none'>
                        {adminUIStore.selectedOrg?.canEdit && (
                            <Button
                                className='custom-button-small mr-3'
                                onClick={adminUIStore.toggleAdminPanelStack}
                                color='primary'
                            >
                                <img src={addIcon} alt='Add' height={15} />
                            </Button>
                        )}
                        <Button className='custom-button-small' onClick={adminUIStore.handleRefresh} color='primary'>
                            <img src={refreshIcon} alt='Refresh' height={15} />
                        </Button>
                    </div>
                </Form>
                {dropdownLabel && isItemsSelected && (
                    <Form className='admin-table-banner admin-table-banner-actions'>
                        <div>
                            <FormGroup className='custom-form-group custom-form-group-inline batch-action-form-group'>
                                <Label className='custom-label'>
                                    <h6>
                                        {adminUIStore.selectedItemIds.size} item
                                        {adminUIStore.selectedItemIds.size !== 1 ? 's' : ''} selected
                                    </h6>
                                </Label>
                                <Button
                                    onClick={this.handleClearSelectedItems}
                                    className='custom-button-link clear-selected-button'
                                    color='link'
                                >
                                    <img src={clearInput} alt='Clear' />
                                </Button>
                            </FormGroup>
                            <FormGroup className='custom-form-group custom-form-group-inline batch-action-form-group version-select'>
                                <Label className='custom-label'>
                                    <h6>{dropdownLabel}</h6>
                                </Label>
                                <Select
                                    className='custom-select-wrapper'
                                    classNamePrefix='custom-select'
                                    onChange={this.handleChangeActionValue}
                                    isSearchable={false}
                                    options={dropdownOptions}
                                    placeholder={i18n.t('common.pleaseSelect')}
                                    defaultValue={dropdownOptions[0]}
                                    components={{
                                        DropdownIndicator,
                                        Option,
                                        NoOptionsMessage,
                                    }}
                                    menuPlacement='auto'
                                />
                                <Button
                                    onClick={this.handleSubmitActionValue.bind(this, action)}
                                    className='custom-button-small'
                                    color='primary'
                                    disabled={!!!adminUIStore.batchActionValue}
                                >
                                    {i18n.t('actions.update')}
                                </Button>
                            </FormGroup>
                        </div>
                    </Form>
                )}
            </>
        )
    }
}

export default withNamespaces()(AdminTab)
