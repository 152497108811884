import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { autorun } from 'mobx'
import RootStore from 'src/common/RootStore'

import ConsoleManager from 'src/common/managers/ConsoleManager'

import { basicShortcuts, infoShortcuts, inputShortcuts, resetShortcuts, Shortcut } from 'src/fixtures/console-shortcuts'
import { svgTags } from 'src/fixtures/sanitized-html'

import {
    Input,
    Button,
    InputGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledButtonDropdown,
} from 'reactstrap'
import Scrollbars from 'react-custom-scrollbars'
import SanitizedHTML from 'react-sanitized-html'
import closeIcon from 'src/assets/images/closeIcon.svg'
import questionMarkIcon from 'src/assets/images/questionMarkIcon.svg'
import { AppToaster } from 'src/common/components/AppToaster'
import { Alert, Icon, Intent } from '@blueprintjs/core'
import LoadingSpinner from 'src/common/components/LoadingSpinner'
import CommandShortcut from './components/CommandShortcut'

interface ConsoleProps {
    controllerId: string
}

@inject('store')
@observer
class Console extends React.Component<ConsoleProps & { store?: RootStore }> {
    private consoleManager?: ConsoleManager
    private scrollbarsRef = React.createRef<Scrollbars>()

    constructor(props: any) {
        super(props)
        this.consoleManager = this.props.store!.consoleStore.managerMap.get(this.props.controllerId)

        // React to messageList updates
        autorun(() => {
            if (!this.consoleManager) {
                return
            }
            // Scroll the console to bottom when new messages are received
            if (this.consoleManager.messageList && this.scrollbarsRef.current) {
                // Wait for message to finish rendering, then scroll to bottom
                setTimeout(() => this.scrollbarsRef.current!.scrollToBottom(), 0)
            }
        })
    }

    handleCommandOnChange = (event: any) => {
        if (!this.consoleManager) {
            return
        }

        this.consoleManager.updateRemoteCommand(event.target.value)
    }

    handleCommandOnKeyPress = (event: any) => {
        if (!this.consoleManager) {
            return
        }

        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Handle "enter" or "return" key press
                this.consoleManager.handleCommandPublish()
                break
            default:
                return
        }
    }

    handleCommandOnKeyDown = (event: any) => {
        if (!this.consoleManager) {
            return
        }

        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                // Prevent up/down arrow key navigation from setting the cursor position
                event.preventDefault()
                // Handle up/down arrow key press
                this.consoleManager.handleCommandHistory(event.key)
                break
            default:
                return
        }
    }

    handleQuickCommand = (command: string) => {
        if (!this.consoleManager) {
            return
        }

        this.consoleManager.updateRemoteCommand(command)
        this.consoleManager.handleCommandPublish()
        this.consoleManager.handleFocusCommandInput()
    }

    launchConsoleDocs = (event: any) => {
        if (!this.consoleManager) {
            return
        }

        const docsWindow = window.open(process.env.REACT_APP_LEDNET_CONSOLE_DOCS, '_blank')
        if (
            !docsWindow ||
            docsWindow.closed ||
            typeof docsWindow === 'undefined' ||
            typeof docsWindow.closed === 'undefined'
        ) {
            // Show a toast on the main window
            AppToaster.show({
                icon: 'duplicate',
                message: 'Popout was blocked. Please check your browser settings and try again',
                intent: Intent.DANGER,
            })
        }
    }

    render() {
        if (!this.consoleManager || !this.consoleManager.selectedController) {
            return null
        }

        const allShortcuts = [basicShortcuts, infoShortcuts, inputShortcuts, resetShortcuts] as Shortcut[][]

        return (
            <>
                <div className='console-container'>
                    {/* Show connected controller */}
                    <div className='console-title'>
                        {this.consoleManager.isDrawerOpen && (
                            <Button
                                className='custom-button-link custom-button-link-sm'
                                onClick={this.consoleManager.toggleDrawer}
                                color='link'
                            >
                                <img src={closeIcon} alt='Close' height={15} />
                            </Button>
                        )}
                        <h2>{this.consoleManager.selectedController.screen?.name || 'Unknown'}</h2>
                        <Button onClick={this.launchConsoleDocs} className='console-help-icon' color='primary'>
                            <img src={questionMarkIcon} alt='Help' height={16} />
                        </Button>
                    </div>
                    {/* Show received messages */}
                    <pre className='console-messages'>
                        <Scrollbars ref={this.scrollbarsRef}>
                            <div>
                                {this.consoleManager.messageList.map((message, i) => (
                                    <div className={message.className()} key={i}>
                                        {/* Note: This library doesn't currently support the allowedStyles prop from the underlying sanitize-html library */}
                                        {/* Allowing style attributes on all elements for now as there doesn't appear to be a real security risk */}
                                        <SanitizedHTML
                                            allowedTags={[
                                                ...svgTags,
                                                'h1',
                                                'h2',
                                                'h3',
                                                'h4',
                                                'h5',
                                                'h6',
                                                'blockquote',
                                                'p',
                                                'a',
                                                'span',
                                                'ul',
                                                'ol',
                                                'nl',
                                                'li',
                                                'b',
                                                'i',
                                                'strong',
                                                'em',
                                                'strike',
                                                'code',
                                                'hr',
                                                'br',
                                                'div',
                                                'table',
                                                'thead',
                                                'caption',
                                                'tbody',
                                                'tr',
                                                'th',
                                                'td',
                                                'pre',
                                                'img',
                                            ]}
                                            allowedAttributes={{
                                                '*': ['style'],
                                                img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
                                                svg: ['width', 'height', 'xmlns'],
                                                path: ['d', 'stroke', 'stroke-width', 'fill'],
                                                line: ['x*', 'y*', 'style'],
                                                text: [
                                                    'transform',
                                                    'style',
                                                    'x',
                                                    'y',
                                                    'fill',
                                                    'font-size',
                                                    'font-weight',
                                                    'font-family',
                                                ],
                                                linearGradient: ['id', 'x*', 'y*'],
                                                stop: ['offset', 'stop-color'],
                                            }}
                                            allowedSchemes={['https']}
                                            allowedSchemesByTag={{
                                                img: ['data'],
                                            }}
                                            html={message.text}
                                            parser={{
                                                lowerCaseTags: false,
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Scrollbars>
                    </pre>
                    {/* Input for sending console commands */}
                    <div className={'console-commands' + (this.consoleManager.isDrawerOpen ? ' is-drawer' : '')}>
                        <InputGroup>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle disabled={!this.consoleManager.connectionEstablished}>
                                    <Icon icon='lightning' size={14} />
                                </DropdownToggle>
                                <DropdownMenu className='custom-dropdown-menu'>
                                    {allShortcuts.map((shortcuts, i) => (
                                        <div key={i}>
                                            {shortcuts.map((shortcut, j) => (
                                                <CommandShortcut
                                                    key={i + '-' + j}
                                                    shortcut={shortcut}
                                                    handleCommand={this.handleQuickCommand}
                                                />
                                            ))}
                                            {i !== allShortcuts.length - 1 && <DropdownItem divider />}
                                        </div>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            <Input
                                id={this.consoleManager.controllerId + '-console-input'}
                                autoFocus
                                className='console-input'
                                placeholder={
                                    this.consoleManager.connectionEstablished ? 'Enter command' : 'Connecting...'
                                }
                                autoComplete='off'
                                autoCorrect='off'
                                autoCapitalize='none'
                                value={this.consoleManager.prevCommand || this.consoleManager.remoteCommand}
                                onChange={this.handleCommandOnChange}
                                onKeyPress={this.handleCommandOnKeyPress}
                                onKeyDown={this.handleCommandOnKeyDown}
                                disabled={!this.consoleManager.connectionEstablished}
                            />
                        </InputGroup>
                        <Button
                            className='ml-3'
                            onClick={this.consoleManager.handleCommandPublish}
                            color='primary'
                            disabled={!this.consoleManager.connectionEstablished}
                        >
                            Send
                        </Button>
                    </div>
                </div>
                {/* Connection dropped overlay */}
                <Alert
                    isOpen={this.consoleManager.connectionDropped}
                    className='custom-alert custom-alert-full bp3-dark'
                    intent={Intent.DANGER}
                    confirmButtonText='Close console'
                    onConfirm={this.consoleManager.removeManager}
                    // @ts-expect-error this is a valid prop
                    usePortal={false}
                >
                    <h1>Lost connection</h1>
                    <h4>Waiting for screen to reconnect...</h4>
                    <div>
                        <LoadingSpinner />
                    </div>
                </Alert>
            </>
        )
    }
}

export default Console
